import { maxLength, required, email } from 'react-admin';
import portugueseMessages from '../../../../config/portuguese-messages';
import { DROP_OFF_AMOUNT_MAX_VALUE } from './constants';

const validateTime = (_, allValues) => {
  const time = allValues?.lastMileCompanyInfo?.pickupWorkingHours;

  if (!time) return '';

  const { closeAt, openAt } = time;
  return closeAt <= openAt ? 'Insira um horário final maior que o inicial' : '';
};

const validateTimeInput = {
  openAt: [validateTime],
  closeAt: [validateTime]
};

const validateLastMileCompanyInputs = {
  name: [required(), maxLength(255)],
  companyType: [required()],
  cnpj: [required()],
  financialEmail: [required(), email(portugueseMessages.ra.validation.email)],
  corporateName: [required(), maxLength(255)]
};

const validateDropOffAmount = value => {
  if (value > DROP_OFF_AMOUNT_MAX_VALUE) {
    const formattedValue = DROP_OFF_AMOUNT_MAX_VALUE.replace('.', ',');

    return `O valor não deve exceder R$ ${formattedValue}`;
  }
  return null;
};

export {
  validateTime,
  validateTimeInput,
  validateLastMileCompanyInputs,
  validateDropOffAmount
};
