import React from 'react';
import PropTypes from 'prop-types';
import { useNotify } from 'react-admin';
import { useForm, useField } from 'react-final-form';
import debounce from 'lodash/debounce';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Api from '../../../api/postal-code-api';
import { TEXTS, ERRORS, LABELS } from './constants';
import styles from './styles';
import serviceTypeLabels from '../../pages/_react-admin/service-type/labels';
import { isServiceTypeRequest } from '../../../api/api-utils';

const PostalCodeGroupAutocomplete = props => {
  const {
    hasWritePermissions,
    postalCodeGroupIdFormPath,
    postalCodeGroupNameFormPath,
    resourceName,
    required,
    label,
    cityTypes,
    dataTestId
  } = props;
  const [postalCodeGroupOptions, setpostalCodeGroupOptions] = React.useState(
    []
  );
  const notify = useNotify();
  const form = useForm();

  const {
    input: { value: postalCodeGroupName },
    meta: { dirty, submitFailed }
  } = useField(postalCodeGroupNameFormPath);

  const handleOnChange = (event, selectedValue) => {
    form.batch(() => {
      form.change(postalCodeGroupIdFormPath, selectedValue?.id || null);
      form.change(postalCodeGroupNameFormPath, selectedValue?.name || null);
    });
  };

  const getAllpostalCodeGroups = async searchText => {
    try {
      const response = await Api.getList(resourceName, {
        filter: { name: searchText, cityTypes },
        pagination: { page: 1, perPage: 30 }
      });

      return response.data || [];
    } catch {
      notify(ERRORS[resourceName], 'warning');
    }

    return [];
  };

  const updateOptionsDebounced = debounce(async inputValue => {
    const postalCodeGroups = await getAllpostalCodeGroups(inputValue);
    setpostalCodeGroupOptions(postalCodeGroups);
  }, 500);

  const handleInputChange = React.useCallback(
    (event, newInputValue) => {
      // Dont find again when selecting value with click or keydown
      if (event?.type !== 'change') return;

      updateOptionsDebounced(newInputValue);
    },
    // eslint-disable-next-line
    []
  );

  const handleGetOptionLabel = option => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return option;
    }

    // Regular option
    return option.name;
  };

  const hasInputError = !postalCodeGroupName && (dirty || submitFailed);

  return (
    <div style={styles.autoCompleteContainer}>
      <Autocomplete
        getOptionLabel={handleGetOptionLabel}
        getOptionSelected={(optionSelected, optionSelectedValue) =>
          optionSelected?.name === optionSelectedValue
        }
        options={postalCodeGroupOptions}
        noOptionsText={TEXTS[resourceName].NO_OPTIONS}
        disabled={!hasWritePermissions}
        value={postalCodeGroupName}
        data-testid={dataTestId}
        onChange={handleOnChange}
        onInputChange={handleInputChange}
        blurOnSelect
        renderInput={params => (
          <TextField
            {...params}
            data-testid="pgc-autocomplete-input"
            label={label || TEXTS[resourceName].LABEL}
            variant="outlined"
            size="small"
            required={required}
            error={required && hasInputError}
            helperText={required && hasInputError && LABELS.required}
          />
        )}
        renderOption={option => (
          <Grid container alignItems="center">
            <Grid item xs>
              <Typography fontWeight="fontWeightBold">{option.name}</Typography>
              {isServiceTypeRequest(resourceName) && (
                <Typography variant="subtitle2" color="textSecondary">
                  {serviceTypeLabels.TYPES[option.serviceTypeInfo.serviceType]}
                </Typography>
              )}
            </Grid>
          </Grid>
        )}
      />
    </div>
  );
};

PostalCodeGroupAutocomplete.defaultProps = {
  hasWritePermissions: false,
  label: null,
  required: false,
  cityTypes: [],
  dataTestId: 'sr-autocomplete'
};

PostalCodeGroupAutocomplete.propTypes = {
  hasWritePermissions: PropTypes.bool,
  postalCodeGroupIdFormPath: PropTypes.string.isRequired,
  postalCodeGroupNameFormPath: PropTypes.string.isRequired,
  resourceName: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  cityTypes: PropTypes.arrayOf(PropTypes.string),
  dataTestId: PropTypes.string
};

export default PostalCodeGroupAutocomplete;
