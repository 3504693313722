export const TEXT = {
  LOADING: 'Procurando ...',
  NO_OPTIONS: 'Nenhum registro encontrado',
  LABEL: 'Buscar transportadora (LMC)',
  CREATE_LMC:
    'Esta é uma transportadora nova. Ao salvar esta página, ela será criada'
};

export const LMC_TYPES = {
  LAST_MILE_COMPANY_TYPE_LOGGI: 'Loggi',
  LAST_MILE_COMPANY_TYPE_LEVE: 'Leve',
  LAST_MILE_COMPANY_TYPE_CORREIOS_NACIONAL: 'Redespacho',
  LAST_MILE_COMPANY_TYPE_CORREIOS_LOCAL: 'Redespacho'
};
