const LABELS = {
  TITLES: {
    SLO_SETTINGS: 'Configurações de SLO',
    MID_MILE_LATENCY: 'Latência de mid mile',
    DELIVERY_LATENCY: 'Latência de last mile',
    SLO: 'SLO (dias)',
    CONFIRMATION_OFFSET: 'Offset do Horario de Corte',
    HELPER_TEXT:
      'Quantas horas o horário do corte varia da meia-noite: [0h + offset])'
  }
};

export default LABELS;
