const LABELS = {
  PAGE_TITLE: {
    DEADLINE_OFFSET_EDIT: 'Editar Horário de Corte',
    DEADLINE_OFFSET_LIST: 'Listar Horário de Corte'
  },
  TITLES: {
    BASE: 'Nome da Base',
    CONFIRMATION_OFFSET: 'Offset do Horário de Corte',
    DEADLINE_OFFSET: 'Horário de Corte',
    DEADLINE_OFFSET_ACTUAL: 'Horário de corte atual',
    HELPER_TEXT:
      'Quantas horas o horário do corte varia da meia-noite: [0h + offset]).',
    ROUTING_CODE: 'Routing Code',
    WITHOUT_OFFSET: 'Sem offset cadastrado'
  },
  BUTTON: {
    SAVE_TEXT: 'Salvar alteração'
  }
};

export default LABELS;
