import enums from '../../../../enums';

const validateServiceType = serviceType => {
  const errors = {};
  if (!serviceType.serviceTypeInfo?.serviceRegionId) {
    errors.serviceTypeInfo = { serviceRegionId: 'ra.validation.required' };
  }

  if (
    serviceType.serviceTypeInfo?.cityType ===
      enums.cityTypes.CITY_TYPE_NEARBY_LMC &&
    !serviceType.serviceTypeInfo?.baseCityServiceTypeName
  ) {
    errors.serviceTypeInfo = {
      baseCityServiceTypeName: 'ra.validation.required'
    };
  }

  return errors;
};

export default validateServiceType;
