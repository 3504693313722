const LABELS = {
  PAGE_TITLE: {
    TITLE: 'Tipo de Serviço'
  },
  TITLES: {
    NAME: 'Nome',
    REGION: 'Região',
    TYPE: 'Tipo',
    SLO: 'SLO (dias)',
    CUTOFF_TIME: 'Horário de Corte',
    LIST_TITLE: 'Listar Tipos de Serviço',
    CITY_TYPE: 'Tipo de cidade',
    BASE_CITY: 'Cidade base'
  },
  TYPES: {
    SERVICE_TYPE_UNKNOWN: 'Desconhecido',
    SERVICE_TYPE_LOGGI_HOJE: 'Loggi Hoje',
    SERVICE_TYPE_LOGGI_EXPRESS: 'Loggi Express',
    SERVICE_TYPE_REDISPATCH: 'Redespacho',
    SERVICE_TYPE_CROSS_DOCKING: 'Loggi Cross-Docking'
  },
  FORM_DATA: {
    SERVICE_TYPE_INFO_CITY_TYPE: 'serviceTypeInfo.cityType',
    BASE_CITY_ID_PATH: 'serviceTypeInfo.baseCityServiceTypeId',
    BASE_CITY_NAME_PATH: 'serviceTypeInfo.baseCityServiceTypeName'
  },
  CITY_TYPES: {
    CITY_TYPE_INVALID: 'Inválida',
    CITY_TYPE_LOCAL_LMC: 'Base',
    CITY_TYPE_NEARBY_LMC: 'Atendida'
  }
};

export default LABELS;
