import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { labels } from './constants';
import { useStyles } from './styles';

const DiffSloChip = props => {
  const { diff, arrowDown } = props;
  const classes = useStyles();

  return (
    <Chip
      className={classes.diffSloChip}
      label={`${diff} ${labels.DAYS}`}
      icon={
        arrowDown ? (
          <ArrowDownwardIcon
            className={classes.arrowIcon}
            data-testid="arrowDown"
          />
        ) : (
          <ArrowUpwardIcon
            className={classes.arrowIcon}
            data-testid="arrowUp"
          />
        )
      }
    />
  );
};

DiffSloChip.defaultProps = {
  arrowDown: false
};

DiffSloChip.propTypes = {
  arrowDown: PropTypes.bool,
  diff: PropTypes.number.isRequired
};

export default DiffSloChip;
