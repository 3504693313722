import React from 'react';
import { usePermissions } from 'react-admin';
import { useHistory } from 'react-router-dom';
import GenerateCoverageSpreadsheetPage from './generate-coverage-spreadsheet-page';
import enums from '../../../../../enums';
import permissionsFor from '../../../../../access/access';

const GenerateCoverageSpreadsheet = () => {
  const { loaded, permissions } = usePermissions();
  const history = useHistory();

  const authorized = permissionsFor(
    enums.customResources.GENERATE_COVERAGE_SPREADSHEET
  )
    .hasRole(permissions)
    .forAction(enums.actions.CREATE);

  if (loaded && !authorized) {
    history.push(`/${enums.resources.LAST_MILE_COMPANY}`);
  }

  return <GenerateCoverageSpreadsheetPage />;
};

export default GenerateCoverageSpreadsheet;
