const CONSTANTS = {
  LABELS: {
    HOURS: 'Horas',
    MINUTES: 'Minutos',
    ERROR_NEGATIVE_VALUE: 'O valor não pode ser negativo',
    ERROR_NOT_INTEGER: 'O valor deve ser inteiro'
  },
  MIN_HOURS: 0,
  MAX_MINUTES: 59,
  MIN_MINUTES: 0
};

export default CONSTANTS;
