import enums from '../../../enums';

export const choices = [
  { id: enums.DAY_OF_WEEK.SUNDAY, name: 'Domingo' },
  { id: enums.DAY_OF_WEEK.MONDAY, name: 'Segunda-feira' },
  { id: enums.DAY_OF_WEEK.TUESDAY, name: 'Terça-feira' },
  { id: enums.DAY_OF_WEEK.WEDNESDAY, name: 'Quarta-feira' },
  { id: enums.DAY_OF_WEEK.THURSDAY, name: 'Quinta-feira' },
  { id: enums.DAY_OF_WEEK.FRIDAY, name: 'Sexta-feira' },
  { id: enums.DAY_OF_WEEK.SATURDAY, name: 'Sábado' }
];

export default { choices };
