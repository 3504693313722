import NumberFormat from 'react-number-format';
import React from 'react';
import PropTypes from 'prop-types';

const CurrencyNumberFormat = props => {
  const { inputRef, onChange, allowNegative, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value
          }
        });
      }}
      decimalSeparator=","
      isNumericString
      decimalScale={2}
      fixedDecimalScale
      allowNegative={allowNegative}
    />
  );
};

CurrencyNumberFormat.defaultProps = {
  allowNegative: true
};

CurrencyNumberFormat.propTypes = {
  onChange: PropTypes.func.isRequired,
  allowNegative: PropTypes.bool,
  inputRef: PropTypes.func.isRequired
};

export default CurrencyNumberFormat;
