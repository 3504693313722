const LABELS = {
  VALIDATION_MESSAGES: {
    FETCH_ERROR: 'Erro ao tentar validar campo',
    ROUTING_CODE_EXISTS: 'Sigla já existente',
    NAME_EXISTS: 'Nome já existente',
    DEFAULT_EXISTS: 'Distribution Center encontrado'
  },
  API_ERROR_CODE: {
    ERROR_CODE_EMPTY_POSTAL_CODE_RANGE: 'ERROR_CODE_EMPTY_POSTAL_CODE_RANGE',
    ERROR_CODE_TRANSIT_TIME_NOT_FOUND: 'ERROR_CODE_TRANSIT_TIME_NOT_FOUND',
    ERROR_CODE_SERVICE_REGION_OVERLAP_CONFLICT:
      'ERROR_CODE_SERVICE_REGION_OVERLAP_CONFLICT',
    ERROR_CODE_SLO_MAPPING_DUPLICATED_CONFLICT:
      'ERROR_CODE_SLO_MAPPING_DUPLICATED_CONFLICT'
  }
};

export default LABELS;
