import React from 'react';
import PropTypes from 'prop-types';
import { TextInput, useNotify } from 'react-admin';
import { useForm } from 'react-final-form';
import debounce from 'lodash/debounce';
import Autocomplete, {
  createFilterOptions
} from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import Api from '../../../api/postal-code-api';
import moment from '../../../utils/moment';
import { TEXTS, ERRORS } from './constants';
import { requiredField } from './validators';
import { useStyles } from './styles';

const validations = [requiredField];

const DeliveryEarningsTableAutocomplete = props => {
  const { hasWritePermissions, lastMileCompanyInfo } = props;
  const [options, setOptions] = React.useState([]);
  const classes = useStyles();
  const notify = useNotify();
  const form = useForm();

  const parseLabelsToEarningsInfo = labels => labels.map(label => ({ label }));

  const handleComponentChange = async (event, table) => {
    if (!table) {
      form.change('lastMileCompanyInfo.deliveryEarningsTable', null);
      return;
    }

    form.change('lastMileCompanyInfo.deliveryEarningsTable.name', table.name);

    try {
      const response = await Api.getLabelsFromDeliveryTable(table.name);
      const tableLabels = parseLabelsToEarningsInfo(response.data.labels);

      form.change(
        'lastMileCompanyInfo.deliveryEarningsTable.earningsInfoByRegions',
        tableLabels
      );
    } catch {
      notify(ERRORS.FIND_LABELS_ERROR, 'warning');
      form.change('lastMileCompanyInfo.deliveryEarningsTable', null);
    }
  };

  const updateOptionsDebounced = debounce(async () => {
    try {
      const response = await Api.getAllSummariesFromDeliveryTable();
      setOptions(response.data.deliveryTable);
    } catch {
      notify(ERRORS.FIND_SUMMARIES_ERROR, 'warning');
    }
  }, 500);

  const handleInputValueChange = React.useCallback(
    (event, newInputValue) => {
      // Dont find again when selecting value with click or keydown
      if (event?.type !== 'change') return;

      updateOptionsDebounced(newInputValue);
    },
    // eslint-disable-next-line
    []
  );

  const handleGetOptionLabel = option => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return option;
    }

    // Regular option
    return option.name;
  };

  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: option => option.name,
    limit: 20
  });

  const parseTimeStampToDate = timestamp =>
    moment.unix(timestamp).format('DD/MM/YYYY');

  return (
    <>
      <Autocomplete
        getOptionLabel={handleGetOptionLabel}
        getOptionSelected={(optionSelected, optionSelectedValue) =>
          optionSelected?.name === optionSelectedValue
        }
        blurOnSelect
        options={options}
        noOptionsText={TEXTS.NO_OPTIONS}
        filterSelectedOptions
        filterOptions={filterOptions}
        disabled={!hasWritePermissions}
        value={lastMileCompanyInfo?.deliveryEarningsTable?.name || ''}
        data-testid="delivery-earnings-table-name"
        onChange={handleComponentChange}
        onInputChange={handleInputValueChange}
        renderInput={params => (
          <TextInput
            {...params}
            id="delivery-earnings-table-name-input"
            data-testid="delivery-earnings-table-name-input"
            label={TEXTS.LABEL}
            source="tempDeliveryTableName"
            variant="outlined"
            validate={validations}
          />
        )}
        renderOption={option => (
          <Grid container alignItems="center">
            <Grid item xs>
              <Typography fontWeight="fontWeightBold">{option.name}</Typography>
            </Grid>
          </Grid>
        )}
      />
      {lastMileCompanyInfo.contractDateTimeAsInt && (
        <Grid container direction="row" justify="flex-end" alignItems="center">
          <Grid item data-testid="delivery-earnings-table-name-active-time">
            <FormHelperText className={classes.inputField}>
              {`${TEXTS.ASSOCIATION_DATE}: ${parseTimeStampToDate(
                lastMileCompanyInfo.contractDateTimeAsInt
              )}`}
            </FormHelperText>
          </Grid>
        </Grid>
      )}
    </>
  );
};

DeliveryEarningsTableAutocomplete.defaultProps = {
  hasWritePermissions: false,
  lastMileCompanyInfo: {}
};

DeliveryEarningsTableAutocomplete.propTypes = {
  hasWritePermissions: PropTypes.bool,
  lastMileCompanyInfo: PropTypes.shape({
    active: PropTypes.bool,
    contractDateTimeAsInt: PropTypes.number,
    deliveryEarningsTable: PropTypes.shape({
      name: PropTypes.string
    })
  })
};

export default DeliveryEarningsTableAutocomplete;
