import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import { useStyles } from './styles';

const LoadingScreen = props => {
  const { backdrop } = useStyles();
  const { visible } = props;

  return (
    <Backdrop data-testid="back-drop" className={backdrop} open={visible}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

LoadingScreen.defaultProps = {
  visible: false
};

LoadingScreen.propTypes = {
  visible: PropTypes.bool
};

export default LoadingScreen;
