import enums from '../enums';

const { resources, roles, actions, customActions, customResources } = enums;
const roleMapping = {
  '*': {
    [actions.READ]: [roles.ADMIN, roles.MEI_ADMIN, roles.READ_ALL],
    [actions.CREATE]: [roles.ADMIN],
    [actions.UPDATE]: [roles.ADMIN],
    [actions.DELETE]: [roles.ADMIN]
  },
  [resources.LAST_MILE_COMPANY]: {
    [actions.CREATE]: [roles.ADMIN, roles.WRITE_LMC],
    [actions.UPDATE]: [roles.ADMIN, roles.WRITE_LMC],
    [customActions.UPDATE_LOCAL_SLO]: [roles.ADMIN, roles.WRITE_LMC],
    [actions.DELETE]: [roles.ADMIN, roles.WRITE_LMC]
  },
  [resources.SERVICE_TYPE]: {
    [actions.CREATE]: [roles.ADMIN, roles.WRITE_SERVICE_TYPE],
    [actions.UPDATE]: [roles.ADMIN, roles.WRITE_SERVICE_TYPE],
    [actions.DELETE]: [roles.ADMIN, roles.WRITE_SERVICE_TYPE]
  },
  [resources.SERVICE_REGION]: {
    [actions.CREATE]: [roles.ADMIN, roles.WRITE_SERVICE_REGION],
    [actions.UPDATE]: [roles.ADMIN, roles.WRITE_SERVICE_REGION],
    [actions.DELETE]: [roles.ADMIN, roles.WRITE_SERVICE_REGION]
  },
  [resources.TRANSIT_TIME]: {
    [actions.CREATE]: [roles.ADMIN, roles.WRITE_SLO_MAPPING],
    [actions.UPDATE]: [roles.ADMIN, roles.WRITE_SLO_MAPPING],
    [actions.DELETE]: [roles.ADMIN, roles.WRITE_SLO_MAPPING]
  },
  [resources.SLO_EXCEPTION]: {
    [actions.CREATE]: [roles.ADMIN, roles.WRITE_SLO_MAPPING],
    [actions.UPDATE]: [roles.ADMIN, roles.WRITE_SLO_MAPPING],
    [actions.DELETE]: [roles.ADMIN, roles.WRITE_SLO_MAPPING]
  },
  [resources.PRICING_REGION]: {
    [actions.UPDATE]: []
  },
  [resources.RULES]: {
    [actions.UPDATE]: [roles.MEI_ADMIN],
    [actions.DELETE]: [roles.MEI_ADMIN]
  },
  [customResources.CREATE_BASE]: {
    [actions.CREATE]: [roles.ADMIN, roles.WRITE_LMC]
  },
  [customResources.CREATE_DELIVERY_EARNINGS_TABLE]: {
    [actions.CREATE]: [roles.ADMIN, roles.WRITE_EARNIGS]
  },
  [customResources.GENERATE_COVERAGE_SPREADSHEET]: {
    [actions.CREATE]: [roles.ADMIN, roles.READ_ALL]
  },
  [customResources.PUBLISH_COVERAGE]: {
    [actions.CREATE]: [roles.ADMIN, roles.PUBLISH_COVERAGE]
  },
  [resources.DISTRIBUTION_CENTER]: {
    [actions.UPDATE]: [roles.ADMIN, roles.WRITE_DISTRIBUTION_CENTER]
  }
};

export default roleMapping;
