import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SimpleForm, FormDataConsumer, useNotify, Create } from 'react-admin';
import { handleFailure, transform } from './utils';
import enums from '../../../../enums';
import permissionsFor from '../../../../access/access';
import SaveWithConfirmToolbar from '../../../molecules/save-with-confirm-toolbar';
import LastMileCompanyForm from './last-mile-company-form';
import { getPostalCodeGroupInfo } from '../../../molecules/postal-codes-table/utils';

export default function LastMileCompanyCreate(props) {
  const { permissions } = props;

  const canCreate = permissionsFor(enums.resources.LAST_MILE_COMPANY)
    .hasRole(permissions)
    .forAction(enums.actions.CREATE);

  const notify = useNotify();

  const [errorRanges, setErrorRanges] = useState([]);
  const [conflictMessage, setConflictMessage] = useState();

  const onFailure = err =>
    handleFailure({ err, setErrorRanges, setConflictMessage, notify });

  const handleCloseDialog = () => {
    setErrorRanges([]);
  };

  return (
    <Create
      undoable={false}
      {...props}
      onFailure={onFailure}
      redirect="list"
      transform={transform}
    >
      <SimpleForm
        warnWhenUnsavedChanges
        variant="outlined"
        redirect="list"
        toolbar={
          <FormDataConsumer>
            {({ handleSubmit, formData }) => {
              const postalCodeGroupInfo = getPostalCodeGroupInfo(formData);
              return (
                <SaveWithConfirmToolbar
                  handleSubmit={handleSubmit}
                  hasWritePermissions={canCreate}
                  postalCodeGroupInfo={postalCodeGroupInfo}
                  changes={formData.changes}
                  loading={false}
                  postalCodes={formData.postalCodes}
                  deliveryEarningsTable={
                    formData?.lastMileCompanyInfo?.deliveryEarningsTable
                  }
                  isVersioned
                />
              );
            }}
          </FormDataConsumer>
        }
      >
        <FormDataConsumer>
          {({ formData }) => {
            return (
              <LastMileCompanyForm
                hasPermissions={canCreate}
                formData={formData}
                errorRanges={errorRanges}
                handleCloseDialog={handleCloseDialog}
                conflictMessage={conflictMessage}
                pageType={enums.pageType.CREATE}
              />
            );
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
}

LastMileCompanyCreate.defaultProps = {
  permissions: []
};

LastMileCompanyCreate.propTypes = {
  id: PropTypes.string.isRequired,
  permissions: PropTypes.arrayOf(PropTypes.string)
};
