import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFormState } from 'react-final-form';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './styles';
import WorkingXdChip from './working-xd-chip';
import ExceptionChip from './exception-chip';
import {
  getExceptionValueWithPrefix,
  isSloException,
  displayLatency,
  isAbleToShowDiffSlos
} from './utils';
import DiffSloChip from './diff-slo-chip';

const MultiOriginRow = props => {
  const { origin, showDiffSlos } = props;
  const { values, initialValues } = useFormState();
  const classes = useStyles();
  const [diffSlo, setDiffSlo] = useState(null);
  const baseSlo = initialValues?.serviceTypeInfo?.slo;
  const serviceRegionName = values?.serviceTypeInfo?.serviceRegionName;
  const isOverrideException = isSloException(origin?.sloException);

  document.addEventListener('sloDidChange', ({ detail }) => {
    const { slo } = detail;
    if (!isOverrideException) {
      setDiffSlo(slo - baseSlo);
    }
  });

  const hasException = !!origin?.sloException?.value;
  const isAttendanceXd = serviceRegionName === origin.name;

  return (
    <TableRow data-testid="multiOriginTableRow" className={classes.tableRow}>
      <TableCell component="th" scope="row">
        <div className={classes.labelCell}>
          <Typography>{origin.name}</Typography>
          {isAttendanceXd && <WorkingXdChip />}
          {hasException && <ExceptionChip />}
        </div>
      </TableCell>
      <TableCell>
        <Typography>
          {displayLatency(origin?.latency?.midMileLatencyInMinutes)}
        </Typography>
      </TableCell>
      <TableCell>
        <div className={classes.labelCell}>
          <Typography>{origin.slo + diffSlo}</Typography>
          {isAbleToShowDiffSlos(showDiffSlos, diffSlo) && (
            <DiffSloChip
              diff={Math.abs(diffSlo)}
              arrowDown={Math.sign(diffSlo) !== 1}
            />
          )}
        </div>
      </TableCell>
      <TableCell>
        <Typography>{origin.transitTimeOriginToReference}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{origin.transitTimeReferenceToOrigin}</Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {hasException
            ? getExceptionValueWithPrefix(origin.sloException)
            : '-'}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

MultiOriginRow.propTypes = {
  origin: PropTypes.shape({
    name: PropTypes.string,

    slo: PropTypes.number,
    transitTimeOriginToReference: PropTypes.number,
    transitTimeReferenceToOrigin: PropTypes.number,
    sloException: PropTypes.shape({
      value: PropTypes.number,
      type: PropTypes.string
    }),
    latency: PropTypes.shape({
      midMileLatencyInMinutes: PropTypes.number
    })
  }).isRequired,
  showDiffSlos: PropTypes.bool.isRequired
};

export default MultiOriginRow;
