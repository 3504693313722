import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  dialogTitle: {
    fontWeight: '600',
    fontSize: '1.25rem',
    lineHeight: '150%'
  }
}));

export default { useStyles };
