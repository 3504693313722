import { downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport/dist';
import LABELS from './labels';
import moment from '../../utils/moment';

const buildFileName = args => {
  const { origin, coverageType, targetDateInMs } = args.data;
  const originName = origin ? `-${origin.name}` : '';
  return `${LABELS.TITLES.CSV_NAME}-${LABELS.RANGE_TYPE[coverageType]}-${moment(
    targetDateInMs
  ).format('DD-MM-YYYY')}${originName}`;
};

const exportToCsv = (data, fileName) => {
  if (!data) return;

  jsonExport(data, (_, csv) => {
    downloadCSV(csv, fileName);
  });
};

export { buildFileName, exportToCsv };
