import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { LABELS } from './constants';
import NumberFormatInput from '../../atoms/number-format-input';

const ZipCodeField = ({ name, value, setType, dataTestId, disabled }) => {
  return (
    <TextField
      data-testid={dataTestId}
      placeholder={name}
      InputProps={{
        inputComponent: NumberFormatInput
      }}
      onChange={event => setType({ code: event.target.value })}
      value={disabled ? '' : value.code}
      disabled={disabled}
      helperText={disabled && LABELS.TEXTS.FILLED_BY_REGION}
    />
  );
};

ZipCodeField.defaultProps = {
  disabled: false
};

ZipCodeField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.shape({
    code: PropTypes.string
  }).isRequired,
  setType: PropTypes.func.isRequired,
  dataTestId: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

export default ZipCodeField;
