import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

export default function ChoiceField({ record }) {
  return <Typography data-testid="choice-field">{record.name}</Typography>;
}

ChoiceField.defaultProps = {
  record: {}
};

ChoiceField.propTypes = {
  record: PropTypes.shape({
    name: PropTypes.string
  })
};
