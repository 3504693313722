import roleMapping from './role-mapping';

function validatePermissions(feature, actionName, permissions) {
  const resourcePermissions = roleMapping[feature];

  if (
    feature !== '*' &&
    (!resourcePermissions ||
      !actionName ||
      !(actionName in resourcePermissions))
  ) {
    return validatePermissions('*', actionName, permissions);
  }

  const rolesAllowed = resourcePermissions[actionName];

  if (!rolesAllowed) return false;

  return rolesAllowed.some(role => permissions && permissions.includes(role));
}

const permissionsFor = featureName => ({
  hasRole: permissions => ({
    forAction: actionName => {
      return validatePermissions(featureName, actionName, permissions);
    }
  })
});

export default permissionsFor;
