import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  grid: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2)
  },
  formHelperText: {
    textAlign: 'center'
  }
}));

export default {
  useStyles
};
