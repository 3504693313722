import React, { useState, useEffect } from 'react';
import {
  Edit,
  SimpleForm,
  NumberInput,
  TextInput,
  useShowController,
  RadioButtonGroupInput
} from 'react-admin';
import FormControl from '@material-ui/core/FormControl';
import PropTypes from 'prop-types';
import { isSloType, sloValueValidator } from './validation';
import enums from '../../../../enums';
import LABELS from './labels';
import SimpleToolbar from '../../../atoms/simple-toolbar';
import permissionsFor from '../../../../access/access';

const MakeTitle = props => {
  return `${LABELS.PAGE_TITLE.TITLE} ${props?.record?.originServiceRegionGroup
    ?.serviceRegionInfo?.name || ''} -> ${props?.record
    ?.destinationServiceTypeGroup?.serviceTypeInfo?.name || ''}`;
};

const transform = data => {
  const { sloMapping } = data;

  if (isSloType(data.sloType)) sloMapping.sloOffset = 0;
  else sloMapping.slo = 0;

  return {
    sloMapping,
    ...data
  };
};

const SloExceptionEdit = props => {
  const { record } = useShowController(props);
  const { permissions } = props;
  const canUpdate = permissionsFor(enums.resources.SLO_EXCEPTION)
    .hasRole(permissions)
    .forAction(enums.actions.UPDATE);
  const [sloType, setSloType] = useState(LABELS.FORM_DATA.SLO_PATH);

  const handleSloTypeChange = value => setSloType(value);

  useEffect(() => {
    if (record?.sloMapping) {
      if (record?.sloMapping?.sloOffset) {
        setSloType(LABELS.FORM_DATA.SLO_OFFSET_PATH);
      } else {
        setSloType(LABELS.FORM_DATA.SLO_PATH);
      }
    }
  }, [record]);

  return (
    <Edit
      {...props}
      undoable={false}
      transform={transform}
      title={<MakeTitle />}
    >
      <SimpleForm
        warnWhenUnsavedChanges
        variant="outlined"
        toolbar={<SimpleToolbar disabled={!canUpdate} />}
        initialValues={{ sloType }}
      >
        <TextInput
          label={LABELS.TITLES.ORIGIN}
          source={LABELS.FORM_DATA.ORIGIN_SERVICE_REGION_NAME_PATH}
          disabled
          fullWidth
        />
        <TextInput
          label={LABELS.TITLES.DESTINATION}
          source={LABELS.FORM_DATA.DESTINATION_SERVICE_TYPE_NAME_PATH}
          disabled
          fullWidth
        />
        <FormControl fullWidth>
          <RadioButtonGroupInput
            row
            label={LABELS.TITLES.SLO_TYPE}
            source="sloType"
            choices={[
              { id: LABELS.FORM_DATA.SLO_PATH, name: LABELS.TITLES.SLO },
              {
                id: LABELS.FORM_DATA.SLO_OFFSET_PATH,
                name: LABELS.TITLES.SLO_OFFSET
              }
            ]}
            disabled={!canUpdate}
            onChange={handleSloTypeChange}
          />
        </FormControl>
        <NumberInput
          data-testid="slo-value"
          label={LABELS.TITLES.SLO_FIELD}
          source={sloType}
          disabled={!canUpdate}
          validate={sloValueValidator}
        />
      </SimpleForm>
    </Edit>
  );
};

SloExceptionEdit.defaultProps = {
  permissions: []
};

SloExceptionEdit.propTypes = {
  id: PropTypes.string.isRequired,
  permissions: PropTypes.arrayOf(PropTypes.string)
};

export default SloExceptionEdit;
