import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  menu: {
    color: '#2E3545',
    fontSize: 14,
    fontWeight: 400,
    fontFamily: 'Open Sans'
  }
}));

export default { useStyles };
