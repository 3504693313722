import React from 'react';
import PropTypes from 'prop-types';
import { NumberInput } from 'react-admin';
import { Box, Grid, Typography } from '@material-ui/core';
import LABELS from './labels';
import handleSLOChange from './utils';
import validateServiceTypeInputs from '../../validators';
import SectionTitle from '../../atoms/section-title/section-title';
import { displayLatency } from '../../molecules/multi-origin-table/utils';

export default function ServiceTypeSloConfig({
  cajamarLatency,
  isEditPage,
  hasPermissions,
  isNearCity
}) {
  return (
    <>
      <SectionTitle title={LABELS.TITLES.SLO_SETTINGS} />

      <Grid container spacing={2} fullWidth xs={12}>
        <Grid item xs={4} sm={4} lg={2}>
          <Box mb={3} mt={1} ml={1} mr={4}>
            <Grid item xs={12}>
              <Typography
                variant="caption"
                data-testid="service-type-mid-mile-latency"
              >
                {`${LABELS.TITLES.MID_MILE_LATENCY}: `}
                {displayLatency(cajamarLatency?.midMileLatencyInMinutes)}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant="caption"
                data-testid="service-type-last-mile-latency"
              >
                {`${LABELS.TITLES.DELIVERY_LATENCY}: `}
                {displayLatency(cajamarLatency?.deliveryLatencyInMinutes)}
              </Typography>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={5} sm={4} lg={2}>
          <NumberInput
            label={LABELS.TITLES.SLO}
            defaultValue={0}
            source="serviceTypeInfo.slo"
            validate={validateServiceTypeInputs.slo}
            variant="outlined"
            data-testid="service-type-info-slo"
            onChange={handleSLOChange}
            disabled={isEditPage && (!hasPermissions || isNearCity)}
          />
        </Grid>

        <Grid item xs={5} sm={4} lg={2}>
          <NumberInput
            label="Multi origin SLO"
            defaultValue={0}
            source="serviceTypeInfo.multiOriginSlo"
            validate={validateServiceTypeInputs.slo}
            variant="outlined"
            data-testid="multi-origin-slo"
            disabled
          />
        </Grid>

        <Grid item xs={5} sm={4} lg={2}>
          <NumberInput
            label={LABELS.TITLES.CONFIRMATION_OFFSET}
            source="serviceTypeInfo.confirmationDeadlineOffset"
            defaultValue={0}
            helperText={LABELS.TITLES.HELPER_TEXT}
            variant="outlined"
            data-testid="service-type-info-confirmation-deadline-offset"
            disabled={isEditPage && (!hasPermissions || isNearCity)}
          />
        </Grid>
      </Grid>
    </>
  );
}

ServiceTypeSloConfig.defaultProps = {
  isNearCity: false
};

ServiceTypeSloConfig.propTypes = {
  hasPermissions: PropTypes.bool.isRequired,
  cajamarLatency: PropTypes.objectOf(PropTypes.string).isRequired,
  isEditPage: PropTypes.bool.isRequired,
  isNearCity: PropTypes.bool
};
