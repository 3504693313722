import { OPERATIONS } from '../batch-ranges-import/utils/constants';

export const LABELS = {
  BUTTONS: {
    EDIT: 'Editar',
    CONFIRM: 'Confirmar',
    REMOVE: 'Remover',
    CANCEL: 'Cancelar',
    CHANGES: 'Visualizar alterações'
  },
  TITLES: {
    NAME: 'Nome',
    START: 'Início',
    END: 'Final',
    SLO: 'SLO Local',
    EARNING_LABEL: 'Faixa de preço',
    TYPE: 'Tipo'
  },
  MODALS: {
    DELETE_CONFIRMATION: 'Você tem certeza que deseja excluir esse item?',
    DELETE: OPERATIONS.JSON_PATCH.OPERATION_REMOVE.LABEL
  },
  NOTIFY: {
    WAS_REMOVED: 'removido'
  },
  INPUTS: {
    NAME: 'Nome',
    START: 'Início',
    END: 'Final',
    SLO: 'SLO',
    TYPE: 'Tipo de CEP'
  },
  ERRORS: {
    NAME_IS_EMPTY: 'O nome é obrigatório',
    CEP_LENGTH: 'O CEP tem que ter oito dígitos',
    START_GT_END: 'CEP inicial maior que final',
    RANGE_INTERSECTION: 'Sobreposição de CEPS',
    EARNING_LABEL_NOT_FOUND: 'Faixa de preço é obrigatória',
    EARNING_LABEL_NOT_EXISTS:
      'Faixa de preço não existe para a tabela selecionada'
  },
  TEXTS: {
    EARNING_NOT_SELECT: 'Não selecionada'
  }
};

export const LABEL_ORDER_MAP = {
  [LABELS.TITLES.NAME]: 'name',
  [LABELS.TITLES.START]: 'start.code',
  [LABELS.TITLES.END]: 'end.code'
};

export const ORDER = {
  ASC: 'asc',
  DESC: 'desc'
};
