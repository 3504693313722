import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Confirm } from 'react-admin';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import WarningIcon from '@material-ui/icons/Warning';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { OPERATIONS } from '../batch-ranges-import/utils/constants';
import {
  isPostalCodeGroupLastMileCompany,
  isLastMileCompanyLeve
} from '../postal-codes-table/utils';
import { refreshPage } from '../../../utils/utils';
import { useStyles } from './styles';
import LABELS from './constants';
import formatPostalCode from '../../../utils/format-postal-code';
import enums from '../../../enums';

const DialogShowChanges = props => {
  const {
    changes,
    handleOpenDialog,
    open,
    isCsvImport,
    onConfirm,
    postalCodeGroupInfo
  } = props;
  const [deleteChanges, setDeleteChanges] = useState(false);
  const changesIsEmpty = !changes || !changes.length;
  const classes = useStyles(props);

  const isLastMileCompany = isPostalCodeGroupLastMileCompany(
    postalCodeGroupInfo
  );

  const customActionMessages = {
    [OPERATIONS.JSON_PATCH.OPERATION_REMOVE.LABEL]: {
      color: classes.actionMessagesDelete,
      label: OPERATIONS.JSON_PATCH.OPERATION_REMOVE.TEXT
    },
    [OPERATIONS.JSON_PATCH.OPERATION_ADD.LABEL]: {
      color: classes.actionMessagesAdd,
      label: OPERATIONS.JSON_PATCH.OPERATION_ADD.TEXT
    },
    [OPERATIONS.JSON_PATCH.OPERATION_REPLACE.LABEL]: {
      color: classes.actionMessagesUpdate,
      label: OPERATIONS.JSON_PATCH.OPERATION_REPLACE.TEXT
    }
  };

  const cancelContentText = isCsvImport
    ? LABELS.MODALS.CONFIRM_CANCEL_CHANGES
    : LABELS.MODALS.CONFIRM_CANCEL_CHANGES_SAVE;

  const dialogTitle = isCsvImport
    ? LABELS.TITLES.MODAL_TITLE.MULTIPLE_CHANGES
    : LABELS.TITLES.MODAL_TITLE.SIMPLE_CHANGES;

  const fillEmptyTableColumns = numberOfColumns =>
    [...Array(numberOfColumns).keys()].map(() => (
      <TableCell component="th" scope="row">
        <Typography>--</Typography>
      </TableCell>
    ));

  const createCopyRow = () => (
    <TableRow key="copy" className={classes.tableRow}>
      <TableCell component="th" scope="row">
        <Typography>
          <Box
            className={classes.actionMessagesAdd}
            fontWeight="fontWeightBold"
          >
            {LABELS.TITLES.COPY_COVERAGE}
          </Box>
        </Typography>
      </TableCell>
      {fillEmptyTableColumns(5)}
    </TableRow>
  );

  const createRows = change => (
    <TableRow
      key={`${change.name}_${change.start.code}_${change.end.code}`}
      className={classes.tableRow}
    >
      <TableCell component="th" scope="row">
        <Typography>
          <Box
            fontWeight="fontWeightBold"
            className={customActionMessages[change.typeChange].color}
          >
            {customActionMessages[change.typeChange].label}
          </Box>
        </Typography>
      </TableCell>
      <TableCell component="th" scope="row">
        <Typography>{change.name}</Typography>
      </TableCell>
      <TableCell>
        <Typography>
          <Box fontWeight="fontWeightBold">
            {formatPostalCode(change.start.code)}
          </Box>
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          <Box fontWeight="fontWeightBold">
            {formatPostalCode(change.end.code)}
          </Box>
        </Typography>
      </TableCell>
      {isLastMileCompany && (
        <>
          {isLastMileCompanyLeve(postalCodeGroupInfo) && (
            <TableCell>
              <Typography>
                {change.lastMileCompanyInfo?.earningsLabel ||
                  LABELS.TITLES.EARNING_NOT_SELECT}
              </Typography>
            </TableCell>
          )}
          <TableCell>
            <Typography>{change.lastMileCompanyInfo?.slo || 0}</Typography>
          </TableCell>
        </>
      )}
    </TableRow>
  );

  return (
    <Dialog
      open={open}
      onClose={() => handleOpenDialog(false)}
      disableBackdropClick={isCsvImport}
      aria-labelledby="form-dialog-title"
      scroll="paper"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
      <DialogContent>
        {changesIsEmpty ? (
          <DialogContentText>{LABELS.MODALS.NO_CHANGES}</DialogContentText>
        ) : (
          <Box my={2}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow data-testid="changes-table-title">
                    <TableCell className={classes.tableHead}>
                      {LABELS.TITLES.ACTION}
                    </TableCell>
                    <TableCell className={classes.tableHead}>
                      {LABELS.TITLES.NAME}
                    </TableCell>
                    <TableCell className={classes.tableHead}>
                      {LABELS.TITLES.START}
                    </TableCell>
                    <TableCell className={classes.tableHead}>
                      {LABELS.TITLES.END}
                    </TableCell>
                    {isLastMileCompany && (
                      <>
                        {isLastMileCompanyLeve(postalCodeGroupInfo) && (
                          <TableCell className={classes.tableHead}>
                            {LABELS.TITLES.EARNING_LABEL}
                          </TableCell>
                        )}
                        <TableCell className={classes.tableHead}>
                          {LABELS.TITLES.SLO}
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody data-testid="changes-table-body">
                  {changes.map(change => {
                    if (change.typeChange === enums.operations.COPY)
                      return createCopyRow();
                    return createRows(change);
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
        {!changesIsEmpty && (
          <DialogActions>
            <Confirm
              isOpen={deleteChanges}
              title={LABELS.TITLES.CONFIRM_CANCEL_CHANGES}
              content={cancelContentText}
              onConfirm={refreshPage}
              onClose={() => setDeleteChanges(false)}
            />
            <Grid container spacing={1} justify="flex-end">
              <Grid item>
                {onConfirm ? (
                  <Button
                    onClick={onConfirm}
                    data-testid="changes-confirm-button"
                    size="small"
                    color="primary"
                  >
                    <CheckIcon />
                    {LABELS.BUTTONS.CONFIRM}
                  </Button>
                ) : (
                  <Button
                    onClick={() => handleOpenDialog(false)}
                    size="small"
                    data-testid="changes-dialog-close"
                  >
                    <ClearIcon />
                    {LABELS.BUTTONS.CLOSE}
                  </Button>
                )}
              </Grid>
              <Grid item>
                <Button
                  onClick={() => setDeleteChanges(true)}
                  color="primary"
                  size="small"
                  data-testid="changes-dialog-cancel-changes"
                >
                  <WarningIcon />
                  {LABELS.BUTTONS.CANCEL_CHANGES}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        )}
      </DialogContent>
    </Dialog>
  );
};

DialogShowChanges.defaultProps = {
  onConfirm: null,
  isCsvImport: false
};

DialogShowChanges.propTypes = {
  changes: PropTypes.arrayOf(
    PropTypes.shape({
      typeChange: PropTypes.string,
      types: PropTypes.arrayOf(PropTypes.string),
      name: PropTypes.string,
      start: PropTypes.shape({
        code: PropTypes.string
      }),
      end: PropTypes.shape({
        code: PropTypes.string
      }),
      lastMileCompanyInfo: PropTypes.shape({
        slo: PropTypes.number
      })
    })
  ).isRequired,
  handleOpenDialog: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  isCsvImport: PropTypes.bool,
  onConfirm: PropTypes.func,
  postalCodeGroupInfo: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.number,
    resourceName: PropTypes.string,
    groupType: PropTypes.string
  }).isRequired
};

export default DialogShowChanges;
