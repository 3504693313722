import enums from '../../../enums';

const LABELS = {
  VERSIONING_STATUS: {
    [enums.versioningStatusType.INVALID]: 'Inválida',
    [enums.versioningStatusType.ACTIVE]: 'Ativa',
    [enums.versioningStatusType.EXPIRED]: 'Inativa',
    [enums.versioningStatusType.PROGRAMMED]: 'Agendada',
    [enums.versioningStatusType.APPROVED]: 'Aprovada',
    [enums.versioningStatusType.REJECTED]: 'Rejeitada'
  }
};

export default LABELS;
