import React from 'react';
import PropTypes from 'prop-types';
import { NumberInput, minValue, number, maxValue } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CONSTANTS from './constants';
import integer from './validations';

const defaultHoursValidation = [
  number(),
  minValue(CONSTANTS.MIN_HOURS, CONSTANTS.LABELS.ERROR_NEGATIVE_VALUE),
  integer()
];

const defaultMinutesValidation = [
  number(),
  minValue(CONSTANTS.MIN_MINUTES, CONSTANTS.LABELS.ERROR_NEGATIVE_VALUE),
  maxValue(CONSTANTS.MAX_MINUTES),
  integer()
];

const HoursMinutesInput = props => {
  const {
    disabled,
    formPath,
    hoursValidations,
    minutesValidations,
    title
  } = props;

  return (
    <>
      <Grid container xs={12} sm={6}>
        <Grid item xs={12}>
          <Typography variant="body2">{title}</Typography>
        </Grid>
        <Grid container spacing="2" alignItems="center">
          <Grid item xs={6} sm={6}>
            <NumberInput
              label={CONSTANTS.LABELS.HOURS}
              source={`${formPath}.hours`}
              variant="outlined"
              validate={hoursValidations}
              disabled={disabled}
              min={CONSTANTS.MIN_HOURS}
              step={1}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <NumberInput
              label={CONSTANTS.LABELS.MINUTES}
              variant="outlined"
              source={`${formPath}.minutes`}
              validate={minutesValidations}
              disabled={disabled}
              min={CONSTANTS.MIN_MINUTES}
              step={1}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

HoursMinutesInput.defaultProps = {
  disabled: false,
  hoursValidations: defaultHoursValidation,
  minutesValidations: defaultMinutesValidation
};

HoursMinutesInput.propTypes = {
  disabled: PropTypes.bool,
  hoursValidations: PropTypes.arrayOf(PropTypes.func),
  minutesValidations: PropTypes.arrayOf(PropTypes.func),
  formPath: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default HoursMinutesInput;
