import React from 'react';
import PropTypes from 'prop-types';

import {
  Datagrid,
  TextField,
  List,
  DateField,
  NumberField,
  ChipField
} from 'react-admin';

import MeiRulesFilters from '../../../molecules/mei-rules-filters';
import LABELS from './labels';
import { MODAL_TYPES, PRODUCTS, STATUS_TYPES } from './constants';
import MeiRulesEditConditionalBtn from '../../../atoms/mei-rules-buttons/edit-button';
import MeiRulesDeleteConditionalBtn from '../../../atoms/mei-rules-buttons/delete-button';
import MeiRulesBulkUpdateBtn from '../../../atoms/mei-rules-buttons/bulk-update-button';

export default function MeiRulesList(props) {
  const { permissions } = props;

  return (
    <List
      filters={
        <MeiRulesFilters
          modalchoices={MODAL_TYPES}
          productchoices={PRODUCTS}
          statuschoices={STATUS_TYPES}
        />
      }
      actions={<MeiRulesBulkUpdateBtn permissions={permissions} />}
      exporter={false}
      bulkActionButtons={false}
      title={LABELS.TITLES.LIST_TITLE}
      perPage={20}
      {...props}
    >
      <Datagrid>
        <TextField
          source="startDriverStrategy.city"
          label={LABELS.TITLES.CITY}
        />
        <TextField
          source="startDriverStrategy.product"
          label={LABELS.TITLES.PRODUCT}
        />
        <TextField
          source="startDriverStrategy.modal"
          label={LABELS.TITLES.MODAL}
        />
        <TextField
          source="startDriverStrategy.productType"
          label={LABELS.TITLES.PRODUCT_TYPE}
        />
        <NumberField
          source="startDriverStrategy.tier"
          label={LABELS.TITLES.TIER}
          locales="pt-BR"
        />
        <NumberField
          source="startDriverStrategy.costPerKm"
          label={LABELS.TITLES.COST_PER_KM}
          locales="pt-BR"
        />
        <NumberField
          source="startDriverStrategy.costPerPoint"
          label={LABELS.TITLES.COST_PER_POINT}
          locales="pt-BR"
        />
        <NumberField
          source="startDriverStrategy.deductibleCost"
          label={LABELS.TITLES.DEDUCTIBLE_COST}
          locales="pt-BR"
        />
        <DateField
          source="startTime"
          label={LABELS.TITLES.START_TIME}
          showTime
          locales="pt-BR"
        />
        <DateField
          source="endTime"
          label={LABELS.TITLES.END_TIME}
          showTime
          locales="pt-BR"
        />
        <ChipField source="status" label={LABELS.TITLES.STATUS} />
        <MeiRulesEditConditionalBtn permissions={permissions} />
        <MeiRulesDeleteConditionalBtn permissions={permissions} />
      </Datagrid>
    </List>
  );
}

MeiRulesList.defaultProps = {
  permissions: []
};

MeiRulesList.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string)
};
