import React from 'react';
import {
  Create,
  SimpleForm,
  NumberInput,
  RadioButtonGroupInput,
  useNotify
} from 'react-admin';
import FormControl from '@material-ui/core/FormControl';
import PropTypes from 'prop-types';
import enums from '../../../../enums';
import LABELS from './labels';
import SimpleToolbar from '../../../atoms/simple-toolbar';
import PostalCodeGroupAutocomplete from '../../../molecules/postal-code-group-autocomplete';
import {
  isSloType,
  sloValueValidator,
  requiredFieldValidator,
  validateSloExceptionCreate
} from './validation';
import DialogConflictSLOMapping from '../../../molecules/dialog-conflict-slo-mapping';
import { handleConflictSloMapping } from '../../../../utils/handle-conflicts';
import permissionsFor from '../../../../access/access';

const transform = dataToBeSent => {
  const { sloMapping } = dataToBeSent;
  const isSlo = isSloType(dataToBeSent.sloType);

  return {
    slo: isSlo ? dataToBeSent.sloValue : 0,
    sloOffset: isSlo ? 0 : dataToBeSent.sloValue,
    ...sloMapping
  };
};

const SloExceptionCreate = props => {
  const { permissions } = props;
  const [
    conflictingSloMappingIds,
    setConflictingSloMappingIds
  ] = React.useState([]);
  const notify = useNotify();

  const canCreate = permissionsFor(enums.resources.SLO_EXCEPTION)
    .hasRole(permissions)
    .forAction(enums.actions.CREATE);

  const handleFailure = error =>
    handleConflictSloMapping(error, setConflictingSloMappingIds, notify);

  const handleCloseDialog = () => {
    setConflictingSloMappingIds([]);
  };

  return (
    <Create
      redirect="list"
      undoable={false}
      title={LABELS.TITLES.CREATE_TITLE}
      transform={transform}
      onFailure={handleFailure}
      {...props}
    >
      <SimpleForm
        variant="outlined"
        toolbar={<SimpleToolbar disabled={!canCreate} />}
        warnWhenUnsavedChanges
        validate={validateSloExceptionCreate}
      >
        <PostalCodeGroupAutocomplete
          label={LABELS.TITLES.ORIGIN}
          hasWritePermissions={canCreate}
          postalCodeGroupIdFormPath={
            LABELS.FORM_DATA.ORIGIN_SERVICE_REGION_ID_PATH
          }
          postalCodeGroupNameFormPath={
            LABELS.FORM_DATA.ORIGIN_SERVICE_REGION_NAME_PATH
          }
          resourceName={enums.resources.SERVICE_REGION}
          required
        />
        <PostalCodeGroupAutocomplete
          label={LABELS.TITLES.DESTINATION}
          hasWritePermissions={canCreate}
          postalCodeGroupIdFormPath={
            LABELS.FORM_DATA.DESTINATION_SERVICE_TYPE_ID_PATH
          }
          postalCodeGroupNameFormPath={
            LABELS.FORM_DATA.DESTINATION_SERVICE_TYPE_NAME_PATH
          }
          resourceName={enums.resources.SERVICE_TYPE}
          required
        />
        <FormControl fullWidth>
          <RadioButtonGroupInput
            row
            label={LABELS.TITLES.SLO_TYPE}
            source="sloType"
            choices={[
              { id: LABELS.FORM_DATA.SLO_PATH, name: LABELS.TITLES.SLO },
              {
                id: LABELS.FORM_DATA.SLO_OFFSET_PATH,
                name: LABELS.TITLES.SLO_OFFSET
              }
            ]}
            defaultValue={LABELS.FORM_DATA.SLO_PATH}
            validate={requiredFieldValidator}
          />
        </FormControl>
        <NumberInput
          data-testid="slo-value"
          label={LABELS.TITLES.SLO_FIELD}
          source="sloValue"
          disabled={!canCreate}
          validate={sloValueValidator}
        />
        <DialogConflictSLOMapping
          onClose={handleCloseDialog}
          open={!!conflictingSloMappingIds?.length}
          conflictingSloMappingIds={conflictingSloMappingIds}
        />
      </SimpleForm>
    </Create>
  );
};

SloExceptionCreate.defaultProps = {
  permissions: []
};

SloExceptionCreate.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string)
};

export default SloExceptionCreate;
