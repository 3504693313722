import queryString from 'query-string';
import { fetchOrThrow } from './api';
import { normalize, SLO_MAPPING_TYPES_MAP } from './slo-mapping-api-utils';
import { DEFAULT_INITIAL_PAGE, DEFAULT_DOCUMENTS_PER_PAGE } from './constants';

const ENDPOINT_URL = `${
  process.env.REACT_APP_ANNOTATOR_URL
}/api/v1/slo-mapping`;

const getOne = async id => {
  const endpoint = `${ENDPOINT_URL}/${id}`;
  const response = await fetchOrThrow(endpoint);
  const data = normalize(response);

  return { data: { ...data, id } };
};

const getList = async (resource, args) => {
  const {
    pagination = {
      page: DEFAULT_INITIAL_PAGE,
      perPage: DEFAULT_DOCUMENTS_PER_PAGE
    },
    filter = {}
  } = args;
  const { originName = '', destinationName = '' } = filter;

  const params = {
    page: pagination.page,
    'results-per-page': pagination.perPage,
    type: SLO_MAPPING_TYPES_MAP[resource]
  };

  if (originName.length) {
    params['origin-name'] = `"${originName}"`;
  }

  if (destinationName.length) {
    params['destination-name'] = `"${destinationName}"`;
  }

  const filters = queryString.stringify(params, { sort: false });
  const response = await fetchOrThrow(`${ENDPOINT_URL}?${filters}`);
  const { sloMappingElements = [], total = '0' } = response;
  const data = sloMappingElements.map(normalize);

  return {
    data,
    total: parseInt(total, 10)
  };
};

const update = async args => {
  const { id, data } = args;
  const { sloMapping } = data;
  const endpoint = `${ENDPOINT_URL}/${id}`;
  const response = await fetchOrThrow(endpoint, {
    method: 'PUT',
    body: JSON.stringify({ sloMapping })
  });

  return { data: { id, ...response } };
};

const create = async (resource, args) => {
  const { data } = args;
  const id = '';

  const response = await fetchOrThrow(ENDPOINT_URL, {
    method: 'POST',
    body: JSON.stringify({
      sloMapping: {
        ...data,
        sloMappingType: SLO_MAPPING_TYPES_MAP[resource]
      }
    })
  });

  return { data: { id, ...response } };
};

const getMultiOriginSlosFromServicetype = async (
  destinationServiceTypeId,
  baseCityServiceTypeId,
  serviceRegionId
) => {
  const endpoint = `${ENDPOINT_URL}/multi-origin-slos/${destinationServiceTypeId}`;
  const urlParams = new URLSearchParams({
    ...(serviceRegionId && { 'parent-service-region-id': serviceRegionId }),
    ...(baseCityServiceTypeId && {
      'base-service-type-id': baseCityServiceTypeId
    })
  });

  const response = await fetchOrThrow(`${endpoint}?${urlParams}`);

  return response?.multiOriginSlos || [];
};

const getMultiOriginSlosFromCustomParameters = async (
  serviceRegionId,
  serviceType,
  cityRegionId
) => {
  const params = {};

  if (serviceRegionId) {
    params['parent-service-region-id'] = serviceRegionId;
  }

  if (serviceType) {
    params['service-type'] = serviceType;
  }

  if (cityRegionId) {
    params['city-region-id'] = cityRegionId;
  }

  const filters = queryString.stringify(params);

  const response = await fetchOrThrow(
    `${ENDPOINT_URL}/multi-origin-slos?${filters}`
  );

  return response?.multiOriginSlos || [];
};

const deleteOne = async id => {
  const endpoint = `${ENDPOINT_URL}/${id}`;
  await fetchOrThrow(endpoint, {
    method: 'DELETE'
  });

  return { data: { id } };
};

export default {
  getList,
  getOne,
  update,
  create,
  deleteOne,
  getMultiOriginSlosFromServicetype,
  getMultiOriginSlosFromCustomParameters
};
