import React from 'react';
import PropTypes from 'prop-types';

import { TextInput, Filter } from 'react-admin';
import LABELS from './labels';
import FilterSelectArrayInput from '../../atoms/filter-select-array-input';

const RegionFilter = props => {
  const { choices } = props;

  return (
    <Filter {...props} variant="outlined">
      <TextInput
        source="name"
        alwaysOn
        label={LABELS.NAME}
        resettable
        data-testid="name-filter"
      />
      <FilterSelectArrayInput
        source="type"
        label={LABELS.TYPE}
        choices={choices}
        dataTestId="type-filter"
      />
    </Filter>
  );
};

RegionFilter.defaultProps = {
  choices: [],
  record: {},
  resource: ''
};

RegionFilter.propTypes = {
  choices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    })
  ),
  record: PropTypes.shape({
    name: PropTypes.string
  }),
  resource: PropTypes.string
};

export default RegionFilter;
