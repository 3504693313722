import enums from '../../../enums';

export const HEADERS = ['Ação', 'Nome', 'CEP Inicial', 'CEP Final'];

export const TEXTS = {
  TITLE_ERROR: 'Ranges de CEP duplicados',
  COVERAGE_REGISTERED: 'Esta abrangência já está cadastrada ',
  COVERAGE_ADDED: 'Abrangência adicionada ',
  DIALOG_DESCRIPTION:
    'Os seguintes ranges de CEP estão duplicados. Resolva-os para prosseguir com a modificação.',
  [enums.resources.SERVICE_TYPE]: {
    IN_SERVICE: 'no Tipo de Serviço ',
    PATH: '/#/service-type',
    SERVICE_NAME: 'ao Tipo de Serviço '
  },
  [enums.resources.SERVICE_REGION]: {
    IN_SERVICE: 'na Região de Serviço ',
    PATH: '/#/service-region',
    SERVICE_NAME: 'a Região de Serviço '
  }
};
