import React from 'react';
import {
  SimpleForm,
  Create,
  TextInput,
  NumberInput,
  FormDataConsumer,
  usePermissions,
  required,
  maxLength,
  minValue,
  maxValue
} from 'react-admin';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useStyles } from './styles';
import distributionCenterValidator from '../../../../../utils/validators';
import { LABELS, defaultWeekDays } from './constants';
import enums from '../../../../../enums';
import { validateTime } from './validators';
import PostalCodesTableTabs from '../../../../molecules/postal-codes-table-tabs';
import TimeInput from '../../../../atoms/time-input';
import SelectMultipleInput from '../../../../molecules/select-multiple-input';
import RoutingCodeInput from '../../../../atoms/routing-code-input/routing-code-input';
import PostalCodeInput from '../../../../atoms/postal-code-input/postal-code-input';
import LmcAutocomplete from '../../../../molecules/lmc-autocomplete-input';
import BaseTypeRadioButton from './base-type-radio-button';
import DatePickerInput from '../../../../molecules/date-picker-input';
import DeliveryEarningsTableAutocomplete from '../../../../molecules/delivery-earnings-table-autocomplete';
import {
  getPostalCodeGroupInfo,
  isLastMileCompanyLeve
} from '../../../../molecules/postal-codes-table/utils';
import mirrorDeliveryRangeToPickup from '../../../../../utils/mirror-delivery-range-to-pickup';
import { formatTimeToObject } from '../../../../../utils/format-time';
import {
  validateLastMileCompanyInputs,
  validateTime as validateWorkingHours
} from '../../last-mile-company/validators';
import SimpleToolbar from '../../../../atoms/simple-toolbar';
import CnpjInput from '../../../../atoms/cnpj-input/index';
import permissionsFor from '../../../../../access/access';
import EmailInput from '../../../../atoms/email-input';

const validateFields = {
  lmc_name: [required(), maxLength(255)],
  dc_name: [required(), maxLength(45), distributionCenterValidator('name', 1)],
  capacity: [required(), minValue(0), maxValue(99999)],
  open_at: [validateTime],
  close_at: [validateTime],
  weekdays: [required()],
  street: [required(), maxLength(255)],
  neighborhood: [required(), maxLength(255)],
  city: [required()],
  state: [required()],
  working_hours_open_at: [validateWorkingHours],
  working_hours_close_at: [validateWorkingHours]
};

export default function CreateBase(props) {
  const classes = useStyles();
  const { loaded, permissions } = usePermissions();
  const history = useHistory();

  const authorized = permissionsFor(enums.customResources.CREATE_BASE)
    .hasRole(permissions)
    .forAction(enums.actions.CREATE);

  if (loaded && !authorized) {
    history.push(`/${enums.resources.LAST_MILE_COMPANY}`);
  }

  const properties = {
    basePath: `/${enums.customResources.CREATE_BASE}`,
    resource: enums.customResources.CREATE_BASE,
    title: LABELS.PAGE_TITLE.TITLE
  };

  const customProps = { ...props, ...properties };

  const transform = data => {
    const { pickupWorkingHours } = data.lastMileCompanyInfo;

    if (pickupWorkingHours?.openAt && pickupWorkingHours?.closeAt) {
      pickupWorkingHours.openAt = formatTimeToObject(pickupWorkingHours.openAt);
      pickupWorkingHours.closeAt = formatTimeToObject(
        pickupWorkingHours.closeAt
      );
    }

    if (data.copyCheckboxValue) {
      const ranges = mirrorDeliveryRangeToPickup(data.postalCodes.ranges);
      return { ...data, postalCodes: { ranges } };
    }
    return data;
  };

  return (
    <Create
      undoable={false}
      transform={transform}
      successMessage={LABELS.SUCCESS}
      {...customProps}
    >
      <SimpleForm
        warnWhenUnsavedChanges
        validateOnBlur
        redirect="/last-mile-company"
        toolbar={<SimpleToolbar isVersioned />}
      >
        <Grid container fullWidth>
          <Grid item xs={12}>
            <Box>
              <CardHeader title={LABELS.LMC_TITLES.DESCRIPTION} />
              <Grid item className={classes.grid}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <BaseTypeRadioButton
                        dataTestId="last-mile-company-company-type"
                        source="lastMileCompanyInfo.companyType"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container justify="space-between" spacing={2}>
                  <Grid item xs={6} sm={5}>
                    <FormDataConsumer>
                      {({ formData }) => {
                        const postalCodeGroupInfo = {
                          groupType: formData.lastMileCompanyInfo.companyType
                        };

                        return isLastMileCompanyLeve(postalCodeGroupInfo) ? (
                          <>
                            <FormControl fullWidth>
                              <TextInput
                                label={LABELS.LMC_TITLES.NAME}
                                source="lastMileCompanyInfo.name"
                                data-testid="last-mile-company-name"
                                variant="outlined"
                                validate={validateFields.lmc_name}
                                helperText={LABELS.LMC_TITLES.NAME_HELPER}
                              />
                            </FormControl>
                            <FormControl fullWidth>
                              <CnpjInput
                                hasWritePermissions={authorized}
                                label={LABELS.LMC_TITLES.CNPJ}
                                source="lastMileCompanyInfo.cnpj"
                                dataTestId="last-mile-company-cnpj"
                                fullWidth
                                variant="outlined"
                                isEditPage={false}
                              />
                            </FormControl>
                            <FormControl fullWidth>
                              <TextInput
                                label={LABELS.LMC_TITLES.CORPORATE_NAME}
                                source="lastMileCompanyInfo.corporateName"
                                data-testid="last-mile-company-input-corporate-name"
                                variant="outlined"
                                validate={
                                  validateLastMileCompanyInputs.corporateName
                                }
                              />
                            </FormControl>
                            <FormControl fullWidth>
                              <EmailInput
                                source="lastMileCompanyInfo.financialEmail"
                                dataTestId="last-mile-company-input-financial-email"
                                validations={
                                  validateLastMileCompanyInputs.financialEmail
                                }
                                variant="outlined"
                                fullWidth
                              />
                            </FormControl>
                            <FormControl fullWidth>
                              <DeliveryEarningsTableAutocomplete
                                hasWritePermissions={authorized}
                                lastMileCompanyInfo={
                                  formData.lastMileCompanyInfo
                                }
                              />
                            </FormControl>
                          </>
                        ) : (
                          <FormControl fullWidth>
                            <LmcAutocomplete
                              source="lastMileCompanyInfo.name"
                              dataTestId="last-mile-company-name-autocomplete"
                              variant="outlined"
                              validate={validateFields.lmc_name}
                              formData={formData}
                            />
                          </FormControl>
                        );
                      }}
                    </FormDataConsumer>
                    <FormDataConsumer>
                      {({ formData }) =>
                        !formData?.lastMileCompanyInfo?.id ? (
                          <>
                            <Box mb={1}>
                              <Typography variant="body2">
                                {LABELS.LMC_TITLES.ACTIVATION_TIME_DESCRIPTION}
                              </Typography>
                            </Box>
                            <FormControl>
                              <DatePickerInput
                                dataTestId="last-mile-company-activation-time"
                                defaultValue={null}
                                label={LABELS.LMC_TITLES.ACTIVATION_TIME}
                                source="lastMileCompanyInfo.activationTimeAsInt"
                              />
                            </FormControl>
                          </>
                        ) : null
                      }
                    </FormDataConsumer>
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <Box mb={1}>
                      <Typography variant="body2">
                        {LABELS.LMC_TITLES.PICKUP_TITLE}
                      </Typography>
                    </Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <TimeInput
                            label={LABELS.DC_TITLES.OPEN_AT}
                            source="lastMileCompanyInfo.pickupWorkingHours.openAt"
                            dataTestId="pickup-open-at"
                            validate={validateFields.working_hours_open_at}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <TimeInput
                            label={LABELS.DC_TITLES.CLOSE_AT}
                            source="lastMileCompanyInfo.pickupWorkingHours.closeAt"
                            dataTestId="pickup-close-at"
                            validate={validateFields.working_hours_close_at}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Box mb={1} mt={3}>
                      <Typography variant="body2">
                        {LABELS.LMC_TITLES.WORKING_WEEKDAYS}
                      </Typography>
                    </Box>
                    <Box>
                      <FormControl fullWidth>
                        <FormDataConsumer>
                          {({ formData }) => (
                            <SelectMultipleInput
                              source="lastMileCompanyInfo.pickupWorkingWeekdays"
                              dataTestId="pickup-working-weekdays"
                              selectedChoices={
                                formData?.lastMileCompanyInfo
                                  ?.pickupWorkingWeekdays
                              }
                            />
                          )}
                        </FormDataConsumer>
                      </FormControl>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Box mt={3}>
                <Divider light />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <Grid container>
                <Grid item xs={12}>
                  <CardHeader title={LABELS.DC_TITLES.DESCRIPTION} />
                </Grid>
                <Grid
                  container
                  justify="space-between"
                  className={classes.grid}
                >
                  <Grid item xs={12} sm={5}>
                    <Grid spacing={2} container>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <TextInput
                            label={LABELS.DC_TITLES.NAME}
                            source="distributionCenter.name"
                            data-testid="distribution-center-name"
                            validate={validateFields.dc_name}
                            variant="outlined"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <FormControl fullWidth>
                          <NumberInput
                            label={LABELS.DC_TITLES.CAPACITY}
                            source="distributionCenter.capacity"
                            data-testid="distribution-center-capacity"
                            variant="outlined"
                            defaultValue={100}
                            validate={validateFields.capacity}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <FormControl fullWidth>
                          <RoutingCodeInput
                            label={LABELS.DC_TITLES.ROUTING_CODE}
                            source="distributionCenter.routing_code"
                            dataTestId="distribution-center-routing-code"
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid spacing={2} container>
                      <Grid item xs={12} sm={3}>
                        <FormControl fullWidth>
                          <PostalCodeInput
                            label={LABELS.ADDRESS_TITLES.POSTAL_CODE}
                            source="address.address_components.postal_code"
                            dataTestId="address-postal-code"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={9}>
                        <FormControl fullWidth>
                          <TextInput
                            label={LABELS.ADDRESS_TITLES.STREET}
                            source="address.address_components.street"
                            data-testid="address-street"
                            variant="outlined"
                            validate={validateFields.street}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid spacing={2} container>
                      <Grid item xs={12} sm={3}>
                        <FormControl fullWidth>
                          <TextInput
                            label={LABELS.ADDRESS_TITLES.NUMBER}
                            source="address.address_components.number"
                            data-testid="address-number"
                            variant="outlined"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={9}>
                        <FormControl fullWidth>
                          <TextInput
                            label={LABELS.ADDRESS_TITLES.NEIGHBORHOOD}
                            source="address.address_components.neighborhood"
                            data-testid="address-neighborhood"
                            variant="outlined"
                            validate={validateFields.neighborhood}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid spacing={2} container>
                      <Grid item xs={12} sm={12}>
                        <FormControl fullWidth>
                          <TextInput
                            label={LABELS.ADDRESS_TITLES.COMPLEMENT}
                            source="address.address_complement"
                            data-testid="address-complement"
                            variant="outlined"
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid spacing={2} container>
                      <Grid item xs={12} sm={9}>
                        <FormControl fullWidth>
                          <TextInput
                            label={LABELS.ADDRESS_TITLES.CITY}
                            source="address.address_components.city"
                            data-testid="address-city"
                            variant="outlined"
                            validate={validateFields.city}
                            disabled
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <FormControl fullWidth>
                          <TextInput
                            label={LABELS.ADDRESS_TITLES.STATE}
                            source="address.address_components.state"
                            data-testid="address-state"
                            variant="outlined"
                            validate={validateFields.state}
                            disabled
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <Box mb={1}>
                      <Typography variant="body2">
                        {LABELS.DC_TITLES.RECEIPT_DATA}
                      </Typography>
                    </Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <TimeInput
                            label={LABELS.DC_TITLES.OPEN_AT}
                            source="distributionCenter.working_time.open_at"
                            dataTestId="distribution-center-open-at"
                            defaultValue="06:00"
                            validate={validateFields.open_at}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <TimeInput
                            label={LABELS.DC_TITLES.CLOSE_AT}
                            source="distributionCenter.working_time.close_at"
                            dataTestId="distribution-center-close-at"
                            defaultValue="22:00"
                            validate={validateFields.close_at}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Box mb={1} mt={3}>
                      <Typography variant="body2">
                        {LABELS.DC_TITLES.RECEIPT_DATA}
                      </Typography>
                    </Box>
                    <Box>
                      <FormControl fullWidth>
                        <FormDataConsumer>
                          {({ formData }) => (
                            <SelectMultipleInput
                              label={LABELS.DC_TITLES.WEEKDAYS_FIELD}
                              source="distributionCenter.working_time.weekdays"
                              dataTestId="distribution-center-weekdays"
                              defaultValue={defaultWeekDays}
                              validate={validateFields.weekdays}
                              selectedChoices={
                                formData
                                  ?.distributionCenter /* eslint-disable-next-line camelcase */
                                  ?.working_time?.weekdays
                              }
                            />
                          )}
                        </FormDataConsumer>
                      </FormControl>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Box mt={3}>
                <Divider light />
              </Box>
            </Box>
            <Grid item xs={12}>
              <Box>
                <CardHeader title={LABELS.ST_TITLES.DESCRIPTION} />
                <FormDataConsumer>
                  {({ formData }) => {
                    const postalCodeGroupInfo = getPostalCodeGroupInfo(
                      formData
                    );
                    return (
                      <PostalCodesTableTabs
                        postalCodeGroupInfo={postalCodeGroupInfo}
                        ranges={formData?.postalCodes?.ranges}
                        changes={formData?.changes}
                        hasWritePermissions={authorized}
                        earningsInfoByRegions={
                          formData?.lastMileCompanyInfo?.deliveryEarningsTable
                            ?.earningsInfoByRegions
                        }
                      />
                    );
                  }}
                </FormDataConsumer>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
}
