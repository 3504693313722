import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNotify } from 'react-admin';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableContainer from '@material-ui/core/TableContainer';
import { useForm } from 'react-final-form';
import MultiOriginRow from './multi-origin-row';
import { origins, tableHeaders, tableHeadersCategories } from './constants';
import {
  getFirstPropertyName,
  isOriginCajamar,
  getCajamarLatency,
  hasAllCustomParametersToGetMultiOriginSlos
} from './utils';
import sloMappingApi from '../../../api/slo-mapping-api';
import TableLoading from './table-loading';

const getCajamarSLO = multiOriginSlos => {
  return multiOriginSlos.find(
    element => element.name === origins.SERVICE_REGION_CAJAMAR_XDS
  )?.slo;
};

const defineAlignPosition = condition => {
  return condition ? 'left' : 'center';
};

const MultiOriginTable = props => {
  const { record, formData, setCajamarLatency, showDiffSlos } = props;
  const id = record?.id;
  const { cityType, baseCityServiceTypeId, serviceRegionId } =
    record?.serviceTypeInfo || {};
  const {
    serviceRegionId: serviceRegionIdFromSTCreatePage,
    serviceType,
    cityRegionId
  } = formData?.serviceTypeInfo || {};
  const [multiOriginSlos, setMultiOriginSlos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const notify = useNotify();
  const form = useForm();

  useEffect(() => {
    const fillMultiOriginSlos = async (
      serviceTypeId,
      parentServiceRegionId,
      serviceTypeType,
      regionId
    ) => {
      setIsLoading(true);
      setMultiOriginSlos([]);
      let response = [];

      try {
        if (serviceTypeId) {
          response = await sloMappingApi.getMultiOriginSlosFromServicetype(
            serviceTypeId,
            baseCityServiceTypeId,
            serviceRegionId
          );
        } else if (
          hasAllCustomParametersToGetMultiOriginSlos(
            parentServiceRegionId,
            serviceTypeType,
            regionId
          )
        ) {
          response = await sloMappingApi.getMultiOriginSlosFromCustomParameters(
            parentServiceRegionId,
            serviceTypeType,
            regionId
          );
        }
      } catch (error) {
        notify(error.message);
      }

      setCajamarLatency(getCajamarLatency(response));
      setMultiOriginSlos(response);
      setIsLoading(response.length);

      const cajamarSLO = getCajamarSLO(response);
      if (cajamarSLO !== undefined) {
        form.change('serviceTypeInfo.multiOriginSlo', cajamarSLO);

        if (
          form.getFieldState('serviceTypeInfo.baseCityServiceTypeName')?.dirty
        ) {
          form.change('serviceTypeInfo.slo', cajamarSLO);
        }
      }
    };

    fillMultiOriginSlos(
      id,
      serviceRegionIdFromSTCreatePage,
      serviceType,
      cityRegionId
    );
  }, [
    id,
    notify,
    cityType,
    baseCityServiceTypeId,
    serviceRegionId,
    serviceRegionIdFromSTCreatePage,
    serviceType,
    cityRegionId,
    setCajamarLatency,
    form
  ]);

  if (!multiOriginSlos.length && !isLoading) return null;

  return multiOriginSlos.length ? (
    <TableContainer component={Paper} data-testid="multiOriginTableContainer">
      <Table style={{ borderSpacing: '4px', borderCollapse: 'unset' }}>
        <TableHead>
          <TableRow data-testid="multiOriginTableHeaderCategories">
            {Object.keys(tableHeadersCategories).map(category => (
              <TableCell
                key={category}
                colSpan={tableHeadersCategories[category]}
                align={defineAlignPosition(
                  getFirstPropertyName(tableHeadersCategories) === category
                )}
                style={{ background: '#E7EEF3', color: 'black' }}
              >
                {category}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableHead>
          <TableRow data-testid="multiOriginTableHeader">
            {tableHeaders.map(headerTitle => (
              <TableCell
                key={headerTitle}
                align={defineAlignPosition(tableHeaders[0] === headerTitle)}
              >
                {headerTitle}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody data-testid="multiOriginTableBody">
          {multiOriginSlos
            .filter(origin => !isOriginCajamar(origin.name))
            .map(origin => (
              <MultiOriginRow
                key={origin.name}
                origin={origin}
                showDiffSlos={showDiffSlos}
              />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <TableLoading />
  );
};

MultiOriginTable.defaultProps = {
  record: {},
  formData: {},
  showDiffSlos: true
};

MultiOriginTable.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.string,
    serviceTypeInfo: PropTypes.shape({
      cityType: PropTypes.string,
      baseCityServiceTypeId: PropTypes.string,
      serviceRegionId: PropTypes.string
    })
  }),
  formData: PropTypes.shape({
    serviceTypeInfo: PropTypes.shape({})
  }),
  setCajamarLatency: PropTypes.func.isRequired,
  showDiffSlos: PropTypes.bool
};

export default MultiOriginTable;
