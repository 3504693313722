import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  MenuItem,
  Grid,
  Typography,
  Box,
  Divider,
  Menu
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import VersioningStatus from '../../atoms/versioning-status/versioning-status';
import VersionTimeRange from '../../atoms/version-time-range/version-time-range';

import { useStyles } from './styles';
import { createSortedVersionsMap } from './utils';
import { MAX_MENU_HEIGHT, MIN_MENU_WIDTH } from './constants';

const VersionMenuItems = props => {
  const { sortedVersionsMapByType, handleSelectOption } = props;

  return (
    <Box p={2}>
      <Grid container direction="column" spacing={1}>
        {Object.entries(sortedVersionsMapByType).map(
          ([versionType, versions]) => {
            return (
              <>
                <Grid item>
                  <VersioningStatus versioningStatusType={versionType} />
                </Grid>
                {versions.map(version => {
                  return (
                    <Grid item>
                      <MenuItem onClick={() => handleSelectOption(version.id)}>
                        <VersionTimeRange
                          versioningStatusType={version.versioningStatus}
                          effectiveOnTimestamp={version.effectiveOnTimeAsInt}
                          expiredAtTimestamp={version.expiredAtTimeAsInt}
                        />
                      </MenuItem>
                      <Divider />
                    </Grid>
                  );
                })}
              </>
            );
          }
        )}
      </Grid>
    </Box>
  );
};

VersionMenuItems.propTypes = {
  handleSelectOption: PropTypes.func.isRequired,
  sortedVersionsMapByType: PropTypes.shape({}).isRequired
};

const VersionMenu = props => {
  const { setSelectedOption, versionsList, selectedOption } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectOption = id => {
    handleClose();
    setSelectedOption(id);
  };

  const selectedVersion = versionsList.find(
    version => version.id === selectedOption
  );
  const sortedVersionsMapByType =
    versionsList.length && createSortedVersionsMap(versionsList);
  const shouldShowMenu = selectedVersion && sortedVersionsMapByType;

  return (
    (shouldShowMenu && (
      <>
        <Button
          color="primary"
          className={classes.menu}
          id="version-button"
          variant="outlined"
          endIcon={<KeyboardArrowDownIcon />}
          aria-controls={open ? 'version-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          data-testid="version-button"
        >
          <Grid container spacing={1}>
            <Grid item>
              <VersioningStatus
                versioningStatusType={selectedVersion.versioningStatus}
              />
            </Grid>
            <Grid item>
              <Typography>-</Typography>
            </Grid>
            <Grid item>
              <VersionTimeRange
                versioningStatusType={selectedVersion.versioningStatus}
                effectiveOnTimestamp={selectedVersion.effectiveOnTimeAsInt}
                expiredAtTimestamp={selectedVersion.expiredAtTimeAsInt}
              />
            </Grid>
          </Grid>
        </Button>

        <Menu
          id="version-menu"
          MenuListProps={{
            'aria-labelledby': 'version-button'
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: MAX_MENU_HEIGHT,
              minWidth: MIN_MENU_WIDTH
            }
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <VersionMenuItems
            sortedVersionsMapByType={sortedVersionsMapByType}
            handleSelectOption={handleSelectOption}
          />
        </Menu>
      </>
    )) || <></>
  );
};

VersionMenu.defaultProps = {
  versionsList: [],
  selectedOption: undefined
};

VersionMenu.propTypes = {
  setSelectedOption: PropTypes.func.isRequired,
  versionsList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      versioningStatus: PropTypes.string,
      effectiveOnTimeAsInt: PropTypes.number,
      expiredAtTimeAsInt: PropTypes.number
    })
  ),
  selectedOption: PropTypes.string
};

export default VersionMenu;
