const LABELS = {
  TITLES: {
    NAME: 'Nome',
    TYPE: 'Tipo'
  },
  FORM_DATA: {
    CITY_REGION_ID_PATH: 'serviceTypeInfo.cityRegionId',
    CITY_REGION_NAME_PATH: 'serviceTypeInfo.cityRegionName',
    SERVICE_REGION_ID_PATH: 'serviceTypeInfo.serviceRegionId',
    SERVICE_REGION_NAME_PATH: 'serviceTypeInfo.serviceRegionName'
  }
};

export default LABELS;
