import React from 'react';
import PropTypes from 'prop-types';
import { TextInput, required, minLength } from 'react-admin';

import distributionCenterValidator from '../../../utils/validators';

const validateRoutingCode = [
  required(),
  distributionCenterValidator('routing_code', 3),
  minLength(3)
];

const RoutingCodeInput = props => {
  const { label, source, dataTestId, variant } = props;

  return (
    <TextInput
      label={label}
      source={source}
      data-testid={dataTestId}
      validate={validateRoutingCode}
      variant={variant}
      parse={value => value.toUpperCase()}
      format={value => value && value.toUpperCase()}
      inputProps={{ maxLength: 4 }}
    />
  );
};

RoutingCodeInput.defaultProps = {
  variant: 'outlined'
};

RoutingCodeInput.propTypes = {
  label: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  dataTestId: PropTypes.string.isRequired,
  variant: PropTypes.string
};

export default RoutingCodeInput;
