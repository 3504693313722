import React from 'react';
import { usePermissions } from 'react-admin';
import { useHistory } from 'react-router-dom';
import AccessDeniedPage from './access-denied-page/index';
import { isAuthorized } from '../../../auth-provider';
import enums from '../../../enums';

const AccessDenied = () => {
  const { loaded, permissions } = usePermissions();
  const history = useHistory();

  if (loaded && isAuthorized(permissions)) {
    history.push(`/${enums.resources.LAST_MILE_COMPANY}`);
  }

  return <AccessDeniedPage />;
};

export default AccessDenied;
