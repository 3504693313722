import enums from '../enums';

export const memoize = fn => {
  let lastArg;
  let lastResult;
  return arg => {
    if (arg !== lastArg) {
      lastArg = arg;
      lastResult = fn(arg);
    }
    return lastResult;
  };
};

export const isTruthy = value => value === 'true';

export const isNearbyCity = value =>
  value === enums.cityTypes.CITY_TYPE_NEARBY_LMC;

export const refreshPage = () => {
  window.location.reload();
};

export const isLoggiHoje = record =>
  record?.serviceTypeInfo?.serviceType === 'SERVICE_TYPE_LOGGI_HOJE';
