import React from 'react';
import { Typography, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useStyles } from './styles';
import LABELS from './constants';
import formatTimestampAsIntToString from './utils';
import enums from '../../../enums';
import { isVersioned } from '../../../utils/versioning';
import VersioningSection from '../versioning-section/versioning-section';

const PageTitle = props => {
  const classes = useStyles();
  const {
    title,
    updatedAtTimeAsInt,
    versioningStatusType,
    effectiveOnTimestamp,
    expiredAtTimestamp
  } = props;

  const showVersioningSection = isVersioned(versioningStatusType);
  const showUpdateAtSection = updatedAtTimeAsInt > 0 && !showVersioningSection;

  return (
    <Box className={classes.pageTitleContainer}>
      <Typography
        data-testid="page-title"
        variant="h5"
        className={classes.pageTitle}
      >
        {title}
      </Typography>

      {showUpdateAtSection && (
        <Typography data-testid="last-updated-at" className={classes.updatedAt}>
          {LABELS.TITLES.LAST_UPDATED}
          {formatTimestampAsIntToString(updatedAtTimeAsInt)}
        </Typography>
      )}

      {showVersioningSection && (
        <VersioningSection
          versioningStatusType={versioningStatusType}
          effectiveOnTimestamp={effectiveOnTimestamp}
          expiredAtTimestamp={expiredAtTimestamp}
          className={classes.versioningSection}
        />
      )}
    </Box>
  );
};

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  updatedAtTimeAsInt: PropTypes.number,
  versioningStatusType: PropTypes.string,
  effectiveOnTimestamp: PropTypes.number,
  expiredAtTimestamp: PropTypes.number
};

PageTitle.defaultProps = {
  updatedAtTimeAsInt: 0,
  versioningStatusType: enums.versioningStatusType.INVALID,
  effectiveOnTimestamp: 0,
  expiredAtTimestamp: 0
};

export default PageTitle;
