import React, { useState } from 'react';
import { SelectArrayInput } from 'react-admin';
import PropTypes from 'prop-types';
import { useStyles } from './styles';
import ChoiceField from '../choice-field/choice-field';

export default function FilterSelectArrayInput(props) {
  const { choices, source, label, alwaysOn, dataTestId } = props;
  const classes = useStyles();

  const [isChoicesOnFocus, setIsChoicesOnFocus] = useState(false);
  const [selectedChoices, setSelectedChoices] = useState([]);

  return (
    <SelectArrayInput
      source={source}
      alwaysOn={alwaysOn}
      label={label}
      resettable
      helperText=""
      data-testid={dataTestId}
      className={
        isChoicesOnFocus || selectedChoices.length
          ? classes.selectArrayOnFocus
          : classes.selectArray
      }
      variant="outlined"
      choices={choices}
      optionText={<ChoiceField />}
      onFocus={() => setIsChoicesOnFocus(true)}
      onBlur={() => setIsChoicesOnFocus(false)}
      onChange={event => setSelectedChoices(event.target.value)}
    />
  );
}

FilterSelectArrayInput.defaultProps = {
  label: '',
  alwaysOn: true,
  dataTestId: 'filter-select-array-input'
};

FilterSelectArrayInput.propTypes = {
  choices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    })
  ).isRequired,
  source: PropTypes.string.isRequired,
  label: PropTypes.string,
  alwaysOn: PropTypes.bool,
  dataTestId: PropTypes.string
};
