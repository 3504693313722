import React from 'react';

import Link from '@material-ui/core/Link';
import { Typography } from '@material-ui/core';

import { useStyles } from './styles';
import { DIALOG_CONTENT } from './utils/constants';

const ContentInputError = () => {
  const classes = useStyles();

  return (
    <Typography variant="subtitle2" color="textSecondary">
      <Typography paragraph>
        {DIALOG_CONTENT.READ_ERROR}
        <br />
        {DIALOG_CONTENT.REVIEW_SHEET}
      </Typography>
      <Typography>{DIALOG_CONTENT.SHEET_INSTRUCTIONS}</Typography>
      <Link
        underline="always"
        href={DIALOG_CONTENT.SHEET_MODEL_LINK}
        className={classes.muiLink}
      >
        {DIALOG_CONTENT.HERE}
      </Link>
      .
    </Typography>
  );
};

export default ContentInputError;
