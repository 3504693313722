import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { useStyles } from './styles';

const GoBackButton = () => {
  const classes = useStyles();
  const history = useHistory();

  const handleClick = async () => {
    history.goBack();
  };

  return (
    <Box>
      <Button
        data-testid="go-back-button"
        className={classes.goBackButton}
        color="primary"
        onClick={handleClick}
      >
        <ArrowBack className={classes.goBackButtonIcon} />
      </Button>
    </Box>
  );
};

export default GoBackButton;
