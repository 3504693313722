import React from 'react';
import { Box, Grid, Button } from '@material-ui/core';
import { makeLogout } from '../../../../auth-provider';
import LABELS from '../labels';
import { useStyles } from './styles';

const SignOut = () => {
  const classes = useStyles();

  return (
    <form>
      <Box mt={3.5} textAlign="center">
        <Grid item xs={12}>
          <Box boxShadow={15} borderRadius={23} mt={3.5}>
            <Button
              variant="text"
              fullWidth
              className={classes.button}
              data-testid="logout-denied-page"
              onClick={makeLogout}
            >
              {LABELS.LOGOUT}
            </Button>
          </Box>
        </Grid>
      </Box>
    </form>
  );
};

export default SignOut;
