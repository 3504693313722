import React from 'react';
import { useSelector } from 'react-redux';
import { useRemoteConfig } from '../../../firebase';

import LoadingScreen from '../../atoms/loading-screen';
import { isTruthy } from '../../../utils/utils';
import { getAdminLoadingState } from './utils';

const SwitchableBlockScreenWhileLoading = () => {
  const isSwitchedOn = isTruthy(
    useRemoteConfig('FS_BLOCK_SCREEN_WHILE_LOADING').value
  );

  const isAdminLoading = useSelector(getAdminLoadingState) > 0;

  return isSwitchedOn && <LoadingScreen visible={isAdminLoading} />;
};

export default SwitchableBlockScreenWhileLoading;
