export const TITLES = {
  CEP: 'Filtrar por CEP',
  NAME: 'Filtrar por Nome',
  RANGE_TYPE: 'Tipo da abrangência',
  CONFIGURATION_ID: 'Filtrar por Identificador',
  CITY_TYPE: 'Tipo de cidade',
  SERVICE_REGION: 'XD de atendimento',
  LOGGI_NAME: 'Filtrar por Loggi',
  REDISPATCH_NAME: 'Filtrar por Redespacho'
};

export default { TITLES };
