import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useInput } from 'react-admin';
import MomentUtils from '@date-io/moment';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import moment from '../../../utils/moment';
import { LABELS } from './constants';
import { validateDate } from './validators';

const validate = [validateDate];

const DatePickerDialog = props => {
  const {
    label,
    dataTestId,
    source,
    variant,
    margin,
    disablePast,
    readOnly,
    ...rest
  } = props;
  const { input, meta } = useInput({
    source,
    validate,
    ...rest
  });
  const { touched, error } = meta;
  const yearsFromNow = 5;
  const yearsFromPast = 1;
  const minDate = useMemo(
    () => (disablePast ? moment() : moment().subtract(yearsFromPast, 'years')),
    [disablePast]
  );
  const maxDate = useMemo(() => moment(minDate).add(yearsFromNow, 'years'), [
    minDate
  ]);

  const handleChange = value => {
    if (value && value.isValid()) {
      input.onChange(value.unix());
    } else {
      input.onChange(value);
    }
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDatePicker
        autoOk
        margin={margin}
        variant="inline"
        data-testid={dataTestId}
        label={label}
        format="DD-MM-YYYY"
        disablePast={disablePast}
        minDate={minDate}
        maxDate={maxDate}
        inputVariant={variant}
        {...input}
        fullWidth
        invalidDateMessage={LABELS.INVALID_DATE_MESSAGE}
        maxDateMessage={LABELS.MAX_DATE_MESSAGE}
        minDateMessage={LABELS.MIN_DATE_MESSAGE}
        error={!!(touched && error)}
        helperText={touched && error}
        value={input.value ? moment.unix(input.value) : null}
        onChange={date => handleChange(date)}
        onBlur={() =>
          input.onBlur(input.value ? moment.unix(input.value) : null)
        }
        readOnly={readOnly}
      />
    </MuiPickersUtilsProvider>
  );
};

DatePickerDialog.defaultProps = {
  variant: 'outlined',
  margin: 'dense',
  disablePast: true,
  readOnly: false
};

DatePickerDialog.propTypes = {
  label: PropTypes.string.isRequired,
  dataTestId: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  variant: PropTypes.string,
  margin: PropTypes.string,
  disablePast: PropTypes.bool,
  readOnly: PropTypes.bool
};

export default DatePickerDialog;
