import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { FormControl } from '@material-ui/core';
import { FormDataConsumer } from 'react-admin';
import TimeInput from '../../../atoms/time-input';
import SelectMultipleInput from '../../../molecules/select-multiple-input';
import LABELS from './labels';
import { validateTimeInput } from './validators';

export default function PickupForm() {
  return (
    <Grid container xs={12}>
      <Grid item xs={12}>
        <Typography variant="body2">{LABELS.TITLES.PICKUP_TIME}</Typography>
      </Grid>

      <Grid container justify="flex-end" spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TimeInput
              label={LABELS.PAGE_TITLE.OPEN_AT}
              source="lastMileCompanyInfo.pickupWorkingHours.openAt"
              dataTestId="pickup-open-at"
              validate={validateTimeInput.openAt}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TimeInput
              label={LABELS.PAGE_TITLE.CLOSE_AT}
              source="lastMileCompanyInfo.pickupWorkingHours.closeAt"
              dataTestId="pickup-close-at"
              validate={validateTimeInput.closeAt}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <Typography variant="body2">
            {LABELS.TITLES.WORKING_WEEKDAYS}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <FormDataConsumer>
              {({ formData }) => (
                <SelectMultipleInput
                  source="lastMileCompanyInfo.pickupWorkingWeekdays"
                  dataTestId="pickup-working-weekdays"
                  selectedChoices={
                    formData?.lastMileCompanyInfo?.pickupWorkingWeekdays
                  }
                />
              )}
            </FormDataConsumer>
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  );
}
