import React from 'react';
import PropTypes from 'prop-types';
import { RadioButtonGroupInput } from 'react-admin';
import { useForm } from 'react-final-form';
import { LABELS } from './contants';

export default function BaseTypeRadioButton(props) {
  const { dataTestId, source } = props;

  const form = useForm();
  const handleChange = () => {
    form.change('tempLastMileName', null);
    form.change('lastMileCompanyInfo.id', null);
    form.change('lastMileCompanyInfo.name', null);
    form.change('lastMileCompanyInfo.activationTimeAsInt', null);
    form.change('lastMileCompanyInfo.deliveryEarningsTable', null);
  };

  return (
    <RadioButtonGroupInput
      label={LABELS.LABEL}
      data-testid={dataTestId}
      defaultValue="LAST_MILE_COMPANY_TYPE_LEVE"
      source={source}
      onChange={handleChange}
      choices={[
        {
          id: 'LAST_MILE_COMPANY_TYPE_LEVE',
          name: LABELS.CHOICES.LEVE
        },
        {
          id: 'LAST_MILE_COMPANY_TYPE_LOGGI',
          name: LABELS.CHOICES.LOGGI
        }
      ]}
    />
  );
}

BaseTypeRadioButton.propTypes = {
  dataTestId: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired
};
