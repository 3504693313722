const LABELS = {
  PAGE_TITLE: {
    TITLE: 'Região de Serviço:'
  },
  TITLES: {
    NAME: 'Nome',
    TYPE: 'Tipo',
    LIST_TITLE: 'Listar Regiões de Serviço',
    MIN_COLLECT_TIME_WINDOW: 'Mínima Janela de Coleta',
    INITIAL_SLOT: 'Slot Inicial',
    DROP_OFF_SLO_FIELD: 'SLO de Drop-off',
    ACTIVE: 'Ativo'
  },
  TYPES: {
    SERVICE_REGION_TYPE_INVALID: 'Desconhecido',
    SERVICE_REGION_TYPE_LOGGI_ONE: 'Loggi Envios',
    SERVICE_REGION_TYPE_CROSS_DOCKING: 'Cross Docking (XD)',
    SERVICE_REGION_TYPE_DROPOFF: 'Dropoff'
  },
  FORM_DATA: {
    SERVICE_REGION_ID_PATH: 'serviceRegionInfo.parentServiceRegionId',
    SERVICE_REGION_NAME_PATH: 'serviceRegionInfo.parentServiceRegionName',
    SERVICE_REGION_INITIAL_SLOT_PATH: 'serviceRegionInfo.initialSlot',
    SERVICE_REGION_MIN_COLLECT_WINDOW_PATH:
      'serviceRegionInfo.minCollectTimeWindow'
  }
};

export default LABELS;
