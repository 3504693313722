import portugueseMessages from '../../../../../config/portuguese-messages';

export const validateTime = (fieldValue, allValues) => {
  if (!fieldValue) return portugueseMessages.ra.validation.required;

  const {
    close_at: closeAt,
    open_at: openAt
  } = allValues.distributionCenter.working_time;

  return closeAt <= openAt ? 'Horário incorreto' : '';
};

export default { validateTime };
