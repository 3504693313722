const getUserAttrMap = () => {
  const key = Object.keys(localStorage).find(k => k.indexOf('userData') >= 0);
  const userObj = JSON.parse(localStorage[key]);
  const { UserAttributes: attributes } = userObj;

  const map = {};
  attributes.forEach(({ Value: value, Name: name }) => {
    map[name] = value;
  });
  return map;
};

export const getEmail = () => {
  const { email } = getUserAttrMap();
  return email;
};

export default getEmail;
