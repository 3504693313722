export default {
  PostalCodeGroupType: {
    POSTAL_CODE_GROUP_TYPE_SERVICE_TYPE: 1,
    POSTAL_CODE_GROUP_TYPE_LAST_MILE_COMPANY: 2,
    POSTAL_CODE_GROUP_TYPE_PRICING_REGION: 3,
    POSTAL_CODE_GROUP_TYPE_RISK_REGION: 4,
    POSTAL_CODE_GROUP_TYPE_SERVICE_REGION: 5
  },
  lastMileCompanyType: {
    LAST_MILE_COMPANY_TYPE_UNKNOWN: 1,
    LAST_MILE_COMPANY_TYPE_LOGGI: 2,
    LAST_MILE_COMPANY_TYPE_LEVE: 3,
    LAST_MILE_COMPANY_TYPE_CORREIOS_NACIONAL: 4,
    LAST_MILE_COMPANY_TYPE_CORREIOS_LOCAL: 5
  },
  serviceType: {
    SERVICE_TYPE_UNKNOWN: 1,
    SERVICE_TYPE_LOGGI_HOJE: 2,
    SERVICE_TYPE_LOGGI_EXPRESS: 3,
    SERVICE_TYPE_REDISPATCH: 4
  },
  rangeType: {
    RANGE_TYPE_INVALID: 'RANGE_TYPE_INVALID',
    RANGE_TYPE_DEFAULT: 'RANGE_TYPE_DEFAULT',
    RANGE_TYPE_PICKUP: 'RANGE_TYPE_PICKUP',
    RANGE_TYPE_DELIVERY: 'RANGE_TYPE_DELIVERY',
    RANGE_TYPE_UNPACKED_PICKUP: 'RANGE_TYPE_UNPACKED_PICKUP'
  },
  roles: {
    ADMIN: 'ABRA_ADMIN',
    READ_ALL: 'ABRA_READ_ALL',
    MEI_ADMIN: 'ABRA_MEI_ADMIN',
    WRITE_LMC: 'ABRA_WRITE_LMC',
    WRITE_DISTRIBUTION_CENTER: 'ABRA_WRITE_DC',
    WRITE_SERVICE_TYPE: 'ABRA_WRITE_SERVICE_TYPE',
    WRITE_SERVICE_REGION: 'ABRA_WRITE_SERVICE_REGION',
    WRITE_SLO_MAPPING: 'ABRA_WRITE_SLO_MAPPING',
    WRITE_EARNIGS: 'ABRA_WRITE_EARNINGS',
    PUBLISH_COVERAGE: 'ABRA_PUBLISH_COVERAGE'
  },
  resources: {
    DISTRIBUTION_CENTER: 'distribution-center',
    LAST_MILE_COMPANY: 'last-mile-company',
    PRICING_REGION: 'pricing-region',
    REGION: 'region',
    RULES: 'rules',
    SERVICE_REGION: 'service-region',
    SERVICE_TYPE: 'service-type',
    SLO_EXCEPTION: 'slo-exception',
    TRANSIT_TIME: 'transit-time',
    RE_ANNOTATION_PROMISED_DATE: 're-annotation-promised-date'
  },
  cityTypes: {
    CITY_TYPE_INVALID: 'CITY_TYPE_INVALID',
    CITY_TYPE_LOCAL_LMC: 'CITY_TYPE_LOCAL_LMC',
    CITY_TYPE_NEARBY_LMC: 'CITY_TYPE_NEARBY_LMC'
  },
  sloMappingType: {
    SLO_MAPPING_TYPE_SERVICE_TYPE: 'SLO_MAPPING_TYPE_SERVICE_TYPE',
    SLO_MAPPING_TYPE_SERVICE_REGION: 'SLO_MAPPING_TYPE_SERVICE_REGION'
  },
  customResources: {
    BULK_UPDATE_MEI_RULES: 'rules/bulk-update',
    IMPORT_REDISPATCH_SLO: 'import-redispatch-slo',
    CREATE_BASE: 'create-base',
    CREATE_DELIVERY_EARNINGS_TABLE: 'create-delivery-earnings-table',
    ACCESS_DENIED: 'access-denied',
    GENERATE_COVERAGE_SPREADSHEET: 'generate-coverage-spreadsheet',
    PUBLISH_COVERAGE: 'publish-coverage',
    RE_ANNOTATION_PROMISED_DATE: 're-annotation-promised-date'
  },
  errorCode: {
    INVALID: 'ERROR_CODE_INVALID',
    SERVICE_TYPE_OVERLAP_CONFLICT: 'ERROR_CODE_SERVICE_TYPE_OVERLAP_CONFLICT',
    SERVICE_REGION_OVERLAP_CONFLICT:
      'ERROR_CODE_SERVICE_REGION_OVERLAP_CONFLICT',
    SERVICE_TYPE_NOT_FOUND_FOR_LMC_COVERAGE:
      'ERROR_CODE_SERVICE_TYPE_NOT_FOUND_FOR_LMC_COVERAGE',
    SERVICE_REGION_NOT_FOUND_FOR_LMC_COVERAGE:
      'ERROR_CODE_SERVICE_REGION_NOT_FOUND_FOR_LMC_COVERAGE',
    SLO_MAPPING_DUPLICATED_CONFLICT:
      'ERROR_CODE_SLO_MAPPING_DUPLICATED_CONFLICT',
    TRANSIT_TIME_NOT_FOUND: 'ERROR_CODE_TRANSIT_TIME_NOT_FOUND',
    EMPTY_POSTAL_CODE_RANGE: 'ERROR_CODE_EMPTY_POSTAL_CODE_RANGE'
  },
  operations: {
    COPY: 'OPERATION_COPY'
  },
  regionTypes: {
    REGION_TYPE_INVALID: 'REGION_TYPE_INVALID',
    REGION_TYPE_STATE: 'REGION_TYPE_STATE',
    REGION_TYPE_CITY: 'REGION_TYPE_CITY',
    REGION_TYPE_DISTRICT: 'REGION_TYPE_DISTRICT',
    REGION_TYPE_STREET: 'REGION_TYPE_STREET',
    REGION_TYPE_CUSTOM_REGION: 'REGION_TYPE_CUSTOM_REGION'
  },
  modalType: {
    MODAL_TYPE_CAR: 'carro',
    MODAL_TYPE_MOTO: 'moto',
    MODAL_TYPE_VAN: 'van',
    MODAL_TYPE_NOT_MOTORIZED: 'nao_motorizado'
  },
  product: {
    PRODUCT_CORP: 'corp',
    PRODUCT_PRO: 'pro',
    PRODUCT_PRESTO: 'presto'
  },
  statusType: {
    STATUS_TYPE_CURRENT: 'Ativa',
    STATUS_TYPE_FUTURE: 'Futura'
  },
  DAY_OF_WEEK: {
    MONDAY: 1,
    TUESDAY: 2,
    WEDNESDAY: 3,
    THURSDAY: 4,
    FRIDAY: 5,
    SATURDAY: 6,
    SUNDAY: 7
  },
  actions: {
    READ: 'READ',
    CREATE: 'CREATE',
    UPDATE: 'UPDATE',
    DELETE: 'DELETE'
  },
  customActions: {
    UPDATE_LOCAL_SLO: 'UPDATE_LOCAL_SLO',
    RE_ANNOTATION_WRITE: 'ABRA_RE_ANNOTATION_WRITE'
  },
  pageType: {
    EDIT: 'EDIT',
    CREATE: 'CREATE'
  },
  versioningStatusType: {
    INVALID: 'VERSIONING_STATUS_INVALID',
    ACTIVE: 'VERSIONING_STATUS_ACTIVE',
    EXPIRED: 'VERSIONING_STATUS_EXPIRED',
    PROGRAMMED: 'VERSIONING_STATUS_PROGRAMMED',
    APPROVED: 'VERSIONING_STATUS_APPROVED',
    REJECTED: 'VERSIONING_STATUS_REJECTED'
  },
  firstMileServiceType: {
    FIRST_MILE_SERVICE_TYPE_DROPOFF: 'FIRST_MILE_SERVICE_TYPE_DROPOFF'
  }
};
