import React from 'react';
import { Box, Grid, Link, Typography, CssBaseline } from '@material-ui/core';
import { ReactComponent as Logo } from '../../../../assets/icons/logo.svg';
import LABELS from '../labels';
import { useStyles } from './styles';
import SignInForm from '../signin-form';

const SignIn = () => {
  const classes = useStyles();

  return (
    <>
      <CssBaseline />
      <Grid container className={classes.grid}>
        <Grid item xs={12} sm={12} md={6} lg={6} className={classes.root}>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            className={classes.alignLogo}
          >
            <Link
              href="http://www.loggi.com"
              target="_blank"
              className={classes.link}
            >
              <Logo className={classes.logo} />
            </Link>
          </Grid>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            className={classes.grid}
          >
            <Box className={classes.box}>
              <Box marginBottom={3}>
                <Typography
                  variant="h2"
                  component="span"
                  data-testid="welcome-title"
                >
                  {LABELS.WELCOME.TITLE}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="subtitle1"
                  component="span"
                  data-testid="welcome-container"
                >
                  {LABELS.WELCOME.CONTAINER}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            className={classes.grid}
          >
            <SignInForm />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SignIn;
