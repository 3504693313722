import React from 'react';
import PropTypes from 'prop-types';
import { TextInput } from 'react-admin';
import LABELS from './labels';

const EmailInput = props => {
  const {
    label,
    source,
    dataTestId,
    validations,
    variant,
    hasWritePermissions
  } = props;

  return (
    <TextInput
      label={label}
      source={source}
      data-testid={dataTestId}
      validate={validations}
      variant={variant}
      disabled={!hasWritePermissions}
    />
  );
};

EmailInput.defaultProps = {
  label: LABELS.EMAIL,
  dataTestId: LABELS.EMAIL_DATA_TEST_ID,
  validations: [],
  variant: 'outlined',
  hasWritePermissions: true
};

EmailInput.propTypes = {
  label: PropTypes.string,
  source: PropTypes.string.isRequired,
  dataTestId: PropTypes.string,
  validations: PropTypes.arrayOf(PropTypes.func),
  variant: PropTypes.string,
  hasWritePermissions: PropTypes.bool
};

export default EmailInput;
