import React from 'react';
import { Datagrid, TextField, List, EditButton, ShowButton } from 'react-admin';
import PropTypes from 'prop-types';
import SloMappingFilter from '../../../molecules/slo-mapping-filter';

import LABELS from './labels';
import enums from '../../../../enums';
import permissionsFor from '../../../../access/access';

export default function SloExceptionList(props) {
  const { permissions } = props;
  const canUpdate = permissionsFor(enums.resources.SLO_EXCEPTION)
    .hasRole(permissions)
    .forAction(enums.actions.UPDATE);

  return (
    <List
      filters={<SloMappingFilter />}
      exporter={false}
      bulkActionButtons={false}
      title={LABELS.TITLES.LIST_TITLE}
      {...props}
    >
      <Datagrid>
        <TextField source="originName" label={LABELS.TITLES.ORIGIN} />
        <TextField source="destinationName" label={LABELS.TITLES.DESTINATION} />
        <TextField source="sloMapping.slo" label={LABELS.TITLES.SLO} />
        <TextField
          source="sloMapping.sloOffset"
          label={LABELS.TITLES.SLO_OFFSET}
        />
        {canUpdate ? <EditButton /> : <ShowButton />}
      </Datagrid>
    </List>
  );
}

SloExceptionList.defaultProps = {
  permissions: []
};

SloExceptionList.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string)
};
