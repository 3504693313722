import React from 'react';
import PropTypes from 'prop-types';

import get from 'lodash/get';

export default function LabeledEnumTextField(props) {
  const { source, record, enumLabels } = props;
  const key = get(record, source);
  const content = (key && (enumLabels[key] ? enumLabels[key] : key)) || '-';

  return <span>{content}</span>;
}

LabeledEnumTextField.propTypes = {
  // The label attribute isn’t used in the render() method, but react-admin uses it to display
  // the table header so we still have to do its prop validation.
  // eslint-disable-next-line react/no-unused-prop-types
  label: PropTypes.string,
  record: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    lastMileCompanyInfo: PropTypes.shape({}),
    serviceTypeInfo: PropTypes.shape({})
  }),
  source: PropTypes.string.isRequired,
  enumLabels: PropTypes.objectOf(PropTypes.string).isRequired
};

LabeledEnumTextField.defaultProps = {
  label: '',
  record: {
    id: '',
    name: '',
    serviceTypeInfo: {},
    lastMileCompanyInfo: {}
  }
};
