import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@loggi/mar';

export const useStyles = makeStyles(({ palette, spacing }) => ({
  grid: {
    minHeight: '100vh'
  },
  box: {
    maxWidth: '600px'
  },
  root: {
    backgroundImage: `linear-gradient(135deg, ${
      palette.primary.main
    }, #007CFF)`,
    color: colors.root[0],
    position: 'relative'
  },
  alignLogo: {
    position: 'absolute',
    height: '200px'
  },
  link: {
    paddingLeft: spacing(1),
    color: colors.blue[500],
    width: '430px',
    textAlign: 'left'
  },
  logo: {
    width: '100px',
    height: '100px'
  }
}));

export default { useStyles };
