import React from 'react';
import PropTypes from 'prop-types';

import get from 'lodash/get';
import CheckCircle from '@material-ui/icons/CheckCircle';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import Chip from '@material-ui/core/Chip';

const ActiveChip = ({ source, record = {} }) => {
  const active = get(record, source);
  const label = active ? 'Ativo' : 'Inativo';
  const color = active ? 'primary' : 'default';

  const icon = active ? (
    <CheckCircle data-testid="check-circle" />
  ) : (
    <RemoveCircle data-testid="remove-circle" />
  );

  return (
    <Chip
      data-testid="active-chip"
      size="small"
      icon={icon}
      label={label}
      color={color}
    />
  );
};

ActiveChip.defaultProps = {
  record: {
    id: '',
    lastMileCompanyInfo: {},
    companyType: ''
  }
};

ActiveChip.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.string,
    lastMileCompanyInfo: PropTypes.shape({}),
    companyType: PropTypes.string
  }),
  source: PropTypes.string.isRequired
};

export default ActiveChip;
