import React from 'react';
import PropTypes from 'prop-types';
import { useNotify } from 'react-admin';
import Autocomplete, {
  createFilterOptions
} from '@material-ui/lab/Autocomplete';
import debounce from 'lodash/debounce';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Api from '../../../api/postal-code-api';
import { LABELS } from './constants';
import { LABELS as POSTAL_CODE_RANGE_LABELS } from '../add-postal-code-range/constants';

const RegionsAutocomplete = props => {
  const { handleSelectedRegion, regionNameToDisplay } = props;
  const notify = useNotify();
  const [regionNames, setRegionNames] = React.useState([]);

  const updateOptionsDebounced = debounce(async searchText => {
    try {
      const response = await Api.getRegions({ name: searchText });
      setRegionNames(response?.data?.regions || []);
    } catch {
      notify(LABELS.ERRORS.FIND_REGIONS, 'warning');
    }
  }, 500);

  const handleInputChange = React.useCallback(
    (event, newInputValue) => {
      // Dont find again when selecting value with click or keydown
      if (event?.type !== 'change') return;

      updateOptionsDebounced(newInputValue);
    },
    // eslint-disable-next-line
    []
  );

  const getRegionFromApi = async regionId => {
    let region = {};

    try {
      const response = await Api.getRegion(regionId);
      region = response.data;
    } catch {
      notify(LABELS.ERRORS.GET_REGION_ERROR_MESSAGE, 'warning');
    }

    return region;
  };

  const handleOnChange = async (event, newValue, reason) => {
    let region = {};

    if (typeof newValue === 'string') {
      region.name = newValue;
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      region.name = newValue.inputValue;
    } else if (reason === 'select-option') {
      const regionId = newValue._id.$oid;
      region = await getRegionFromApi(regionId);
    }

    handleSelectedRegion(region, reason);
  };

  const handleGetOptionLabel = option => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return option;
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // Regular option
    return option.name;
  };

  const renderOption = option => (
    <Grid container alignItems="center">
      <Grid item xs>
        <Typography fontWeight="fontWeightBold">{option.name}</Typography>
      </Grid>
    </Grid>
  );

  const filter = createFilterOptions();

  const filterOptions = (options, params) => {
    const filtered = filter(options, params);

    // Suggest the creation of a new value
    if (params.inputValue !== '') {
      filtered.push({
        inputValue: params.inputValue,
        name: `Add "${params.inputValue}"`
      });
    }

    return filtered;
  };

  return (
    <Autocomplete
      value={regionNameToDisplay}
      onChange={handleOnChange}
      onInputChange={handleInputChange}
      filterOptions={filterOptions}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      freeSolo
      options={regionNames}
      getOptionLabel={handleGetOptionLabel}
      renderOption={renderOption}
      getOptionSelected={(optionSelected, optionSelectedValue) =>
        optionSelected?.name === optionSelectedValue
      }
      renderInput={params => (
        <TextField
          {...params}
          data-testid="regions-autocomplete-input"
          placeholder={POSTAL_CODE_RANGE_LABELS.INPUTS.NAME}
        />
      )}
    />
  );
};

RegionsAutocomplete.defaultProps = {
  regionNameToDisplay: ''
};

RegionsAutocomplete.propTypes = {
  handleSelectedRegion: PropTypes.func.isRequired,
  regionNameToDisplay: PropTypes.string
};

export default RegionsAutocomplete;
