import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Link } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import { Button as MuiButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import LABELS from './labels';
import baseCityUrl from './utils';
import enums from '../../../../enums';
import PostalCodesTable from '../../../molecules/postal-codes-table';
import { getPostalCodeGroupInfo } from '../../../molecules/postal-codes-table/utils';
import DialogConflictRanges from '../../../molecules/dialog-conflict-ranges';
import MultiOriginTable from '../../../molecules/multi-origin-table';
import CustomDivider from '../../../atoms/custom-divider/custom-divider';
import SectionTitle from '../../../atoms/section-title/section-title';
import ServiceTypeInfo from '../../../organisms/service-type-info';
import ServiceTypeSloConfig from '../../../organisms/service-type-slo-config';
import { isLoggiHoje, isNearbyCity } from '../../../../utils/utils';

export default function ServiceTypeForm({
  hasPermissions,
  formData,
  pageType,
  errorRanges,
  handleCloseDialog,
  serviceTypeTypes
}) {
  const [cajamarLatency, setCajamarLatency] = useState({});
  const postalCodeGroupInfo = getPostalCodeGroupInfo(formData);
  const isEditPage = pageType === enums.pageType.EDIT;
  const isNearCity = isNearbyCity(formData?.serviceTypeInfo?.cityType);

  return (
    <>
      <Grid container alignItems="flex-end" fullWidth spacing={2} xs={12}>
        <Grid
          justifyContent="center"
          alignItens="center"
          item
          lg={isEditPage ? 9 : 12}
        >
          <ServiceTypeInfo
            hasPermissions={hasPermissions}
            serviceTypeTypes={serviceTypeTypes}
            isEditPage={isEditPage}
            isNearCity={isNearCity}
          />
        </Grid>

        {isEditPage && (
          <Grid lg={3} item>
            {isNearCity && (
              <Button
                style={{ padding: '0 0 26px 8px' }}
                data-testid="baseCityButton"
              >
                <Link to={baseCityUrl(formData)} target="_blank">
                  <MuiButton
                    variant="outlined"
                    startIcon={
                      hasPermissions ? (
                        <EditIcon data-testid="edit-icon-component" />
                      ) : (
                        <VisibilityIcon data-testid="view-icon-component" />
                      )
                    }
                    color="primary"
                    style={{ fontSize: '14px' }}
                  >
                    {`${(hasPermissions
                      ? LABELS.BUTTONS.ACTIONS.EDIT
                      : LABELS.BUTTONS.ACTIONS.VIEW) +
                      LABELS.BUTTONS.BASE_CITY}`}
                  </MuiButton>
                </Link>
              </Button>
            )}
          </Grid>
        )}
      </Grid>

      <CustomDivider />

      <ServiceTypeSloConfig
        hasPermissions={hasPermissions}
        cajamarLatency={cajamarLatency}
        isEditPage={isEditPage}
        isNearCity={isNearCity}
      />

      {!isLoggiHoje(formData) && (
        <MultiOriginTable
          setCajamarLatency={setCajamarLatency}
          record={isEditPage && formData}
          formData={!isEditPage && formData}
          showDiffSlos={isEditPage}
        />
      )}

      <CustomDivider />

      <SectionTitle title={LABELS.TITLES.POSTAL_CODES_RANGES} />

      <PostalCodesTable
        postalCodeGroupInfo={postalCodeGroupInfo}
        ranges={formData?.postalCodes?.ranges}
        changes={isEditPage && formData.changes}
        hasWritePermissions={hasPermissions}
        typeOfRanges={enums.rangeType.RANGE_TYPE_DEFAULT}
      />

      <DialogConflictRanges
        conflicts={errorRanges}
        postalCodeGroupName={postalCodeGroupInfo.name}
        onClose={handleCloseDialog}
        open={!!errorRanges?.length}
      />
    </>
  );
}

ServiceTypeForm.propTypes = {
  hasPermissions: PropTypes.bool.isRequired,
  formData: PropTypes.objectOf(PropTypes.string).isRequired,
  pageType: PropTypes.string.isRequired,
  errorRanges: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  serviceTypeTypes: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string))
    .isRequired
};
