import React from 'react';
import PropTypes from 'prop-types';

import { TextInput, Filter, SelectInput } from 'react-admin';
import { useStyles } from './styles';
import LABELS from './labels';

const MeiRulesFilters = props => {
  const classes = useStyles();

  const { modalchoices, productchoices, statuschoices } = props;

  return (
    <Filter {...props} variant="outlined">
      <TextInput
        source="city"
        alwaysOn
        label={LABELS.CITY}
        resettable
        data-testid="city-filter"
      />
      <SelectInput
        source="product"
        alwaysOn
        label={LABELS.PRODUCT}
        resettable
        allowEmpty={false}
        fullWidth
        choices={productchoices}
        data-testid="product-filter"
        className={classes.selectInput}
      />
      <SelectInput
        source="modal"
        alwaysOn
        label={LABELS.MODAL_TYPE}
        resettable
        allowEmpty={false}
        fullWidth
        choices={modalchoices}
        data-testid="modal-filter"
        className={classes.selectInput}
      />
      <TextInput
        source="tier"
        alwaysOn
        label={LABELS.TIER}
        resettable
        data-testid="tier-filter"
      />
      <SelectInput
        source="status"
        alwaysOn
        label={LABELS.STATUS}
        resettable
        allowEmpty={false}
        fullWidth
        choices={statuschoices}
        data-testid="status-filter"
        className={classes.selectInput}
      />
    </Filter>
  );
};

MeiRulesFilters.defaultProps = {
  modalchoices: [],
  productchoices: [],
  statuschoices: [],
  record: {},
  resource: ''
};

MeiRulesFilters.propTypes = {
  modalchoices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    })
  ),
  productchoices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    })
  ),
  statuschoices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    })
  ),
  record: PropTypes.shape({
    name: PropTypes.string
  }),
  resource: PropTypes.string
};

export default MeiRulesFilters;
