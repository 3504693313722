import React from 'react';
import PropTypes from 'prop-types';
import { useInput } from 'react-admin';
import TextField from '@material-ui/core/TextField';

const TimeInput = props => {
  const { label, dataTestId, source, ...rest } = props;
  const {
    input,
    meta: { error, invalid, touched }
  } = useInput({
    source,
    ...rest
  });

  return (
    <TextField
      data-testid={dataTestId}
      label={label}
      type="time"
      inputProps={{
        step: 900
      }}
      variant="outlined"
      margin="dense"
      {...input}
      error={!!(invalid && error && touched)}
      helperText={error}
      InputLabelProps={{
        shrink: true
      }}
    />
  );
};

TimeInput.defaultProps = {
  error: null
};

TimeInput.propTypes = {
  label: PropTypes.string.isRequired,
  dataTestId: PropTypes.string.isRequired,
  error: PropTypes.func,
  source: PropTypes.string.isRequired
};

export default TimeInput;
