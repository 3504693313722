import React from 'react';
import { Create, SimpleForm, useNotify } from 'react-admin';
import { useForm } from 'react-final-form';
import { Box, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import moment from '../../../../../utils/moment';
import enums from '../../../../../enums';
import GenericToolbar from '../../../../atoms/generic-toolbar';
import PageTitle from '../../../../molecules/page-title';
import SectionTitle from '../../../../atoms/section-title/section-title';
import MultipleServiceRegionGroupAutocomplete from '../../../../molecules/multiple-service-region-group-autocomplete';
import CustomDivider from '../../../../atoms/custom-divider/custom-divider';
import DatePickerDialog from '../../../../molecules/date-picker-input';
import UpdateTimestampRangePicker from '../../../../molecules/update-timestamp-range-picker';
import validateUserInput from './validators';
import LABELS from './labels';

const todayDate = moment()
  .startOf('day')
  .unix();

function transform(data) {
  return {
    targetDateTimestampInMs: data.dateInSeconds * 1000,
    ...data
  };
}

const UpdateTimestampRangePickerWithForm = props => {
  const form = useForm();
  return <UpdateTimestampRangePicker form={form} {...props} />;
};

export default function PublishCoveragePage(props) {
  const notify = useNotify();

  const style = {
    fontSize: '14px',
    lineHeight: '150%',
    color: '#757D8C',
    fontFamily: 'Open Sans'
  };

  const properties = {
    basePath: `/${enums.customResources.PUBLISH_COVERAGE}`,
    resource: enums.customResources.PUBLISH_COVERAGE,
    title: LABELS.PAGE_TITLE.TITLE
  };
  const customProps = { ...props, ...properties };

  const onSuccess = () => {
    notify(LABELS.AFTER_ACTION.SUCCESS);
  };

  return (
    <Create {...customProps} onSuccess={onSuccess} transform={transform}>
      <SimpleForm
        variant="outlined"
        toolbar={<GenericToolbar buttonText={LABELS.ACTIONS.PUBLISH} />}
        validate={validateUserInput}
      >
        <PageTitle title={LABELS.PAGE_TITLE.TITLE} />
        <Grid fullWidth>
          <SectionTitle title={LABELS.SECTIONS.ORIGINS} mb={0} />
          <Box mb={2}>
            <Typography style={style}>
              {LABELS.SECTION_SUBTITLE.ORIGINS}
            </Typography>
          </Box>
        </Grid>
        <Grid container fullWidth>
          <Grid item xs={12}>
            <MultipleServiceRegionGroupAutocomplete
              label={LABELS.SECTIONS.ORIGINS}
              required
              outputIdsField="originServiceRegionIds"
            />
          </Grid>
          <CustomDivider />
        </Grid>
        <Grid container fullWidth>
          <SectionTitle title={LABELS.SECTIONS.UPDATE_TIMESTAMP_RANGE} mb={0} />
          <Box mb={2}>
            <Typography style={style}>
              {LABELS.SECTION_SUBTITLE.UPDATE_TIMESTAMP_RANGE}
            </Typography>
          </Box>
          <Grid item xs={12}>
            <UpdateTimestampRangePickerWithForm />
          </Grid>
          <CustomDivider />
        </Grid>
        <Grid fullWidth>
          <SectionTitle title={LABELS.SECTIONS.REFERENCE_DATE_TIME} mb={0} />
          <Box mb={2}>
            <Typography style={style}>
              {LABELS.SECTION_SUBTITLE.REFERENCE_DATE_TIME}
            </Typography>
          </Box>
        </Grid>
        <Grid xs="auto">
          <DatePickerDialog
            defaultValue={todayDate}
            label={LABELS.INPUT_TEXT.DATE_LABEL}
            source={LABELS.FORM_DATA.DATE_IN_SECONDS}
            disablePast
            dataTestId={LABELS.DATA_TEST.DATE_PICKER_DIALOG_PUBLISH_COVERAGE}
          />
        </Grid>
      </SimpleForm>
    </Create>
  );
}
