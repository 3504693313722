import React from 'react';
import { Typography } from '@material-ui/core';
import { CONTENT_NOT_MODIFIED } from './utils/constants';

const ContentNotModifiedError = () => {
  return (
    <Typography
      variant="subtitle2"
      color="textSecondary"
      data-testid="notModifiedDescription"
    >
      {CONTENT_NOT_MODIFIED.READ_ERROR}
      <br />
      <br />
      {CONTENT_NOT_MODIFIED.REVIEW_SHEET}
    </Typography>
  );
};

export default ContentNotModifiedError;
