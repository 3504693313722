import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const ScreenHeader = ({ title, subTitle }) => (
  <>
    <Typography variant="h4" component="h1" gutterBottom>
      <Box fontWeight="fontWeightMedium" component="strong">
        {title}
      </Box>
    </Typography>
    {subTitle && (
      <Typography variant="h6" component="h2">
        {subTitle}
      </Typography>
    )}
  </>
);

ScreenHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string
};

ScreenHeader.defaultProps = {
  subTitle: null
};

export default ScreenHeader;
