import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  toolbar: {
    justifyContent: 'flex-end'
  },
  deleteButton: {
    fontSize: '0.875rem'
  }
});

export default useStyles;
