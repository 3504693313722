import React from 'react';
import { TextInput } from 'react-admin';
import { InputAdornment } from '@material-ui/core';
import PropTypes from 'prop-types';
import CurrencyNumberFormat from '../../atoms/currency-number-format';

const BrazilianCurrencyInput = ({
  label,
  source,
  defaultValue,
  allowNegative,
  testId,
  variant,
  validate
}) => (
  <TextInput
    label={label}
    source={source}
    variant={variant}
    data-testid={testId}
    defaultValue={defaultValue}
    validate={validate}
    InputProps={{
      inputComponent: CurrencyNumberFormat,
      inputProps: { allowNegative },
      startAdornment: <InputAdornment position="start">R$</InputAdornment>
    }}
  />
);

BrazilianCurrencyInput.defaultProps = {
  testId: 'brazilian-currency-input',
  variant: 'outlined',
  validate: null
};

BrazilianCurrencyInput.propTypes = {
  label: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  defaultValue: PropTypes.number.isRequired,
  allowNegative: PropTypes.bool.isRequired,
  testId: PropTypes.string,
  variant: PropTypes.string,
  validate: PropTypes.func
};

export default BrazilianCurrencyInput;
