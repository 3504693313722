export const TEXTS = {
  NO_OPTIONS: 'Nenhum registro encontrado',
  LABEL: 'Buscar tabela de precificação',
  ASSOCIATION_DATE: 'Data de associação'
};

export const ERRORS = {
  FIND_LABELS_ERROR: 'Erro ao tentar buscar labels',
  FIND_SUMMARIES_ERROR: 'Erro ao tentar buscar tabelas de precificação'
};
