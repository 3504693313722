import React from 'react';
import PropTypes from 'prop-types';
import {
  Datagrid,
  EditButton,
  FunctionField,
  List,
  ShowButton,
  TextField
} from 'react-admin';
import cutoffHourFromOffset from '../../../../utils/cutoff-hour-from-offset';
import DistributionCenterFilter from '../../../molecules/distribution-center-filters';
import enums from '../../../../enums';
import LABELS from './labels';
import permissionsFor from '../../../../access/access';

export default function DistributionCenterList(props) {
  const { permissions } = props;
  const canUpdate = permissionsFor(enums.resources.DISTRIBUTION_CENTER)
    .hasRole(permissions)
    .forAction(enums.actions.UPDATE);

  return (
    <List
      filters={<DistributionCenterFilter />}
      bulkActionButtons={false}
      exporter={false}
      title={LABELS.PAGE_TITLE.DEADLINE_OFFSET_LIST}
      {...props}
    >
      <Datagrid>
        <TextField source="name" label={LABELS.TITLES.BASE} />
        <TextField source="routing_code" label={LABELS.TITLES.ROUTING_CODE} />
        <FunctionField
          label={LABELS.TITLES.DEADLINE_OFFSET}
          render={record =>
            (record &&
              record.confirmation_deadline_offset &&
              cutoffHourFromOffset(record.confirmation_deadline_offset)) ||
            LABELS.TITLES.WITHOUT_OFFSET
          }
        />
        {canUpdate ? <EditButton /> : <ShowButton />}
      </Datagrid>
    </List>
  );
}

DistributionCenterList.defaultProps = {
  permissions: []
};

DistributionCenterList.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string)
};
