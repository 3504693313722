import enums from '../enums';
import { filterRangesByType } from '../view/molecules/postal-codes-table/utils';

const mirrorDeliveryRangeToPickup = ranges => {
  const deliveryRanges = filterRangesByType(
    ranges,
    enums.rangeType.RANGE_TYPE_DELIVERY
  );

  const pickupRanges = deliveryRanges.map(range => ({
    ...range,
    rangeType: enums.rangeType.RANGE_TYPE_PICKUP
  }));

  return [...deliveryRanges, ...pickupRanges];
};

export default mirrorDeliveryRangeToPickup;
