import enums from '../enums';

const { resources } = enums;

// React-Admin needs an `id` property for every record, so it can keep track of changes.
// This function moves the value stored at `_id.$oid` from the API response to a property `id`
export const normalize = element => {
  const { _id, ...rest } = element;
  return { id: _id.$oid, ...rest };
};

// API request bodies should not have an `id` (like React-Admin) or `_id` property, since it can
// cause conflict during write operations. This function removes the `id` property used internally
// by React-Admin so the request can work properly
export const denormalize = element => {
  const { id, ...rest } = element;
  return rest;
};

export const isDistributionCenterRequest = groupName =>
  groupName === resources.DISTRIBUTION_CENTER;

export const isLastMileCompanyRequest = groupName =>
  groupName === resources.LAST_MILE_COMPANY;

export const isPricingRegionRequest = groupName =>
  groupName === resources.PRICING_REGION;

export const isRegionRequest = groupName => groupName === resources.REGION;

export const isRulesRequest = groupName => groupName === resources.RULES;

export const isServiceTypeRequest = groupName =>
  groupName === resources.SERVICE_TYPE;

export const isServiceRegionRequest = groupName =>
  groupName === resources.SERVICE_REGION;
