import React from 'react';
import PropTypes from 'prop-types';
import { DeleteWithConfirmButton } from 'react-admin';
import LABELS from './labels';
import enums from '../../../enums';
import permissionsFor from '../../../access/access';

const shouldRenderDeleteButton = (permissions, record) => {
  const canUserDelete = permissionsFor(enums.resources.RULES)
    .hasRole(permissions)
    .forAction(enums.actions.DELETE);

  const canRuleBeDeleted =
    record && record.canBeUpdated && record.status === LABELS.FUTURE;

  return canUserDelete && canRuleBeDeleted;
};

export default function MeiRulesDeleteConditionalBtn(props) {
  const { permissions, record } = props;

  return shouldRenderDeleteButton(permissions, record) ? (
    <DeleteWithConfirmButton {...props} data-testid="mei-rules-delete-button" />
  ) : null;
}

MeiRulesDeleteConditionalBtn.propTypes = {
  record: PropTypes.shape({
    canBeUpdated: PropTypes.bool,
    status: PropTypes.string
  }),
  permissions: PropTypes.arrayOf(PropTypes.string)
};

MeiRulesDeleteConditionalBtn.defaultProps = {
  record: {
    canBeUpdated: false,
    status: ''
  },
  permissions: []
};
