import React from 'react';
import PropTypes from 'prop-types';

import {
  Show,
  SimpleShowLayout,
  TextField,
  ArrayField,
  Datagrid,
  FunctionField,
  downloadCSV,
  TopToolbar
} from 'react-admin';

import jsonExport from 'jsonexport/dist';

import Button from '@material-ui/core/Button';
import SaveAltIcon from '@material-ui/icons/SaveAlt';

import LABELS from './labels';
import formatPostalCode from '../../../../utils/format-postal-code';

const PriccingRegionShowActions = ({ data }) => {
  const handleClick = () => {
    const {
      postalCodes,
      pricingRegionInfo: { regionName }
    } = data;
    const { ranges } = postalCodes;
    const rangesForExport = ranges.map(range => {
      return {
        [LABELS.TITLES.NAME]: range.name,
        [LABELS.TITLES.START]: range.start.code,
        [LABELS.TITLES.END]: range.end.code
      };
    });

    jsonExport(
      rangesForExport,
      {
        headers: [LABELS.TITLES.NAME, LABELS.TITLES.START, LABELS.TITLES.END]
      },
      (_, csv) => {
        downloadCSV(csv, regionName);
      }
    );
  };

  return (
    <TopToolbar>
      <Button
        size="small"
        color="primary"
        startIcon={<SaveAltIcon />}
        data-testid="exporter-buttton"
        onClick={handleClick}
      >
        {LABELS.TITLES.DOWNLOAD}
      </Button>
    </TopToolbar>
  );
};

PriccingRegionShowActions.propTypes = {
  data: PropTypes.shape({
    postalCodes: PropTypes.shape({
      ranges: PropTypes.arrayOf(
        PropTypes.shape({
          start: PropTypes.shape({
            code: PropTypes.string
          }),
          end: PropTypes.shape({
            code: PropTypes.string
          }),
          name: PropTypes.string
        })
      )
    }),
    pricingRegionInfo: PropTypes.shape({
      regionName: PropTypes.string
    })
  }).isRequired
};

export default function PricingRegionShow(props) {
  return (
    <Show actions={<PriccingRegionShowActions />} {...props}>
      <SimpleShowLayout>
        <TextField
          label={LABELS.TITLES.REGION_NAME}
          source="pricingRegionInfo.regionName"
        />
        <ArrayField
          label={LABELS.TITLES.LIST_POSTAL_CODE}
          source="postalCodes.ranges"
        >
          <Datagrid optimized>
            <TextField label={LABELS.TITLES.NAME} source="name" />
            <FunctionField
              label={LABELS.TITLES.START}
              render={data => formatPostalCode(data.start.code)}
            />
            <FunctionField
              label={LABELS.TITLES.END}
              render={data => formatPostalCode(data.end.code)}
            />
          </Datagrid>
        </ArrayField>
      </SimpleShowLayout>
    </Show>
  );
}
