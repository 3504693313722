import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@loggi/mar';

export const useStyles = makeStyles({
  button: {
    width: '100%',
    backgroundColor: colors.storm[50],
    '&:hover': {
      backgroundColor: colors.storm[100]
    }
  }
});

export default { useStyles };
