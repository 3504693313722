import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import {
  CheckboxGroupInput,
  Create,
  FormDataConsumer,
  RadioButtonGroupInput,
  SimpleForm
} from 'react-admin';
import DatePickerInput from '../../../../molecules/date-picker-input';
import CustomDivider from '../../../../atoms/custom-divider/custom-divider';
import SectionTitle from '../../../../atoms/section-title/section-title';
import PageTitle from '../../../../molecules/page-title';
import PostalCodeGroupAutocomplete from '../../../../molecules/postal-code-group-autocomplete';
import GenericToolbar from '../../../../atoms/generic-toolbar';
import validations from './validations';
import SERVICES_CHOICES from './constants';
import LABELS from './labels';
import enums from '../../../../../enums';
import { useStyles } from './styles';
import {
  transformToPickupData,
  transformToDeliveryData,
  transformToDropoffData,
  isCoverageTypeDelivery,
  isCoverageTypeDropoff
} from './utils';
import moment from '../../../../../utils/moment';

const transform = data => {
  let response;

  if (isCoverageTypeDelivery(data?.coverageType))
    response = transformToDeliveryData(data);
  else if (isCoverageTypeDropoff(data?.coverageType))
    response = transformToDropoffData(data);
  else response = transformToPickupData(data);

  return response;
};

export default function GenerateCoverageSpreadsheetPage(props) {
  const classes = useStyles();

  const properties = {
    basePath: `/${enums.customResources.GENERATE_COVERAGE_SPREADSHEET}`,
    resource: enums.customResources.GENERATE_COVERAGE_SPREADSHEET,
    title: LABELS.PAGE_TITLE.TITLE
  };

  const customProps = { ...props, ...properties };

  return (
    <Create {...customProps} transform={transform}>
      <SimpleForm
        variant="outlined"
        warnWhenUnsavedChanges
        toolbar={
          <GenericToolbar buttonText={LABELS.BUTTON.BUTTON_SUBIMIT_TEXT} />
        }
        validate={validations}
      >
        <Grid container fullWidth>
          <PageTitle title={LABELS.PAGE_TITLE.TITLE} />
          <Grid item xs={12}>
            <SectionTitle title={LABELS.SECTION_TITLE.COVERAGE_TYPE} mb={0} />
            <Typography className={classes.body2}>
              {LABELS.SECTION_SUBTITLE.COVERAGE_TYPE}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <RadioButtonGroupInput
              row={false}
              label=""
              source="coverageType"
              choices={[
                {
                  id: enums.rangeType.RANGE_TYPE_PICKUP,
                  name: LABELS.RANGE_TYPE.RANGE_TYPE_PICKUP
                },
                {
                  id: enums.rangeType.RANGE_TYPE_DELIVERY,
                  name: LABELS.RANGE_TYPE.RANGE_TYPE_DELIVERY
                },
                {
                  id:
                    enums.firstMileServiceType.FIRST_MILE_SERVICE_TYPE_DROPOFF,
                  name:
                    LABELS.FIRST_MILE_SERVICE_TYPE
                      .FIRST_MILE_SERVICE_TYPE_DROPOFF
                }
              ]}
            />
          </Grid>
          <FormDataConsumer>
            {({ formData }) => {
              return (
                isCoverageTypeDelivery(formData?.coverageType) && (
                  <>
                    <Grid item xs={12}>
                      <SectionTitle
                        title={LABELS.SECTION_TITLE.ORIGIN}
                        mb={0}
                      />
                      <Box mb={3}>
                        <Typography className={classes.body2}>
                          {LABELS.SECTION_SUBTITLE.ORIGIN}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <PostalCodeGroupAutocomplete
                        hasWritePermissions
                        postalCodeGroupIdFormPath={
                          LABELS.FORM_DATA.SERVICE_REGION_ID_PATH
                        }
                        postalCodeGroupNameFormPath={
                          LABELS.FORM_DATA.SERVICE_REGION_NAME_PATH
                        }
                        label={LABELS.SECTION_TITLE.ORIGIN}
                        resourceName={enums.resources.SERVICE_REGION}
                        required
                      />
                    </Grid>
                    <CustomDivider />
                    <Grid item xs={12}>
                      <Grid item mb={3} xs={3}>
                        <CheckboxGroupInput
                          dataTestId="generate-coverage-spreadsheet-service-type-input"
                          label={
                            <Typography className={classes.h4}>
                              {LABELS.SECTION_TITLE.SERVICE_TYPE}
                            </Typography>
                          }
                          source="serviceTypes"
                          choices={SERVICES_CHOICES}
                          row={false}
                        />
                      </Grid>
                    </Grid>
                  </>
                )
              );
            }}
          </FormDataConsumer>
        </Grid>
        <FormDataConsumer>
          {({ formData }) => {
            return (
              formData?.coverageType && (
                <Grid item xs={12}>
                  <CustomDivider />
                  <Grid item xs={12}>
                    <SectionTitle
                      title={LABELS.SECTION_TITLE.REFERENCE_DATE}
                      mb={0}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <DatePickerInput
                      defaultValue={moment()
                        .startOf('day')
                        .unix()}
                      label={LABELS.FORM_DATA.INPUT_DATE_LABEL}
                      source="targetDateInMs"
                      disablePast={false}
                      readOnly={isCoverageTypeDropoff(formData?.coverageType)}
                    />
                  </Grid>
                </Grid>
              )
            );
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
}
