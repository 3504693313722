import React from 'react';
import PropTypes from 'prop-types';
import { Datagrid, TextField, List, EditButton, ShowButton } from 'react-admin';
import SloMappingFilter from '../../../molecules/slo-mapping-filter';

import LABELS from './labels';
import enums from '../../../../enums';
import permissionsFor from '../../../../access/access';

export default function TransitTimeList(props) {
  const { permissions } = props;
  const canUpdate = permissionsFor(enums.resources.TRANSIT_TIME)
    .hasRole(permissions)
    .forAction(enums.actions.UPDATE);

  return (
    <List
      filters={<SloMappingFilter />}
      exporter={false}
      bulkActionButtons={false}
      title={LABELS.TITLES.LIST_TITLE}
      {...props}
    >
      <Datagrid>
        <TextField source="originName" label={LABELS.TITLES.ORIGIN} />
        <TextField source="destinationName" label={LABELS.TITLES.DESTINATION} />
        <TextField source="sloMapping.slo" label={LABELS.TITLES.TT} />
        {canUpdate ? <EditButton /> : <ShowButton />}
      </Datagrid>
    </List>
  );
}

TransitTimeList.defaultProps = {
  permissions: []
};

TransitTimeList.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string)
};
