import enums from '../../../../enums';

export const CITY_TYPES = [
  { id: enums.cityTypes.CITY_TYPE_LOCAL_LMC, name: 'Base' },
  { id: enums.cityTypes.CITY_TYPE_NEARBY_LMC, name: 'Atendida' }
];

export const SERVICE_REGION = {
  resourceName: 'service-region'
};
