import coverageMappingApi from '../coverage-mapping/coverage-mapping-api';
import serviceProvidersLocationApi from '../service-providers-location/service-providers-locations-api';
import { exportToCsv, buildFileName } from './coverage-spreadsheet-utils';

const spreadsheet = { coverageMappingApi, serviceProvidersLocationApi };

const generateSpreadsheet = async args => {
  const { apiType } = args.data;
  const fileName = buildFileName(args);
  const { csvData, data } = await spreadsheet[apiType].getData(args);
  exportToCsv(csvData, fileName);

  return { data: { id: 0, ...data } };
};

export default {
  generateSpreadsheet
};
