import React from 'react';
import { usePermissions } from 'react-admin';
import { useHistory } from 'react-router-dom';
import PublishCoveragePage from './publish-coverage-page';
import enums from '../../../../../enums';
import permissionsFor from '../../../../../access/access';

const PublishCoverage = () => {
  const { loaded, permissions } = usePermissions();
  const history = useHistory();

  const authorized = permissionsFor(enums.customResources.PUBLISH_COVERAGE)
    .hasRole(permissions)
    .forAction(enums.actions.CREATE);

  if (loaded && !authorized) {
    history.push(`/${enums.resources.LAST_MILE_COMPANY}`);
  }

  return <PublishCoveragePage />;
};

export default PublishCoverage;
