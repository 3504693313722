import React from 'react';
import { Resource } from 'react-admin';
import PropTypes from 'prop-types';

import permissionsFor from '../../../../access/access';
import enums from '../../../../enums';

const hasPermission = (name, permissions, permission) => {
  return permissionsFor(name)
    .hasRole(permissions)
    .forAction(permission);
};

const resourceShouldRender = (name, permissions, customRole) => {
  if (customRole) {
    return permissions.indexOf(customRole) >= 0;
  }
  return hasPermission(name, permissions, enums.actions.READ);
};

export default function CoverageResource(props) {
  const { name, create, permissions, customRole } = props;

  const hasCreatePermissions = hasPermission(
    name,
    permissions,
    enums.actions.CREATE
  );
  const shouldRender = resourceShouldRender(name, permissions, customRole);

  const newProps = {
    ...props,
    create: hasCreatePermissions && create
  };

  return shouldRender && <Resource {...newProps} />;
}

CoverageResource.defaultProps = {
  create: null,
  customRole: null
};

CoverageResource.propTypes = {
  name: PropTypes.string.isRequired,
  permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  customRole: PropTypes.string,
  create: PropTypes.elementType
};
