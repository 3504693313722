import { required, number, minValue } from 'react-admin';

import { ERROR_MESSAGES } from './constants';

const numberValidations = [
  required(),
  number(ERROR_MESSAGES.INVALID_NUMBER),
  minValue(0, ERROR_MESSAGES.MINIMUM_NUMBER)
];

const dateValidation = value => {
  const date = new Date(value).toUTCString();
  const currentDate = new Date().toUTCString();

  if (Date.parse(date) <= Date.parse(currentDate)) {
    return ERROR_MESSAGES.PAST_DATE;
  }
  return undefined;
};

export const validations = {
  costPerKm: numberValidations,
  costPerPoint: numberValidations,
  deductibleCost: numberValidations,
  startTime: [required(), dateValidation]
};

export default { validations };
