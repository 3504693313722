import LABELS from './labels';
import moment from '../../../../../utils/moment';

const validateUserInput = data => {
  const errors = {};
  const todayDate = moment()
    .startOf('day')
    .unix();

  if (data?.dateInSeconds < todayDate) {
    errors.dateInSeconds = LABELS.WARNINGS.INVALID_DATE;
  }

  if (!data?.originServiceRegionIds?.length) {
    errors.originServiceRegionIds = LABELS.WARNINGS.MANDATORY;
  }

  return errors;
};

export default validateUserInput;
