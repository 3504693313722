import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Toolbar, SaveButton, DeleteButton } from 'react-admin';
import { useForm } from 'react-final-form';
import useStyles from './styles';
import { useRemoteConfig } from '../../../firebase';
import { isTruthy } from '../../../utils/utils';
import { getTomorrowTimestamp } from '../../../utils/format-time';
import SaveGroupButton from '../../molecules/save-group-button/save-group-button';
import enums from '../../../enums';

const tomorrow = getTomorrowTimestamp();

const SimpleToolbar = props => {
  const { disabled, handleSubmit, isVersioned, ...rest } = props;
  const [scheduledDate, changeScheduledDate] = useState(tomorrow);
  const classes = useStyles();
  const form = useForm();

  const { value: fsValue } = useRemoteConfig('FS_SHOW_DELETE_BUTTON');
  const shouldDeleteButtonBeVisible = isTruthy(fsValue);

  const handleOnSave = async (isScheduled = false) => {
    if (isScheduled) {
      const versioningStatus = enums.versioningStatusType.PROGRAMMED;
      const effectiveOn = scheduledDate.unix();
      form.change('versioningStatus', versioningStatus);
      form.change('effectiveOnTimeAsInt', effectiveOn);
    }

    await handleSubmit();
  };

  return (
    <Toolbar {...rest} className={classes.toolbar}>
      {shouldDeleteButtonBeVisible && (
        <DeleteButton
          className={classes.deleteButton}
          undoable={false}
          disabled={disabled}
        />
      )}
      {!isVersioned && (
        <SaveButton onClick={() => handleOnSave()} disabled={disabled} />
      )}
      {isVersioned && (
        <SaveGroupButton
          onSubmit={() => handleOnSave()}
          scheduledDate={scheduledDate}
          changeScheduledDate={changeScheduledDate}
          disabled={disabled}
          onScheduled={() => handleOnSave(true)}
        />
      )}
    </Toolbar>
  );
};

SimpleToolbar.defaultProps = {
  disabled: false,
  isVersioned: false
};

SimpleToolbar.propTypes = {
  disabled: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  isVersioned: PropTypes.bool
};

export default SimpleToolbar;
