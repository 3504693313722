import parse from 'csv-parse';
import {
  OPERATIONS,
  LABELS,
  CSV_HEADERS,
  POSTAL_CODE_TYPES,
  POSTAL_CODE_REGEX,
  CSV_PARSE
} from './constants';
import {
  isLastMileCompanyLeve,
  isPostalCodeGroupLastMileCompany
} from '../../postal-codes-table/utils';

const buildPostalCodeRange = params => {
  const {
    name,
    range,
    postalCodeGroupInfo,
    slo,
    rangeType,
    earningsLabel
  } = params;
  const postalcodes = range.match(POSTAL_CODE_REGEX.POSTAL_CODE);

  const jsonPatch = {
    start: { code: postalcodes[0] },
    end: { code: postalcodes[1] },
    name,
    types: POSTAL_CODE_TYPES.NOT_EXCLUSIVE,
    rangeType
  };

  if (isPostalCodeGroupLastMileCompany(postalCodeGroupInfo)) {
    jsonPatch.lastMileCompanyInfo = {
      slo
    };

    if (isLastMileCompanyLeve(postalCodeGroupInfo)) {
      jsonPatch.lastMileCompanyInfo.earningsLabel = earningsLabel;
    }
  }

  return jsonPatch;
};

const parseCsvToObjects = async csvContent => {
  return new Promise((resolve, reject) => {
    parse(csvContent, { ...CSV_PARSE.CONFIGS }, (error, output) => {
      if (error) reject(error);
      resolve(output);
    });
  });
};

const objectsToJsonPatch = (csvObjects, postalCodeGroupInfo, rangeType) => {
  const result = [];
  csvObjects.forEach(element => {
    const ranges = element[CSV_HEADERS.RANGES];
    ranges.match(POSTAL_CODE_REGEX.POSTAL_CODES_TUPLE).forEach(range => {
      const operation = element[CSV_HEADERS.OPERATION];
      const obj = {
        op: OPERATIONS.CSV[operation.toUpperCase()],
        path: '/postalCodeGroup/postalCodes/ranges'
      };
      obj.postalCodeRange = buildPostalCodeRange({
        name: element[CSV_HEADERS.RANGE_NAME].trim(),
        range,
        postalCodeGroupInfo,
        // eslint-disable-next-line
        slo: ~~element[CSV_HEADERS.LOCAL_SLO],
        rangeType,
        earningsLabel: element[CSV_HEADERS.LABEL]
      });
      result.push(obj);
    });
  });

  return result;
};

export const jsonPatchToChanges = operations => {
  return operations.map(operation => ({
    ...operation.postalCodeRange,
    typeChange: operation.op
  }));
};

const validateHeadersFound = (headersFoundIndex, csvLength) => {
  if (
    headersFoundIndex === -1 ||
    csvLength <= 1 ||
    csvLength <= headersFoundIndex
  ) {
    throw Error(LABELS.ALERT.MISSING_FIELD);
  }
};

const removeHeaders = csvRows => {
  const headersFoundIndex = csvRows.findIndex(row =>
    Object.values(row).every(column =>
      CSV_PARSE.CONFIGS.columns.includes(column)
    )
  );

  validateHeadersFound(headersFoundIndex, csvRows.length);

  return csvRows.slice(headersFoundIndex + 1);
};

export const parseCsvContentToJsonPatch = async (
  csvContent,
  postalCodeGroupInfo,
  rangeType
) => {
  try {
    const parsedCsv = await parseCsvToObjects(csvContent);
    const objectsFromCsv = removeHeaders(parsedCsv);

    return objectsToJsonPatch(objectsFromCsv, postalCodeGroupInfo, rangeType);
  } catch (error) {
    if (error instanceof TypeError) {
      throw Error(LABELS.ALERT.MISSING_FIELD);
    } else if (CSV_PARSE.ERRORS_CODE.includes(error.code)) {
      throw Error(LABELS.ALERT.MISSING_FIELD);
    } else {
      throw error;
    }
  }
};
