import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  selectArray: {
    minWidth: 258,
    '& legend': {
      width: 0
    }
  },
  selectArrayOnFocus: {
    minWidth: 258,
    '& legend': {
      minWidth: 120
    }
  }
});

export default {
  useStyles
};
