import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

const SectionTitle = props => {
  const { title, variant, fontWeight, mt, mb, ml, mr } = props;

  return (
    <Grid container fullWidth xs={12}>
      <Grid item xs={12}>
        <Box mt={mt} mb={mb} ml={ml} mr={mr} data-testid="section-title">
          <Typography variant={variant}>
            <Box fontWeight={fontWeight}>{title}</Box>
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  variant: PropTypes.string,
  fontWeight: PropTypes.string,
  mt: PropTypes.number,
  mb: PropTypes.number,
  ml: PropTypes.number,
  mr: PropTypes.number
};

SectionTitle.defaultProps = {
  variant: 'h6',
  fontWeight: 'fontWeightBold',
  mt: 1,
  mb: 3,
  ml: 0,
  mr: 0
};

export default SectionTitle;
