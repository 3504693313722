import { isTypeCrossdocking } from './utils';

const validateServiceRegion = serviceRegion => {
  const errors = {};
  if (
    !isTypeCrossdocking(serviceRegion) &&
    !serviceRegion.serviceRegionInfo?.parentServiceRegionId
  ) {
    errors.serviceRegionInfo = {
      parentServiceRegionId: 'ra.validation.required'
    };
  }

  return errors;
};

export default validateServiceRegion;
