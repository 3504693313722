import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@loggi/mar';

export const useStyles = makeStyles({
  tableHead: {
    backgroundColor: colors.smoke[100]
  },
  tableRow: {
    backgroundColor: colors.root[0],
    '&:nth-child(even)': {
      backgroundColor: colors.smoke[50]
    }
  }
});

export default {
  useStyles
};
