import React from 'react';
import PropTypes from 'prop-types';

import { Loading } from 'react-admin';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

export const LABELS = {
  ERRORS: {
    PAGE_LOADING: 'Carregando Página'
  }
};

const useStyles = makeStyles({
  container: {
    height: '100vh'
  }
});

const CenterLoading = props => {
  const { errorMessage } = props;
  const classes = useStyles();
  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      className={classes.container}
    >
      <Grid item>
        <Loading
          loadingPrimary={LABELS.ERRORS.PAGE_LOADING}
          loadingSecondary={errorMessage || ''}
        />
      </Grid>
    </Grid>
  );
};

CenterLoading.defaultProps = {
  errorMessage: ''
};

CenterLoading.propTypes = {
  errorMessage: PropTypes.string
};

export default CenterLoading;
