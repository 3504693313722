import React from 'react';
import PropTypes from 'prop-types';
import {
  Edit,
  NumberInput,
  SimpleForm,
  useQuery,
  useShowController
} from 'react-admin';
import { Typography } from '@material-ui/core';
import CustomDivider from '../../../atoms/custom-divider/custom-divider';
import cutoffHourFromOffset from '../../../../utils/cutoff-hour-from-offset';
import enums from '../../../../enums';
import GenericToolbar from '../../../atoms/generic-toolbar';
import LABELS from './labels';
import permissionsFor from '../../../../access/access';
import TopBar from '../../../organisms/top-bar/top-bar';

export default function DistributionCenterEdit(props) {
  const { id, permissions } = props;
  const { record } = useShowController(props);
  const canUpdate = permissionsFor(enums.resources.DISTRIBUTION_CENTER)
    .hasRole(permissions)
    .forAction(enums.actions.UPDATE);

  const { data } = useQuery({
    type: 'getOne',
    resource: 'distribution-center',
    payload: { id }
  });

  const pageTitle = `
    ${LABELS.PAGE_TITLE.DEADLINE_OFFSET_EDIT} (${data?.routingCode || ''})
  `;
  const deadLineOffsetText =
    cutoffHourFromOffset(record?.confirmationDeadlineOffset) ||
    LABELS.TITLES.WITHOUT_OFFSET;

  return (
    <Edit undoable={false} title={pageTitle} {...props}>
      <SimpleForm
        variant="outlined"
        toolbar={
          <GenericToolbar
            buttonText={LABELS.BUTTON.SAVE_TEXT}
            disabled={!canUpdate}
          />
        }
      >
        {record && <TopBar id={record.id} referenceId={record.referenceId} />}

        <Typography variant="h4" fullWidth data-testid="page-title-offset">
          {record?.name} - {record?.routingCode}
        </Typography>

        <Typography variant="body1" fullWidth data-testid="page-body-offset">
          {LABELS.TITLES.DEADLINE_OFFSET_ACTUAL}: {deadLineOffsetText}
        </Typography>

        <CustomDivider />

        <NumberInput
          label={LABELS.TITLES.CONFIRMATION_OFFSET}
          source="confirmationDeadlineOffset"
          variant="outlined"
          data-testid="confirmation-deadline-offset-input"
          helperText={LABELS.TITLES.HELPER_TEXT}
          step={0.1}
        />
      </SimpleForm>
    </Edit>
  );
}

DistributionCenterEdit.defaultProps = {
  permissions: []
};

DistributionCenterEdit.propTypes = {
  id: PropTypes.string.isRequired,
  permissions: PropTypes.arrayOf(PropTypes.string)
};
