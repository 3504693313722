import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@loggi/mar';

export const useStyles = makeStyles(theme => ({
  toolbar: { justifyContent: 'flex-end' },
  saveButton: {
    background: colors.gradients.sanches,
    color: colors.root[0],
    borderRadius: '62.5rem',
    padding: '0.875rem 1.25rem',
    fontSize: '0.875rem',
    '&:disabled': {
      background: colors.smoke[300],
      color: colors.root[0]
    }
  },
  saveIcon: {
    marginRight: '8px',
    fontSize: '18px'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: colors.smoke[50]
  }
}));

export default {
  useStyles
};
