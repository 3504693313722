import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MomentUtils from '@date-io/moment';
import { useNotify } from 'react-admin';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import ClearIcon from '@material-ui/icons/Clear';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker
} from '@material-ui/pickers';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CircularProgress from '@material-ui/core/CircularProgress';
import Api from '../../../api/postal-code-api';
import { LABELS } from './constants';

const isValidDate = input => {
  return input && input.isValid() && (input.hour() || input.minutes());
};

const DialogReAnnotation = props => {
  const { open, handleOpenDialog } = props;
  const defaultTime = new Date().setHours(0, 0);
  const [canReannotate, setCanReannotate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(defaultTime);

  const notify = useNotify();

  const onSubmittingRequest = () => {
    setIsLoading(true);
    setCanReannotate(false);
  };

  const clearInputAndCloseDialog = () => {
    setIsLoading(false);
    setSelectedDate(defaultTime);
    handleOpenDialog(false);
  };

  const handleSubmit = async () => {
    onSubmittingRequest();

    try {
      await Api.reAnnotate(selectedDate.hour(), selectedDate.minutes());
      notify(LABELS.SUCCESS);
    } catch (e) {
      notify(e.message || LABELS.FAILURE, 'warning');
    }

    clearInputAndCloseDialog();
  };

  const handleDateChange = input => {
    setSelectedDate(input);

    const isValid = isValidDate(input);
    setCanReannotate(isValid);
  };

  return (
    <Dialog
      open={open}
      onClose={clearInputAndCloseDialog}
      maxWidth="sm"
      fullWidth
      aria-labelledby="alert-dialog-title"
      data-testid="re-annotation-dialog"
    >
      <DialogTitle id="alert-dialog-title">{LABELS.DIALOG_TITLE}</DialogTitle>
      <DialogContent>
        <DialogContentText>{LABELS.DIALOG_CONTENT_TEXT} </DialogContentText>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardTimePicker
            ampm={false}
            openTo="minutes"
            views={['hours', 'minutes']}
            format="HH:mm"
            label={LABELS.DIALOG_INPUT_LABEL}
            invalidDateMessage={LABELS.INVALID_INPUT}
            value={selectedDate}
            onChange={handleDateChange}
            data-testid="re-annotation-time-selector"
          />
        </MuiPickersUtilsProvider>

        <Box my={2}>
          <Divider light />
        </Box>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleSubmit}
            color="primary"
            disabled={!canReannotate}
            data-testid="re-annotation-dialog-submit-button"
            startIcon={
              !isLoading ? (
                <CheckCircleIcon />
              ) : (
                <CircularProgress size={20} color="inherit" />
              )
            }
          >
            {LABELS.CONFIRM_BUTTON_LABEL}
          </Button>
          <Button
            onClick={() => handleOpenDialog(false)}
            startIcon={<ClearIcon />}
            data-testid="re-annotation-dialog-close-button"
          >
            {LABELS.CLOSE_DIALOG_BUTTON_LABEL}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

DialogReAnnotation.propTypes = {
  open: PropTypes.bool.isRequired,
  handleOpenDialog: PropTypes.func.isRequired
};

export default DialogReAnnotation;
