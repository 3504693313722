import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Create, SimpleForm, FormDataConsumer, useNotify } from 'react-admin';
import LABELS from './labels';
import enums from '../../../../enums';
import { handleConflictRangeOverlap } from '../../../../utils/handle-conflicts';
import permissionsFor from '../../../../access/access';
import ServiceTypeForm from '../../../templates/service-type/service-type-form';
import validateServiceType from './validations';
import SimpleToolbar from '../../../atoms/simple-toolbar';

const choices = Object.entries(LABELS.TYPES)
  .filter(item => item[0] !== 'SERVICE_TYPE_UNKNOWN')
  .map(item => ({
    id: item[0],
    name: item[1]
  }));

export default function ServiceTypeCreate(props) {
  const { permissions } = props;
  const [errorRanges, setErrorRanges] = useState([]);

  const canCreate = permissionsFor(enums.resources.SERVICE_TYPE)
    .hasRole(permissions)
    .forAction(enums.actions.CREATE);
  const notify = useNotify();

  const handleFailure = error =>
    handleConflictRangeOverlap(error, setErrorRanges, notify);

  const handleCloseDialog = () => {
    setErrorRanges([]);
  };

  return (
    <Create
      redirect="list"
      undoable={false}
      onFailure={handleFailure}
      {...props}
    >
      <SimpleForm
        warnWhenUnsavedChanges
        variant="outlined"
        redirect="list"
        toolbar={<SimpleToolbar isVersioned />}
        validate={validateServiceType}
      >
        <FormDataConsumer>
          {({ formData }) => {
            return (
              <ServiceTypeForm
                hasPermissions={canCreate}
                formData={formData}
                errorRanges={errorRanges}
                handleCloseDialog={handleCloseDialog}
                serviceTypeTypes={choices}
                pageType={enums.pageType.CREATE}
                {...props}
              />
            );
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
}

ServiceTypeCreate.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string).isRequired
};
