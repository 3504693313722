import React from 'react';
import PropTypes from 'prop-types';
import {
  Create,
  SimpleForm,
  required,
  minValue,
  NumberInput,
  useNotify
} from 'react-admin';
import PostalCodeGroupAutocomplete from '../../../molecules/postal-code-group-autocomplete';
import SimpleToolbar from '../../../atoms/simple-toolbar';

import LABELS from './labels';
import enums from '../../../../enums';
import DialogConflictSLOMapping from '../../../molecules/dialog-conflict-slo-mapping';
import { handleConflictSloMapping } from '../../../../utils/handle-conflicts';
import validateTransitTimeCreate from './validations';
import permissionsFor from '../../../../access/access';

const transform = dataToBeSent => {
  const { sloMapping } = dataToBeSent;
  return { ...sloMapping };
};

export default function TransitTimeCreate(props) {
  const { permissions } = props;
  const [
    conflictingSloMappingIds,
    setConflictingSloMappingIds
  ] = React.useState([]);
  const notify = useNotify();

  const canCreate = permissionsFor(enums.resources.TRANSIT_TIME)
    .hasRole(permissions)
    .forAction(enums.actions.CREATE);

  const handleFailure = error =>
    handleConflictSloMapping(error, setConflictingSloMappingIds, notify);

  const handleCloseDialog = () => {
    setConflictingSloMappingIds([]);
  };

  return (
    <Create
      redirect="list"
      undoable={false}
      title={LABELS.TITLES.CREATE_TITLE}
      transform={transform}
      onFailure={handleFailure}
      {...props}
    >
      <SimpleForm
        warnWhenUnsavedChanges
        variant="outlined"
        toolbar={<SimpleToolbar disabled={!canCreate} />}
        validate={validateTransitTimeCreate}
      >
        <PostalCodeGroupAutocomplete
          label={LABELS.TITLES.ORIGIN}
          hasWritePermissions={canCreate}
          postalCodeGroupIdFormPath={
            LABELS.FORM_DATA.ORIGIN_SERVICE_REGION_ID_PATH
          }
          postalCodeGroupNameFormPath={
            LABELS.FORM_DATA.ORIGIN_SERVICE_REGION_NAME_PATH
          }
          resourceName={enums.resources.SERVICE_REGION}
          required
        />
        <PostalCodeGroupAutocomplete
          label={LABELS.TITLES.DESTINATION}
          hasWritePermissions={canCreate}
          postalCodeGroupIdFormPath={
            LABELS.FORM_DATA.DESTINATION_SERVICE_REGION_ID_PATH
          }
          postalCodeGroupNameFormPath={
            LABELS.FORM_DATA.DESTINATION_SERVICE_REGION_NAME_PATH
          }
          resourceName={enums.resources.SERVICE_REGION}
          required
        />
        <NumberInput
          label={LABELS.TITLES.TT}
          source="sloMapping.slo"
          data-testid="slo-mapping-slo"
          disabled={!canCreate}
          validate={[required(), minValue(0)]}
        />
        <DialogConflictSLOMapping
          onClose={handleCloseDialog}
          open={!!conflictingSloMappingIds?.length}
          conflictingSloMappingIds={conflictingSloMappingIds}
        />
      </SimpleForm>
    </Create>
  );
}

TransitTimeCreate.defaultProps = {
  permissions: []
};

TransitTimeCreate.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string)
};
