import React from 'react';
import { Route } from 'react-router-dom';
import ImportRedispatchSlo from './view/templates/_react-admin/actions/import-redispatch-slo/ImportRedispatchSlo';
import CreateBase from './view/templates/_react-admin/actions/create-base';
import CreateDeliveryEarningsTable from './view/templates/_react-admin/actions/create-delivery-earnings-table';
import MeiRulesBulkUpdate from './view/templates/_react-admin/mei-rules/mei-rules-bulk-update';
import GenerateCoverageSpreadsheet from './view/templates/_react-admin/actions/generate-coverage-spreadsheet';
import AccessDenied from './view/pages/access-denied';
import enums from './enums';
import PublishCoverage from './view/templates/_react-admin/actions/publish-coverage';

const customRoutes = [
  <Route
    exact
    path={`/${enums.customResources.BULK_UPDATE_MEI_RULES}`}
    component={MeiRulesBulkUpdate}
  />,
  <Route
    exact
    path={`/${enums.customResources.IMPORT_REDISPATCH_SLO}`}
    component={ImportRedispatchSlo}
  />,
  <Route
    exact
    path={`/${enums.customResources.CREATE_BASE}`}
    component={CreateBase}
  />,
  <Route
    exact
    path={`/${enums.customResources.CREATE_DELIVERY_EARNINGS_TABLE}`}
    component={CreateDeliveryEarningsTable}
  />,
  <Route
    exact
    path={`/${enums.customResources.ACCESS_DENIED}`}
    component={AccessDenied}
    noLayout
  />,
  <Route
    exact
    path={`/${enums.customResources.GENERATE_COVERAGE_SPREADSHEET}`}
    component={GenerateCoverageSpreadsheet}
  />,
  <Route
    exact
    path={`/${enums.customResources.PUBLISH_COVERAGE}`}
    component={PublishCoverage}
  />
];

export default customRoutes;
