const LABELS = {
  TITLES: {
    NAME: 'Nome',
    START: 'Início',
    END: 'Final',
    DOWNLOAD: 'Baixar'
  }
};

export default LABELS;
