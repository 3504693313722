const LABELS = {
  WELCOME: {
    TITLE: 'Que bom ter você aqui!',
    CONTAINER: 'Essa é a plataforma de abrangência.'
  },
  TITLE_FORM: 'Acesse a plataforma',
  GOOGLE: 'Entrar com o Google',
  AUTHENTICATING: 'Autenticando...'
};

export default LABELS;
