import React, { useState } from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import MenuList from '@material-ui/core/MenuList';
import { usePermissions, UserMenu } from 'react-admin';
import { ReactComponent as IconReAnnotation } from '../../../assets/icons/ic-re-annotation.svg';
import { LABELS } from './constants';
import { useStyles } from './styles';
import DialogReAnnotation from '../dialog-re-annotation';
import enums from '../../../enums';

const CustomUserMenu = props => {
  const classes = useStyles();
  const { permissions } = usePermissions();
  const hasWritePermissions =
    permissions && permissions.includes(enums.roles.ADMIN);
  const [openReAnnotationDialog, setOpenReAnnotationDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenReAnnotationDialog(!openReAnnotationDialog);
  };

  return (
    <>
      <UserMenu {...props}>
        {hasWritePermissions && (
          <MenuList className={classes.menuList}>
            <MenuItem
              onClick={handleOpenDialog}
              className={classes.menuItem}
              data-testid="re-annotation-item"
            >
              <ListItemIcon className={classes.listIcon}>
                <IconReAnnotation className={classes.icon} />
              </ListItemIcon>
              <ListItemText primary={LABELS.MENU_TITLE} />
            </MenuItem>
          </MenuList>
        )}
      </UserMenu>
      {hasWritePermissions && (
        <DialogReAnnotation
          open={openReAnnotationDialog}
          handleOpenDialog={handleOpenDialog}
        />
      )}
    </>
  );
};

export default CustomUserMenu;
