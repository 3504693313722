import enums from './enums';
import postalCodeTypes from './postal-code-types';

const postalCodeTypesByGroup = {
  [enums.serviceType.SERVICE_TYPE_REDISPATCH]: [
    postalCodeTypes.CAIXA_POSTAL_COMUNITARIA,
    postalCodeTypes.UNIDADE_OPERACIONAL_CORREIOS
  ],
  [enums.lastMileCompanyType.LAST_MILE_COMPANY_TYPE_CORREIOS_NACIONAL]: [
    postalCodeTypes.CAIXA_POSTAL_COMUNITARIA,
    postalCodeTypes.UNIDADE_OPERACIONAL_CORREIOS
  ],
  [enums.lastMileCompanyType.LAST_MILE_COMPANY_TYPE_CORREIOS_LOCAL]: [
    postalCodeTypes.CAIXA_POSTAL_COMUNITARIA,
    postalCodeTypes.UNIDADE_OPERACIONAL_CORREIOS
  ],
  default: [
    postalCodeTypes.LOGRADOURO,
    postalCodeTypes.LOCALIDADE,
    postalCodeTypes.GRANDE_USUARIO
  ]
};

export default postalCodeTypesByGroup;
