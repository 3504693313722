import React from 'react';
import PropTypes from 'prop-types';
import { TextInput, SelectInput } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import { FormControl } from '@material-ui/core';
import {
  isLastMileCompanyLeve,
  isLastMileCompanyNoCoverage,
  getPostalCodeGroupInfo
} from '../../../molecules/postal-codes-table/utils';
import DeliveryEarningsTableAutocomplete from '../../../molecules/delivery-earnings-table-autocomplete';
import CnpjInput from '../../../atoms/cnpj-input';
import EmailInput from '../../../atoms/email-input';
import LABELS from './labels';
import enums from '../../../../enums';
import {
  validateDropOffAmount,
  validateLastMileCompanyInputs
} from './validators';
import BrazilianCurrencyInput from '../../../molecules/brazilian-currency-input';
import { DROP_OFF_AMOUNT_DEFAULT } from './constants';

const choices = Object.entries(LABELS.TYPES)
  .map(item => ({
    id: item[0],
    name: item[1]
  }))
  .filter(item => item.name !== LABELS.TYPES.LAST_MILE_COMPANY_TYPE_UNKNOWN);

export default function LastMileCompanyInfo({ canUpdate, pageType, formData }) {
  const postalCodeGroupInfo = getPostalCodeGroupInfo(formData);
  const isEditPage = pageType === enums.pageType.EDIT;
  const isLmcWithoutCoverage = isLastMileCompanyNoCoverage(postalCodeGroupInfo);
  const isLmcWithCompanyData =
    isLastMileCompanyLeve(postalCodeGroupInfo) || isLmcWithoutCoverage;

  return (
    <Grid container fullWidth>
      <Grid item xs={12}>
        <Grid item>
          <TextInput
            label={LABELS.TITLES.NAME}
            source="lastMileCompanyInfo.name"
            disabled={!canUpdate}
            fullWidth
            variant="outlined"
            data-testid="last-mile-company-name"
            validate={validateLastMileCompanyInputs.name}
          />

          <Grid container spacing={2}>
            <Grid item xs={12} sm={isLmcWithCompanyData ? 6 : 12}>
              <SelectInput
                label={LABELS.TITLES.TYPE}
                source="lastMileCompanyInfo.companyType"
                data-testid="last-mile-company-type-select"
                choices={choices}
                disabled={isEditPage}
                fullWidth
                variant="outlined"
                validate={validateLastMileCompanyInputs.companyType}
              />
            </Grid>

            {isLmcWithCompanyData && (
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <CnpjInput
                    source="lastMileCompanyInfo.cnpj"
                    dataTestId="last-mile-company-input-cnpj"
                    hasWritePermissions={canUpdate}
                    fullWidth
                    isEditPage={isEditPage}
                  />
                </FormControl>
              </Grid>
            )}
          </Grid>
        </Grid>

        {isLmcWithCompanyData && (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextInput
                  label={LABELS.TITLES.CORPORATE_NAME}
                  source="lastMileCompanyInfo.corporateName"
                  data-testid="last-mile-company-input-corporate-name"
                  variant="outlined"
                  validate={validateLastMileCompanyInputs.corporateName}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <EmailInput
                  source="lastMileCompanyInfo.financialEmail"
                  dataTestId="last-mile-company-input-financial-email"
                  validations={validateLastMileCompanyInputs.financialEmail}
                  variant="outlined"
                  fullWidth
                />
              </FormControl>
            </Grid>
          </Grid>
        )}

        {isLmcWithCompanyData && (
          <DeliveryEarningsTableAutocomplete
            hasWritePermissions={canUpdate}
            lastMileCompanyInfo={formData.lastMileCompanyInfo}
          />
        )}

        {isLmcWithoutCoverage && (
          <BrazilianCurrencyInput
            label={LABELS.TITLES.DROP_OFF_TRANSFER_VALUE}
            source="lastMileCompanyInfo.dropOffAmount"
            defaultValue={DROP_OFF_AMOUNT_DEFAULT}
            allowNegative={false}
            validate={validateDropOffAmount}
          />
        )}
      </Grid>
    </Grid>
  );
}

LastMileCompanyInfo.defaultProps = {
  pageType: enums.pageType.EDIT
};

LastMileCompanyInfo.propTypes = {
  canUpdate: PropTypes.bool.isRequired,
  pageType: PropTypes.string,
  formData: PropTypes.objectOf(PropTypes.string).isRequired
};
