export const LABELS = {
  BUTTONS: {
    SAVE: 'Salvar'
  },
  DIALOG: {
    TITLE: 'Inconsistência nas faixas de preço',
    DESCRIPTION: 'Revise as alterações dos ranges a seguir'
  }
};

export default { LABELS };
