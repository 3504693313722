import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  body2: {
    fontSize: '14px',
    lineHeight: '150%',
    color: '#757D8C',
    fontFamily: 'Open Sans'
  },
  h4: {
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
    fontSize: '26px',
    lineHeight: '150%',
    color: '#2E3545',
    marginBottom: '8px'
  }
}));

export default { useStyles };
