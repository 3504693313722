import enums from '../../../../enums';
import postalCodeTypesByGroup from '../../../../postal-code-types-by-group';

export const getRangeKey = range => {
  let slo = 0;
  let earningsLabel = '';

  if (range.lastMileCompanyInfo && range.lastMileCompanyInfo.slo) {
    slo = range.lastMileCompanyInfo.slo;
  }

  if (range.lastMileCompanyInfo && range.lastMileCompanyInfo.earningsLabel) {
    earningsLabel = range.lastMileCompanyInfo.earningsLabel;
  }

  return `${range.name}-${range.start.code}-${
    range.end.code
  }-${slo}-${earningsLabel}-${range.rangeType}`;
};

export const removeRange = (ranges, rangeToRemove) => {
  const currentRangeKey = getRangeKey(rangeToRemove);
  return ranges.filter(range => currentRangeKey !== getRangeKey(range));
};

export const updateRange = (newRange, ranges, editingRangeKey) => {
  return ranges.map(range => {
    if (editingRangeKey !== getRangeKey(range)) return range;
    return newRange;
  });
};

export const hasRangeChanged = (newRange, ranges) => {
  const newRangeKey = `${getRangeKey(newRange)}`;

  return !ranges.some(range => {
    return newRangeKey === getRangeKey(range);
  });
};

export const isPostalCodeGroupLastMileCompany = postalCodeGroupInfo => {
  return (
    postalCodeGroupInfo &&
    postalCodeGroupInfo.type ===
      enums.PostalCodeGroupType.POSTAL_CODE_GROUP_TYPE_LAST_MILE_COMPANY
  );
};

export const isLastMileCompanyLeve = postalCodeGroupInfo =>
  postalCodeGroupInfo.groupType === 'LAST_MILE_COMPANY_TYPE_LEVE';

export const isLastMileCompanyLoggi = postalCodeGroupInfo =>
  postalCodeGroupInfo.groupType === 'LAST_MILE_COMPANY_TYPE_LOGGI';

export const isLastMileCompanyNoCoverage = postalCodeGroupInfo =>
  postalCodeGroupInfo.groupType === 'LAST_MILE_COMPANY_TYPE_NO_COVERAGE';

const isLastMileCompanyPage = formData => !!formData.lastMileCompanyInfo;

const isServiceTypePage = formData => !!formData.serviceTypeInfo;

const isServiceRegionPage = formData => !!formData.serviceRegionInfo;

export const getPostalCodeGroupInfo = (formData = {}) => {
  const defaultInfo = {
    id: formData.id
  };

  if (isLastMileCompanyPage(formData)) {
    return {
      ...defaultInfo,
      type: enums.PostalCodeGroupType.POSTAL_CODE_GROUP_TYPE_LAST_MILE_COMPANY,
      resourceName: enums.resources.LAST_MILE_COMPANY,
      groupType: formData.lastMileCompanyInfo.companyType,
      active: formData.lastMileCompanyInfo.active,
      name: formData.lastMileCompanyInfo.name
    };
  }

  if (isServiceTypePage(formData)) {
    return {
      ...defaultInfo,
      type: enums.PostalCodeGroupType.POSTAL_CODE_GROUP_TYPE_SERVICE_TYPE,
      resourceName: enums.resources.SERVICE_TYPE,
      groupType: formData.serviceTypeInfo.serviceType,
      name: formData.serviceTypeInfo.name
    };
  }

  if (isServiceRegionPage(formData)) {
    return {
      ...defaultInfo,
      type: enums.PostalCodeGroupType.POSTAL_CODE_GROUP_TYPE_SERVICE_REGION,
      resourceName: enums.resources.SERVICE_REGION,
      name: formData.serviceRegionInfo.name
    };
  }

  return {};
};

const groupTypeId = postalCodeGroupInfo => {
  if (!postalCodeGroupInfo.type) {
    return '';
  }

  return isPostalCodeGroupLastMileCompany(postalCodeGroupInfo)
    ? enums.lastMileCompanyType[postalCodeGroupInfo.groupType]
    : enums.serviceType[postalCodeGroupInfo.groupType];
};

export const buildRange = ({
  name,
  start,
  end,
  slo,
  earningsLabel,
  postalCodeGroupInfo,
  rangeType
}) => {
  const groupType = groupTypeId(postalCodeGroupInfo);
  const hasGroupType = groupType in postalCodeTypesByGroup;
  const additionalTypes = hasGroupType ? postalCodeTypesByGroup[groupType] : [];

  const newRange = {
    name,
    start,
    end,
    types: [...postalCodeTypesByGroup.default, ...additionalTypes],
    rangeType
  };

  if (isPostalCodeGroupLastMileCompany(postalCodeGroupInfo)) {
    newRange.lastMileCompanyInfo = {
      // eslint-disable-next-line no-bitwise
      slo: ~~slo
    };

    if (isLastMileCompanyLeve(postalCodeGroupInfo) && earningsLabel) {
      newRange.lastMileCompanyInfo.earningsLabel = earningsLabel;
    }
  }

  return newRange;
};

export const filterRangesByType = (ranges, rangeType) =>
  ranges && ranges.filter(range => range.rangeType === rangeType);

export const filterRangesByDifferentTypes = (ranges, rangeTypes) =>
  ranges && ranges.filter(range => !rangeTypes.includes(range.rangeType));
