const LABELS = {
  TABS_TITLES: {
    DELIVERY: 'Abrangência de Entrega',
    PICKUP: 'Abrangência de Coleta',
    UNPACKED_PICKUP: 'Abrangência de Coleta sem Embalagem'
  },
  ALT_ICON: {
    TRUNK_CLOSED: 'Baú Loggi',
    Sack: 'Sacola'
  },
  CHECKBOX: {
    TITLE: 'Abrangência de coleta igual à de entrega'
  }
};

export default LABELS;
