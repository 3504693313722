import React from 'react';
import { Admin, Layout as RaLayout, AppBar as RaAppBar } from 'react-admin';
import { hotjar } from 'react-hotjar';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import FeedbackIcon from '@material-ui/icons/Feedback';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import MoveToInboxIcon from '@material-ui/icons/MoveToInbox';
import AutoRenewIcon from '@material-ui/icons/Autorenew';
import RestoreIcon from '@material-ui/icons/Restore';
import SpeedIcon from '@material-ui/icons/Speed';
import SyncAlt from '@material-ui/icons/SyncAlt';
import { ThemeProvider } from '@material-ui/core/styles';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import { ReactComponent as RegionIcon } from '../assets/icons/icon-region.svg';

import portugueseMessages from '../config/portuguese-messages';
import theme from './theme/constants';
import postalCodeAPI from '../api/postal-code-api';
import authProvider from '../auth-provider';
import CustomMenu from './atoms/custom-menu';
import CenterLoading from './atoms/center-loading';
import customRoutes from '../custom-routes';
import DistributionCenterEdit from './templates/_react-admin/distribution-center/distribution-center-edit';
import DistributionCenterList from './templates/_react-admin/distribution-center/distribution-center-list';
import LastMileCompanyEdit from './templates/_react-admin/last-mile-company/last-mile-company-edit';
import LastMileCompanyList from './templates/_react-admin/last-mile-company/last-mile-company-list';
import ServiceTypeList from './pages/_react-admin/service-type/service-type-list';
import ServiceTypeEdit from './pages/_react-admin/service-type/service-type-edit';
import ServiceTypeCreate from './pages/_react-admin/service-type/service-type-create';
import PricingRegionList from './templates/_react-admin/pricing-region/pricing-region-list';
import PricingRegionCreate from './templates/_react-admin/pricing-region/pricing-region-create';
import PricingRegionShow from './templates/_react-admin/pricing-region/pricing-region-show';
import TransitTimeList from './templates/_react-admin/transit-time/transit-time-list';
import TransitTimeEdit from './templates/_react-admin/transit-time/transit-time-edit';
import TransitTimeCreate from './templates/_react-admin/transit-time/transit-time-create';
import SloExceptionList from './templates/_react-admin/slo-exception/slo-exception-list';
import SloExceptionEdit from './templates/_react-admin/slo-exception/slo-exception-edit';
import SloExceptionCreate from './templates/_react-admin/slo-exception/slo-exception-create';
import Login from './pages/login';
import CustomUserMenu from './molecules/custom-user-menu';
import ServiceRegionList from './templates/_react-admin/service-region/service-region-list';
import ServiceRegionEdit from './templates/_react-admin/service-region/service-region-edit';
import ServiceRegionCreate from './templates/_react-admin/service-region/service-region-create';
import RegionList from './templates/_react-admin/region/region-list';
import RegionCreate from './templates/_react-admin/region/region-create';
import MeiRulesList from './templates/_react-admin/mei-rules/mei-rules-list';
import MeiRulesEdit from './templates/_react-admin/mei-rules/mei-rules-edit';
import ReAnnotationPromisedDate from './templates/_react-admin/reannotation/re-annotation-promised-date';
import BlockScreenWhileLoading from './molecules/block-screen-while-loading';

import enums from '../enums';
import LABELS from '../api/labels';
import RegionEdit from './templates/_react-admin/region/region-edit';
import CoverageResource from './templates/_react-admin/access-resource/coverage-resource';
import LastMileCompanyCreate from './templates/_react-admin/last-mile-company/last-mile-company-create';

const AppBar = props => (
  <RaAppBar {...props} color="primary" userMenu={<CustomUserMenu />} />
);

const Layout = props => (
  <RaLayout {...props} menu={CustomMenu} appBar={AppBar} />
);

const Loading = () => <CenterLoading errorMessage={LABELS.ALERTS.WAITING} />;

const i18nProvider = polyglotI18nProvider(() => portugueseMessages, 'pt-br', {
  allowMissing: true
});

const hotjarSiteId = Number(process.env.REACT_APP_HOTJAR_SITE_ID);
const hotjarTrackerVersion = process.env.REACT_APP_HOTJAR_TRACKER_VERSION;

if (hotjarSiteId) {
  hotjar.initialize(hotjarSiteId, hotjarTrackerVersion);
}

export default function Home() {
  return (
    <ThemeProvider theme={theme}>
      <Admin
        loading={Loading}
        loginPage={Login}
        dataProvider={postalCodeAPI}
        authProvider={authProvider}
        theme={theme}
        layout={Layout}
        customRoutes={customRoutes}
        i18nProvider={i18nProvider}
      >
        {permissions => {
          return [
            <CoverageResource
              name={enums.resources.LAST_MILE_COMPANY}
              permissions={permissions}
              list={LastMileCompanyList}
              edit={LastMileCompanyEdit}
              options={{ label: 'Transportadora' }}
              icon={LocalShippingIcon}
              create={LastMileCompanyCreate}
            />,
            <CoverageResource
              name={enums.resources.SERVICE_TYPE}
              permissions={permissions}
              list={ServiceTypeList}
              edit={ServiceTypeEdit}
              create={ServiceTypeCreate}
              options={{ label: 'Tipo de Serviço' }}
              icon={SpeedIcon}
            />,
            <CoverageResource
              name={enums.resources.SERVICE_REGION}
              permissions={permissions}
              list={ServiceRegionList}
              edit={ServiceRegionEdit}
              create={ServiceRegionCreate}
              options={{ label: 'Região de Serviço' }}
              icon={MoveToInboxIcon}
            />,
            <CoverageResource
              name={enums.resources.RE_ANNOTATION_PROMISED_DATE}
              permissions={permissions}
              list={ReAnnotationPromisedDate}
              options={{ label: 'Reanotação de pacotes' }}
              icon={AutoRenewIcon}
              customRole={enums.customActions.RE_ANNOTATION_WRITE}
            />,
            <CoverageResource
              name={enums.resources.DISTRIBUTION_CENTER}
              permissions={permissions}
              list={DistributionCenterList}
              edit={DistributionCenterEdit}
              options={{ label: 'Horário de Corte' }}
              icon={RestoreIcon}
            />,
            <CoverageResource
              name={enums.resources.PRICING_REGION}
              permissions={permissions}
              list={PricingRegionList}
              create={PricingRegionCreate}
              show={PricingRegionShow}
              options={{ label: 'Precificação' }}
              icon={AttachMoneyIcon}
            />,
            <CoverageResource
              name={enums.resources.TRANSIT_TIME}
              permissions={permissions}
              list={TransitTimeList}
              edit={TransitTimeEdit}
              create={TransitTimeCreate}
              options={{ label: 'Tempo de Trânsito' }}
              icon={SyncAlt}
            />,
            <CoverageResource
              name={enums.resources.SLO_EXCEPTION}
              permissions={permissions}
              list={SloExceptionList}
              edit={SloExceptionEdit}
              create={SloExceptionCreate}
              options={{ label: 'Exceções de SLO' }}
              icon={FeedbackIcon}
            />,
            <CoverageResource
              name={enums.resources.REGION}
              permissions={permissions}
              list={RegionList}
              edit={RegionEdit}
              create={RegionCreate}
              options={{ label: 'Regiões' }}
              icon={RegionIcon}
            />,
            <CoverageResource
              name={enums.resources.RULES}
              permissions={permissions}
              list={MeiRulesList}
              edit={MeiRulesEdit}
              options={{ label: 'Regras dos MEIs' }}
            />,
            <BlockScreenWhileLoading />
          ];
        }}
      </Admin>
    </ThemeProvider>
  );
}
