import React from 'react';
import PropTypes from 'prop-types';
import { TextInput } from 'react-admin';
import validateCnpj from './validator';
import cnpjNumberFormat from './cnpj-format';
import LABELS from './labels';

const CnpjInput = props => {
  const {
    label,
    source,
    dataTestId,
    variant,
    hasWritePermissions,
    isEditPage
  } = props;

  return (
    <TextInput
      label={label}
      source={source}
      data-testid={dataTestId}
      validate={[validateCnpj(isEditPage)]}
      InputProps={{
        inputComponent: cnpjNumberFormat
      }}
      variant={variant}
      disabled={!hasWritePermissions}
    />
  );
};

CnpjInput.defaultProps = {
  label: LABELS.CNPJ,
  dataTestId: LABELS.CNPJ_DATA_TEST_ID,
  variant: 'outlined',
  hasWritePermissions: true,
  isEditPage: true
};

CnpjInput.propTypes = {
  label: PropTypes.string,
  source: PropTypes.string.isRequired,
  dataTestId: PropTypes.string,
  variant: PropTypes.string,
  hasWritePermissions: PropTypes.bool,
  isEditPage: PropTypes.bool
};

export default CnpjInput;
