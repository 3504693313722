import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import Typography from '@material-ui/core/Typography';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { useStyles } from './styles';
import formatPostalCode from '../../../utils/format-postal-code';
import HEADERS from './constants';

const ErrorRangesContent = props => {
  const { ranges, description } = props;
  const classes = useStyles();

  return (
    <>
      {description && (
        <>
          <Typography
            variant="subtitle2"
            color="textSecondary"
            data-testid="descriptionField"
          >
            {description}
          </Typography>
          <br />
        </>
      )}
      <Box my={2}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow data-testid="changes-table-title">
                {HEADERS.map(header => (
                  <TableCell className={classes.tableHead}>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody data-testid="changes-table-body">
              {ranges.map(range => (
                <TableRow
                  key={`${range.name}-${range.start.code}-${range.end.code}`}
                  className={classes.tableRow}
                >
                  <TableCell component="th" scope="row">
                    <Typography>{range.name}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      <Box fontWeight="fontWeightBold">
                        {formatPostalCode(range.start.code)}
                      </Box>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      <Box fontWeight="fontWeightBold">
                        {formatPostalCode(range.end.code)}
                      </Box>
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

ErrorRangesContent.defaultProps = {
  description: ''
};

ErrorRangesContent.propTypes = {
  ranges: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      start: PropTypes.shape({
        code: PropTypes.string
      }),
      end: PropTypes.shape({
        code: PropTypes.string
      })
    })
  ).isRequired,
  description: PropTypes.string
};

export default ErrorRangesContent;
