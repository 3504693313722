import queryString from 'query-string';
import { fetchOrThrow } from './api';
import { normalize } from './api-utils';
import { buildRegionRangeName } from './region-utils';
import enums from '../enums';
import { DEFAULT_INITIAL_PAGE, DEFAULT_DOCUMENTS_PER_PAGE } from './constants';

const ENDPOINT_URL = `${process.env.REACT_APP_ANNOTATOR_URL}/api/v1/region`;

const getOne = async id => {
  const endpoint = `${ENDPOINT_URL}/${id}`;
  const response = await fetchOrThrow(endpoint);
  const data = normalize(response);

  return {
    data: {
      ...data,
      postalCodes: {
        ranges: data.ranges.map(range => {
          return {
            name: buildRegionRangeName(range),
            start: { code: range.start },
            end: { code: range.end },
            rangeType: enums.rangeType.RANGE_TYPE_DEFAULT
          };
        })
      }
    }
  };
};

const getList = async args => {
  const {
    pagination = {
      page: DEFAULT_INITIAL_PAGE,
      perPage: DEFAULT_DOCUMENTS_PER_PAGE
    },
    filter = {}
  } = args;

  const params = {
    page: pagination.page,
    'results-per-page': pagination.perPage
  };

  if (filter.name) {
    params.name = filter.name;
  }

  if (filter.type) {
    params.types = filter.type;
  }

  const filters = queryString.stringify(params, { sort: false });
  const response = await fetchOrThrow(`${ENDPOINT_URL}?${filters}`);
  const { regions = [], total = '0' } = response;
  const data = regions.map(normalize);

  return {
    data,
    total: parseInt(total, 10)
  };
};

const update = async args => {
  const { id, data } = args;
  const response = await fetchOrThrow(ENDPOINT_URL, {
    method: 'PUT',
    body: JSON.stringify({ ...data })
  });

  return { data: { id, ...response } };
};

const create = async args => {
  const { data } = args;
  const id = '';

  const response = await fetchOrThrow(ENDPOINT_URL, {
    method: 'POST',
    body: JSON.stringify({
      ...data
    })
  });

  return { data: { id, ...response } };
};

const deleteOne = async id => {
  const endpoint = `${ENDPOINT_URL}/${id}`;
  await fetchOrThrow(endpoint, {
    method: 'DELETE'
  });

  return { data: { id } };
};

export default {
  getOne,
  getList,
  update,
  create,
  deleteOne
};
