import React from 'react';
import Chip from '@material-ui/core/Chip';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { labels } from './constants';
import { useStyles } from './styles';

const WorkingXdChip = () => {
  const classes = useStyles();

  return (
    <Chip
      className={classes.workingXdChip}
      label={labels.ATTENDANCE_XD}
      icon={<LocationOnIcon className={classes.icon} />}
    />
  );
};

export default WorkingXdChip;
