import React from 'react';
import PropTypes from 'prop-types';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { Box, Grid, Typography } from '@material-ui/core';

import LABELS from './labels';
import VERSIONING_COLOR from './styles';

const VersioningIcon = props => {
  const { versioningStatusType } = props;

  return (
    <Box
      display="flex"
      alignItems="center"
      minHeight="100%"
      data-testid="versioning-status-icon"
    >
      <FiberManualRecordIcon
        style={{
          color: VERSIONING_COLOR[versioningStatusType],
          fontSize: 12,
          alignSelf: 'center'
        }}
      />
    </Box>
  );
};

VersioningIcon.propTypes = {
  versioningStatusType: PropTypes.string.isRequired
};

const VersioningStatus = props => {
  const { versioningStatusType, ...rest } = props;

  return (
    <Grid container spacing={1}>
      <Grid item>
        <VersioningIcon {...props} />
      </Grid>

      <Grid item>
        <Typography {...rest}>
          {LABELS.VERSIONING_STATUS[versioningStatusType]}
        </Typography>
      </Grid>
    </Grid>
  );
};

VersioningStatus.propTypes = {
  versioningStatusType: PropTypes.string.isRequired
};

export default VersioningStatus;
