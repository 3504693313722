import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNotify, useRedirect, useResourceContext } from 'react-admin';
import { Box, Grid } from '@material-ui/core';
import GoBackButton from '../../atoms/go-back-button/go-back-button';
import VersionMenu from '../version-menu/version-menu';
import Api from '../../../api/postal-code-api';
import { useRemoteConfig } from '../../../firebase';
import { IS_RESOURCE_ENABLED_FS } from './constants';

const TopBar = props => {
  const { id, referenceId } = props;
  const [versionsList, setVersionsList] = useState([]);
  const [enabledResources, setEnabledResources] = useState('');

  const redirect = useRedirect();
  const resource = useResourceContext();
  const notify = useNotify();
  const enabledResourcesRC = useRemoteConfig(IS_RESOURCE_ENABLED_FS);

  const onVersionSelection = selectedId => {
    redirect('edit', `/${resource}`, selectedId);
  };

  useEffect(() => {
    const { isLoading, hasError, value } = enabledResourcesRC;

    if (!isLoading && !hasError && value) {
      setEnabledResources(value);
    }
  }, [enabledResourcesRC]);

  useEffect(() => {
    const loadVersions = async () => {
      let response = [];

      try {
        if (referenceId) {
          response = await Api.listDocumentVersions(resource, referenceId);
        }
      } catch (error) {
        notify(error.message);
      }

      setVersionsList(response);
    };

    if (enabledResources.split(',').includes(resource)) {
      loadVersions();
    }
  }, [enabledResources, resource, notify, referenceId, setVersionsList]);

  return (
    <Grid container direction="row" fullWidth>
      <Grid item xs={6}>
        <GoBackButton />
      </Grid>
      <Grid item xs={6}>
        <Box display="flex" justifyContent="flex-end">
          <VersionMenu
            setSelectedOption={onVersionSelection}
            versionsList={versionsList}
            selectedOption={id}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

TopBar.propTypes = {
  id: PropTypes.string.isRequired,
  referenceId: PropTypes.string.isRequired
};

export default TopBar;
