import { blue, green, grey, red } from '@material-ui/core/colors';
import enums from '../../../enums';

const VERSIONING_COLOR = {
  [enums.versioningStatusType.INVALID]: grey[400],
  [enums.versioningStatusType.ACTIVE]: green[500],
  [enums.versioningStatusType.EXPIRED]: grey[400],
  [enums.versioningStatusType.PROGRAMMED]: blue[500],
  [enums.versioningStatusType.APPROVED]: blue[500],
  [enums.versioningStatusType.REJECTED]: red[500]
};

export default VERSIONING_COLOR;
