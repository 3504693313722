import { memoize } from './utils';
import Api from '../api/postal-code-api';
import LABELS from './labels';

const ERROR_MESSAGES_MAP = {
  name: LABELS.VALIDATION_MESSAGES.NAME_EXISTS,
  routing_code: LABELS.VALIDATION_MESSAGES.ROUTING_CODE_EXISTS
};

const validateDistributionCenterOnServer = async (fieldName, value) => {
  const endpoint = `${
    process.env.REACT_APP_ANNOTATOR_URL
  }/api/v1/hub?${fieldName}=${value}`;

  try {
    return await Api.fetchOrThrow(endpoint);
  } catch (e) {
    return null;
  }
};

const distributionCenterExists = hubs => !!(hubs && hubs.length);

const distributionCenterValidator = (fieldName, minLength) =>
  memoize(async value => {
    if (!value || value.length < minLength) {
      return null;
    }

    const response = await validateDistributionCenterOnServer(fieldName, value);

    if (!response) {
      return LABELS.VALIDATION_MESSAGES.FETCH_ERROR;
    }

    if (distributionCenterExists(response.hubs)) {
      return (
        ERROR_MESSAGES_MAP[fieldName] ||
        LABELS.VALIDATION_MESSAGES.DEFAULT_EXISTS
      );
    }

    return null;
  });

export default distributionCenterValidator;
