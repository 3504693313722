import LABELS from './labels';

const firstCheckDigitFactor = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2, 0, 0];
const secondCheckDigitFactor = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2, 0];

const decimalBase = 10;

const calculateCheckDigit = (cnpj, factors) => {
  const sum = cnpj.split('').reduce((partialSum, element, index) => {
    return partialSum + parseInt(element, decimalBase) * factors[index];
  }, 0);

  const result = 11 - (sum % 11);

  return result >= 10 ? 0 : result;
};

const isFirstDigitValid = cnpj => {
  return (
    calculateCheckDigit(cnpj, firstCheckDigitFactor) ===
    parseInt(cnpj[12], decimalBase)
  );
};

const isSecondDigitValid = cnpj => {
  return (
    calculateCheckDigit(cnpj, secondCheckDigitFactor) ===
    parseInt(cnpj[13], decimalBase)
  );
};

const validateCnpj = isEditPage => cnpj => {
  if (!cnpj) {
    return isEditPage ? undefined : LABELS.VALIDATION_MESSAGES.REQUIRED;
  }

  if (cnpj.length !== 14) {
    return LABELS.VALIDATION_MESSAGES.CNPJ_INVALID_LENGTH;
  }

  if (!isFirstDigitValid(cnpj) || !isSecondDigitValid(cnpj)) {
    return LABELS.VALIDATION_MESSAGES.INVALID_CNPJ;
  }

  return undefined;
};

export default validateCnpj;
