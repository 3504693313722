import React from 'react';
import { Box, Grid, Link, CssBaseline } from '@material-ui/core';
import { ReactComponent as DeniedImage } from '../../../../assets/images/packages.svg';
import { ReactComponent as Logo } from '../../../../assets/icons/logo.svg';
import ScreenHeader from '../../../atoms/screen-header';
import SignOut from '../signout-form';
import { useStyles } from './styles';
import LABELS from '../labels';

const AccessDeniedPage = () => {
  const classes = useStyles();

  return (
    <>
      <CssBaseline />
      <Grid container className={classes.grid}>
        <Grid item xs={12} sm={12} md={12} lg={12} className={classes.root}>
          <Grid
            container
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            direction="column"
            justify="center"
            alignItems="center"
            className={classes.alignLogo}
          >
            <Link
              href="http://www.loggi.com"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              <Logo className={classes.logo} />
            </Link>
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            direction="column"
            justify="center"
            alignItems="center"
            className={classes.grid}
          >
            <Box className={classes.box}>
              <Grid item>
                <DeniedImage className={classes.image} />
              </Grid>
              <Grid item>
                <Box mt={2}>
                  <ScreenHeader
                    title={LABELS.TITLE}
                    subTitle={LABELS.SUBTITLE}
                  />
                </Box>
              </Grid>
              <Grid item>
                <SignOut />
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default AccessDeniedPage;
