import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { FormControl } from '@material-ui/core';
import LABELS from './labels';
import {
  getPostalCodeGroupInfo,
  isLastMileCompanyNoCoverage
} from '../../../molecules/postal-codes-table/utils';
import DatePickerInput from '../../../molecules/date-picker-input';
import PostalCodesTableTabs from '../../../molecules/postal-codes-table-tabs';
import ErrorRangesContent from '../../../molecules/error-ranges-content';
import CustomDialog from '../../../molecules/custom-dialog';
import CustomDivider from '../../../atoms/custom-divider/custom-divider';
import ActiveLmcSelect from './active-lmc-select';
import LastMileCompanyInfo from './last-mile-company-info';
import PickupForm from './pickup-form';
import enums from '../../../../enums';

export default function LastMileCompanyForm({
  hasPermissions,
  formData,
  errorRanges,
  handleCloseDialog,
  conflictMessage,
  pageType,
  canUpdateLocalSlo
}) {
  const postalCodeGroupInfo = getPostalCodeGroupInfo(formData);
  const isEditPage = pageType === enums.pageType.EDIT;

  return (
    <>
      <LastMileCompanyInfo
        canUpdate={isEditPage ? hasPermissions : true}
        pageType={pageType}
        formData={formData}
      />

      <CustomDivider />

      <Grid container spacing={2} alignItems="center">
        <Grid item sm={2} lg={1}>
          <ActiveLmcSelect
            hasWritePermissions={isEditPage ? hasPermissions : false}
          />
        </Grid>

        <Grid item sm={4} lg={5}>
          {!formData?.lastMileCompanyInfo?.active && (
            <FormControl>
              <DatePickerInput
                dataTestId="last-mile-company-activation-time"
                defaultValue={null}
                label={LABELS.TITLES.ACTIVATION_DATE}
                source="lastMileCompanyInfo.activationTimeAsInt"
              />
            </FormControl>
          )}
        </Grid>

        <Grid item sm={6}>
          <PickupForm />
        </Grid>
      </Grid>

      <CustomDivider />

      {!isLastMileCompanyNoCoverage(postalCodeGroupInfo) && (
        <PostalCodesTableTabs
          postalCodeGroupInfo={postalCodeGroupInfo}
          ranges={formData?.postalCodes?.ranges}
          changes={formData?.changes}
          hasWritePermissions={hasPermissions}
          earningsInfoByRegions={
            formData?.lastMileCompanyInfo?.deliveryEarningsTable
              ?.earningsInfoByRegions
          }
          canUpdateLocalSlo={canUpdateLocalSlo}
        />
      )}

      <CustomDialog
        fullWidth
        open={!!errorRanges?.length}
        onClose={handleCloseDialog}
        title={LABELS.ERRORS.COVERAGE_ERROR}
      >
        <ErrorRangesContent
          ranges={errorRanges}
          description={conflictMessage}
        />
      </CustomDialog>
    </>
  );
}

LastMileCompanyForm.propTypes = {
  hasPermissions: PropTypes.bool.isRequired,
  formData: PropTypes.objectOf(PropTypes.string).isRequired,
  errorRanges: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  conflictMessage: PropTypes.string.isRequired,
  pageType: PropTypes.string.isRequired,
  canUpdateLocalSlo: PropTypes.bool.isRequired
};
