import React from 'react';
import PropTypes from 'prop-types';

import {
  Datagrid,
  FunctionField,
  TextField,
  List,
  EditButton,
  ShowButton
} from 'react-admin';

import ResourceFilters from '../../../molecules/resource-filters';
import LABELS from './labels';
import LabeledEnumTextField from '../../../atoms/labeled-enum-text-field';
import enums from '../../../../enums';
import permissionsFor from '../../../../access/access';
import { CITY_TYPES, SERVICE_REGION } from './constants';
import cutoffHourFromOffset from '../../../../utils/cutoff-hour-from-offset';

export default function ServiceTypeList(props) {
  const { permissions } = props;
  const canUpdate = permissionsFor(enums.resources.SERVICE_TYPE)
    .hasRole(permissions)
    .forAction(enums.actions.UPDATE);

  const filters = {
    cityType: CITY_TYPES,
    serviceRegion: SERVICE_REGION
  };

  return (
    <List
      filters={<ResourceFilters customFilters={filters} />}
      sort={{ field: 'serviceTypeInfo.name', order: 'ASC' }}
      exporter={false}
      bulkActionButtons={false}
      title={LABELS.TITLES.LIST_TITLE}
      {...props}
    >
      <Datagrid>
        <TextField source="serviceTypeInfo.name" label={LABELS.TITLES.NAME} />
        <LabeledEnumTextField
          source="serviceTypeInfo.serviceType"
          label={LABELS.TITLES.TYPE}
          enumLabels={LABELS.TYPES}
        />
        <LabeledEnumTextField
          source="serviceTypeInfo.cityType"
          label={LABELS.TITLES.CITY_TYPE}
          enumLabels={LABELS.CITY_TYPES}
        />
        <TextField source="serviceTypeInfo.slo" label={LABELS.TITLES.SLO} />
        <FunctionField
          label={LABELS.TITLES.CUTOFF_TIME}
          render={record =>
            record &&
            record.serviceTypeInfo &&
            cutoffHourFromOffset(
              record.serviceTypeInfo.confirmationDeadlineOffset
            )
          }
        />
        {canUpdate ? <EditButton /> : <ShowButton />}
      </Datagrid>
    </List>
  );
}

ServiceTypeList.defaultProps = {
  permissions: undefined
};

ServiceTypeList.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string)
};
