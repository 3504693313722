import firebase from 'firebase/app';
import 'firebase/remote-config';
import 'firebase/messaging';

const apiKey = process.env.REACT_APP_FIREBASE_API_KEY;
const authDomain = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
const databaseURL = process.env.REACT_APP_FIREBASE_DATABASE_URL;
const projectId = process.env.REACT_APP_FIREBASE_PROJECT_ID;
const storageBucket = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
const messagingSenderId = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
const appId = process.env.REACT_APP_FIREBASE_APP_ID;

const firebaseConfig = {
  apiKey,
  authDomain,
  databaseURL,
  projectId,
  storageBucket,
  messagingSenderId,
  appId
};

const firebaseInit = () => {
  firebase.initializeApp(firebaseConfig);

  const remoteConfig = firebase.remoteConfig();
  remoteConfig.settings = {
    fetchTimeoutMillis: 60000
  };
  remoteConfig.defaultConfig = {
    FS_SHOW_DELETE_BUTTON: 'false',
    FS_BLOCK_SCREEN_WHILE_LOADING: 'false'
  };
};

export default firebaseInit;
