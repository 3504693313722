import { makeStyles } from '@material-ui/core/styles';

const backgroundColor =
  'linear-gradient(286.66deg, #007CFF 0.1%, #0186FF 100%)';

export const useStyles = makeStyles(() => ({
  buttonMenu: {
    background: backgroundColor,
    color: 'white',
    width: '2.75rem',
    '&:hover': {
      background: backgroundColor
    },
    '&.Mui-disabled': {
      backgroundImage: 'none'
    }
  }
}));

export default { useStyles };
