import enums from '../enums';

const { versioningStatusType } = enums;

const futureVersionStatuses = [
  versioningStatusType.APPROVED,
  versioningStatusType.PROGRAMMED
];

export const isVersioned = versioningStatus =>
  versioningStatus && versioningStatus !== versioningStatusType.INVALID;

export const shouldShowDeleteButton = versioningStatus =>
  futureVersionStatuses.includes(versioningStatus);
