import React from 'react';
import PropTypes from 'prop-types';
import { BooleanInput } from 'react-admin';
import { useForm } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import LABELS from './labels';

export default function ActiveLmcSelect(props) {
  const useStyles = makeStyles(theme => ({
    activeField: {
      paddingTop: theme.spacing(1)
    }
  }));

  const { hasWritePermissions } = props;
  const classes = useStyles();
  const form = useForm();

  return (
    <BooleanInput
      className={classes.activeField}
      data-testid="last-mile-company-active-toggle"
      label={LABELS.TITLES.ACTIVE}
      source="lastMileCompanyInfo.active"
      onChange={() =>
        form.change('lastMileCompanyInfo.activationTimeAsInt', null)
      }
      disabled={!hasWritePermissions}
    />
  );
}

ActiveLmcSelect.defaultProps = {
  hasWritePermissions: false
};

ActiveLmcSelect.propTypes = {
  hasWritePermissions: PropTypes.bool
};
