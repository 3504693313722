import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  inputField: {
    color: theme.palette.primary.main,
    paddingLeft: theme.spacing(2),
    marginTop: theme.spacing(-1.5)
  }
}));

export default { useStyles };
