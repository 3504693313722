import enums from '../../../../../enums';
import moment from '../../../../../utils/moment';
import LABELS from './labels';

export const transformToPickupData = data => {
  return {
    coverageType: data?.coverageType,
    targetDateInMs: moment.unix(data?.targetDateInMs).valueOf(),
    apiType: LABELS.API_TYPE.COVERAGE_MAPPING_API
  };
};

export const transformToDeliveryData = data => {
  return {
    origin: {
      id: data?.serviceRegionInfo?.parentServiceRegionId,
      name: data?.originServiceRegionGroup?.serviceRegionInfo?.name
    },
    serviceTypes: data?.serviceTypes,
    coverageType: data?.coverageType,
    targetDateInMs: moment.unix(data?.targetDateInMs).valueOf(),
    apiType: LABELS.API_TYPE.COVERAGE_MAPPING_API
  };
};

export const transformToDropoffData = data => {
  return {
    coverageType: data?.coverageType,
    page: 1,
    resultsPerPage: 1000,
    apiType: LABELS.API_TYPE.SERVICE_PROVIDERS_LOCATION_API
  };
};

export const isCoverageTypeDelivery = coverageType => {
  return coverageType === enums.rangeType.RANGE_TYPE_DELIVERY;
};

export const isCoverageTypeDropoff = coverageType => {
  return (
    coverageType === enums.firstMileServiceType.FIRST_MILE_SERVICE_TYPE_DROPOFF
  );
};

export default {
  transformToPickupData,
  transformToDeliveryData,
  transformToDropoffData,
  isCoverageTypeDelivery,
  isCoverageTypeDropoff
};
