const LABELS = {
  PAGE_TITLE: {
    TITLE: 'Regiões:'
  },
  TITLES: {
    NAME: 'Nome',
    TYPE: 'Tipo',
    DESCRIPTION: 'Descrição',
    POSTAL_CODES_RANGES: 'Faixas de CEP',
    LIST_TITLE: 'Listar Regiões'
  },
  TYPES: {
    REGION_TYPE_INVALID: 'Desconhecido',
    REGION_TYPE_STATE: 'Estado',
    REGION_TYPE_CITY: 'Cidade',
    REGION_TYPE_DISTRICT: 'Distrito',
    REGION_TYPE_STREET: 'Rua',
    REGION_TYPE_CUSTOM_REGION: 'Customizado'
  }
};

export default LABELS;
