import { required } from 'react-admin';

export const validations = {
  name: [required()],
  type: [required()],
  description: [required()],
  ranges: [required()]
};

export default { validations };
