import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  theme => ({
    chip: {
      margin: theme.spacing(1 / 4)
    },
    errorMessage: {
      color: theme.palette.error.main,
      paddingLeft: theme.spacing(2)
    }
  }),
  { name: 'RaSelectMultipleInput' }
);

export default useStyles;
