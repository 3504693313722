import { OPERATIONS } from '../batch-ranges-import/utils/constants';

export const LABELS = {
  BUTTONS: {
    ADD: 'Adicionar'
  },
  INPUTS: {
    NAME: 'Nome',
    START: 'Início',
    END: 'Final',
    SLO: 'SLO Local',
    TYPE: 'Tipo de CEP'
  },
  TEXTS: {
    TYPE_CHANGE: OPERATIONS.JSON_PATCH.OPERATION_ADD.LABEL,
    FILLED_BY_REGION: 'Preenchido a partir da Região'
  },
  ERRORS: {
    NAME_IS_EMPTY: 'O nome é obrigatório',
    CEP_LENGTH: 'O CEP tem que ter oito dígitos',
    TYPE_IS_EMPTY: 'Tipo de CEP é obrigatório',
    START_GT_END: 'CEP inicial maior que final',
    RANGE_INTERSECTION: 'Sobreposição de CEPS'
  }
};

export default {
  LABELS
};
