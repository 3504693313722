const LABELS = {
  PAGE_TITLE: {
    TITLE: 'Exportar abrangência'
  },
  SECTION_TITLE: {
    COVERAGE_TYPE: 'Serviço',
    ORIGIN: 'Origem',
    SERVICE_TYPE: 'Tipo de serviço',
    REFERENCE_DATE: 'Data de referência'
  },
  SECTION_SUBTITLE: {
    ORIGIN:
      'Selecione uma origem que será utilizada como referência para gerar a planilha de abrangência.',
    COVERAGE_TYPE:
      'Selecione o serviço abaixo para exportar a planilha de abrangência.'
  },
  SERVICE_TYPES: {
    EXPRESS: 'Express',
    HOJE: 'Hoje',
    REDESPACHO: 'Redespacho'
  },
  FORM_DATA: {
    SERVICE_REGION_ID_PATH: 'serviceRegionInfo.parentServiceRegionId',
    SERVICE_REGION_NAME_PATH: 'originServiceRegionGroup.serviceRegionInfo.name',
    INPUT_DATE_LABEL: 'Data'
  },
  BUTTON: {
    BUTTON_SUBIMIT_TEXT: 'Exportar'
  },
  RANGE_TYPE: {
    RANGE_TYPE_PICKUP: 'Coleta',
    RANGE_TYPE_DELIVERY: 'Entrega'
  },
  FIRST_MILE_SERVICE_TYPE: {
    FIRST_MILE_SERVICE_TYPE_DROPOFF: 'Dropoff'
  },
  API_TYPE: {
    COVERAGE_MAPPING_API: 'coverageMappingApi',
    SERVICE_PROVIDERS_LOCATION_API: 'serviceProvidersLocationApi'
  }
};
export default LABELS;
