import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  dialogTitle: {
    paddingBottom: 0
  }
});

export default {
  useStyles
};
