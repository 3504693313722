import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@loggi/mar';

export const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  tabBar: {
    backgroundColor: colors.root[0],
    color: colors.blue[500]
  },
  wrapperTab: {
    flexDirection: 'row',
    gap: theme.spacing(1),
    padding: theme.spacing(0, 2)
  }
}));

export default {
  useStyles
};
