import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  tableTypeChip: {
    margin: theme.spacing(0.5)
  },
  redColor: {
    color: theme.palette.error.main
  },
  removeButton: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    '&:hover, &:focus': {
      color: theme.palette.error.dark,
      borderColor: theme.palette.error.dark
    }
  },
  earningsLabelCell: {
    width: '10%'
  },
  sloCell: {
    width: '7%'
  },
  actionsCell: {
    width: '12%'
  }
}));

export default { useStyles };
