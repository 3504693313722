import queryString from 'query-string';
import { fetchOrThrow } from '../api';
import serviceProvidesLocationApiUtils from './service-providers-locations-api-utils';

const ENDPOINT_URL = `${
  process.env.REACT_APP_ANNOTATOR_URL
}/api/v1/service-providers-location`;

const getData = async args => {
  const { page, resultsPerPage } = args.data;

  const params = {};

  if (page) {
    params.page = page;
  }

  if (resultsPerPage) {
    params['results-per-page'] = resultsPerPage;
  }

  const filters = queryString.stringify(params);
  const response = await fetchOrThrow(`${ENDPOINT_URL}?${filters}`);
  const { serviceLocations } = response;

  if (!serviceLocations.length) return {};

  const csvData = serviceLocations.map(
    serviceProvidesLocationApiUtils.buildDeliveryData
  );

  return { csvData, data: { serviceLocations } };
};

export default { getData };
