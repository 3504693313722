import React from 'react';
import PropTypes from 'prop-types';
import { maxValue, minValue, NumberInput } from 'react-admin';
import { Grid } from '@material-ui/core';
import LABELS from './labels';

const LatLng = props => {
  const { latSource, lngSource, disabled } = props;

  const latitudeValidation = [minValue(-90.0), maxValue(90.0)];
  const longetudeValidation = [minValue(-180.0), maxValue(180.0)];

  return (
    <Grid container fullWidth spacing={2}>
      <Grid item xs={12} sm={6}>
        <NumberInput
          label={LABELS.LATITUDE}
          source={latSource}
          disabled={disabled}
          validate={latitudeValidation}
          data-testid="latitude"
          fullWidth
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <NumberInput
          label={LABELS.LONGITUDE}
          source={lngSource}
          disabled={disabled}
          validate={longetudeValidation}
          data-testid="longitude"
          fullWidth
          variant="outlined"
        />
      </Grid>
    </Grid>
  );
};

LatLng.defaultProps = {
  disabled: false
};

LatLng.propTypes = {
  latSource: PropTypes.string.isRequired,
  lngSource: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

export default LatLng;
