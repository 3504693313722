import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Toolbar, DeleteButton } from 'react-admin';
import isEqual from 'lodash/isEqual';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import SaveIcon from '@material-ui/icons/Save';
import { useForm } from 'react-final-form';
import DialogShowChanges from '../dialog-show-changes';
import DialogLeveRangesError from '../dialog-leve-ranges-error';
import { LABELS } from './constants';
import { useStyles } from './styles';
import { getInvalidRanges, shouldValidateEarningsLabels } from './validations';
import SaveGroupButton from '../save-group-button/save-group-button';
import enums from '../../../enums';
import { getTomorrowTimestamp } from '../../../utils/format-time';
import getBrazilMoment from '../../../utils/date-utils';

const tomorrow = getTomorrowTimestamp();

const SaveWithConfirmToolbar = props => {
  const {
    loading,
    handleSubmit,
    hasWritePermissions,
    postalCodeGroupInfo,
    changes,
    postalCodes,
    deliveryEarningsTable,
    isVersioned,
    showDeleteButton
  } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const classes = useStyles();
  const [openChangesDialog, setOpenChangesDialog] = useState(false);
  const [errorRanges, setErrorRanges] = useState([]);
  const [scheduledDate, changeScheduledDate] = useState(tomorrow);
  const validateEarningsLabels = shouldValidateEarningsLabels(
    postalCodeGroupInfo,
    postalCodes
  );

  const form = useForm();

  const closeDialogsAndSubmit = async () => {
    setOpenChangesDialog(false);
    setIsSubmitting(true);
    await handleSubmit();
    setIsSubmitting(false);
  };

  const handleOnSave = async () => {
    if (validateEarningsLabels) {
      const invalidRanges = getInvalidRanges(
        postalCodes,
        deliveryEarningsTable
      );
      if (invalidRanges.length) {
        setErrorRanges(invalidRanges);
        return;
      }
    }
    closeDialogsAndSubmit();
  };

  const handleOnScheduled = async () => {
    form.change('versioningStatus', enums.versioningStatusType.PROGRAMMED);
    const scheduledEffectiveOn = getBrazilMoment(
      scheduledDate.year(),
      scheduledDate.month(),
      scheduledDate.date()
    );
    form.change('effectiveOnTimeAsInt', scheduledEffectiveOn.unix());
    form.change('isNewProgrammedDocument', true);

    closeDialogsAndSubmit();
  };

  return (
    !loading && (
      <Toolbar
        {...props}
        className={classes.toolbar}
        data-testid="save-with-confirm-toolbar"
      >
        {showDeleteButton && (
          <DeleteButton data-testid="delete-button" undoable={false} />
        )}
        {!isVersioned && (
          <Button
            onClick={() =>
              changes?.length ? setOpenChangesDialog(true) : handleOnSave()
            }
            disabled={!hasWritePermissions}
            data-testid="save-with-confirm-button"
            className={classes.saveButton}
          >
            <SaveIcon className={classes.saveIcon} />
            {LABELS.BUTTONS.SAVE}
          </Button>
        )}
        {isVersioned && (
          <SaveGroupButton
            onSubmit={() =>
              changes?.length ? setOpenChangesDialog(true) : handleOnSave()
            }
            scheduledDate={scheduledDate}
            changeScheduledDate={changeScheduledDate}
            disabled={!hasWritePermissions}
            onScheduled={handleOnScheduled}
          />
        )}
        <DialogShowChanges
          open={openChangesDialog}
          handleOpenDialog={setOpenChangesDialog}
          changes={changes}
          postalCodeGroupInfo={postalCodeGroupInfo}
          onConfirm={handleOnSave}
        />
        <Backdrop
          data-testid="save-with-confirm-backdrop"
          className={classes.backdrop}
          open={isSubmitting}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        {validateEarningsLabels && (
          <DialogLeveRangesError
            open={!!errorRanges?.length}
            onClose={() => setErrorRanges([])}
            ranges={errorRanges}
          />
        )}
      </Toolbar>
    )
  );
};

SaveWithConfirmToolbar.defaultProps = {
  changes: [],
  hasWritePermissions: false,
  postalCodes: { ranges: [] },
  deliveryEarningsTable: { earningsInfoByRegions: [] },
  isVersioned: false,
  showDeleteButton: false
};

SaveWithConfirmToolbar.propTypes = {
  loading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  changes: PropTypes.arrayOf(
    PropTypes.shape({
      typeChange: PropTypes.string,
      types: PropTypes.arrayOf(PropTypes.string),
      name: PropTypes.string,
      start: PropTypes.shape({
        code: PropTypes.string
      }),
      end: PropTypes.shape({
        code: PropTypes.string
      }),
      lastMileCompanyInfo: PropTypes.shape({
        slo: PropTypes.number
      })
    })
  ),
  hasWritePermissions: PropTypes.bool,
  postalCodeGroupInfo: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.number,
    resourceName: PropTypes.string,
    groupType: PropTypes.string,
    active: PropTypes.bool
  }).isRequired,
  postalCodes: PropTypes.shape({
    ranges: PropTypes.arrayOf(
      PropTypes.shape({
        start: PropTypes.shape({
          code: PropTypes.string
        }),
        end: PropTypes.shape({
          code: PropTypes.string
        }),
        name: PropTypes.string,
        types: PropTypes.arrayOf(PropTypes.string),
        lastMileCompanyInfo: PropTypes.shape({
          slo: PropTypes.number,
          earningsLabel: PropTypes.string
        })
      })
    )
  }),
  deliveryEarningsTable: PropTypes.shape({
    name: PropTypes.string,
    earningsInfoByRegions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string
      })
    )
  }),
  isVersioned: PropTypes.bool,
  showDeleteButton: PropTypes.bool
};

const rangePropsAreEqual = (prevProps, nextProps) => {
  return (
    prevProps.loading === nextProps.loading &&
    isEqual(prevProps.changes, nextProps.changes) &&
    prevProps.hasWritePermissions === nextProps.hasWritePermissions &&
    isEqual(prevProps.deliveryEarningsTable, nextProps.deliveryEarningsTable) &&
    prevProps.postalCodeGroupInfo.active ===
      nextProps.postalCodeGroupInfo.active &&
    prevProps.isVersioned === nextProps.isVersioned
  );
};

export default React.memo(SaveWithConfirmToolbar, rangePropsAreEqual);
