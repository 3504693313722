import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, useNotify } from 'react-admin';
import { Grid, TextField } from '@material-ui/core';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from 'date-fns/locale';
import Api from '../../../api/postal-code-api';
import { SERVICE_REGION } from '../../pages/_react-admin/service-type/constants';
import { ERRORS, LABELS } from './constants';

export default function UpdateTimestampRangePicker({
  form,
  startDataTestId,
  endDataTestId
}) {
  const notify = useNotify();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    setStartDate(today);
  }, []);

  const getEpochTimestampString = dateTime => {
    if (dateTime !== null) {
      // getTime() comes in ms, we want it in seconds
      return Math.floor(dateTime.getTime() / 1000).toString();
    }
    return '';
  };

  const getAllpostalCodeGroups = async (startDatetime, endDatetime) => {
    try {
      const response = await Api.getList(SERVICE_REGION.resourceName, {
        filter: {
          updatedAtStart: getEpochTimestampString(startDatetime),
          updatedAtEnd: getEpochTimestampString(endDatetime)
        },
        pagination: { page: 1, perPage: 1000 }
      });

      return response.data || [];
    } catch {
      notify(ERRORS.FAILED_TO_FETCH, 'warning');
    }
    return [];
  };

  const importPostalCodeGroups = async () => {
    if (!startDate) {
      notify(ERRORS.START_DATE_MANDATORY, 'warning');
    } else if (endDate !== null && startDate > endDate) {
      notify(ERRORS.END_DATE_BIGGER_THAN_STAR_DATE_MANDATORY, 'warning');
    } else {
      const postalCodeGroups = await getAllpostalCodeGroups(startDate, endDate);

      form.batch(() => {
        const currentGroups = form.getState().values.serviceRegionGroups || [];
        const combinedGroups = [...currentGroups, ...postalCodeGroups];
        const uniqueGroups = Array.from(
          new Map(combinedGroups.map(group => [group.id, group])).values()
        );
        const uniqueGroupIds = uniqueGroups.map(value => value.id);

        form.change(LABELS.SERVICE_REGION_GROUP_IDS, uniqueGroupIds);
        form.change(LABELS.SERVICE_REGION_GROUP_OPTIONS, uniqueGroups);
      });
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <DateTimePicker
            data-testid={startDataTestId}
            label={LABELS.START_DATE_TIME}
            value={startDate}
            onChange={setStartDate}
            renderInput={props => <TextField {...props} />}
            format="dd/MM/yyyy HH:mm"
          />
        </Grid>
        <Grid item xs={2}>
          <DateTimePicker
            data-testid={endDataTestId}
            label={LABELS.END_DATE_TIME}
            value={endDate}
            onChange={setEndDate}
            renderInput={props => <TextField {...props} />}
            format="dd/MM/yyyy HH:mm"
          />
        </Grid>
        <Grid
          container
          justifyContent="center"
          style={{ marginTop: '16px', marginBottom: '16px' }}
        >
          <Grid item>
            <Button
              label="Importar"
              variant="contained"
              color="secondary"
              onClick={importPostalCodeGroups}
            />
          </Grid>
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
}

UpdateTimestampRangePicker.defaultProps = {
  startDataTestId: 'start-update-timestamp-range-picker',
  endDataTestId: 'end-update-timestamp-range-picker'
};

UpdateTimestampRangePicker.propTypes = {
  form: PropTypes.shape({
    batch: PropTypes.func.isRequired,
    getState: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired
  }).isRequired,
  startDataTestId: PropTypes.string,
  endDataTestId: PropTypes.string
};
