import moment from '../../../utils/moment';
import enums from '../../../enums';

const timestampToString = timestampInSeconds => {
  const timestampInSecondsTmp = timestampInSeconds || 0;
  return moment
    .unix(timestampInSecondsTmp)
    .utcOffset(-180) // Brazilia time
    .format('DD MMM YYYY')
    .toLocaleLowerCase();
};

const validityEndLabel = (versioningStatusType, expiredAtTimestamp) => {
  if (
    !expiredAtTimestamp &&
    versioningStatusType === enums.versioningStatusType.ACTIVE
  ) {
    return ' - atual';
  }

  if (!expiredAtTimestamp) {
    return '';
  }

  return ` - ${timestampToString(expiredAtTimestamp)}`;
};

const getValidityLabel = (
  versioningStatusType,
  effectiveOnTimestamp,
  expiredAtTimestamp
) =>
  `${timestampToString(effectiveOnTimestamp)}${validityEndLabel(
    versioningStatusType,
    expiredAtTimestamp
  )}`;
export default getValidityLabel;
