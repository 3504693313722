import React from 'react';
import PropTypes from 'prop-types';
import { Datagrid, TextField, List, EditButton, ShowButton } from 'react-admin';

import LABELS from './labels';
import RegionFilter from '../../../molecules/region-filters';
import LabeledEnumTextField from '../../../atoms/labeled-enum-text-field';
import { REGION_TYPES } from './constants';
import permissionsFor from '../../../../access/access';
import enums from '../../../../enums';

export default function RegionList(props) {
  const { permissions } = props;
  const canUpdate = permissionsFor(enums.resources.REGION)
    .hasRole(permissions)
    .forAction(enums.actions.UPDATE);

  return (
    <List
      filters={<RegionFilter choices={REGION_TYPES} />}
      exporter={false}
      bulkActionButtons={false}
      title={LABELS.TITLES.LIST_TITLE}
      {...props}
    >
      <Datagrid>
        <TextField source="name" label={LABELS.TITLES.NAME} />
        <LabeledEnumTextField
          source="type"
          label={LABELS.TITLES.TYPE}
          enumLabels={LABELS.TYPES}
        />
        {canUpdate ? <EditButton /> : <ShowButton />}
      </Datagrid>
    </List>
  );
}

RegionList.defaultProps = {
  permissions: []
};

RegionList.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string)
};
