import LABELS from './labels';
import enums from '../../../../enums';
import mirrorDeliveryRangeToPickup from '../../../../utils/mirror-delivery-range-to-pickup';
import { formatTimeToObject } from '../../../../utils/format-time';
import { convertDecimalToMoneyProto } from '../../../../utils/currency-format';

function handleFailure({ err, setErrorRanges, setConflictMessage, notify }) {
  if (
    err.status === 404 &&
    (err?.body?.error?.code ===
      enums.errorCode.SERVICE_TYPE_NOT_FOUND_FOR_LMC_COVERAGE ||
      err?.body?.error?.code ===
        enums.errorCode.SERVICE_REGION_NOT_FOUND_FOR_LMC_COVERAGE)
  ) {
    const {
      body: {
        error: {
          rangeNotFound: { ranges }
        }
      }
    } = err;
    setErrorRanges(ranges);
    if (
      err?.body?.error?.code ===
      enums.errorCode.SERVICE_TYPE_NOT_FOUND_FOR_LMC_COVERAGE
    ) {
      setConflictMessage(LABELS.ERRORS.SERVICE_TYPE_NOT_FOUND);
    } else {
      setConflictMessage(LABELS.ERRORS.SERVICE_REGION_NOT_FOUND);
    }
  } else {
    notify(err.message);
  }
}

function transform(data) {
  const dataValue = { ...data };
  const { pickupWorkingHours } = dataValue.lastMileCompanyInfo;

  if (pickupWorkingHours?.openAt && pickupWorkingHours?.closeAt) {
    pickupWorkingHours.openAt = formatTimeToObject(pickupWorkingHours.openAt);
    pickupWorkingHours.closeAt = formatTimeToObject(pickupWorkingHours.closeAt);
  }
  if (dataValue.copyCheckboxValue) {
    const ranges = mirrorDeliveryRangeToPickup(dataValue.postalCodes.ranges);
    dataValue.postalCodes = { ranges };
  }
  if (dataValue.lastMileCompanyInfo.dropOffAmount) {
    dataValue.lastMileCompanyInfo.dropOffAmount = convertDecimalToMoneyProto(
      dataValue.lastMileCompanyInfo.dropOffAmount
    );
  }
  return dataValue;
}

export { handleFailure, transform };
