import React from 'react';
import PropTypes from 'prop-types';
import { TextInput, Filter } from 'react-admin';
import NumberFormatInput from '../../atoms/number-format-input';
import { TITLES } from './constants';
import FilterSelectArrayInput from '../../atoms/filter-select-array-input';
import FilterAutocompleteArrayInput from '../../atoms/filter-autocomplete-array-input';
import {
  isLastMileCompanyRequest,
  isPricingRegionRequest,
  isServiceTypeRequest
} from '../../../api/api-utils';

const ResourceFilters = props => {
  const { resource, customFilters } = props;

  return (
    <Filter {...props} variant="outlined">
      <TextInput
        source="zipCode"
        alwaysOn
        resettable
        label={TITLES.CEP}
        data-testid="zipcode-filter"
        InputProps={{
          inputComponent: NumberFormatInput
        }}
      />
      <TextInput
        source="name"
        alwaysOn
        label={TITLES.NAME}
        resettable
        data-testid="name-filter"
      />
      {isPricingRegionRequest(resource) && (
        <TextInput
          source="loggiName"
          alwaysOn
          label={TITLES.LOGGI_NAME}
          resettable
          resource={resource}
        />
      )}
      {isPricingRegionRequest(resource) && (
        <TextInput
          source="redispatchName"
          alwaysOn
          label={TITLES.REDISPATCH_NAME}
          resettable
          resource={resource}
        />
      )}
      {isPricingRegionRequest(resource) && (
        <TextInput
          source="configurationId"
          alwaysOn
          label={TITLES.CONFIGURATION_ID}
          resettable
          resource={resource}
        />
      )}
      {isLastMileCompanyRequest(resource) && (
        <FilterSelectArrayInput
          source="rangeTypes"
          label={TITLES.RANGE_TYPE}
          choices={customFilters.rangeTypes}
        />
      )}
      {isServiceTypeRequest(resource) && (
        <FilterSelectArrayInput
          choices={customFilters.cityType}
          source="cityTypes"
          label={TITLES.CITY_TYPE}
          dataTestId="city-type-filter"
        />
      )}
      {isServiceTypeRequest(resource) && (
        <FilterAutocompleteArrayInput
          resourceName={customFilters.serviceRegion.resourceName}
          source="serviceRegion"
          label={TITLES.SERVICE_REGION}
          dataTestId="service-region-filter"
        />
      )}
    </Filter>
  );
};

ResourceFilters.defaultProps = {
  customFilters: {},
  record: {},
  resource: ''
};

ResourceFilters.propTypes = {
  customFilters: PropTypes.shape({
    rangeTypes: PropTypes.arrayOf(PropTypes.string),
    cityType: PropTypes.arrayOf(PropTypes.string),
    serviceRegion: PropTypes.objectOf(PropTypes.string)
  }),
  record: PropTypes.shape({
    name: PropTypes.string
  }),
  resource: PropTypes.string
};

export default ResourceFilters;
