import enums from '../../../enums';

export const TEXTS = {
  [enums.resources.SERVICE_REGION]: {
    NO_OPTIONS: 'Nenhuma XD de atendimento encontrada',
    LABEL: 'XD de atendimento'
  },
  [enums.resources.REGION]: {
    NO_OPTIONS: 'Nenhuma região encontrada',
    LABEL: 'Região'
  },
  [enums.resources.SERVICE_TYPE]: {
    NO_OPTIONS: 'Nenhum Tipo de Serviço encontrado',
    LABEL: 'Tipo de Serviço relacionado'
  }
};

export const ERRORS = {
  [enums.resources.SERVICE_REGION]: 'Erro ao tentar buscar Regiões de Serviço',
  [enums.resources.SERVICE_TYPE]: 'Erro ao tentar buscar Tipos de Serviço'
};

export const LABELS = {
  required: 'Obrigatório'
};
