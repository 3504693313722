import React from 'react';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { labels } from './constants';
import { useStyles } from './styles';

const TableLoading = () => {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.circularProgress}>
        <CircularProgress />
      </div>
      <div className={classes.circularProgress}>
        <Typography>{labels.LOADING_SLOS}</Typography>
      </div>
    </div>
  );
};

export default TableLoading;
