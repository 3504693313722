import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Close from '@material-ui/icons/Close';
import Divider from '@material-ui/core/Divider';
import CheckIcon from '@material-ui/icons/Check';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { useStyles } from './styles';
import LABELS from './constants';

const CustomDialog = props => {
  const {
    open,
    onClose,
    onConfirm,
    title,
    children,
    fullWidth,
    maxWidth
  } = props;
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      data-testid="custom-dialog-mui"
      fullWidth={fullWidth}
      maxWidth={maxWidth}
    >
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Typography component="h2" variant="h6">
          <Box fontWeight="fontWeightBold">{title}</Box>
        </Typography>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <Divider variant="middle" />
      <DialogActions>
        {onConfirm && (
          <Button
            onClick={onConfirm}
            startIcon={<CheckIcon />}
            size="small"
            data-testid="confirm-button"
          >
            {LABELS.CONFIRM}
          </Button>
        )}
        {onClose && (
          <Button
            onClick={onClose}
            startIcon={<Close />}
            size="small"
            data-testid="close-button"
          >
            {LABELS.CLOSE}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

CustomDialog.defaultProps = {
  open: false,
  onClose: null,
  onConfirm: null,
  fullWidth: false,
  maxWidth: 'sm'
};

CustomDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  title: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  fullWidth: PropTypes.bool,
  maxWidth: PropTypes.string
};

export default CustomDialog;
