import React from 'react';
import Alert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';
import {
  Create,
  FileInput,
  FileField,
  SimpleForm,
  useNotify,
  usePermissions,
  Toolbar,
  SaveButton,
  FormDataConsumer
} from 'react-admin';
import { useHistory } from 'react-router-dom';
import LABELS from './labels';
import enums from '../../../../enums';
import GoBackButton from '../../../atoms/go-back-button/go-back-button';
import permissionsFor from '../../../../access/access';
import useStyles from './styles';
import dataProvider from '../../../../api/postal-code-api';

export function CustomToolbar(props) {
  const classes = useStyles();

  const { fileInput, customProps } = props;

  return (
    <Toolbar className={classes.toolbar}>
      <SaveButton
        handleSubmitWithRedirect={() =>
          dataProvider.updateMany(customProps.resource, fileInput)
        }
      />
    </Toolbar>
  );
}

export default function MeiRulesBulkUpdate(props) {
  const { loaded, permissions } = usePermissions();
  const history = useHistory();
  const notify = useNotify();

  const authorized = permissionsFor(enums.resources.RULES)
    .hasRole(permissions)
    .forAction(enums.actions.UPDATE);

  if (loaded && !authorized) {
    history.push(`/${enums.resources.RULES}`);
  }

  const onSuccess = () => {
    notify(LABELS.BULK_UPDATE_LABELS.SUCCESS);
  };

  const properties = {
    basePath: `/${enums.customResources.BULK_UPDATE_MEI_RULES}`,
    resource: enums.resources.RULES,
    title: LABELS.BULK_UPDATE_LABELS.PAGE_TITLE.TITLE
  };

  const customProps = { ...props, ...properties };

  return (
    <Create undoable={false} onSuccess={onSuccess} {...customProps}>
      <SimpleForm
        variant="outlined"
        data-testid="update-bulk-form"
        toolbar={
          <FormDataConsumer>
            {({ formData }) => {
              return (
                <CustomToolbar fileInput={formData} customProps={customProps} />
              );
            }}
          </FormDataConsumer>
        }
      >
        <GoBackButton data-testid="go-back-button" />
        <Alert severity="info" fullWidth>
          {LABELS.BULK_UPDATE_LABELS.UPLOAD.ADVICE}
          <FileInput
            source="updateMeiRules.attachment"
            label={LABELS.BULK_UPDATE_LABELS.UPLOAD.TITLE}
            accept=".csv"
          >
            <FileField source="updateMeiRules.src" title="title" />
          </FileInput>
        </Alert>
      </SimpleForm>
    </Create>
  );
}

CustomToolbar.defaultProps = {
  fileInput: PropTypes.string,
  customProps: PropTypes.string
};

CustomToolbar.propTypes = {
  fileInput: PropTypes.string,
  customProps: PropTypes.string
};
