import React from 'react';

import { Datagrid, TextField, List, ShowButton } from 'react-admin';

import LABELS from './labels';
import ExportButton from '../../../atoms/export-button/export-button';
import LabeledEnumTextField from '../../../atoms/labeled-enum-text-field';
import ResourceFilters from '../../../molecules/resource-filters';

export default function PricingRegionList(props) {
  return (
    <List
      filters={<ResourceFilters />}
      sort={{ field: 'pricingGroupInfo.regionName', order: 'ASC' }}
      exporter={false}
      bulkActionButtons={false}
      title={LABELS.TITLES.LIST_TITLE}
      {...props}
    >
      <Datagrid>
        <TextField
          source="pricingGroupInfo.regionName"
          label={LABELS.TITLES.NAME}
        />
        <TextField
          source="pricingGroupInfo.loggiName"
          label={LABELS.TITLES.LOGGI_NAME}
        />
        <TextField
          source="pricingGroupInfo.redispatchName"
          label={LABELS.TITLES.REDISPATCH_NAME}
        />
        <TextField
          source="pricingGroupInfo.configurationId"
          label={LABELS.TITLES.CONFIGURATION_ID}
        />
        <LabeledEnumTextField
          source="pricingGroupInfo.pricingComponent"
          label={LABELS.TITLES.TYPE}
          enumLabels={LABELS.TYPES}
        />
        <ShowButton />
        <ExportButton />
      </Datagrid>
    </List>
  );
}
