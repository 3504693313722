const validateTransitTimeCreate = sloMapping => {
  const errors = {};

  if (!sloMapping.sloMapping?.originServiceRegionGroupId) {
    errors.sloMapping = {
      originServiceRegionGroupId: 'ra.validation.required'
    };
  }

  if (!sloMapping.sloMapping?.destinationServiceRegionGroupId) {
    errors.sloMapping = {
      destinationServiceRegionGroupId: 'ra.validation.required'
    };
  }

  return errors;
};

export default validateTransitTimeCreate;
