import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Edit,
  SimpleForm,
  useNotify,
  FormDataConsumer,
  useShowController
} from 'react-admin';
import SaveWithConfirmToolbar from '../../../molecules/save-with-confirm-toolbar';
import LABELS from './labels';
import enums from '../../../../enums';
import { getPostalCodeGroupInfo } from '../../../molecules/postal-codes-table/utils';
import validateServiceType from './validations';
import { handleConflictRangeOverlap } from '../../../../utils/handle-conflicts';
import PageTitle from '../../../molecules/page-title';
import permissionsFor from '../../../../access/access';
import { isNearbyCity } from '../../../../utils/utils';
import ServiceTypeForm from '../../../templates/service-type/service-type-form';
import {
  isVersioned,
  shouldShowDeleteButton
} from '../../../../utils/versioning';
import TopBar from '../../../organisms/top-bar/top-bar';

const serviceTypeTypes = Object.entries(LABELS.TYPES).map(item => ({
  id: item[0],
  name: item[1]
}));

export default function ServiceTypeEdit(props) {
  const { permissions } = props;
  const { record } = useShowController(props);
  const canUpdate = permissionsFor(enums.resources.SERVICE_TYPE)
    .hasRole(permissions)
    .forAction(enums.actions.UPDATE);

  const notify = useNotify();

  const [errorRanges, setErrorRanges] = useState([]);

  const pageTitle = `${LABELS.PAGE_TITLE.TITLE}: ${record?.serviceTypeInfo
    ?.name || ''}`;

  const handleFailure = error =>
    handleConflictRangeOverlap(error, setErrorRanges, notify);

  const handleCloseDialog = () => {
    setErrorRanges([]);
  };

  const transform = data => {
    const serviceTypeGroup = { ...data };

    if (
      !isNearbyCity(serviceTypeGroup.serviceTypeInfo.cityType) &&
      serviceTypeGroup.serviceTypeInfo.baseCityServiceTypeId
    ) {
      delete serviceTypeGroup.serviceTypeInfo.baseCityServiceTypeId;
    }

    if (isNearbyCity(serviceTypeGroup.serviceTypeInfo.cityType)) {
      serviceTypeGroup.serviceTypeInfo.slo =
        serviceTypeGroup.serviceTypeInfo.multiOriginSlo;
    }

    return serviceTypeGroup;
  };

  return (
    <Edit
      mutationMode="undoable"
      {...props}
      title={pageTitle}
      onFailure={handleFailure}
      transform={transform}
    >
      <SimpleForm
        warnWhenUnsavedChanges
        variant="outlined"
        validate={validateServiceType}
        toolbar={
          <FormDataConsumer>
            {({ handleSubmit, formData, ...rest }) => {
              const postalCodeGroupInfo = getPostalCodeGroupInfo(formData);
              return (
                <SaveWithConfirmToolbar
                  {...rest}
                  handleSubmit={handleSubmit}
                  hasWritePermissions={canUpdate}
                  postalCodeGroupInfo={postalCodeGroupInfo}
                  changes={formData.changes}
                  isVersioned={isVersioned(record?.versioningStatus)}
                  showDeleteButton={shouldShowDeleteButton(
                    record?.versioningStatus
                  )}
                />
              );
            }}
          </FormDataConsumer>
        }
      >
        {record && <TopBar id={record.id} referenceId={record.referenceId} />}

        <PageTitle
          title={LABELS.PAGE_TITLE.TITLE}
          updatedAtTimeAsInt={record?.updatedAtTimeAsInt}
          versioningStatusType={record?.versioningStatus}
          effectiveOnTimestamp={record?.effectiveOnTimeAsInt}
          expiredAtTimestamp={record?.expiredAtTimeAsInt}
          data-testid="service-type-page-title"
        />

        <FormDataConsumer>
          {({ formData }) => {
            return (
              <ServiceTypeForm
                hasPermissions={canUpdate}
                formData={formData}
                errorRanges={errorRanges}
                handleCloseDialog={handleCloseDialog}
                serviceTypeTypes={serviceTypeTypes}
                pageType={enums.pageType.EDIT}
                {...props}
              />
            );
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
}

ServiceTypeEdit.propTypes = {
  id: PropTypes.string.isRequired,
  permissions: PropTypes.arrayOf(PropTypes.string).isRequired
};
