import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import Typography from '@material-ui/core/Typography';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { useStyles } from '../common-styles';
import { HEADERS, TEXTS } from './constants';
import ConflictTableRow from './conflict-table-row';
import CustomDialog from '../custom-dialog';

const DialogConflictRanges = props => {
  const { conflicts, postalCodeGroupName, open, onClose } = props;
  const classes = useStyles();

  return (
    <CustomDialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={onClose}
      title={TEXTS.TITLE_ERROR}
    >
      <Typography
        variant="subtitle2"
        color="textSecondary"
        data-testid="descriptionField"
      >
        {TEXTS.DIALOG_DESCRIPTION}
      </Typography>
      <br />
      <Box my={2}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow data-testid="changes-table-title">
                {HEADERS.map(header => (
                  <TableCell className={classes.tableHead}>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            {conflicts.map(conflict => (
              <TableBody
                data-testid="conflict-table-body"
                className={classes.conflictTableBody}
              >
                <>
                  <ConflictTableRow
                    postalCodeGroupName={postalCodeGroupName}
                    rangeName={conflict.range.name}
                    start={conflict.range.start.code}
                    end={conflict.range.end.code}
                  />
                  {conflict.postalCodeGroups.map(postalCodeGroup => (
                    <ConflictTableRow
                      postalCodeGroupName={
                        postalCodeGroup.serviceTypeInfo?.name ||
                        postalCodeGroup.serviceRegionInfo?.name
                      }
                      rangeName="--"
                      start="--"
                      end="--"
                      oid={postalCodeGroup._id.$oid}
                      isConflict
                    />
                  ))}
                </>
              </TableBody>
            ))}
          </Table>
        </TableContainer>
      </Box>
    </CustomDialog>
  );
};

DialogConflictRanges.defaultProps = {
  open: false,
  onClose: null
};

DialogConflictRanges.propTypes = {
  conflicts: PropTypes.arrayOf(
    PropTypes.shape({
      range: PropTypes.shape({
        name: PropTypes.string,
        start: PropTypes.shape({
          code: PropTypes.string
        }),
        end: PropTypes.shape({
          code: PropTypes.string
        })
      }),
      postalCodeGroups: PropTypes.arrayOf(
        PropTypes.shape({
          serviceTypeInfo: PropTypes.shape({
            serviceType: PropTypes.string,
            slo: PropTypes.string,
            name: PropTypes.string,
            id: PropTypes.string,
            confirmationDeadlineOffset: PropTypes.number
          }),
          _id: PropTypes.shape({
            $oid: PropTypes.string
          })
        })
      )
    })
  ).isRequired,
  postalCodeGroupName: PropTypes.string.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func
};

export default DialogConflictRanges;
