import React, { useState } from 'react';
import { useFormState } from 'react-final-form';
import { SelectInput } from 'react-admin';
import PropTypes from 'prop-types';
import LABELS from '../../pages/_react-admin/service-type/labels';
import validateServiceTypeInputs from '../../validators';
import PostalCodeGroupAutocomplete from '../postal-code-group-autocomplete';
import enums from '../../../enums';
import { isNearbyCity } from '../../../utils/utils';

const cityTypes = Object.entries(LABELS.CITY_TYPES)
  .filter(item => item[0] !== 'CITY_TYPE_INVALID')
  .map(item => ({
    id: item[0],
    name: item[1]
  }));

const CityTypeInput = ({
  label,
  source,
  validate,
  fullWidth,
  hasWritePermissions,
  ...props
}) => {
  const { initialValues } = useFormState();
  const cityType = initialValues?.serviceTypeInfo?.cityType;
  const [showCityType, setShowCityType] = useState(isNearbyCity(cityType));

  const changeHandler = ev => {
    setShowCityType(isNearbyCity(ev.target.value));
  };

  return (
    <>
      <SelectInput
        label={label}
        fullWidth={fullWidth}
        source={source}
        validate={validate}
        choices={cityTypes}
        onChange={changeHandler}
        data-testid="city-type-input"
        disabled={!hasWritePermissions}
        {...props}
      />
      {showCityType && (
        <PostalCodeGroupAutocomplete
          label={LABELS.TITLES.BASE_CITY}
          resourceName={enums.resources.SERVICE_TYPE}
          hasWritePermissions={hasWritePermissions}
          postalCodeGroupIdFormPath={LABELS.FORM_DATA.BASE_CITY_ID_PATH}
          postalCodeGroupNameFormPath={LABELS.FORM_DATA.BASE_CITY_NAME_PATH}
          cityTypes={[enums.cityTypes.CITY_TYPE_LOCAL_LMC]}
          required
          dataTestId="service-type-autocomplete"
          {...props}
        />
      )}
    </>
  );
};

CityTypeInput.defaultProps = {
  label: LABELS.TITLES.CITY_TYPE,
  fullWidth: true,
  validate: validateServiceTypeInputs.cityType,
  hasWritePermissions: true,
  source: LABELS.FORM_DATA.SERVICE_TYPE_INFO_CITY_TYPE
};

CityTypeInput.propTypes = {
  label: PropTypes.string,
  source: PropTypes.string,
  fullWidth: PropTypes.bool,
  validate: PropTypes.arrayOf(PropTypes.func),
  hasWritePermissions: PropTypes.bool
};

export default CityTypeInput;
