import enums from '../enums';

// React-Admin needs an `id` property for every record, so it can keep track of changes.
// This function moves the value stored at `_id.$oid` from the API response to a property `id`
export const normalize = element => {
  const { sloMapping, ...rest } = element;
  const { Id, ...sloMappingRest } = sloMapping;
  return { id: Id.$oid, sloMapping: { ...sloMappingRest }, ...rest };
};

export const SLO_MAPPING_TYPES_MAP = {
  [enums.resources.TRANSIT_TIME]:
    enums.sloMappingType.SLO_MAPPING_TYPE_SERVICE_REGION,
  [enums.resources.SLO_EXCEPTION]:
    enums.sloMappingType.SLO_MAPPING_TYPE_SERVICE_TYPE
};
