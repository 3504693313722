import enums from '../../../../../enums';

const validations = data => {
  const errors = {};

  if (!data?.coverageType) {
    errors.coverageType = 'ra.validation.required';
  } else if (data?.coverageType === enums.rangeType.RANGE_TYPE_DELIVERY) {
    if (!data?.serviceRegionInfo?.parentServiceRegionId) {
      errors.serviceRegionInfo = {
        parentServiceRegionId: 'ra.validation.required'
      };
    }
  }

  return errors;
};

export default validations;
