import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import {
  Create,
  TextInput,
  SimpleForm,
  SelectInput,
  required,
  minLength,
  FormDataConsumer,
  useNotify,
  NumberInput
} from 'react-admin';
import PostalCodesTable from '../../../molecules/postal-codes-table';
import LABELS from './labels';
import enums from '../../../../enums';
import DialogConflictRanges from '../../../molecules/dialog-conflict-ranges';
import { getPostalCodeGroupInfo } from '../../../molecules/postal-codes-table/utils';
import PostalCodeGroupAutocomplete from '../../../molecules/postal-code-group-autocomplete';
import { isTypeCrossdocking, isTypeDropOff } from './utils';
import HoursMinutesInput from '../../../atoms/hours-minutes-input';
import { handleConflictRangeOverlap } from '../../../../utils/handle-conflicts';
import SimpleToolbar from '../../../atoms/simple-toolbar';
import validateServiceRegion from './validations';
import CustomDivider from '../../../atoms/custom-divider/custom-divider';
import permissionsFor from '../../../../access/access';
import ActivationToggle from '../../../molecules/activation-toggle';

const choices = Object.entries(LABELS.TYPES)
  .filter(item => item[0] !== 'SERVICE_TYPE_UNKNOWN')
  .map(item => ({
    id: item[0],
    name: item[1]
  }));

const transform = dataToBeSent => {
  // Removing parentServiceRegionName from serviceRegionInfo
  const { serviceRegionInfo } = dataToBeSent;
  const {
    parentServiceRegionName,
    ...restWithoutParentName
  } = serviceRegionInfo;

  return {
    ...dataToBeSent,
    serviceRegionInfo: restWithoutParentName
  };
};

export default function ServiceRegionCreate(props) {
  const notify = useNotify();
  const { permissions } = props;
  const [errorRanges, setErrorRanges] = React.useState([]);

  const canCreate = permissionsFor(enums.resources.SERVICE_REGION)
    .hasRole(permissions)
    .forAction(enums.actions.CREATE);

  const handleCloseDialog = () => {
    setErrorRanges([]);
  };

  const handleFailure = error =>
    handleConflictRangeOverlap(error, setErrorRanges, notify);

  return (
    <Create
      redirect="list"
      undoable={false}
      {...props}
      transform={transform}
      onFailure={handleFailure}
    >
      <SimpleForm
        warnWhenUnsavedChanges
        variant="outlined"
        redirect="list"
        toolbar={<SimpleToolbar />}
        validate={validateServiceRegion}
      >
        <TextInput
          label={LABELS.TITLES.NAME}
          source="serviceRegionInfo.name"
          data-testid="service-type-name"
          validate={[required(), minLength(1)]}
          fullWidth
        />
        <SelectInput
          label={LABELS.TITLES.TYPE}
          source="serviceRegionInfo.serviceRegionType"
          choices={choices}
          data-testid="service-region-type"
          validate={[required()]}
          fullWidth
        />
        <FormDataConsumer>
          {({ formData }) => (
            <PostalCodeGroupAutocomplete
              hasWritePermissions={canCreate}
              postalCodeGroupIdFormPath={
                LABELS.FORM_DATA.SERVICE_REGION_ID_PATH
              }
              postalCodeGroupNameFormPath={
                LABELS.FORM_DATA.SERVICE_REGION_NAME_PATH
              }
              resourceName={enums.resources.SERVICE_REGION}
              required={!isTypeCrossdocking(formData.serviceRegionInfo)}
            />
          )}
        </FormDataConsumer>
        <CustomDivider />
        <Grid container direction="row" fullWidth>
          <Grid item xs={6} sm={6}>
            <ActivationToggle
              hasWritePermissions={canCreate}
              source="serviceRegionInfo.active"
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <HoursMinutesInput
              formPath={LABELS.FORM_DATA.SERVICE_REGION_INITIAL_SLOT_PATH}
              disabled={!canCreate}
              title={LABELS.TITLES.INITIAL_SLOT}
            />
            <HoursMinutesInput
              formPath={LABELS.FORM_DATA.SERVICE_REGION_MIN_COLLECT_WINDOW_PATH}
              disabled={!canCreate}
              title={LABELS.TITLES.MIN_COLLECT_TIME_WINDOW}
            />
          </Grid>
        </Grid>
        <CustomDivider />
        <FormDataConsumer>
          {({ formData }) => {
            return (
              isTypeDropOff(formData.serviceRegionInfo) && (
                <>
                  <NumberInput
                    data-testid="drop-off-slo"
                    label={LABELS.TITLES.DROP_OFF_SLO_FIELD}
                    source="serviceRegionInfo.dropOffSlo"
                    disabled={!canCreate}
                    validate={[required()]}
                  />
                  <CustomDivider />
                </>
              )
            );
          }}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData }) => {
            const postalCodeGroupInfo = getPostalCodeGroupInfo(formData);

            return (
              <>
                <PostalCodesTable
                  postalCodeGroupInfo={postalCodeGroupInfo}
                  ranges={formData?.postalCodes?.ranges}
                  hasWritePermissions={canCreate}
                  typeOfRanges={enums.rangeType.RANGE_TYPE_DEFAULT}
                />
                <DialogConflictRanges
                  conflicts={errorRanges}
                  postalCodeGroupName={postalCodeGroupInfo.name}
                  onClose={handleCloseDialog}
                  open={!!errorRanges?.length}
                />
              </>
            );
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
}

ServiceRegionCreate.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string).isRequired
};
