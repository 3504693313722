import React from 'react';
import PropTypes from 'prop-types';

import { useResourceContext } from 'react-admin';
import Box from '@material-ui/core/Box';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableRow from '@material-ui/core/TableRow';
import Link from '@material-ui/core/Link';

import { useStyles } from '../common-styles';
import { TEXTS } from './constants';

const ConflictTableRow = props => {
  const { postalCodeGroupName, rangeName, start, end, oid, isConflict } = props;

  const resource = useResourceContext();

  const conflictTypeMessages = TEXTS[resource];

  const classes = useStyles();

  return (
    <TableRow key={`${rangeName}-${start}-${end}`}>
      <TableCell component="th" scope="row">
        {isConflict ? (
          <Typography className={classes.actionMessageConflict}>
            {TEXTS.COVERAGE_REGISTERED}
            <br />
            {conflictTypeMessages.IN_SERVICE}
            <Link
              href={`${conflictTypeMessages.PATH}/${oid}`}
              target="_blank"
              rel="noopener"
              underline="always"
              className={classes.linkConflict}
            >
              {postalCodeGroupName}
            </Link>
          </Typography>
        ) : (
          <Typography className={classes.actionMessageAdd}>
            {TEXTS.COVERAGE_ADDED}
            <br />
            {conflictTypeMessages.SERVICE_NAME}
            <Box
              fontWeight="fontWeightBold"
              className={classes.actionMessageAdd}
              component="string"
            >
              {postalCodeGroupName}
            </Box>
          </Typography>
        )}
      </TableCell>
      <TableCell component="th" scope="row">
        <Typography>{rangeName}</Typography>
      </TableCell>
      <TableCell>
        <Typography>
          <Box fontWeight="fontWeightBold">{start}</Box>
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          <Box fontWeight="fontWeightBold">{end}</Box>
        </Typography>
      </TableCell>
    </TableRow>
  );
};

ConflictTableRow.defaultProps = {
  oid: '',
  isConflict: false
};

ConflictTableRow.propTypes = {
  postalCodeGroupName: PropTypes.string.isRequired,
  rangeName: PropTypes.string.isRequired,
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
  oid: PropTypes.string,
  isConflict: PropTypes.bool
};

export default ConflictTableRow;
