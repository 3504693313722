const handleSLOChange = event => {
  const newSLO = event?.target?.value;

  if (newSLO) {
    const sloChangeEvent = new CustomEvent('sloDidChange', {
      detail: { slo: newSLO }
    });
    document.dispatchEvent(sloChangeEvent);
  }
};

export default handleSLOChange;
