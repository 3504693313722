import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  toolbar: {
    justifyContent: 'flex-end'
  },
  button: {
    height: '40px',
    minWidth: '115px',
    width: 'auto',
    background: 'linear-gradient(286.66deg, #007CFF 0.97%, #00BAFF 100%)',
    borderRadius: '100px',
    color: 'white'
  }
});

export default useStyles;
