import enums from '../../../../enums';

export const MODAL_TYPES = [
  { id: enums.modalType.MODAL_TYPE_CAR, name: 'Carro' },
  { id: enums.modalType.MODAL_TYPE_MOTO, name: 'Moto' },
  { id: enums.modalType.MODAL_TYPE_VAN, name: 'Van' },
  { id: enums.modalType.MODAL_TYPE_NOT_MOTORIZED, name: 'Não Motorizado' }
];

export const PRODUCTS = [
  { id: enums.product.PRODUCT_CORP, name: 'Corp' },
  { id: enums.product.PRODUCT_PRESTO, name: 'Presto' },
  { id: enums.product.PRODUCT_PRO, name: 'Pro' }
];

export const STATUS_TYPES = [
  { id: enums.statusType.STATUS_TYPE_CURRENT, name: 'Ativa' },
  { id: enums.statusType.STATUS_TYPE_FUTURE, name: 'Futura' }
];

export const ERROR_MESSAGES = {
  INVALID_NUMBER: 'O valor inserido tem de ser um número.',
  MINIMUM_NUMBER: 'Insira um valor maior ou igual a 0.',
  PAST_DATE: 'Insira uma data no futuro.'
};
