import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Grid, Typography } from '@material-ui/core';

import VersioningStatus from '../../atoms/versioning-status/versioning-status';
import VersionTimeRange from '../../atoms/version-time-range/version-time-range';
import LABELS from './labels';

const VersioningSection = props => {
  const {
    versioningStatusType,
    effectiveOnTimestamp,
    expiredAtTimestamp,
    ...rest
  } = props;

  return (
    <Grid container>
      <Grid item>
        <VersioningStatus
          versioningStatusType={versioningStatusType}
          {...rest}
        />
      </Grid>
      <Divider orientation="vertical" variant="middle" flexItem />
      <Grid item>
        <Box mr={0.5}>
          <Typography color="textSecondary" {...rest}>
            {LABELS.VALIDITY}
          </Typography>
        </Box>
      </Grid>
      <Grid item>
        <VersionTimeRange
          versioningStatusType={versioningStatusType}
          effectiveOnTimestamp={effectiveOnTimestamp}
          expiredAtTimestamp={expiredAtTimestamp}
          {...rest}
        />
      </Grid>
    </Grid>
  );
};

VersioningSection.defaultProps = {
  expiredAtTimestamp: 0
};

VersioningSection.propTypes = {
  versioningStatusType: PropTypes.string.isRequired,
  effectiveOnTimestamp: PropTypes.number.isRequired,
  expiredAtTimestamp: PropTypes.number
};

export default VersioningSection;
