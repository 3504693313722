export const LABELS = {
  PAGE_TITLE: {
    TITLE: 'Cadastrar Base'
  },
  LMC_TITLES: {
    DESCRIPTION: 'Identifique a transportadora (LMC) que irá operar a base',
    NAME: 'Nome da transportadora (LMC)',
    NAME_HELPER: 'Ex: Leve - SP São Paulo 1 - Brooklin',
    CNPJ: 'CNPJ',
    ACTIVATION_TIME: 'Data de ativação',
    ACTIVATION_TIME_DESCRIPTION:
      'Informe a data de ativação da transportadora (LMC)',
    WORKING_WEEKDAYS: 'Informe os dias da semana para atendimento de coleta',
    PICKUP_TITLE: 'Informe o horário para atendimento de coleta',
    CORPORATE_NAME: 'Razão Social'
  },
  ADDRESS_TITLES: {
    POSTAL_CODE: 'CEP',
    STREET: 'Rua',
    NUMBER: 'Número',
    COMPLEMENT: 'Complemento',
    NEIGHBORHOOD: 'Bairro',
    CITY: 'Cidade',
    STATE: 'UF'
  },
  DC_TITLES: {
    DESCRIPTION: 'Informe os dados cadastrais da base (DC)',
    NAME: 'Nome da base',
    ROUTING_CODE: 'Sigla',
    CAPACITY: 'Capacidade',
    RECEIPT_DATA: 'Informe quando a base terá pessoas para receber pacotes',
    OPEN_AT: 'Hora inicial',
    CLOSE_AT: 'Hora final',
    WEEKDAYS: 'Dias da semana para recebimento de pacotes',
    WEEKDAYS_FIELD: 'Dias da semana'
  },
  ST_TITLES: {
    DESCRIPTION: 'Identifique onde a transportadora (LMC) irá atender'
  },
  SUCCESS: 'Base cadastrada com sucesso'
};

export const defaultWeekDays = [1, 2, 3, 4, 5];
