import React from 'react';
import PropTypes from 'prop-types';
import { EditButton } from 'react-admin';
import LABELS from './labels';
import enums from '../../../enums';
import permissionsFor from '../../../access/access';

const shouldRenderEditButton = (permissions, record) => {
  const canUserUpdate = permissionsFor(enums.resources.RULES)
    .hasRole(permissions)
    .forAction(enums.actions.UPDATE);

  const canRuleBeUpdated = record && record.canBeUpdated;

  return canUserUpdate && canRuleBeUpdated;
};

export default function MeiRulesEditConditionalBtn(props) {
  const { permissions, record } = props;

  return shouldRenderEditButton(permissions, record) ? (
    <EditButton
      {...props}
      icon={null}
      label={LABELS.EDIT}
      data-testid="mei-rules-edit-button"
    />
  ) : null;
}

MeiRulesEditConditionalBtn.propTypes = {
  record: PropTypes.shape({
    canBeUpdated: PropTypes.bool
  }),
  permissions: PropTypes.arrayOf(PropTypes.string)
};

MeiRulesEditConditionalBtn.defaultProps = {
  record: {
    canBeUpdated: false
  },
  permissions: []
};
