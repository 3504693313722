import React from 'react';
import Chip from '@material-ui/core/Chip';
import InfoIcon from '@material-ui/icons/Info';
import { labels } from './constants';
import { useStyles } from './styles';

const ExceptionChip = () => {
  const classes = useStyles();

  return (
    <Chip
      className={classes.exceptionChip}
      label={labels.EXCEPTION}
      icon={<InfoIcon />}
    />
  );
};

export default ExceptionChip;
