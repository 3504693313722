export const filterServiceRegionXD = data => {
  return data.filter(
    ({ serviceRegionInfo }) =>
      serviceRegionInfo.serviceRegionType ===
      'SERVICE_REGION_TYPE_CROSS_DOCKING'
  );
};

export const transform = data => {
  return data.map(({ id, name }) => ({ id, name }));
};

export const isInvalidEventType = event => {
  if (!event) return undefined;

  return event.type !== '__autocomplete_change_input__';
};
