import React from 'react';

import PropTypes from 'prop-types';
import {
  Edit,
  TextInput,
  DateTimeInput,
  NumberInput,
  SimpleForm
} from 'react-admin';
import GenericToolbar from '../../../atoms/generic-toolbar';
import { validations } from './validations';
import GoBackButton from '../../../atoms/go-back-button/go-back-button';
import PageTitle from '../../../molecules/page-title';
import LABELS from './labels';

export default function MeiRulesEdit(props) {
  return (
    <Edit redirect="list" title={LABELS.TITLES.EDIT_TITLE} {...props}>
      <SimpleForm variant="outlined" toolbar={<GenericToolbar />}>
        <GoBackButton data-testid="go-back-button" />
        <PageTitle title={LABELS.PAGE_TITLE.TITLE} data-testid="page-title" />
        <TextInput
          source="rule.startDriverStrategy.city"
          label={LABELS.TITLES.CITY}
          disabled
          fullWidth
        />
        <TextInput
          source="rule.startDriverStrategy.modal"
          label={LABELS.TITLES.MODAL}
          disabled
          fullWidth
        />
        <TextInput
          source="rule.startDriverStrategy.product"
          label={LABELS.TITLES.PRODUCT}
          disabled
          fullWidth
        />
        <TextInput
          source="rule.startDriverStrategy.tier"
          label={LABELS.TITLES.TIER}
          disabled
          fullWidth
        />
        <NumberInput
          source="startDriverStrategy.costPerKm"
          validate={validations.costPerKm}
          label={LABELS.TITLES.COST_PER_KM}
          fullWidth
        />
        <NumberInput
          source="startDriverStrategy.costPerPoint"
          validate={validations.costPerPoint}
          label={LABELS.TITLES.COST_PER_POINT}
          fullWidth
        />
        <NumberInput
          source="startDriverStrategy.deductibleCost"
          validate={validations.deductibleCost}
          label={LABELS.TITLES.DEDUCTIBLE_COST}
          fullWidth
        />
        <DateTimeInput
          source="startTime"
          validate={validations.startTime}
          label={LABELS.TITLES.START_TIME}
          fullWidth
        />
      </SimpleForm>
    </Edit>
  );
}

MeiRulesEdit.propTypes = {
  id: PropTypes.string.isRequired
};
