import React from 'react';
import PropTypes from 'prop-types';
import {
  Edit,
  TextInput,
  SimpleForm,
  SelectInput,
  FormDataConsumer,
  useNotify,
  useShowController
} from 'react-admin';
import PostalCodesTable from '../../../molecules/postal-codes-table';
import LABELS from './labels';
import enums from '../../../../enums';
import { REGION_CHOICES } from './constants';
import DialogConflictRanges from '../../../molecules/dialog-conflict-ranges';
import { validations } from './validations';
import { handleConflictRangeOverlap } from '../../../../utils/handle-conflicts';
import SimpleToolbar from '../../../atoms/simple-toolbar';
import SectionTitle from '../../../atoms/section-title/section-title';
import permissionsFor from '../../../../access/access';
import LatLng from '../../../molecules/lat-lng-input';

const transform = data => {
  const region = {
    ...data,
    _id: { $oid: data.id },
    ranges: data.postalCodes.ranges.map(range => {
      return {
        start: range.start.code,
        end: range.end.code
      };
    })
  };

  delete region.postalCodes;
  delete region.changes;
  delete region.id;

  return region;
};

export default function RegionEdit(props) {
  const { permissions } = props;
  const { record } = useShowController(props);
  const [errorRanges, setErrorRanges] = React.useState([]);
  const canUpdate = permissionsFor(enums.resources.REGION)
    .hasRole(permissions)
    .forAction(enums.actions.UPDATE);
  const notify = useNotify();

  const handleFailure = error =>
    handleConflictRangeOverlap(error, setErrorRanges, notify);
  const handleCloseDialog = () => setErrorRanges([]);

  const headerTitle = `${LABELS.PAGE_TITLE.TITLE} ${record?.name || ''}`;

  return (
    <Edit
      undoable={false}
      {...props}
      title={headerTitle}
      transform={transform}
      onFailure={handleFailure}
    >
      <SimpleForm
        warnWhenUnsavedChanges
        variant="outlined"
        redirect="list"
        toolbar={<SimpleToolbar disabled={!canUpdate} />}
      >
        <TextInput
          label={LABELS.TITLES.NAME}
          source="name"
          data-testid="region-name"
          disabled={!canUpdate}
          validate={validations.name}
          fullWidth
        />
        <TextInput
          label={LABELS.TITLES.DESCRIPTION}
          source="description"
          data-testid="region-description"
          disabled={!canUpdate}
          validate={validations.description}
          fullWidth
        />
        <SelectInput
          label={LABELS.TITLES.TYPE}
          source="type"
          data-testid="region-type"
          choices={REGION_CHOICES}
          validate={validations.type}
          disabled
          fullWidth
        />
        <LatLng
          latSource="referencePoint.latitude"
          lngSource="referencePoint.longitude"
          disabled={!canUpdate}
        />
        <SectionTitle title={LABELS.TITLES.POSTAL_CODES_RANGES} />
        <FormDataConsumer>
          {({ formData }) => {
            return (
              <>
                <PostalCodesTable
                  ranges={formData?.postalCodes?.ranges}
                  changes={formData?.changes}
                  hasWritePermissions={canUpdate}
                  typeOfRanges={enums.rangeType.RANGE_TYPE_DEFAULT}
                  showName={false}
                />
                <DialogConflictRanges
                  conflicts={errorRanges}
                  postalCodeGroupName={formData?.name}
                  onClose={handleCloseDialog}
                  open={!!errorRanges?.length}
                />
              </>
            );
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
}

RegionEdit.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string).isRequired
};
