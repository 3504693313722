const STATUS_CODE = {
  NOT_FOUND: 404,
  CONFLICT: 409,
  BAD_REQUEST: 400,
  NOT_MODIFIED: 304,
  OK: 200,
  CREATED: 201,
  NO_CONTENT: 204
};

export default STATUS_CODE;
