import queryString from 'query-string';
import { fetchOrThrow } from '../api';
import coverageMappingApiUtils from './coverage-mapping-api-utils';

const ENDPOINT_URL = `${
  process.env.REACT_APP_ANNOTATOR_URL
}/api/v1/coverage-mapping`;

const getData = async args => {
  const { origin, serviceTypes, coverageType, targetDateInMs } = args.data;

  const params = {
    'range-start': '00000000',
    'range-end': '99999999'
  };

  if (origin) {
    params['origin-id'] = origin.id;
  }

  if (serviceTypes?.length) {
    params['service-types'] = serviceTypes;
  }

  if (coverageType) {
    params['coverage-type'] = coverageType;
  }

  if (targetDateInMs) {
    params['target-date-in-ms'] = targetDateInMs;
  }

  const filters = queryString.stringify(params, { sort: false });
  const response = await fetchOrThrow(`${ENDPOINT_URL}?${filters}`);
  const { coverageMappings } = response;

  if (!coverageMappings.length) return {};

  const csvData = coverageMappings.map(
    coverageMappingApiUtils.buildDeliveryData
  );

  return { csvData, data: { coverageMappings } };
};

export default { getData };
