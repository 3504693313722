export const convertMoneyProtoToDecimal = proto => {
  if (!proto) {
    return null;
  }

  const unit = proto.units == null ? 0 : proto.units;
  const nanos = proto.nanos == null ? 0 : proto.nanos;

  const decimal = `${nanos.toString().padStart(9, '0')}`;

  return parseFloat(`${unit.toString()}.${decimal}`);
};

const numberToNano = value => {
  return (value % 1).toFixed(2) * 10 ** 9;
};

export const convertDecimalToMoneyProto = value => {
  if (!value) {
    return null;
  }

  const units = Math.trunc(value);
  // Number of nano (10^-9) units of the amount.
  const nanos = numberToNano(value);
  const currencyCode = 'BRL';

  return {
    units,
    nanos,
    currencyCode
  };
};

export default {
  convertMoneyProtoToDecimal,
  convertDecimalToMoneyProto
};
