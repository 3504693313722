import React from 'react';
import PropTypes from 'prop-types';
import EditIcon from '@material-ui/icons/Edit';
import { Button as MuiButton } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import LABELS from './labels';
import enums from '../../../enums';
import permissionsFor from '../../../access/access';

export default function BulkUpdateBtn(props) {
  const { permissions } = props;

  const canUpdate = permissionsFor(enums.resources.RULES)
    .hasRole(permissions)
    .forAction(enums.actions.UPDATE);

  const history = useHistory();

  const routeChange = () => {
    history.push(enums.customResources.BULK_UPDATE_MEI_RULES);
  };

  return canUpdate ? (
    <MuiButton
      variant="outlined"
      startIcon={<EditIcon />}
      color="primary"
      style={{ fontSize: '14px' }}
      onClick={routeChange}
      data-testid="bulk-update-button"
    >
      {LABELS.BULK_UPDATE}
    </MuiButton>
  ) : null;
}

BulkUpdateBtn.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string)
};

BulkUpdateBtn.defaultProps = {
  permissions: []
};
