const LABELS = {
  PAGE_TITLE: {
    TITLE: 'Editar Regra'
  },

  TITLES: {
    CITY: 'Cidade',
    PRODUCT: 'Produto',
    MODAL: 'Veículo',
    PRODUCT_TYPE: 'Tipo',
    TIER: 'Tier',
    STATUS: 'Estado',
    START_TIME: 'Começa',
    END_TIME: 'Termina',
    COST_PER_KM: 'R$ por Km',
    COST_PER_POINT: 'R$ por Ponto',
    DEDUCTIBLE_COST: 'R$ de Franquia',
    LIST_TITLE: 'Listar regras dos MEIs',
    EDIT_TITLE: 'Alterar regra dos MEIs'
  },

  BULK_UPDATE_LABELS: {
    PAGE_TITLE: {
      TITLE: 'Atualizar Mei Rules'
    },
    UPLOAD: {
      TITLE: 'Enviar',
      ADVICE:
        'O arquivo tem que ser um CSV, onde a primeira linha corresponde ao cabeçalho e contém 5 colunas - chave da regra, preço por ponto, preço por km, franquia e data de início - por essa ordem exata. Para valores que não devem ser atualizados o campo deve ser vazio ou 0.'
    },
    SUCCESS: 'Regras atualizadas com sucesso'
  }
};

export default LABELS;
