import enums from '../../../enums';

const TEXTS = {
  TITLE: 'Revise as informações',
  BODY_HERE: 'aqui.',
  [enums.resources.SLO_EXCEPTION]: {
    ALREADY_EXISTS:
      'Já existe uma Exceção de SLO para esse par de Origem e Destino.',
    TO_EDIT: 'Você pode editá-la ',
    PATH: `/#/${enums.resources.SLO_EXCEPTION}`
  },
  [enums.resources.TRANSIT_TIME]: {
    ALREADY_EXISTS:
      'Já existe um Tempo de Trânsito para esse par de Origem e Destino.',
    TO_EDIT: 'Você pode editá-lo ',
    PATH: `/#/${enums.resources.TRANSIT_TIME}`
  }
};

export default TEXTS;
