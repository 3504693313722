const LABELS = {
  HEADINGS: {
    TITLE: 'Nova reanotação',
    SUB: 'Insira a lista de pacotes'
  },
  ELEMENTS: {
    BTN_SAVE: 'Salvar reanotação',
    INPUT_REASON: 'Motivo da reanotação',
    ALERT:
      'O arquivo tem que ser um CSV, onde a primeira linha corresponde ao cabeçalho e contém 2 colunas: Package Id e Dias. Nesta ordem, representando id do pacote e quantidade de dias de adição à data de promessa'
  },
  ALERTS: {
    SUCCESS:
      'Solicitação de reanotação enviada. Aguarde o resultado no canal de notificações.'
  }
};

export default LABELS;
