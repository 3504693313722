export const LABELS = {
  PAGE_TITLE: {
    TITLE: 'Cadastro de Repasse'
  },
  UPLOAD: {
    TITLE: 'Enviar',
    ADVICE:
      'O arquivo tem que ser um CSV, onde a primeira linha corresponde ao cabeçalho e contém 3 colunas - label, valor do repasse dentro do prazo, valor do repasse fora do prazo - por essa ordem exata.'
  },
  SUCCESS: 'Tabela cadastrada com sucesso'
};

export default { LABELS };
