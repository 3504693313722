import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@loggi/mar';

export const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: colors.smoke[50]
  },
  greenColor: {
    color: colors.green[700]
  },
  uploadedButton: {
    color: colors.blue[500]
  },
  uploadedIcon: {
    fill: colors.green[700]
  },
  muiLink: {
    color: colors.smoke[900],
    fontWeight: 'bold'
  },
  toUploadIcon: {
    fill: colors.smoke[700]
  },
  dropZone: {
    background: 'none'
  },
  inputPlaceHolder: {
    height: '39px',
    width: '200px',
    border: `1px solid ${colors.blue[500]}`,
    borderRadius: '25px 25px 25px 25px',
    '&:hover': {
      border: `1px solid ${colors.blue[500]}`,
      backgroundColor: 'rgba(0, 186, 255, 0.04)'
    }
  },
  infoButton: {
    marginBottom: '10px'
  },
  popper: {
    zIndex: theme.zIndex.drawer + 1
  },
  paper: {
    maxHeight: '88px',
    maxWidth: '267px'
  },
  popperText: {
    margin: '10px'
  },
  link: {
    textDecoration: 'underline',
    color: colors.smoke[700]
  },
  arrow: {
    '&:before': {
      border: '1px solid #E6E8ED'
    },
    color: theme.palette.common.white
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    border: '1px solid #E6E8ED',
    color: '#4A4A4A',
    maxHeight: '88px',
    maxWidth: '267px'
  }
}));

export default {
  useStyles
};
