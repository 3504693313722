const LABELS = {
  TITLES: {
    NAME: 'Nome',
    TYPE: 'Tipo',
    CONFIGURATION_ID: 'Identificador',
    LOGGI_NAME: 'Região Loggi',
    REDISPATCH_NAME: 'Região de Redespacho',
    START: 'Início',
    END: 'Final',
    LIST_POSTAL_CODE: 'Lista de CEPs',
    REGION_NAME: 'Nome da Região',
    DOWNLOAD: 'Baixar',
    UPLOAD: 'Enviar',
    ADVICE_UPLOAD:
      'O arquivo deve ter as colunas nome, início e final e deve ser enviado no formato CSV.',
    LIST_TITLE: 'Listar Regiões de Precificação'
  },
  TYPES: {
    PRICING_COMPONENT_INVALID: 'Inválido',
    PRICING_COMPONENT_FIRST_MILE: 'First Mile',
    PRICING_COMPONENT_MID_MILE: 'Mid Mile',
    PRICING_COMPONENT_LAST_MILE: 'Last Mile'
  }
};

export default LABELS;
