import React from 'react';
import PropTypes from 'prop-types';
import { Datagrid, TextField, List, EditButton, ShowButton } from 'react-admin';

import ActiveChip from '../../../atoms/active-chip';
import LABELS from './labels';
import ResourceFilters from '../../../molecules/resource-filters';
import LabeledEnumTextField from '../../../atoms/labeled-enum-text-field';
import permissionsFor from '../../../../access/access';
import enums from '../../../../enums';

export default function ServiceRegionList(props) {
  const { permissions } = props;
  const canUpdate = permissionsFor(enums.resources.SERVICE_REGION)
    .hasRole(permissions)
    .forAction(enums.actions.UPDATE);
  return (
    <List
      filters={<ResourceFilters />}
      sort={{ field: 'serviceRegionInfo.name', order: 'ASC' }}
      exporter={false}
      bulkActionButtons={false}
      title={LABELS.TITLES.LIST_TITLE}
      {...props}
    >
      <Datagrid>
        <TextField source="serviceRegionInfo.name" label={LABELS.TITLES.NAME} />
        <LabeledEnumTextField
          source="serviceRegionInfo.serviceRegionType"
          label={LABELS.TITLES.TYPE}
          enumLabels={LABELS.TYPES}
        />
        <ActiveChip
          source="serviceRegionInfo.active"
          label={LABELS.TITLES.ACTIVE}
        />
        {canUpdate ? <EditButton /> : <ShowButton />}
      </Datagrid>
    </List>
  );
}

ServiceRegionList.defaultProps = {
  permissions: undefined
};

ServiceRegionList.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string)
};
