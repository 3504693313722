import React from 'react';
import { maxLength, TextInput } from 'react-admin';
import PropTypes from 'prop-types';
import { useForm } from 'react-final-form';
import debounce from 'lodash/debounce';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import Api from '../../../api/postal-code-api';
import { TEXT, LMC_TYPES } from './constants';
import { useStyles } from './styles';
import { requiredLmc } from './validators';

const validations = [requiredLmc, maxLength(255)];

const LmcAutoCompleteInput = props => {
  const { dataTestId, source, variant, formData } = props;
  const { lastMileCompanyInfo } = formData;
  const classes = useStyles();
  const form = useForm();

  const [options, setOptions] = React.useState([]);

  const handleComponentChange = (event, newValue) => {
    const name = newValue?.suggestValue
      ? newValue.suggestValue
      : newValue?.lastMileCompanyInfo?.name;

    form.change('lastMileCompanyInfo.id', newValue?.lastMileCompanyInfo?.id);
    form.change(source, name);
  };

  const updateOptionsDebounced = debounce(async searchText => {
    const newOptions = await Api.getList('last-mile-company', {
      filter: { name: searchText }
    });

    setOptions(newOptions.data);
  }, 500);

  const handleInputValueChange = React.useCallback(
    (event, newInputValue) => {
      // Dont find again when selecting value with click or keydown
      if (event?.type !== 'change') return;

      updateOptionsDebounced(newInputValue);
    },
    // eslint-disable-next-line
    []
  );

  const handleGetOptionLabel = option => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return option;
    }

    // Add "xxx" option created dynamically
    if (option.suggestValue) {
      return option.suggestValue;
    }

    // Regular option
    return option.lastMileCompanyInfo.name;
  };

  const handleFilterOptions = filterOptions => {
    const newOptions = [...filterOptions];
    // Suggest the creation of a new value
    if (formData.tempLastMileName) {
      const newItem = {
        lastMileCompanyInfo: {
          name: `Adicionar "${formData?.tempLastMileName || ''}"`
        },
        suggestValue: formData?.tempLastMileName || ''
      };

      newOptions.unshift(newItem);
    }

    return newOptions;
  };

  return (
    <Autocomplete
      getOptionLabel={handleGetOptionLabel}
      getOptionSelected={(optionSelected, optionSelectedValue) =>
        optionSelected?.lastMileCompanyInfo?.name === optionSelectedValue
      }
      blurOnSelect
      options={options}
      noOptionsText={TEXT.NO_OPTIONS}
      filterSelectedOptions
      filterOptions={handleFilterOptions}
      disabled={!!lastMileCompanyInfo?.id}
      disableClearable
      value={lastMileCompanyInfo?.name}
      data-testid={dataTestId}
      onChange={handleComponentChange}
      onInputChange={handleInputValueChange}
      renderInput={params => (
        <>
          <TextInput
            {...params}
            id={`${dataTestId}-input`}
            data-testid={`${dataTestId}-input`}
            label={TEXT.LABEL}
            source="tempLastMileName"
            variant={variant}
            validate={validations}
          />
          {lastMileCompanyInfo?.name && !lastMileCompanyInfo?.id && (
            <FormHelperText className={classes.inputField}>
              {TEXT.CREATE_LMC}
            </FormHelperText>
          )}
        </>
      )}
      renderOption={option => (
        <Grid container alignItems="center">
          <Grid item xs>
            <Typography fontWeight="fontWeightBold">
              {option?.lastMileCompanyInfo?.name}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {LMC_TYPES[(option?.lastMileCompanyInfo?.companyType)]}
            </Typography>
          </Grid>
        </Grid>
      )}
    />
  );
};

LmcAutoCompleteInput.defaultProps = {
  variant: 'outlined',
  validate: [],
  error: null,
  formData: {}
};

LmcAutoCompleteInput.propTypes = {
  dataTestId: PropTypes.string.isRequired,
  error: PropTypes.func,
  source: PropTypes.string.isRequired,
  variant: PropTypes.string,
  validate: PropTypes.arrayOf(PropTypes.func),
  formData: PropTypes.shape({
    lastMileCompanyInfo: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    }),
    tempLastMileName: PropTypes.string
  })
};

export default LmcAutoCompleteInput;
