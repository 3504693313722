import uuid from 'uuid';

import {
  convertMoneyProtoToDecimal,
  convertDecimalToMoneyProto
} from '../../utils/currency-format';

const parseStatus = startTime => {
  const date = new Date(startTime).toUTCString();
  const currentDate = new Date().toUTCString();

  return Date.parse(date) <= Date.parse(currentDate) ? 'Ativa' : 'Futura';
};

const formatString = str => {
  if (str.includes('_')) {
    const parts = str.split('_');
    const firstWord = parts[0].charAt(0).toUpperCase() + parts[0].slice(1);
    const secondWord = parts[1].charAt(0).toUpperCase() + parts[1].slice(1);

    return `${firstWord} ${secondWord}`;
  }

  return str.charAt(0).toUpperCase() + str.slice(1);
};

function convertFromServer(rule) {
  const strategy = rule.startDriverStrategy;

  return {
    ...rule,
    startDriverStrategy: {
      ...rule.startDriverStrategy,
      product: formatString(strategy.product),
      modal: formatString(strategy.modal),
      productType: formatString(strategy.productType),
      costPerKm: convertMoneyProtoToDecimal(strategy.costPerKm),
      costPerPoint: convertMoneyProtoToDecimal(strategy.costPerPoint),
      deductibleCost: convertMoneyProtoToDecimal(strategy.deductibleCost)
    },
    status: parseStatus(rule.startTime),
    canBeUpdated: rule.endTime == null
  };
}

function convertToServer(data) {
  const id = data[0];
  const input = data[1];

  const strategyNewValues = input.startDriverStrategy;
  const rulePreviousValues = input.rule;
  const utcDate = new Date(input.startTime).toUTCString();
  const startTimeNewValue = new Date(utcDate).toISOString();

  const rule = {
    ...rulePreviousValues,
    id: uuid.v4(),
    creationTime: null,
    lastUpdatedTime: null,
    endTime: null,
    startTime: startTimeNewValue,
    startDriverStrategy: {
      ...rulePreviousValues.startDriverStrategy,
      costPerKm: convertDecimalToMoneyProto(strategyNewValues.costPerKm),
      costPerPoint: convertDecimalToMoneyProto(strategyNewValues.costPerPoint),
      deductibleCost: convertDecimalToMoneyProto(
        strategyNewValues.deductibleCost
      )
    }
  };

  return { id, rule };
}

export default {
  fromServer: data => {
    return data.map(rule => {
      return convertFromServer(rule);
    });
  },

  toServer: data => {
    return convertToServer(data);
  }
};
