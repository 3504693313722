import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@loggi/mar';

export const useStyles = makeStyles(theme => ({
  container: {
    height: '100vh'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: colors.smoke[50]
  }
}));

export default {
  useStyles
};
