import { HttpError } from 'react-admin';
import { Auth } from 'aws-amplify';
import STATUS_CODE from './status-code';
import LABELS from './labels';

export const getResponseBodyOrThrow = async response => {
  const { statusText, status } = response;

  const responseBody = await response.json().catch(() => {
    if (
      status !== STATUS_CODE.NOT_MODIFIED &&
      status !== STATUS_CODE.OK &&
      status !== STATUS_CODE.CREATED &&
      status !== STATUS_CODE.NO_CONTENT
    ) {
      throw Error(LABELS.ALERTS.CONECTION_ERROR);
    }
  });

  if (!response.ok) {
    throw HttpError(
      (responseBody && responseBody.message) || statusText,
      status,
      responseBody || {}
    );
  }

  return responseBody;
};

export const fetchOrThrow = async (endpoint, options = {}) => {
  const {
    accessToken: { jwtToken }
  } = await Auth.currentSession();
  const defaultHeaders = {
    'Content-Type': 'application/json'
  };
  const headers = options.headers || defaultHeaders;
  headers.Authorization = `Bearer ${jwtToken}`;

  const response = await fetch(endpoint, {
    credentials: 'include',
    headers,
    ...options
  });

  const responseBody = await getResponseBodyOrThrow(response);

  return responseBody;
};

export default { getResponseBodyOrThrow, fetchOrThrow };
