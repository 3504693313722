import React from 'react';
import { TextInput, Filter } from 'react-admin';
import LABELS from './labels';

const SloMappingFilter = props => {
  return (
    <Filter {...props} variant="outlined">
      <TextInput
        source="originName"
        alwaysOn
        label={LABELS.ORIGIN}
        resettable
        data-testid="origin-filter"
      />
      <TextInput
        source="destinationName"
        alwaysOn
        label={LABELS.DESTINATION}
        resettable
        data-testid="destination-filter"
      />
    </Filter>
  );
};

export default SloMappingFilter;
