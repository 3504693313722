export const ERRORS = {
  START_DATE_MANDATORY: 'Por favor selecione a data de início',
  END_DATE_BIGGER_THAN_STAR_DATE_MANDATORY:
    'A data final necessita ser depois da data inicial',
  FAILED_TO_FETCH: 'Erro ao tentar buscar Regiões de Serviço'
};

export const LABELS = {
  START_DATE_TIME: 'Data Inicial',
  END_DATE_TIME: 'Data Final',
  SERVICE_REGION_GROUP_IDS: 'originServiceRegionIds',
  SERVICE_REGION_GROUP_OPTIONS: 'serviceRegionGroupOptions'
};
