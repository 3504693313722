export const tableHeadersCategories = {
  'Cross-Docking de Origem': 1,
  Latências: 1,
  SLO: 4
};

export const tableHeaders = [
  'Nome',
  'Mid Mile',
  'SLO Origem',
  'Origem ➝ CAJ',
  'CAJ ➝ Origem',
  'Exceção'
];

export const labels = {
  ATTENDANCE_XD: 'XD de atendimento',
  EXCEPTION: 'Exceção',
  DAYS: 'Dias',
  LOADING_SLOS: 'Carregando SLOs Multi Origem'
};

export const exceptionTypes = {
  EXCEPTION_TYPE_INVALID: 'EXCEPTION_TYPE_INVALID',
  EXCEPTION_TYPE_SLO: 'EXCEPTION_TYPE_SLO',
  EXCEPTION_TYPE_OFFSET: 'EXCEPTION_TYPE_OFFSET'
};

export const origins = {
  SERVICE_REGION_CAJAMAR_XDS: 'service-region-cajamar-xds'
};
