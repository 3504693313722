import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  pageTitleContainer: {
    marginTop: 24,
    marginBottom: 24
  },
  pageTitle: {
    fontWeight: 'bold',
    marginBottom: 8
  },
  updatedAt: {
    marginTop: 4,
    color: '#757D8C',
    fontSize: 12,
    fontFamily: 'Open Sans'
  },
  versioningSection: {
    fontSize: 14
  }
}));

export default { useStyles };
