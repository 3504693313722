import React from 'react';
import PropTypes from 'prop-types';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import CustomDialog from '../custom-dialog';
import TEXTS from './constants';
import enums from '../../../enums';

const DialogConflictSLOMapping = props => {
  const { open, onClose, conflictingSloMappingIds, resource } = props;

  return (
    <CustomDialog title={TEXTS.TITLE} open={open} onClose={onClose} fullWidth>
      <Typography
        variant="subtitle2"
        color="textSecondary"
        data-testid="descriptionField"
      >
        <>
          {TEXTS[resource].ALREADY_EXISTS}
          <br />
          {TEXTS[resource].TO_EDIT}
          <Link
            href={`${TEXTS[resource].PATH}/${conflictingSloMappingIds[0]}`}
            target="_blank"
            rel="noopener"
            underline="always"
          >
            {TEXTS.BODY_HERE}
          </Link>
        </>
      </Typography>
    </CustomDialog>
  );
};

DialogConflictSLOMapping.defaultProps = {
  open: false,
  resource: enums.resources.SLO_EXCEPTION
};

DialogConflictSLOMapping.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  conflictingSloMappingIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  resource: PropTypes.string
};

export default DialogConflictSLOMapping;
