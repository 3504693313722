export const LABELS = {
  DIALOG_TITLE: 'Reanotação',
  DIALOG_CONTENT_TEXT: 'Reanotar todos os pacotes da(s) última(s):',
  DIALOG_INPUT_LABEL: 'Horas e minutos',
  CONFIRM_BUTTON_LABEL: 'Reanotar pacotes',
  CLOSE_DIALOG_BUTTON_LABEL: 'Fechar',
  SUCCESS: 'Pacotes reanotados com sucesso!',
  FAILURE: 'Falha ao reanotar pacotes.',
  INVALID_INPUT: 'Formato de hora inválido'
};

export default { LABELS };
