import enums from '../../../../enums';

export const RANGE_TYPES = [
  { id: enums.rangeType.RANGE_TYPE_DELIVERY, name: 'Entrega' },
  { id: enums.rangeType.RANGE_TYPE_PICKUP, name: 'Coleta' }
];

export const DROP_OFF_AMOUNT_DEFAULT = '0.50';

export const DROP_OFF_AMOUNT_MAX_VALUE = '10.00';
export default { RANGE_TYPES };
