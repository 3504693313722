import { zeroPad } from './string';
import moment from './moment';

export const formatTimeToObject = time => {
  const [hours, minutes] = time.split(':');
  return { hours: +hours, minutes: +minutes };
};

export const convertObjectToTimeString = ({ hours, minutes }) =>
  `${zeroPad(hours, 2)}:${zeroPad(minutes, 2)}`;

export const getTomorrowTimestamp = () => moment().add(1, 'days');

export default {
  formatTimeToObject,
  convertObjectToTimeString,
  getTomorrowTimestamp
};
