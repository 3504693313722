const LABELS = {
  MENU_TITLE: {
    GENERATE_COVERAGE_SPREADSHEET: 'Exportar Abrangência',
    PUBLISH_COVERAGE: 'Publicar Abrangência',
    IMPORT_REDISPATCH_SLO: 'SLO de Redespacho',
    CREATE_LMC: 'Nova Transportadora',
    CREATE_BASE: 'Cadastrar Base',
    CREATE_DELIVERY_EARNINGS_TABLE: 'Cadastro de Repasse'
  }
};

export default LABELS;
