import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import CheckCircleOutline from '@material-ui/icons/CheckCircle';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';

import enums from '../../../enums';
import ZipCodeField from '../add-postal-code-range/zip-code-field';
import formatPostalCode from '../../../utils/format-postal-code';
import { OPERATIONS } from '../batch-ranges-import/utils/constants';
import { useStyles } from './styles';
import { LABELS } from './constants';
import {
  getRangeKey,
  buildRange,
  isLastMileCompanyLeve,
  isPostalCodeGroupLastMileCompany
} from './utils';
import { buildRegionRangeName } from '../../../api/region-utils';

const PostalCodeRow = props => {
  const {
    range,
    onDelete,
    hasWritePermissions,
    postalCodeGroupInfo,
    isEditing,
    startEditing,
    onConfirmEdit,
    stopEditing,
    earningsInfoByRegions,
    typeOfRanges,
    showName,
    canUpdateLocalSlo
  } = props;
  const classes = useStyles();

  const [name, setName] = useState(range.name);
  const [start, setStart] = useState(range.start);
  const [end, setEnd] = useState(range.end);
  const [slo, setSlo] = useState(range?.lastMileCompanyInfo?.slo);
  const [earningsLabel, setEarningsLabel] = useState(
    range?.lastMileCompanyInfo?.earningsLabel || ''
  );

  const isLastMileCompany = isPostalCodeGroupLastMileCompany(
    postalCodeGroupInfo
  );

  const earningsInfoHasLabel = useMemo(() => {
    if (!earningsInfoByRegions || !range?.lastMileCompanyInfo?.earningsLabel)
      return false;

    // eslint-disable-next-line react/prop-types
    return earningsInfoByRegions.some(info => info.label === earningsLabel);
  }, [earningsInfoByRegions, earningsLabel, range]);

  const resetInputs = () => {
    setName(range.name);
    setStart(range.start);
    setEnd(range.end);
    setSlo(range?.lastMileCompanyInfo?.slo);
    setEarningsLabel(range?.lastMileCompanyInfo?.earningsLabel || '');
  };

  const handleConfirmButton = () => {
    const editedRange = buildRange({
      name: showName ? name : buildRegionRangeName(range),
      start,
      end,
      slo,
      earningsLabel,
      postalCodeGroupInfo,
      rangeType: typeOfRanges
    });
    const typeChange = OPERATIONS.JSON_PATCH.OPERATION_REPLACE.LABEL;

    onConfirmEdit(editedRange, typeChange);
  };

  const handleCancel = () => {
    resetInputs();
    stopEditing();
  };

  return (
    <TableRow data-testid="postalCodesTableData">
      {isEditing ? (
        <>
          {showName && (
            <TableCell component="th" scope="row">
              <FormControl>
                <TextField
                  data-testid="range-name-edit-input"
                  type="text"
                  placeholder={LABELS.INPUTS.NAME}
                  value={name}
                  onChange={event => setName(event.target.value)}
                />
              </FormControl>
            </TableCell>
          )}
          <TableCell className={classes.zipCodeCell}>
            <FormControl>
              <ZipCodeField
                dataTestId="range-start-edit-input"
                name={LABELS.INPUTS.START}
                value={start}
                setType={setStart}
              />
            </FormControl>
          </TableCell>
          <TableCell className={classes.zipCodeCell}>
            <FormControl>
              <ZipCodeField
                dataTestId="range-end-edit-input"
                name={LABELS.INPUTS.END}
                value={end}
                setType={setEnd}
              />
            </FormControl>
          </TableCell>
          {isLastMileCompany && (
            <>
              {isLastMileCompanyLeve(postalCodeGroupInfo) && (
                <TableCell className={classes.earningsLabelCell}>
                  <FormControl fullWidth>
                    <Select
                      labelId="range-earnings-label-edit-input"
                      data-testid="range-earnings-label-edit-input"
                      displayEmpty
                      value={earningsLabel}
                      onChange={event => setEarningsLabel(event.target.value)}
                    >
                      <MenuItem value="">
                        {LABELS.TEXTS.EARNING_NOT_SELECT}
                      </MenuItem>
                      {Object.values(earningsInfoByRegions).map(info => (
                        <MenuItem value={info.label}>{info.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </TableCell>
              )}
              <TableCell className={classes.sloCell}>
                <FormControl>
                  <TextField
                    data-testid="range-slo-edit-input"
                    type="number"
                    placeholder={LABELS.INPUTS.SLO}
                    onChange={event => setSlo(event.target.value)}
                    value={slo}
                    disabled={!canUpdateLocalSlo}
                  />
                </FormControl>
              </TableCell>
            </>
          )}
          <TableCell className={classes.actionsCell}>
            <Grid container spacing={3}>
              <Grid item>
                <Button
                  onClick={handleConfirmButton}
                  variant="outlined"
                  size="small"
                  color="primary"
                  disabled={!hasWritePermissions}
                  data-testid="range-edit-confirm-button"
                >
                  <CheckCircleOutline />
                  {LABELS.BUTTONS.CONFIRM}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={handleCancel}
                  variant="outlined"
                  size="small"
                  disabled={!hasWritePermissions}
                  data-testid="range-edit-cancel-button"
                  className={classes.removeButton}
                >
                  <CancelIcon />
                  {LABELS.BUTTONS.CANCEL}
                </Button>
              </Grid>
            </Grid>
          </TableCell>
        </>
      ) : (
        <>
          {showName && (
            <TableCell component="th" scope="row">
              <Typography>{range.name}</Typography>
            </TableCell>
          )}
          <TableCell className={classes.zipCodeCell}>
            <Typography>{formatPostalCode(range.start.code)}</Typography>
          </TableCell>
          <TableCell className={classes.zipCodeCell}>
            <Typography>{formatPostalCode(range.end.code)}</Typography>
          </TableCell>
          {isLastMileCompany && (
            <>
              {isLastMileCompanyLeve(postalCodeGroupInfo) && (
                <TableCell className={classes.zipCodeCell}>
                  <Tooltip
                    title={LABELS.ERRORS.EARNING_LABEL_NOT_EXISTS}
                    arrow
                    placement="top-start"
                    disableHoverListener={earningsInfoHasLabel}
                  >
                    <Typography
                      className={earningsInfoHasLabel ? '' : classes.redColor}
                    >
                      {range?.lastMileCompanyInfo?.earningsLabel ||
                        LABELS.TEXTS.EARNING_NOT_SELECT}
                    </Typography>
                  </Tooltip>
                </TableCell>
              )}
              <TableCell className={classes.sloCell}>
                <Typography>{range?.lastMileCompanyInfo?.slo || 0}</Typography>
              </TableCell>
            </>
          )}
          <TableCell className={classes.actionsCell}>
            <Grid container spacing={2}>
              <Grid item>
                <Button
                  onClick={startEditing}
                  variant="outlined"
                  size="small"
                  color="primary"
                  disabled={!hasWritePermissions}
                  data-testid="range-edit-button"
                >
                  <EditIcon />
                  {LABELS.BUTTONS.EDIT}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={onDelete}
                  variant="outlined"
                  size="small"
                  disabled={!hasWritePermissions}
                  data-testid="range-delete-button"
                  className={classes.removeButton}
                >
                  <DeleteForeverIcon />
                  {LABELS.BUTTONS.REMOVE}
                </Button>
              </Grid>
            </Grid>
          </TableCell>
        </>
      )}
    </TableRow>
  );
};

PostalCodeRow.defaultProps = {
  earningsInfoByRegions: [],
  typeOfRanges: enums.rangeType.RANGE_TYPE_DEFAULT,
  postalCodeGroupInfo: {},
  showName: true,
  canUpdateLocalSlo: true
};

PostalCodeRow.propTypes = {
  range: PropTypes.shape({
    name: PropTypes.string,
    start: PropTypes.shape({
      code: PropTypes.string
    }).isRequired,
    end: PropTypes.shape({
      code: PropTypes.string
    }).isRequired,
    lastMileCompanyInfo: PropTypes.shape({
      slo: PropTypes.number
    })
  }).isRequired,
  startEditing: PropTypes.func.isRequired,
  onConfirmEdit: PropTypes.func.isRequired,
  stopEditing: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  hasWritePermissions: PropTypes.bool.isRequired,
  earningsInfoByRegions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string
    })
  ),
  postalCodeGroupInfo: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.number,
    resourceName: PropTypes.string,
    groupType: PropTypes.string,
    active: PropTypes.bool
  }),
  typeOfRanges: PropTypes.string,
  showName: PropTypes.bool,
  canUpdateLocalSlo: PropTypes.bool
};

const rangePropsAreEqual = (prevProps, nextProps) => {
  const prevRange = prevProps.range;
  const nextRange = nextProps.range;

  return (
    getRangeKey(prevRange) === getRangeKey(nextRange) &&
    prevProps.isEditing === nextProps.isEditing &&
    isEqual(prevProps.earningsInfoByRegions, nextProps.earningsInfoByRegions) &&
    prevProps.postalCodeGroupInfo.active ===
      nextProps.postalCodeGroupInfo.active
  );
};

export default React.memo(PostalCodeRow, rangePropsAreEqual);
