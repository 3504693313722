import React from 'react';
import Alert from '@material-ui/lab/Alert';
import {
  Create,
  FileInput,
  FileField,
  SimpleForm,
  useNotify,
  usePermissions
} from 'react-admin';
import { useHistory } from 'react-router-dom';
import { LABELS } from './constants';
import enums from '../../../../../enums';
import permissionsFor from '../../../../../access/access';
import SimpleToolbar from '../../../../atoms/simple-toolbar';

export default function CreateDeliveryEarningsTable(props) {
  const { loaded, permissions } = usePermissions();
  const history = useHistory();
  const notify = useNotify();
  const authorized = permissionsFor(
    enums.customResources.CREATE_DELIVERY_EARNINGS_TABLE
  )
    .hasRole(permissions)
    .forAction(enums.actions.CREATE);

  if (loaded && !authorized) {
    history.push(`/${enums.resources.LAST_MILE_COMPANY}`);
  }

  const onSuccess = () => {
    notify(LABELS.SUCCESS);
  };

  const properties = {
    basePath: `/${enums.customResources.CREATE_DELIVERY_EARNINGS_TABLE}`,
    resource: enums.customResources.CREATE_DELIVERY_EARNINGS_TABLE,
    title: LABELS.PAGE_TITLE.TITLE
  };

  const customProps = { ...props, ...properties };

  return (
    <Create undoable={false} onSuccess={onSuccess} {...customProps}>
      <SimpleForm variant="outlined" toolbar={<SimpleToolbar />}>
        <Alert data-testid="alert-update-advice" severity="info" fullWidth>
          {LABELS.UPLOAD.ADVICE}
          <FileInput
            source="createDeliveryEarningsTable.attachment"
            label={LABELS.UPLOAD.TITLE}
            accept=".csv"
          >
            <FileField source="createDeliveryEarningsTable.src" title="title" />
          </FileInput>
        </Alert>
      </SimpleForm>
    </Create>
  );
}
