import enums from '../../../enums';

// The bigger is the number, the bigger is the priority
const typePriority = {
  [enums.versioningStatusType.APPROVED]: 5,
  [enums.versioningStatusType.PROGRAMMED]: 4,
  [enums.versioningStatusType.ACTIVE]: 3,
  [enums.versioningStatusType.EXPIRED]: 2,
  [enums.versioningStatusType.REJECTED]: 1,
  [enums.versioningStatusType.INVALID]: 0
};

export const createSortedVersionsMap = versions => {
  const statusTypesPrioriterized = [
    ...new Set(versions.map(version => version.versioningStatus))
  ].sort((a, b) => typePriority[b] - typePriority[a]);

  return statusTypesPrioriterized.reduce((acc, statusType) => {
    const correspondingVersionsSortedByEffectiveOn = versions
      .filter(version => version.versioningStatus === statusType)
      .sort((a, b) => b.effectiveOnTimeAsInt - a.effectiveOnTimeAsInt);

    return { ...acc, [statusType]: correspondingVersionsSortedByEffectiveOn };
  }, {});
};

export default { createSortedVersionsMap };
