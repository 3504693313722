export const AUTOCOMPLETE_ACTIONS = {
  CLEAR: 'clear',
  SELECT_OPTION: 'select-option'
};

export const LABELS = {
  ERRORS: {
    FIND_REGIONS: 'Erro ao buscar Regiões',
    GET_REGION_ERROR_MESSAGE: 'Erro ao obter informações da região selecionada.'
  }
};

export default { AUTOCOMPLETE_ACTIONS, LABELS };
