/* eslint-disable no-unused-vars */
import { Auth } from 'aws-amplify';
import enums from './enums';

export const isAuthorized = permissions => {
  if (!permissions) return false;

  const roles = Object.values(enums.roles);

  return roles.some(role => permissions.includes(role));
};

export const isAuthorizedWithPermission = (permissions, permission) => {
  if (!permissions) return false;
  return permissions.includes(permission);
};

export const isAuthorizedWithWritePermissions = permissions => {
  return isAuthorizedWithPermission(permissions, enums.roles.ADMIN);
};

export const makeLogout = async () => Auth.signOut();

export default {
  login: async () => Auth.federatedSignIn({ provider: 'Google' }),
  logout: async () => makeLogout(),
  checkAuth: async () => {
    const data = await Auth.currentSession();
    const groups = data.accessToken.payload['cognito:groups'];
    return isAuthorized(groups)
      ? Promise.resolve(groups)
      : window.location.replace(`/#/${enums.customResources.ACCESS_DENIED}`);
  },
  checkError: async () => Auth.currentCredentials(),
  getPermissions: async () => {
    const data = await Auth.currentSession();
    const groups = data.accessToken.payload['cognito:groups'];
    if (!groups) return Promise.resolve([]);
    return Promise.resolve(groups);
  }
};
