import merge from 'lodash/merge';
import { createMuiTheme } from '@material-ui/core';
import mar, { colors } from '@loggi/mar';

const { pxToRem } = mar.typography;

const overrides = merge(mar.overrides, {
  MuiTableCell: {
    root: {
      height: pxToRem(6),
      paddingLeft: pxToRem(16),
      paddingRight: pxToRem(16)
    }
  },
  MuiButton: {
    textSizeSmall: {
      padding: `${pxToRem(10)} ${pxToRem(20)}`
    },
    // Default (medium)
    text: {
      padding: `${pxToRem(6)} ${pxToRem(16)}`
    },
    textSizeLarge: {
      padding: `${pxToRem(6)} ${pxToRem(16)}`
    },
    outlinedSizeSmall: {
      padding: `${pxToRem(4)} ${pxToRem(10)}`
    },
    // Default (medium)
    outlined: {
      padding: `${pxToRem(8)} ${pxToRem(16)}`
    },
    outlinedSizeLarge: {
      // lineHeight: pxToRem(22),
      padding: `${pxToRem(16)} ${pxToRem(24)}`
    }
  },
  MuiSnackbarContent: {
    root: {
      border: `${pxToRem(1)} solid ${colors.blue[300]}`,
      padding: '0.8rem'
    },
    message: {
      width: '100%',
      textAlign: 'center',
      fontSize: `${pxToRem(mar.typography.fontSize)}`,
      display: 'block'
    }
  },
  RaNotification: {
    warning: {
      border: 'none'
    }
  },
  MuiTypography: {
    h4: {
      fontFamily: 'Open Sans',
      fontSize: pxToRem(28)
    },
    h6: {
      fontFamily: 'Open Sans',
      fontSize: pxToRem(20)
    }
  }
});

const theme = {
  ...mar,
  overrides
};

export default createMuiTheme(theme);
