import { exceptionTypes, origins } from './constants';

const isSloException = exception =>
  exception?.exceptionType === exceptionTypes.EXCEPTION_TYPE_SLO;

const getExceptionValueWithPrefix = exception => {
  if (exception.exceptionType === exceptionTypes.EXCEPTION_TYPE_OFFSET) {
    return Math.sign(exception.value) === 1
      ? `+${exception.value}`
      : `${exception.value}`;
  }

  return `${exception.value}`;
};

const getFirstPropertyName = obj => Object.keys(obj || {})[0];

const _formatLatency = latencyInMinutes => {
  const MINUTES_IN_A_DAY = 60 * 24;

  const days = Math.floor(latencyInMinutes / MINUTES_IN_A_DAY);
  const remainder = latencyInMinutes % MINUTES_IN_A_DAY;

  const hours = Math.floor(remainder / 60);
  const minutes = remainder % 60;

  return `${days}d ${hours}h ${minutes}m`;
};

const displayLatency = latency => {
  return latency ? _formatLatency(latency) : '-';
};

const getCajamarLatency = multiOriginSlos => {
  const cajamarOrigin = multiOriginSlos.find(
    origin => origin.name === origins.SERVICE_REGION_CAJAMAR_XDS
  );

  return cajamarOrigin?.latency ? cajamarOrigin.latency : null;
};

const isOriginCajamar = origin => {
  return origin === origins.SERVICE_REGION_CAJAMAR_XDS;
};

const hasAllCustomParametersToGetMultiOriginSlos = (
  serviceRegionId,
  serviceType,
  cityRegionId
) => {
  return !!(serviceRegionId && serviceType && cityRegionId);
};

const isAbleToShowDiffSlos = (showDiffSlos, diffSlo) => {
  return !!(showDiffSlos && diffSlo);
};

export {
  getExceptionValueWithPrefix,
  isSloException,
  getFirstPropertyName,
  getCajamarLatency,
  isOriginCajamar,
  displayLatency,
  hasAllCustomParametersToGetMultiOriginSlos,
  isAbleToShowDiffSlos
};
