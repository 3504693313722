import enums from '../../../../enums';
import LABELS from './labels';

export const REGION_TYPES = [
  { id: enums.regionTypes.REGION_TYPE_INVALID, name: 'Desconhecido' },
  { id: enums.regionTypes.REGION_TYPE_STATE, name: 'Estado' },
  { id: enums.regionTypes.REGION_TYPE_CITY, name: 'Cidade' },
  { id: enums.regionTypes.REGION_TYPE_DISTRICT, name: 'Distrito' },
  { id: enums.regionTypes.REGION_TYPE_STREET, name: 'Rua' },
  { id: enums.regionTypes.REGION_TYPE_CUSTOM_REGION, name: 'Customizado' }
];

export const REGION_CHOICES = Object.entries(LABELS.TYPES)
  .filter(([id]) => id !== 'REGION_TYPE_INVALID')
  .map(([id, name]) => ({ id, name }));
