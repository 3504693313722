import React from 'react';
import { SaveButton, Toolbar } from 'react-admin';
import PropTypes from 'prop-types';
import useStyles from './styles';
import LABELS from './labels';

const GenericToolbar = props => {
  const { buttonText, disabled, ...rest } = props;

  const classes = useStyles();

  return (
    <Toolbar {...rest} className={classes.toolbar}>
      <SaveButton
        icon={<></>}
        label={buttonText}
        disabled={disabled}
        className={classes.button}
      />
    </Toolbar>
  );
};

GenericToolbar.defaultProps = {
  buttonText: LABELS.BUTTON.DEFAULT_TEXT,
  disabled: false
};

GenericToolbar.propTypes = {
  buttonText: PropTypes.string,
  disabled: PropTypes.bool
};

export default GenericToolbar;
