import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import getValidityLabel from './utils';

const VersionTimeRange = props => {
  const {
    versioningStatusType,
    effectiveOnTimestamp,
    expiredAtTimestamp,
    ...rest
  } = props;

  return (
    <Typography {...rest}>
      {getValidityLabel(
        versioningStatusType,
        effectiveOnTimestamp,
        expiredAtTimestamp
      )}
    </Typography>
  );
};

VersionTimeRange.defaultProps = {
  expiredAtTimestamp: 0
};

VersionTimeRange.propTypes = {
  versioningStatusType: PropTypes.string.isRequired,
  effectiveOnTimestamp: PropTypes.number.isRequired,
  expiredAtTimestamp: PropTypes.number
};

export default VersionTimeRange;
