import LABELS from './labels';

export const handleConflictSloMapping = (error, onConflict, onError) => {
  const sloMappingsConflicting =
    error?.body?.sloMappingDuplicatedConflict?.sloMappings;

  if (sloMappingsConflicting) {
    onConflict(sloMappingsConflicting.map(sloMapping => sloMapping.Id.$oid));
    return;
  }

  onError(error.message);
};

export const handleConflictRangeOverlap = (error, onConflict, onError) => {
  const conflicts = error?.body?.error?.rangeOverlapConflict?.conflicts;

  if (conflicts) {
    onConflict(conflicts);
    return;
  }

  onError(error.message);
};

export const handleEmptyPostalCodeRange = (error, setError, onError) => {
  const groupType = error?.body?.error?.emptyPostalCodeRange?.groupType;

  if (groupType) {
    setError(['']);
    return;
  }

  onError(error.message);
};

export const handleTransitTimeNotFound = (error, setError, onError) => {
  const { originPostalCodeId, destinationPostalCodeId } =
    error?.body?.error?.transitTimeNotFound || {};

  if (originPostalCodeId && destinationPostalCodeId) {
    setError([originPostalCodeId, destinationPostalCodeId]);
    return;
  }

  onError(error.message);
};

export const handleConflictErrorsMap = {
  [LABELS.API_ERROR_CODE
    .ERROR_CODE_SERVICE_REGION_OVERLAP_CONFLICT]: handleConflictRangeOverlap,
  [LABELS.API_ERROR_CODE
    .ERROR_CODE_SLO_MAPPING_DUPLICATED_CONFLICT]: handleConflictSloMapping
};

export const handleApiErrorsMap = {
  [LABELS.API_ERROR_CODE
    .ERROR_CODE_TRANSIT_TIME_NOT_FOUND]: handleTransitTimeNotFound,
  [LABELS.API_ERROR_CODE
    .ERROR_CODE_EMPTY_POSTAL_CODE_RANGE]: handleEmptyPostalCodeRange,
  DEFAULT: (error, _, onError) => onError(error.message)
};
