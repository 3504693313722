import { makeStyles } from '@material-ui/core/styles';
import mar, { colors } from '@loggi/mar';

const { pxToRem } = mar.typography;

export const useStyles = makeStyles(theme => ({
  menuList: {
    padding: 0,
    margin: 0,
    color: colors.smoke[700],
    '&:focus': {
      outline: 'none'
    }
  },
  menuItem: {
    height: pxToRem(36)
  },
  listIcon: {
    minWidth: theme.spacing(5)
  },
  icon: {
    fill: 'rgba(0, 0, 0, 0.54)'
  }
}));

export default { useStyles };
