import React from 'react';
import PropTypes from 'prop-types';

import { Datagrid, TextField, List, EditButton, ShowButton } from 'react-admin';

import ActiveChip from '../../../atoms/active-chip';
import ResourceFilters from '../../../molecules/resource-filters';
import LABELS from './labels';
import LabeledEnumTextField from '../../../atoms/labeled-enum-text-field';
import { RANGE_TYPES } from './constants';
import permissionsFor from '../../../../access/access';
import enums from '../../../../enums';

export default function LastMileCompanyList(props) {
  const { permissions } = props;
  const canUpdate = permissionsFor(enums.resources.LAST_MILE_COMPANY)
    .hasRole(permissions)
    .forAction(enums.actions.UPDATE);

  return (
    <List
      filters={<ResourceFilters customFilters={{ rangeTypes: RANGE_TYPES }} />}
      sort={{ field: 'lastMileCompanyInfo.name', order: 'ASC' }}
      exporter={false}
      bulkActionButtons={false}
      title={LABELS.TITLES.LIST_TITLE}
      {...props}
    >
      <Datagrid>
        <TextField
          source="lastMileCompanyInfo.name"
          label={LABELS.TITLES.NAME}
        />
        <LabeledEnumTextField
          source="lastMileCompanyInfo.companyType"
          label={LABELS.TITLES.TYPE}
          enumLabels={LABELS.TYPES}
        />
        <ActiveChip
          source="lastMileCompanyInfo.active"
          label={LABELS.TITLES.ACTIVE}
        />
        {canUpdate ? <EditButton /> : <ShowButton />}
      </Datagrid>
    </List>
  );
}

LastMileCompanyList.defaultProps = {
  permissions: undefined
};

LastMileCompanyList.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string)
};
