import queryString from 'query-string';
import { fetchOrThrow } from '../api';
import { STATUS_TYPES } from '../../view/templates/_react-admin/mei-rules/constants';
import meiRulesAdapter from './mei-rules-adapter';

const ENDPOINT_URL = `${process.env.REACT_APP_ANNOTATOR_URL}/api/v1/rules`;
const BULK_UPDATE_URL = `${
  process.env.REACT_APP_ANNOTATOR_URL
}/api/v1/rules/bulk-update`;

function parseDataFromServer(jsonData) {
  const data = Object.values(jsonData);

  return meiRulesAdapter.fromServer(data);
}

function parseDataToServer(jsonData) {
  const data = Object.values(jsonData);

  return meiRulesAdapter.toServer(data);
}

const getList = async args => {
  const { pagination = { page: 1, perPage: 20 }, filter = {} } = args;

  const params = {
    skip: (pagination.page - 1) * pagination.perPage,
    'results-per-page': pagination.perPage
  };

  if (filter.city) {
    params.city = filter.city;
  }

  if (filter.tier) {
    params.tier = filter.tier;
  }

  if (filter.modal) {
    params.modal = filter.modal;
  }

  if (filter.product) {
    params.product = filter.product;
  }

  if (filter.status === STATUS_TYPES[0].name) {
    params['is-active'] = true;
  } else if (filter.status === STATUS_TYPES[1].name) {
    params['is-active'] = false;
  }

  const filters = queryString.stringify(params, { sort: false });

  const url = `${ENDPOINT_URL}?${filters}`;

  const response = await fetchOrThrow(`${url}`);
  const { rules = [], total = 0 } = response;
  const data = parseDataFromServer(rules);

  return {
    data,
    total
  };
};

const getOne = async id => {
  const url = `${ENDPOINT_URL}/${id}`;
  const response = await fetchOrThrow(url);

  return { data: { id, ...response } };
};

const update = async args => {
  const { id, rule } = parseDataToServer(args);

  const url = `${ENDPOINT_URL}/${id}`;

  const response = await fetchOrThrow(url, {
    method: 'PUT',
    body: JSON.stringify({
      ...rule
    })
  });

  return { data: { id, ...response } };
};

const updateMany = async args => {
  const {
    updateMeiRules: { attachment }
  } = args;

  const formData = new FormData();
  formData.append('fileUpload', attachment.rawFile);

  const response = await fetchOrThrow(BULK_UPDATE_URL, {
    method: 'POST',
    body: formData,
    headers: {}
  });

  const { rules = [] } = response;
  const data = parseDataFromServer(rules);

  return { data };
};

const deleteOne = async id => {
  const url = `${ENDPOINT_URL}/${id}`;
  await fetchOrThrow(url, {
    method: 'DELETE'
  });

  return { data: { id } };
};

export default {
  getList,
  getOne,
  update,
  updateMany,
  deleteOne
};
