import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import {
  Edit,
  TextInput,
  SimpleForm,
  SelectInput,
  useQuery,
  FormDataConsumer,
  useNotify,
  NumberInput
} from 'react-admin';
import SaveWithConfirmToolbar from '../../../molecules/save-with-confirm-toolbar';
import PostalCodesTable from '../../../molecules/postal-codes-table';
import LABELS from './labels';
import enums from '../../../../enums';
import DialogConflictRanges from '../../../molecules/dialog-conflict-ranges';
import { getPostalCodeGroupInfo } from '../../../molecules/postal-codes-table/utils';
import PostalCodeGroupAutocomplete from '../../../molecules/postal-code-group-autocomplete';
import { isTypeCrossdocking, isTypeDropOff } from './utils';
import HoursMinutesInput from '../../../atoms/hours-minutes-input';
import {
  handleConflictErrorsMap,
  handleApiErrorsMap
} from '../../../../utils/handle-conflicts';
import validateServiceRegion from './validations';
import CustomDivider from '../../../atoms/custom-divider/custom-divider';
import permissionsFor from '../../../../access/access';
import ActivationToggle from '../../../molecules/activation-toggle';
import DialogErrors from '../../../molecules/dialog-errors';
import STATUS_CODE from '../../../../api/status-code';

const choices = Object.entries(LABELS.TYPES).map(item => ({
  id: item[0],
  name: item[1]
}));

export default function ServiceRegionEdit(props) {
  const { id, permissions } = props;
  const canUpdate = permissionsFor(enums.resources.SERVICE_REGION)
    .hasRole(permissions)
    .forAction(enums.actions.UPDATE);
  const [errorRanges, setErrorRanges] = React.useState([]);
  const [errorApi, setErrorApi] = React.useState([]);
  const [errorCode, setErrorCode] = React.useState('');

  const notify = useNotify();

  const handleFailure = error => {
    const code = error?.body?.error?.code || 'DEFAULT';
    setErrorCode(code);
    if (error.status === STATUS_CODE.CONFLICT) {
      handleConflictErrorsMap[code](error, setErrorRanges, notify);
    } else {
      handleApiErrorsMap[code](error, setErrorApi, notify);
    }
  };

  const handleCloseDialog = () => {
    setErrorRanges([]);
    setErrorApi([]);
    setErrorCode('');
  };

  const { loading, data } = useQuery({
    type: 'getOne',
    resource: 'service-region',
    payload: { id }
  });

  const pageTitle = `${LABELS.PAGE_TITLE.TITLE} ${data?.serviceRegionInfo
    .name || ''}`;

  const transform = dataToBeSent => {
    // Removing parentServiceRegionName from serviceRegionInfo
    const { serviceRegionInfo } = dataToBeSent;
    const {
      parentServiceRegionName,
      ...restWithoutParentName
    } = serviceRegionInfo;

    return {
      ...dataToBeSent,
      serviceRegionInfo: restWithoutParentName
    };
  };

  return (
    <Edit
      undoable={false}
      {...props}
      title={pageTitle}
      transform={transform}
      onFailure={handleFailure}
    >
      <SimpleForm
        warnWhenUnsavedChanges
        variant="outlined"
        validate={validateServiceRegion}
        toolbar={
          <FormDataConsumer>
            {({ handleSubmit, formData, ...rest }) => {
              const postalCodeGroupInfo = getPostalCodeGroupInfo(formData);
              return (
                <SaveWithConfirmToolbar
                  {...rest}
                  handleSubmit={handleSubmit}
                  hasWritePermissions={canUpdate}
                  postalCodeGroupInfo={postalCodeGroupInfo}
                  changes={formData.changes}
                  loading={loading}
                />
              );
            }}
          </FormDataConsumer>
        }
      >
        <TextInput
          label={LABELS.TITLES.NAME}
          source="serviceRegionInfo.name"
          data-testid="service-region-name"
          disabled={!canUpdate}
          fullWidth
        />
        <SelectInput
          label={LABELS.TITLES.TYPE}
          source="serviceRegionInfo.serviceRegionType"
          choices={choices}
          disabled
          data-testid="service-region-type"
          fullWidth
        />
        <FormDataConsumer>
          {({ formData }) => (
            <PostalCodeGroupAutocomplete
              hasWritePermissions={canUpdate}
              postalCodeGroupIdFormPath={
                LABELS.FORM_DATA.SERVICE_REGION_ID_PATH
              }
              postalCodeGroupNameFormPath={
                LABELS.FORM_DATA.SERVICE_REGION_NAME_PATH
              }
              resourceName={enums.resources.SERVICE_REGION}
              required={!isTypeCrossdocking(formData.serviceRegionInfo)}
            />
          )}
        </FormDataConsumer>
        <CustomDivider />
        <Grid container direction="row" fullWidth>
          <Grid item xs={6} sm={6}>
            <FormDataConsumer>
              {({ formData }) => {
                return (
                  <ActivationToggle
                    hasWritePermissions={canUpdate}
                    active={formData.serviceRegionInfo.active}
                    source="serviceRegionInfo.active"
                  />
                );
              }}
            </FormDataConsumer>
          </Grid>
          <Grid item xs={6} sm={6}>
            <HoursMinutesInput
              formPath={LABELS.FORM_DATA.SERVICE_REGION_INITIAL_SLOT_PATH}
              disabled={!canUpdate}
              title={LABELS.TITLES.INITIAL_SLOT}
            />
            <HoursMinutesInput
              formPath={LABELS.FORM_DATA.SERVICE_REGION_MIN_COLLECT_WINDOW_PATH}
              disabled={!canUpdate}
              title={LABELS.TITLES.MIN_COLLECT_TIME_WINDOW}
            />
          </Grid>
        </Grid>
        <CustomDivider />
        <FormDataConsumer>
          {({ formData }) => {
            return (
              isTypeDropOff(formData.serviceRegionInfo) && (
                <>
                  <NumberInput
                    data-testid="drop-off-slo"
                    label={LABELS.TITLES.DROP_OFF_SLO_FIELD}
                    source="serviceRegionInfo.dropOffSlo"
                    disabled={!canUpdate}
                  />
                  <CustomDivider />
                </>
              )
            );
          }}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData }) => {
            const postalCodeGroupInfo = getPostalCodeGroupInfo(formData);
            return (
              <>
                <PostalCodesTable
                  postalCodeGroupInfo={postalCodeGroupInfo}
                  ranges={formData?.postalCodes?.ranges}
                  changes={formData.changes}
                  hasWritePermissions={canUpdate}
                  resource={enums.resources.SERVICE_REGION}
                  typeOfRanges={enums.rangeType.RANGE_TYPE_DEFAULT}
                />
                <DialogConflictRanges
                  conflicts={errorRanges}
                  postalCodeGroupName={postalCodeGroupInfo.name}
                  onClose={handleCloseDialog}
                  open={!!errorRanges?.length}
                />
                <DialogErrors
                  errors={errorApi}
                  errorCode={errorCode}
                  onClose={handleCloseDialog}
                  open={!!errorApi?.length}
                />
              </>
            );
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
}

ServiceRegionEdit.propTypes = {
  id: PropTypes.string.isRequired,
  permissions: PropTypes.arrayOf(PropTypes.string).isRequired
};
