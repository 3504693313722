import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { BooleanInput } from 'react-admin';
import LABELS from './constants';

const ActivationToggle = props => {
  const { hasWritePermissions, active, onChange, source } = props;
  const [activated, setActivated] = useState(active);
  const [toggleLabel, setToggleLabel] = useState('');

  const handleClick = event => {
    setActivated(event.target.checked);

    if (onChange) onChange();
  };

  useEffect(() => {
    setToggleLabel(activated ? LABELS.TITLES.ACTIVE : LABELS.TITLES.INACTIVE);
  }, [activated]);

  return (
    <BooleanInput
      data-testid="activation-toggle"
      label={toggleLabel}
      source={source}
      disabled={!hasWritePermissions}
      onClick={handleClick}
    />
  );
};

ActivationToggle.defaultProps = {
  hasWritePermissions: false,
  active: false,
  onChange: null
};

ActivationToggle.propTypes = {
  hasWritePermissions: PropTypes.bool,
  active: PropTypes.bool,
  onChange: PropTypes.func,
  source: PropTypes.bool.isRequired
};

export default ActivationToggle;
