const LABELS = {
  TITLES: {
    ORIGIN: 'Origem',
    CITY: 'Cidade',
    STATE: 'Estado',
    START: 'Início',
    END: 'Fim',
    PRODUCT: 'Produto',
    SLO: 'Slo'
  }
};

export default LABELS;
