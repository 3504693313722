import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import Typography from '@material-ui/core/Typography';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';

import { useStyles } from '../common-styles';
import { HEADERS, TEXTS } from './constants';
import CustomDialog from '../custom-dialog';

const DialogErrors = props => {
  const { errors, errorCode, open, onClose } = props;
  const classes = useStyles();

  return (
    <CustomDialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={onClose}
      title={TEXTS[errorCode]?.TITLE_ERROR}
    >
      <Typography
        variant="subtitle2"
        color="textSecondary"
        data-testid="descriptionField"
      >
        {TEXTS[errorCode]?.DIALOG_DESCRIPTION}
      </Typography>
      <br />
      <Box my={2}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow data-testid="table-header">
                {HEADERS[errorCode]?.map(header => (
                  <TableCell className={classes.tableHead}>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody
              data-testid="errors-table-body"
              className={classes.conflictTableBody}
            >
              <>
                <TableRow>
                  <TableCell component="th" scope="row">
                    {TEXTS[errorCode]?.LINK ? (
                      <Link
                        href={TEXTS[errorCode]?.LINK}
                        target="_blank"
                        rel="noopener"
                        underline="always"
                        className={classes.linkConflict}
                      >
                        {TEXTS[errorCode]?.ACTION}
                      </Link>
                    ) : (
                      <Typography className={classes.actionMessageAdd}>
                        {TEXTS[errorCode]?.ACTION}
                      </Typography>
                    )}
                  </TableCell>
                  {errors.length > 0 ? (
                    errors.map(error => (
                      <TableCell component="th" scope="row" key={error}>
                        <Typography>
                          <Box fontWeight="fontWeightBold">{error}</Box>
                        </Typography>
                      </TableCell>
                    ))
                  ) : (
                    <></>
                  )}
                </TableRow>
              </>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </CustomDialog>
  );
};

DialogErrors.defaultProps = {
  open: false,
  onClose: null
};

DialogErrors.propTypes = {
  errors: PropTypes.arrayOf().isRequired,
  errorCode: PropTypes.string.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func
};

export default DialogErrors;
