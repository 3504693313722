import React from 'react';
import PropTypes from 'prop-types';

import CheckIcon from '@material-ui/icons/Check';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Grid from '@material-ui/core/Grid';

import { LABELS } from './utils/constants';
import { useStyles } from './styles';

const CsvInputPlaceholder = props => {
  const classes = useStyles();
  const { isUploaded } = props;

  return (
    <Grid
      className={classes.inputPlaceHolder}
      container
      justify="center"
      alignItems="center"
      spacing={1}
    >
      {isUploaded ? (
        <CheckIcon fontSize="small" className={classes.uploadedIcon} />
      ) : (
        <CloudUploadIcon fontSize="small" className={classes.toUploadIcon} />
      )}
      <Grid item>
        <Typography
          className={isUploaded ? classes.greenColor : classes.uploadedButton}
          variant="body2"
        >
          <Box fontWeight="600">
            {isUploaded
              ? LABELS.BUTTONS.FILE_UPLOADED
              : LABELS.BUTTONS.CSV_BATCH}
          </Box>
        </Typography>
      </Grid>
    </Grid>
  );
};

CsvInputPlaceholder.defaultProps = {
  isUploaded: false
};

CsvInputPlaceholder.propTypes = {
  isUploaded: PropTypes.bool
};

export default CsvInputPlaceholder;
