import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'fast-deep-equal';
import { useForm } from 'react-final-form';
import AppBar from '@material-ui/core/AppBar';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import { Box } from '@material-ui/core';
import TrunkClosed from '../../../assets/icons/trunk-closed.svg';
import Sack from '../../../assets/icons/sack.svg';
import PostalCodesTable from '../postal-codes-table';
import TabPanel from './tab-panel';
import { useStyles } from './styles';
import LABELS from './constants';
import { a11yProps } from './utils';
import enums from '../../../enums';
import { filterRangesByType } from '../postal-codes-table/utils';

const PostalCodesTableTabs = props => {
  const {
    postalCodeGroupInfo,
    ranges,
    changes,
    hasWritePermissions,
    earningsInfoByRegions,
    canUpdateLocalSlo
  } = props;
  const form = useForm();
  const classes = useStyles(props);
  const [value, setValue] = useState(0);
  const [copyCheckboxValue, setCopyCheckboxValue] = useState(false);
  const [isEqualFromDB, setIsEqualFromDB] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const setCopyCheckboxChange = shouldAddChange => {
    const copyChange = { typeChange: enums.operations.COPY };
    const newChanges = shouldAddChange
      ? [...changes, copyChange]
      : changes.filter(change => change.typeChange !== enums.operations.COPY);

    form.change('changes', newChanges);
  };

  const handleCopyCheckboxChange = (event, newCheckBoxValue) => {
    const setCopyCheckboxChangeValue = !!newCheckBoxValue;

    setCopyCheckboxValue(newCheckBoxValue);
    form.change('copyCheckboxValue', newCheckBoxValue);
    if (!isEqualFromDB) {
      setCopyCheckboxChange(setCopyCheckboxChangeValue);
    }
  };

  const removeRangeType = range => ({ ...range, rangeType: '' });

  useEffect(() => {
    const areRangesEquals = () => {
      const deliveryRangesWithoutType = filterRangesByType(
        ranges,
        enums.rangeType.RANGE_TYPE_DELIVERY
      ).map(removeRangeType);
      const pickupRangesWithoutType = filterRangesByType(
        ranges,
        enums.rangeType.RANGE_TYPE_PICKUP
      ).map(removeRangeType);

      return isEqual(deliveryRangesWithoutType, pickupRangesWithoutType);
    };

    if (!isLoaded && ranges && areRangesEquals()) {
      setIsLoaded(true);
      setCopyCheckboxValue(true);
      setIsEqualFromDB(true);
      form.change('copyCheckboxValue', true);
    }
  }, [form, isLoaded, ranges]);

  return (
    <Box className={classes.root} data-testid="postal-code-table-tabs">
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          className={classes.tabBar}
          data-testid="PostalCodesTableTabs"
        >
          <Tab
            classes={{ wrapper: classes.wrapperTab }}
            icon={<LocalShippingIcon />}
            label={LABELS.TABS_TITLES.DELIVERY}
            {...a11yProps(0)}
          />
          <Tab
            classes={{ wrapper: classes.wrapperTab }}
            icon={<img src={TrunkClosed} alt={LABELS.ALT_ICON.TRUNK_CLOSED} />}
            label={LABELS.TABS_TITLES.PICKUP}
            data-testid="pickup-tab-button"
            disabled={copyCheckboxValue}
            {...a11yProps(1)}
          />
          <Tab
            classes={{ wrapper: classes.wrapperTab }}
            icon={<img src={Sack} alt={LABELS.ALT_ICON.SACK} />}
            label={LABELS.TABS_TITLES.UNPACKED_PICKUP}
            data-testid="unpacked-pickup-tab-button"
            disabled={copyCheckboxValue}
            {...a11yProps(2)}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <FormGroup row>
          <FormControlLabel
            control={<Checkbox />}
            label={LABELS.CHECKBOX.TITLE}
            onChange={handleCopyCheckboxChange}
            checked={copyCheckboxValue}
          />
        </FormGroup>
        <PostalCodesTable
          postalCodeGroupInfo={postalCodeGroupInfo}
          ranges={ranges}
          changes={changes}
          hasWritePermissions={hasWritePermissions}
          earningsInfoByRegions={earningsInfoByRegions}
          data-testid="delivery-postal-codes-table"
          typeOfRanges={enums.rangeType.RANGE_TYPE_DELIVERY}
          canUpdateLocalSlo={canUpdateLocalSlo}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <PostalCodesTable
          postalCodeGroupInfo={postalCodeGroupInfo}
          ranges={ranges}
          changes={changes}
          hasWritePermissions={hasWritePermissions}
          earningsInfoByRegions={earningsInfoByRegions}
          data-testid="pickup-postal-codes-table"
          typeOfRanges={enums.rangeType.RANGE_TYPE_PICKUP}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <PostalCodesTable
          postalCodeGroupInfo={postalCodeGroupInfo}
          ranges={ranges}
          changes={changes}
          hasWritePermissions={hasWritePermissions}
          earningsInfoByRegions={earningsInfoByRegions}
          data-testid="beyond-postal-codes-table"
          typeOfRanges={enums.rangeType.RANGE_TYPE_UNPACKED_PICKUP}
        />
      </TabPanel>
    </Box>
  );
};

PostalCodesTableTabs.defaultProps = {
  earningsInfoByRegions: [],
  changes: [],
  canUpdateLocalSlo: true
};

PostalCodesTableTabs.propTypes = {
  postalCodeGroupInfo: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.number,
    resourceName: PropTypes.string,
    groupType: PropTypes.string
  }).isRequired,
  ranges: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      start: PropTypes.shape({
        code: PropTypes.string
      }),
      end: PropTypes.shape({
        code: PropTypes.string
      }),
      lastMileCompanyInfo: PropTypes.shape({
        slo: PropTypes.number
      })
    })
  ).isRequired,
  changes: PropTypes.arrayOf(
    PropTypes.shape({
      typeChange: PropTypes.string,
      types: PropTypes.arrayOf(PropTypes.string),
      name: PropTypes.string,
      start: PropTypes.shape({
        code: PropTypes.string
      }),
      end: PropTypes.shape({
        code: PropTypes.string
      }),
      lastMileCompanyInfo: PropTypes.shape({
        slo: PropTypes.number
      })
    })
  ),
  hasWritePermissions: PropTypes.bool.isRequired,
  earningsInfoByRegions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string
    })
  ),
  canUpdateLocalSlo: PropTypes.bool
};

const postalCodesTablePropsAreEqual = (prevProps, nextProps) => {
  return (
    prevProps.postalCodeGroupInfo.groupType ===
      nextProps.postalCodeGroupInfo.groupType &&
    prevProps.postalCodeGroupInfo.active ===
      nextProps.postalCodeGroupInfo.active &&
    isEqual(prevProps.ranges, nextProps.ranges) &&
    isEqual(prevProps.changes, nextProps.changes) &&
    prevProps.hasWritePermissions === nextProps.hasWritePermissions &&
    isEqual(prevProps.earningsInfoByRegions, nextProps.earningsInfoByRegions)
  );
};

export default React.memo(PostalCodesTableTabs, postalCodesTablePropsAreEqual);
