import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  tableRow: {
    height: '72px'
  },
  labelCell: {
    display: 'flex',
    alignItems: 'center'
  },
  workingXdChip: {
    marginLeft: '7px',
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.light
  },
  exceptionChip: {
    marginLeft: '7px'
  },
  diffSloChip: {
    marginLeft: '7px',
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.dark
  },
  arrowIcon: {
    color: theme.palette.primary.dark
  },
  icon: {
    color: theme.palette.primary.light
  },
  circularProgress: {
    display: 'flex',
    justifyContent: 'center'
  }
}));

export default { useStyles };
