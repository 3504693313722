const LABELS = {
  VALIDATION_MESSAGES: {
    INVALID_CNPJ: 'Dígito verificador do CNPJ inválido',
    CNPJ_INVALID_LENGTH: 'O CNPJ deve ser nulo ou conter 14 dígitos',
    REQUIRED: 'Obrigatório'
  },
  CNPJ: 'CNPJ',
  CNPJ_DATA_TEST_ID: 'cnpj'
};

export default LABELS;
