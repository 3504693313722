import React from 'react';
import {
  SimpleForm,
  FileInput,
  FileField,
  Create,
  usePermissions
} from 'react-admin';
import { useHistory } from 'react-router-dom';
import { isAuthorizedWithWritePermissions } from '../../../../../auth-provider';
import LABELS from './labels';
import enums from '../../../../../enums';
import SimpleToolbar from '../../../../atoms/simple-toolbar';

export default function ImportRedispatchSlo(props) {
  const { loaded, permissions } = usePermissions();
  const history = useHistory();
  const authorized = isAuthorizedWithWritePermissions(permissions);

  if (loaded && !authorized) {
    history.push(`/${enums.resources.LAST_MILE_COMPANY}`);
  }

  const properties = {
    basePath: `/${enums.customResources.IMPORT_REDISPATCH_SLO}`,
    resource: enums.customResources.IMPORT_REDISPATCH_SLO,
    title: LABELS.TITLES.REDISPATCH_SLO_PAGE
  };
  const customProps = { ...props, ...properties };

  return (
    <Create undoable={false} {...customProps} redirect="/last-mile-company">
      <SimpleForm
        variant="outlined"
        redirect="/last-mile-company"
        toolbar={<SimpleToolbar />}
      >
        <FileInput
          source="action.attachment"
          label={LABELS.TITLES.REDISPATCH_SLO_UPLOAD_FIELD}
          accept=".csv"
        >
          <FileField source="action.src" title="title" />
        </FileInput>
      </SimpleForm>
    </Create>
  );
}
