import moment from 'moment';

const getBrazilMoment = (year, month, date) => {
  const newMoment = moment.utc();
  newMoment.utcOffset(-3);
  newMoment.year(year);
  newMoment.month(month);
  newMoment.date(date);
  newMoment.startOf('day');

  return newMoment;
};

export default getBrazilMoment;
