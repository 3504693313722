const LABELS = {
  BUTTONS: {
    BASE_CITY: 'cidade base',
    ACTIONS: {
      VIEW: 'Visualizar ',
      EDIT: 'Editar '
    }
  },
  TITLES: {
    POSTAL_CODES_RANGES: 'Faixas de CEP'
  }
};

export default LABELS;
