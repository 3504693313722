import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import isEqual from 'lodash/isEqual';
import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Confirm, useNotify } from 'react-admin';
import { useForm } from 'react-final-form';
import enums from '../../../enums';
import AddPostalCodeRange from '../add-postal-code-range';
import BatchRangesImport from '../batch-ranges-import';
import DialogShowChanges from '../dialog-show-changes/index';
import { LABELS, LABEL_ORDER_MAP, ORDER } from './constants';
import PostalCodeRow from './postal-code-row';
import {
  filterRangesByDifferentTypes,
  filterRangesByType,
  getRangeKey,
  hasRangeChanged,
  isLastMileCompanyLeve,
  isPostalCodeGroupLastMileCompany,
  removeRange,
  updateRange
} from './utils';
import { validateRange } from './validations';

const PostalCodesTable = props => {
  const {
    hasWritePermissions,
    postalCodeGroupInfo,
    ranges,
    changes,
    earningsInfoByRegions,
    typeOfRanges,
    showName,
    canUpdateLocalSlo
  } = props;
  const form = useForm();
  const notify = useNotify();
  const [deleting, setDeleting] = useState(null);
  const [openChangesDialog, setOpenChangesDialog] = useState(false);
  const [order, setOrder] = useState(ORDER.ASC);
  const [orderColumn, setOrderColumn] = useState(LABELS.TITLES.NAME);
  const [isCsvImport, setIsCsvImport] = useState(false);
  const [editingRangeKey, setEditingRangeKey] = useState(null);

  const isCreate = !!postalCodeGroupInfo.id;

  const CUSTOM_FILTERS = {
    [enums.rangeType.RANGE_TYPE_PICKUP]: filterRangesByType(
      ranges,
      enums.rangeType.RANGE_TYPE_PICKUP
    ),
    [enums.rangeType.RANGE_TYPE_UNPACKED_PICKUP]: filterRangesByType(
      ranges,
      enums.rangeType.RANGE_TYPE_UNPACKED_PICKUP
    ),
    [enums.rangeType.RANGE_TYPE_DELIVERY]: filterRangesByDifferentTypes(
      ranges,
      [
        enums.rangeType.RANGE_TYPE_PICKUP,
        enums.rangeType.RANGE_TYPE_UNPACKED_PICKUP
      ]
    ),
    [enums.rangeType.RANGE_TYPE_DEFAULT]: ranges
  };
  const filteredRangesByType = CUSTOM_FILTERS[typeOfRanges];

  const handleRequestSort = column => {
    if ([LABELS.TITLES.TYPE, LABELS.TITLES.SLO].includes(column)) return;
    const orderingSameColumn = orderColumn === column;
    const newOrder =
      orderingSameColumn && order === ORDER.ASC ? ORDER.DESC : ORDER.ASC;

    const filteredRanges = orderBy(
      ranges,
      [LABEL_ORDER_MAP[column]],
      [newOrder]
    );
    form.change('postalCodes.ranges', filteredRanges);

    setOrder(newOrder);
    setOrderColumn(column);
  };

  const handleDelete = () => {
    const newChange = { ...deleting, typeChange: LABELS.MODALS.DELETE };

    const filteredRanges = removeRange(ranges, newChange);

    form.change('postalCodes.ranges', filteredRanges);
    setDeleting(null);
    form.change('changes', [...changes, newChange]);
  };

  const handleOpenChangesDialog = (openDialog, isCsvFlow) => {
    setOpenChangesDialog(openDialog);
    setIsCsvImport(isCsvFlow);
  };

  const setChangesAndForm = (newChanges, postalCodes) => {
    form.change('changes', [...changes, ...newChanges]);
    handleOpenChangesDialog(true, true);
    form.change('postalCodes.ranges', postalCodes.ranges || []);
  };

  const handleConfirmEditRange = (editedRange, typeChange) => {
    try {
      const options = {
        postalCodeGroupInfo,
        editingRangeKey
      };
      validateRange(editedRange, ranges, options);
    } catch (error) {
      notify(error.message, 'warning');
      return;
    }

    const hasChanges = hasRangeChanged(editedRange, ranges);
    if (!hasChanges) {
      setEditingRangeKey(null);
      return;
    }

    const newChange = { ...editedRange, typeChange };

    form.change(
      'postalCodes.ranges',
      updateRange(editedRange, ranges, editingRangeKey)
    );

    setEditingRangeKey(null);
    form.change('changes', [...changes, newChange]);
  };

  const handleConfirmDialogShowChanges = isCsvImport
    ? () => handleOpenChangesDialog(false)
    : null;

  return (
    <>
      {isCreate && hasWritePermissions && (
        <Grid item xs={6}>
          <BatchRangesImport
            postalCodeGroupInfo={postalCodeGroupInfo}
            setChangesAndForm={setChangesAndForm}
            typeOfRanges={typeOfRanges}
          />
        </Grid>
      )}
      <AddPostalCodeRange
        onAdd={newRanges =>
          form.change('postalCodes.ranges', [...ranges, ...newRanges])
        }
        addChange={newChanges => {
          form.change('changes', [...changes, ...newChanges]);
        }}
        ranges={ranges}
        hasWritePermissions={hasWritePermissions}
        postalCodeGroupInfo={postalCodeGroupInfo}
        typeOfRanges={typeOfRanges}
        showName={showName}
      />
      <TableContainer component={Paper} data-testid="postal-code-table">
        <Table>
          <TableHead>
            <TableRow data-testid="postalCodesTableTitle">
              {Object.keys(LABEL_ORDER_MAP).map(cellTitle => {
                if (!showName && cellTitle === LABELS.TITLES.NAME) return null;

                const cellLabel = LABEL_ORDER_MAP[cellTitle];

                return (
                  <TableCell
                    key={cellLabel}
                    sortDirection={orderColumn === cellTitle ? order : false}
                  >
                    <TableSortLabel
                      active={orderColumn === cellTitle}
                      direction={orderColumn === cellTitle ? order : ORDER.ASC}
                      onClick={() => handleRequestSort(cellTitle)}
                    >
                      {cellTitle}
                    </TableSortLabel>
                  </TableCell>
                );
              })}
              {isPostalCodeGroupLastMileCompany(postalCodeGroupInfo) && (
                <>
                  {isLastMileCompanyLeve(postalCodeGroupInfo) && (
                    <TableCell data-testid="postal-code-table-field-earning-label">
                      {LABELS.TITLES.EARNING_LABEL}
                    </TableCell>
                  )}
                  <TableCell data-testid="postal-code-table-field-slo">
                    {LABELS.TITLES.SLO}
                  </TableCell>
                </>
              )}
              <TableCell>
                {isCreate && (
                  <Button
                    color="primary"
                    variant="outlined"
                    data-testid="show-changes-button"
                    onClick={() => handleOpenChangesDialog(true, false)}
                    disabled={!hasWritePermissions}
                  >
                    {LABELS.BUTTONS.CHANGES}
                  </Button>
                )}
                <DialogShowChanges
                  open={openChangesDialog}
                  handleOpenDialog={handleOpenChangesDialog}
                  changes={changes}
                  postalCodeGroupInfo={postalCodeGroupInfo}
                  isCsvImport={isCsvImport}
                  onConfirm={handleConfirmDialogShowChanges}
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody data-testid="postalCodesTableBody">
            {filteredRangesByType.map(range => {
              const rangeKey = getRangeKey(range);
              return (
                <PostalCodeRow
                  range={range}
                  key={rangeKey}
                  onDelete={() => setDeleting(range)}
                  hasWritePermissions={hasWritePermissions}
                  postalCodeGroupInfo={postalCodeGroupInfo}
                  isEditing={editingRangeKey === rangeKey}
                  startEditing={() => setEditingRangeKey(rangeKey)}
                  stopEditing={() => setEditingRangeKey(null)}
                  onConfirmEdit={handleConfirmEditRange}
                  earningsInfoByRegions={earningsInfoByRegions}
                  typeOfRanges={typeOfRanges}
                  showName={showName}
                  canUpdateLocalSlo={canUpdateLocalSlo}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Confirm
        isOpen={!!deleting}
        title={deleting?.name || ''}
        content={LABELS.MODALS.DELETE_CONFIRMATION}
        onConfirm={handleDelete}
        onClose={() => setDeleting(null)}
      />
    </>
  );
};

PostalCodesTable.defaultProps = {
  ranges: [],
  changes: [],
  earningsInfoByRegions: [],
  typeOfRanges: enums.rangeType.RANGE_TYPE_DEFAULT,
  showName: true,
  postalCodeGroupInfo: {},
  canUpdateLocalSlo: true
};

PostalCodesTable.propTypes = {
  postalCodeGroupInfo: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.number,
    resourceName: PropTypes.string,
    groupType: PropTypes.string,
    active: PropTypes.bool
  }),
  changes: PropTypes.arrayOf(
    PropTypes.shape({
      typeChange: PropTypes.string,
      types: PropTypes.arrayOf(PropTypes.string),
      name: PropTypes.string,
      start: PropTypes.shape({
        code: PropTypes.string
      }),
      end: PropTypes.shape({
        code: PropTypes.string
      }),
      lastMileCompanyInfo: PropTypes.shape({
        slo: PropTypes.number
      }),
      rangeType: PropTypes.string
    })
  ),
  ranges: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      start: PropTypes.shape({
        code: PropTypes.string
      }).isRequired,
      end: PropTypes.shape({
        code: PropTypes.string
      }).isRequired,
      lastMileCompanyInfo: PropTypes.shape({
        slo: PropTypes.number
      }),
      rangeType: PropTypes.string
    })
  ),
  hasWritePermissions: PropTypes.bool.isRequired,
  earningsInfoByRegions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string
    })
  ),
  typeOfRanges: PropTypes.string,
  showName: PropTypes.bool,
  canUpdateLocalSlo: PropTypes.bool
};

const rangePropsAreEqual = (prevProps, nextProps) => {
  return (
    prevProps.postalCodeGroupInfo.groupType ===
      nextProps.postalCodeGroupInfo.groupType &&
    prevProps.postalCodeGroupInfo.active ===
      nextProps.postalCodeGroupInfo.active &&
    isEqual(prevProps.ranges, nextProps.ranges) &&
    isEqual(prevProps.changes, nextProps.changes) &&
    prevProps.hasWritePermissions === nextProps.hasWritePermissions &&
    isEqual(prevProps.earningsInfoByRegions, nextProps.earningsInfoByRegions)
  );
};

export default React.memo(PostalCodesTable, rangePropsAreEqual);
