const LABELS = {
  PAGE_TITLE: {
    TITLE: 'Transportadora:',
    OPEN_AT: 'Hora inicial',
    CLOSE_AT: 'Hora final'
  },
  TITLES: {
    NAME: 'Nome',
    TYPE: 'Tipo',
    ACTIVE: 'Ativo',
    ACTIVATION_DATE: 'Data de ativação',
    LIST_TITLE: 'Listar Transportadoras',
    PICKUP_TIME: 'Informe o horário para atendimento de coleta',
    WORKING_WEEKDAYS: 'Dias da semana para recebimento de pacotes',
    CORPORATE_NAME: 'Razão Social',
    DROP_OFF_TRANSFER_VALUE: 'Valor de repasse para Drop Off'
  },
  TYPES: {
    LAST_MILE_COMPANY_TYPE_UNKNOWN: 'Desconhecido',
    LAST_MILE_COMPANY_TYPE_LOGGI: 'Loggi',
    LAST_MILE_COMPANY_TYPE_LEVE: 'Leve',
    LAST_MILE_COMPANY_TYPE_CORREIOS_NACIONAL: 'Correios Nacional',
    LAST_MILE_COMPANY_TYPE_CORREIOS_LOCAL: 'Correios Local',
    LAST_MILE_COMPANY_TYPE_CARRIER: 'Transportadora',
    LAST_MILE_COMPANY_TYPE_NO_COVERAGE: 'Parceiro'
  },
  ERRORS: {
    COVERAGE_ERROR: 'Erro de abrangência',
    SERVICE_TYPE_NOT_FOUND:
      'Os seguintes ranges de CEP não possuem um tipo de serviço associado',
    SERVICE_REGION_NOT_FOUND:
      'Os seguintes ranges de CEP não possuem uma região de serviço associada'
  }
};

export default LABELS;
