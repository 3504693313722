const LABELS = {
  ALERTS: {
    AUTHENTICATING: 'Autenticando...',
    AUTHENTICATION_FAILURE: 'Não foi possível autenticar',
    SUCESS: 'Sucesso',
    CONECTION_ERROR: 'Não foi possível conectar',
    CREATE_DC_ERROR: 'Erro ao criar base',
    WAITING: 'Aguarde',
    INVALID_INPUT_REANNOTATION:
      'Entrada inválida. Tempo total tem que ser maior que 0.',
    INVALID_POSTAL_CODE_GROUP_INFO: 'Erro interno na criação do documento'
  }
};

export default LABELS;
