export const OPERATIONS = {
  JSON_PATCH: {
    OPERATION_INVALID: {
      LABEL: 'OPERATION_INVALID',
      TEXT: 'Abrangência inválida'
    },
    OPERATION_ADD: {
      LABEL: 'OPERATION_ADD',
      TEXT: 'Abrangência adicionada'
    },
    OPERATION_REMOVE: {
      LABEL: 'OPERATION_REMOVE',
      TEXT: 'Abrangência excluída'
    },
    OPERATION_REPLACE: {
      LABEL: 'OPERATION_REPLACE',
      TEXT: 'Abrangência atualizada'
    }
  },
  CSV: {
    ADD: 'OPERATION_ADD',
    REMOVE: 'OPERATION_REMOVE',
    REPLACE: 'OPERATION_REPLACE'
  }
};

export const CSV_HEADERS = {
  OPERATION: 'operation',
  RANGE_NAME: 'range_name',
  RANGES: 'ranges',
  LOCAL_SLO: 'local_slo',
  LABEL: 'label'
};
export const LABELS = {
  BUTTONS: {
    CSV_BATCH: 'Operações em lote',
    FILE_UPLOADED: 'Upload concluído'
  },
  ALERT: {
    MISSING_FIELD: 'Verifique se todas as colunas do CSV estão corretas.'
  }
};

export const POSTAL_CODE_TYPES = {
  EXCLUSIVE: [
    'POSTAL_CODE_TYPE_ADDRESS',
    'POSTAL_CODE_TYPE_LOCALITY',
    'POSTAL_CODE_TYPE_HUGE_USER',
    'POSTAL_CODE_TYPE_COMMUNITY_MAILBOX',
    'POSTAL_CODE_TYPE_OPERATIONAL_UNITY'
  ],
  NOT_EXCLUSIVE: [
    'POSTAL_CODE_TYPE_ADDRESS',
    'POSTAL_CODE_TYPE_LOCALITY',
    'POSTAL_CODE_TYPE_HUGE_USER'
  ]
};

export const POSTAL_CODE_REGEX = {
  POSTAL_CODE: /\d{8}/gm,
  POSTAL_CODES_TUPLE: /\(\s*\d{8},\s*\d{8}\)/gm
};

export const CSV_PARSE = {
  ERRORS_CODE: [
    'CSV_INVALID_CLOSING_QUOTE',
    'CSV_INCONSISTENT_RECORD_LENGTH',
    'CSV_RECORD_DONT_MATCH_COLUMNS_LENGTH',
    'CSV_INVALID_COLUMN_MAPPING',
    'CSV_MAX_RECORD_SIZE',
    'CSV_NON_TRIMABLE_CHAR_AFTER_CLOSING_QUOTE',
    'CSV_QUOTE_NOT_CLOSED'
  ],
  CONFIGS: {
    trim: true,
    delimiter: ',',
    columns: ['operation', 'range_name', 'ranges', 'local_slo', 'label']
  }
};

export const DIALOG_CONTENT = {
  TITLE: 'Oops!',
  READ_ERROR: 'Houve um erro na leitura da planilha.',
  REVIEW_SHEET: 'Revise a formatação e as informações e tente novamente.',
  SHEET_INSTRUCTIONS:
    'Para instruções de uso da planilha, faça o download do nosso modelo ',
  HERE: 'aqui',
  SHEET_MODEL_LINK:
    'https://docs.google.com/spreadsheets/d/1bIQLB9fHgifm6Z8CNMde6AtT0mfI_fn3Lhj-htFCOL0/edit?usp=sharing'
};

export const CONTENT_NOT_MODIFIED = {
  TITLE: 'Oops!',
  READ_ERROR:
    'De acordo com as informações da planilha, não há modificações a serem feitas neste documento.',
  REVIEW_SHEET: 'Verifique se as informações estão corretas.'
};
