import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@loggi/mar';

export const useStyles = makeStyles({
  tableHead: {
    backgroundColor: colors.smoke[100]
  },
  tableRow: {
    backgroundColor: colors.root[0],
    '&:nth-child(even)': {
      backgroundColor: colors.smoke[50]
    }
  },
  actionMessagesDelete: {
    color: colors.red[700]
  },
  actionMessagesAdd: {
    color: colors.green[700]
  },
  actionMessagesUpdate: {
    color: colors.blue[500]
  }
});

export default {
  useStyles
};
