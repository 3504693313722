import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { getTomorrowTimestamp } from '../../../utils/format-time';
import { useStyles } from './styles';
import { LABELS } from './labels';

const tomorrow = getTomorrowTimestamp();

const DialogScheduleChanges = props => {
  const classes = useStyles();
  const { open, toggleScheduleDialog, onSubmit, date, changeDate } = props;

  const closeDialog = () => {
    toggleScheduleDialog();
  };

  const handleSubmit = async () => {
    toggleScheduleDialog();
    onSubmit();
  };

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      aria-labelledby="alert-dialog-title"
      data-testid="schedule-changes-dialog"
      maxWidth="sm"
    >
      <DialogTitle id="alert-dialog-title">
        <Typography align="center" className={classes.dialogTitle}>
          {LABELS.DIALOG_TITLE}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                autoOk
                disableToolbar
                orientation="landscape"
                variant="static"
                openTo="date"
                minDate={tomorrow}
                value={date}
                onChange={changeDate}
                className={classes.datePicker}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item>
            <DialogActions>
              <Button
                onClick={toggleScheduleDialog}
                color="primary"
                data-testid="schedule-changes-close-button"
              >
                {LABELS.CLOSE_BUTTON}
              </Button>
              <Button
                variant="contained"
                onClick={handleSubmit}
                color="primary"
                data-testid="schedule-changes-save-button"
              >
                {LABELS.SUBMIT_BUTTON}
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

DialogScheduleChanges.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleScheduleDialog: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  date: PropTypes.instanceOf(moment).isRequired,
  changeDate: PropTypes.func.isRequired
};

export default DialogScheduleChanges;
