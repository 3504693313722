import React from 'react';
import PropTypes from 'prop-types';
import {
  Create,
  TextInput,
  SimpleForm,
  SelectInput,
  FormDataConsumer,
  useNotify
} from 'react-admin';
import PostalCodesTable from '../../../molecules/postal-codes-table';
import LABELS from './labels';
import enums from '../../../../enums';
import { REGION_CHOICES } from './constants';
import DialogConflictRanges from '../../../molecules/dialog-conflict-ranges';
import { validations } from './validations';
import { handleConflictRangeOverlap } from '../../../../utils/handle-conflicts';
import SimpleToolbar from '../../../atoms/simple-toolbar';
import SectionTitle from '../../../atoms/section-title/section-title';
import permissionsFor from '../../../../access/access';
import LatLng from '../../../molecules/lat-lng-input';

const transform = dataToBeSent => {
  return {
    name: dataToBeSent.name,
    description: dataToBeSent.description,
    type: enums.regionTypes[dataToBeSent.type],
    ranges: dataToBeSent.postalCodes.ranges.map(postalCodeRanges => {
      return {
        start: postalCodeRanges.start.code,
        end: postalCodeRanges.end.code
      };
    })
  };
};

export default function RegionCreate(props) {
  const { permissions } = props;
  const [errorRanges, setErrorRanges] = React.useState([]);
  const canCreate = permissionsFor(enums.resources.REGION)
    .hasRole(permissions)
    .forAction(enums.actions.CREATE);
  const notify = useNotify();

  const handleFailure = error =>
    handleConflictRangeOverlap(error, setErrorRanges, notify);

  const handleCloseDialog = () => {
    setErrorRanges([]);
  };

  return (
    <Create
      redirect="list"
      undoable={false}
      onFailure={handleFailure}
      {...props}
      transform={transform}
    >
      <SimpleForm
        warnWhenUnsavedChanges
        variant="outlined"
        redirect="list"
        toolbar={<SimpleToolbar />}
      >
        <TextInput
          label={LABELS.TITLES.NAME}
          source="name"
          data-testid="region-name"
          validate={validations.name}
          fullWidth
        />
        <TextInput
          label={LABELS.TITLES.DESCRIPTION}
          source="description"
          data-testid="region-description"
          validate={validations.description}
          fullWidth
        />
        <SelectInput
          label={LABELS.TITLES.TYPE}
          source="type"
          choices={REGION_CHOICES}
          data-testid="region-type"
          validate={validations.type}
          fullWidth
        />
        <LatLng
          latSource="reference_point.latitude"
          lngSource="reference_point.longitude"
        />
        <SectionTitle title={LABELS.TITLES.POSTAL_CODES_RANGES} />
        <FormDataConsumer>
          {({ formData }) => {
            return (
              <>
                <PostalCodesTable
                  ranges={formData?.postalCodes?.ranges}
                  hasWritePermissions={canCreate}
                  typeOfRanges={enums.rangeType.RANGE_TYPE_DEFAULT}
                  showName={false}
                />
                <DialogConflictRanges
                  conflicts={errorRanges}
                  postalCodeGroupName={formData.name}
                  onClose={handleCloseDialog}
                  open={!!errorRanges?.length}
                />
              </>
            );
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
}

RegionCreate.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string).isRequired
};
