import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  goBackButton: {
    border: '1px solid #00BAFF',
    borderRadius: '50%',
    padding: 12.42,
    marginBottom: 8,
    minWidth: 40,
    maxWidth: 40,
    height: 40
  }
});

export default {
  useStyles
};
