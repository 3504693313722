import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { FileField, FileInput } from 'react-admin';
import { useForm } from 'react-final-form';

import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import STATUS_CODE from '../../../api/status-code';
import Api from '../../../api/postal-code-api';
import { parseCsvContentToJsonPatch, jsonPatchToChanges } from './utils';
import { useStyles } from './styles';
import { TEXTS, URLS } from './constants';
import CsvInputPlaceholder from './csv-input-placeholder';
import CustomDialog from '../custom-dialog';
import { DIALOG_CONTENT, CONTENT_NOT_MODIFIED } from './utils/constants';
import ContentInputError from './content-input-error';
import ContentNotModifiedError from './content-not-modified-error';
import DialogConflictRanges from '../dialog-conflict-ranges';
import enums from '../../../enums';

const BatchRangesImport = props => {
  const { setChangesAndForm, postalCodeGroupInfo, typeOfRanges } = props;
  const classes = useStyles();
  const { change, getFieldState } = useForm();
  const [loadingCsv, setLoadingCsv] = useState(false);
  const [hasInputError, setHasInputError] = useState(false);
  const [contentNotModified, setContentNotModified] = useState(false);
  const [errorRanges, setErrorRanges] = useState([]);
  const csvField = getFieldState('csvAttachment');
  const isUploaded = !!csvField?.value;

  const resetInput = () => {
    change('csvAttachment', null);
    setLoadingCsv(false);
  };

  const resetInputAndDisable = () => {
    change('csvAttachment', []);
    setLoadingCsv(false);
  };

  const handleCsvInput = async csvFile => {
    setLoadingCsv(true);
    const csvContent = await csvFile.text();

    let responseJsonPatch;
    try {
      const requestOperations = await parseCsvContentToJsonPatch(
        csvContent,
        postalCodeGroupInfo,
        typeOfRanges
      );

      responseJsonPatch = await Api.patch(postalCodeGroupInfo.resourceName, {
        id: postalCodeGroupInfo.id,
        persist: false,
        data: { operations: requestOperations }
      });
    } catch (error) {
      resetInput();

      if (error?.status === STATUS_CODE.NOT_MODIFIED) {
        setContentNotModified(true);
        return;
      }

      if (error?.status === STATUS_CODE.CONFLICT) {
        const conflicts = error?.body?.error?.rangeOverlapConflict?.conflicts;

        if (conflicts) {
          setErrorRanges(conflicts);
          return;
        }
      }

      setHasInputError(true);
      return;
    }

    const {
      data: {
        operations,
        postalCodeGroup: { postalCodes }
      }
    } = responseJsonPatch;

    const newChanges = jsonPatchToChanges(operations);
    resetInputAndDisable();
    setChangesAndForm(newChanges, postalCodes);
  };

  const handleCloseDialog = () => {
    setErrorRanges([]);
    setHasInputError(false);
    setContentNotModified(false);
  };

  return (
    <>
      <Grid container direction="row" justify="flex-start" alignItems="center">
        <Grid item>
          <FileInput
            source="csvAttachment"
            label=""
            accept=".csv"
            classes={{ dropZone: classes.dropZone }}
            placeholder={<CsvInputPlaceholder isUploaded={isUploaded} />}
            onChange={handleCsvInput}
            options={{ disabled: isUploaded, noClick: isUploaded }}
          >
            <FileField source="csvImport.src" title="title" />
          </FileInput>
        </Grid>
        {!isUploaded && (
          <Grid item>
            <Tooltip
              interactive
              title={
                <Box>
                  {TEXTS.START_INFO}
                  <Link
                    className={classes.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={URLS.SPREADSHEET}
                  >
                    aqui.
                  </Link>
                  {TEXTS.END_INFO}
                </Box>
              }
              arrow
              placement="right"
              leaveDelay={350}
              classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
            >
              <IconButton className={classes.infoButton}>
                <InfoOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        )}
      </Grid>
      <Backdrop
        data-testid="postal-code-table-loading"
        className={classes.backdrop}
        open={loadingCsv}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <CustomDialog
        open={hasInputError}
        onClose={handleCloseDialog}
        title={DIALOG_CONTENT.TITLE}
      >
        <ContentInputError />
      </CustomDialog>
      <CustomDialog
        open={contentNotModified}
        onClose={handleCloseDialog}
        title={CONTENT_NOT_MODIFIED.TITLE}
      >
        <ContentNotModifiedError />
      </CustomDialog>
      <DialogConflictRanges
        conflicts={errorRanges}
        postalCodeGroupName={postalCodeGroupInfo.name}
        onClose={handleCloseDialog}
        open={!!errorRanges?.length}
      />
    </>
  );
};

BatchRangesImport.defaultProps = {
  typeOfRanges: enums.rangeType.RANGE_TYPE_DEFAULT
};

BatchRangesImport.propTypes = {
  setChangesAndForm: PropTypes.func.isRequired,
  postalCodeGroupInfo: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.number,
    resourceName: PropTypes.string,
    groupType: PropTypes.string,
    active: PropTypes.bool,
    name: PropTypes.string
  }).isRequired,
  typeOfRanges: PropTypes.string
};

export default BatchRangesImport;
