const LABELS = {
  BUTTONS: {
    CLOSE: 'Fechar',
    CANCEL_CHANGES: 'Cancelar alterações',
    CONFIRM: 'Confirmar alterações'
  },
  TITLES: {
    MODAL_TITLE: {
      MULTIPLE_CHANGES: 'Edição de múltiplo itens',
      SIMPLE_CHANGES: 'Alterações realizadas'
    },
    NAME: 'Nome',
    START: 'CEP Inicial',
    END: 'CEP Final',
    SLO: 'SLO Local',
    TYPE: 'Tipo',
    ACTION: 'Ação',
    EARNING_LABEL: 'Faixa de preço',
    CONFIRM_CANCEL_CHANGES: 'Cancelar as alterações',
    EARNING_NOT_SELECT: 'Não selecionada',
    COPY_COVERAGE: 'Abrangência de entrega copiada para coleta'
  },
  MODALS: {
    NO_CHANGES: 'Nenhuma alteração realizada',
    DELETE: 'Deletar',
    CONFIRM_CANCEL_CHANGES:
      'Você tem certeza que deseja cancelar as alterações?',
    CONFIRM_CANCEL_CHANGES_SAVE:
      'Nenhuma informação será salva. Tem certeza que deseja cancelar as alterações?'
  }
};

export default LABELS;
