import React from 'react';
import PropTypes from 'prop-types';
import { TextInput, SelectInput } from 'react-admin';
import LABELS from './labels';
import validateServiceTypeInputs from '../../validators';
import enums from '../../../enums';
import CityTypeInput from '../../molecules/city-type-input';
import PostalCodeGroupAutocomplete from '../../molecules/postal-code-group-autocomplete';

export default function ServiceTypeInfo({
  hasPermissions,
  serviceTypeTypes,
  isEditPage,
  isNearCity
}) {
  return (
    <>
      <TextInput
        label={LABELS.TITLES.NAME}
        source="serviceTypeInfo.name"
        data-testid="service-type-name"
        disabled={isEditPage && !hasPermissions}
        validate={validateServiceTypeInputs.name}
        variant="outlined"
        fullWidth
      />

      <PostalCodeGroupAutocomplete
        dataTestId="service-type-city-region"
        hasWritePermissions={hasPermissions}
        postalCodeGroupIdFormPath={LABELS.FORM_DATA.CITY_REGION_ID_PATH}
        postalCodeGroupNameFormPath={LABELS.FORM_DATA.CITY_REGION_NAME_PATH}
        resourceName={enums.resources.REGION}
        required
      />

      <SelectInput
        label={LABELS.TITLES.TYPE}
        source="serviceTypeInfo.serviceType"
        choices={serviceTypeTypes}
        disabled={isEditPage}
        data-testid="service-type-type"
        variant="outlined"
        validate={validateServiceTypeInputs.type}
        fullWidth
      />

      <PostalCodeGroupAutocomplete
        dataTestId="service-type-service-region"
        hasWritePermissions={isEditPage ? hasPermissions && !isNearCity : true}
        postalCodeGroupIdFormPath={LABELS.FORM_DATA.SERVICE_REGION_ID_PATH}
        postalCodeGroupNameFormPath={LABELS.FORM_DATA.SERVICE_REGION_NAME_PATH}
        resourceName={enums.resources.SERVICE_REGION}
        required
      />

      <CityTypeInput hasWritePermissions={hasPermissions} variant="outlined" />
    </>
  );
}

ServiceTypeInfo.propTypes = {
  hasPermissions: PropTypes.bool.isRequired,
  serviceTypeTypes: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string))
    .isRequired,
  isEditPage: PropTypes.bool.isRequired,
  isNearCity: PropTypes.bool.isRequired
};
