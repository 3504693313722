import React from 'react';
import Alert from '@material-ui/lab/Alert';

import {
  Create,
  TextInput,
  SimpleForm,
  SelectInput,
  required,
  minLength,
  FileInput,
  FileField
} from 'react-admin';
import LABELS from './labels';
import SimpleToolbar from '../../../atoms/simple-toolbar';

const choices = Object.entries(LABELS.TYPES)
  .filter(item => item[0] !== 'PRICING_COMPONENT_INVALID')
  .map(item => ({
    id: item[0],
    name: item[1]
  }));

export default function PricingRegionCreate(props) {
  return (
    <Create undoable={false} redirect="list" {...props}>
      <SimpleForm
        variant="outlined"
        redirect="list"
        toolbar={<SimpleToolbar />}
      >
        <TextInput
          label={LABELS.TITLES.NAME}
          source="pricingRegionInfo.regionName"
          validate={[required(), minLength(1)]}
          fullWidth
        />
        <SelectInput
          label={LABELS.TITLES.TYPE}
          source="pricingRegionInfo.pricingComponent"
          choices={choices}
          validate={[required()]}
          fullWidth
        />
        <TextInput
          label={LABELS.TITLES.CONFIGURATION_ID}
          source="pricingRegionInfo.configurationId"
          validate={[required(), minLength(1)]}
          fullWidth
        />
        <Alert data-testid="alert-advice-upload" severity="info" fullWidth>
          {LABELS.TITLES.ADVICE_UPLOAD}
          <FileInput
            source="pricingRegionInfo.attachment"
            label={LABELS.TITLES.UPLOAD}
            accept=".csv"
          >
            <FileField source="pricingRegionInfo.src" title="title" />
          </FileInput>
        </Alert>
      </SimpleForm>
    </Create>
  );
}
