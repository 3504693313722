import React, { createElement } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { usePermissions, MenuItemLink, getResources } from 'react-admin';
import { withRouter } from 'react-router-dom';
import SettingsIcon from '@material-ui/icons/Settings';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import AddBoxIcon from '@material-ui/icons/AddBox';
import GridOnIcon from '@material-ui/icons/GridOn';
import PublishIcon from '@material-ui/icons/Publish';
import { makeStyles } from '@material-ui/core/styles';
import enums from '../../enums';
import { ReactComponent as CoverageSpreadsheetIcon } from '../../assets/icons/icon-coverage-spreadsheet.svg';
import LABELS from './constants';
import permissionsFor from '../../access/access';

const useStyles = makeStyles(() => ({
  multipleLines: {
    whiteSpace: 'pre-wrap'
  }
}));

const CustomMenu = props => {
  const { resources, onMenuClick } = props;
  const { permissions } = usePermissions();
  const hasWritePermissions =
    permissions && permissions.includes(enums.roles.ADMIN);

  const hasGenerateCoverageSpreadsheetPermission = permissionsFor(
    enums.customResources.GENERATE_COVERAGE_SPREADSHEET
  )
    .hasRole(permissions)
    .forAction(enums.actions.CREATE);

  const hasPublishCoveragePermission = permissionsFor(
    enums.customResources.PUBLISH_COVERAGE
  )
    .hasRole(permissions)
    .forAction(enums.actions.CREATE);

  const hasCreateBasePermission = permissionsFor(
    enums.customResources.CREATE_BASE
  )
    .hasRole(permissions)
    .forAction(enums.actions.CREATE);

  const hasCreateDeliveryEarningsTableWritePermissions = permissionsFor(
    enums.customResources.CREATE_DELIVERY_EARNINGS_TABLE
  )
    .hasRole(permissions)
    .forAction(enums.actions.CREATE);

  const classes = useStyles(props);

  return (
    <div>
      {resources.map(resource => (
        <MenuItemLink
          data-testid={resource.name}
          key={resource.name}
          to={`/${resource.name}`}
          primaryText={
            (resource.options && resource.options.label) || resource.name
          }
          leftIcon={
            resource.icon ? createElement(resource.icon) : <SettingsIcon />
          }
          onClick={onMenuClick}
        />
      ))}
      {hasGenerateCoverageSpreadsheetPermission && (
        <MenuItemLink
          data-testid={enums.customResources.GENERATE_COVERAGE_SPREADSHEET}
          to={`/${enums.customResources.GENERATE_COVERAGE_SPREADSHEET}`}
          primaryText={LABELS.MENU_TITLE.GENERATE_COVERAGE_SPREADSHEET}
          leftIcon={<CoverageSpreadsheetIcon />}
        />
      )}
      {hasPublishCoveragePermission && (
        <MenuItemLink
          data-testid={enums.customResources.PUBLISH_COVERAGE}
          to={`/${enums.customResources.PUBLISH_COVERAGE}`}
          primaryText={LABELS.MENU_TITLE.PUBLISH_COVERAGE}
          leftIcon={<PublishIcon />}
        />
      )}
      {hasWritePermissions && (
        <MenuItemLink
          data-testid={enums.customResources.IMPORT_REDISPATCH_SLO}
          to={`/${enums.customResources.IMPORT_REDISPATCH_SLO}`}
          primaryText={LABELS.MENU_TITLE.IMPORT_REDISPATCH_SLO}
          leftIcon={<InsertDriveFileIcon />}
        />
      )}
      {hasCreateBasePermission && (
        <MenuItemLink
          data-testid={enums.customResources.CREATE_BASE}
          to={`/${enums.customResources.CREATE_BASE}`}
          primaryText={LABELS.MENU_TITLE.CREATE_BASE}
          leftIcon={<AddBoxIcon />}
          className={classes.multipleLines}
        />
      )}
      {hasCreateDeliveryEarningsTableWritePermissions && (
        <MenuItemLink
          data-testid={enums.customResources.CREATE_DELIVERY_EARNINGS_TABLE}
          to={`/${enums.customResources.CREATE_DELIVERY_EARNINGS_TABLE}`}
          primaryText={LABELS.MENU_TITLE.CREATE_DELIVERY_EARNINGS_TABLE}
          leftIcon={<GridOnIcon />}
        />
      )}
    </div>
  );
};

CustomMenu.defaultProps = {
  onMenuClick: () => {}
};

CustomMenu.propTypes = {
  resources: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      options: PropTypes.shape({}),
      icon: PropTypes.shape({})
    })
  ).isRequired,
  onMenuClick: PropTypes.func
};

const mapStateToProps = state => ({
  resources: getResources(state)
});

export default withRouter(connect(mapStateToProps)(CustomMenu));
