import {
  isPostalCodeGroupLastMileCompany,
  isLastMileCompanyLeve
} from '../postal-codes-table/utils';

export const getInvalidRanges = (postalCodes, deliveryEarningsTable) => {
  const { earningsInfoByRegions } = deliveryEarningsTable;
  const { ranges } = postalCodes;

  const pricingTableLabels = new Set(
    earningsInfoByRegions.map(earningInfo => earningInfo.label)
  );

  const invalidRanges = ranges.filter(
    range => !pricingTableLabels.has(range.lastMileCompanyInfo.earningsLabel)
  );

  return invalidRanges.length ? invalidRanges : [];
};

export const shouldValidateEarningsLabels = (
  postalCodeGroupInfo,
  postalCodes
) =>
  isPostalCodeGroupLastMileCompany(postalCodeGroupInfo) &&
  isLastMileCompanyLeve(postalCodeGroupInfo) &&
  !!postalCodeGroupInfo.active &&
  !!postalCodes?.ranges?.length;
