import LABELS from '../../../utils/labels';

export const HEADERS = {
  [LABELS.API_ERROR_CODE.ERROR_CODE_EMPTY_POSTAL_CODE_RANGE]: ['Ação'],
  [LABELS.API_ERROR_CODE.ERROR_CODE_TRANSIT_TIME_NOT_FOUND]: [
    'Ação',
    'Origem',
    'Destino'
  ]
};

export const TEXTS = {
  [LABELS.API_ERROR_CODE.ERROR_CODE_EMPTY_POSTAL_CODE_RANGE]: {
    TITLE_ERROR: 'Ranges de CEP vazios',
    DIALOG_DESCRIPTION: `Você precisa cadastrar ao menos um range de CEP. 
      Resolva-os para prosseguir com a modificação.`,
    ACTION: 'Cadastrar Range de CEP'
  },
  [LABELS.API_ERROR_CODE.ERROR_CODE_TRANSIT_TIME_NOT_FOUND]: {
    TITLE_ERROR: 'Tempo de trânsito não encontrado',
    DIALOG_DESCRIPTION: `Você precisa cadastrar um tempo de trânsito para a origem/destino descritas abaixo. 
      Resolva-os para prosseguir com a modificação.`,
    ACTION: 'Cadastrar tempo de trânsito',
    LINK: '/#/transit-time'
  }
};
