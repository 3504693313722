import React from 'react';
import PropTypes from 'prop-types';
import { useInput } from 'react-admin';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import sortBy from 'lodash/sortBy';
import portugueseMessages from '../../../config/portuguese-messages';
import useStyles from './styles';
import { choices } from './constants';

const SelectMultipleInput = props => {
  const {
    optionText,
    optionValue,
    source,
    dataTestId,
    selectedChoices,
    ...rest
  } = props;
  const classes = useStyles(props);

  const {
    input,
    meta: { error, touched },
    isRequired
  } = useInput({
    source,
    ...rest
  });

  const renderMenuItem = choice => (
    <MenuItem key={choice.id} value={choice.id}>
      <div>
        <Checkbox checked={selectedChoices.includes(choice.id)} />
        {choice.name}
      </div>
    </MenuItem>
  );

  return (
    <>
      <Select
        multiple
        variant="outlined"
        margin="dense"
        error={!!(touched && error)}
        renderValue={selected => (
          <div>
            {sortBy(selected)
              .map(item => choices.find(choice => choice.id === item))
              .map(
                (item, index) =>
                  item && (
                    <Chip
                      data-testid={`${dataTestId}-chip-${index}`}
                      key={item.id}
                      label={item.name}
                      className={classes.chip}
                    />
                  )
              )}
          </div>
        )}
        data-testid={dataTestId}
        {...input}
        value={input.value || []}
        helperText={touched && error}
        required={isRequired}
      >
        {choices.map(renderMenuItem)}
      </Select>
      {touched && error && (
        <FormHelperText className={classes.errorMessage}>
          {portugueseMessages.ra.validation.required}
        </FormHelperText>
      )}
    </>
  );
};

SelectMultipleInput.defaultProps = {
  optionText: 'name',
  optionValue: 'id',
  selectedChoices: []
};

SelectMultipleInput.propTypes = {
  choices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string
    })
  ).isRequired,
  optionText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.element
  ]),
  optionValue: PropTypes.string,
  source: PropTypes.string.isRequired,
  dataTestId: PropTypes.string.isRequired,
  selectedChoices: PropTypes.arrayOf(PropTypes.number)
};

export default SelectMultipleInput;
