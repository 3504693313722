const LABELS = {
  PAGE_TITLE: {
    TITLE: 'Exceções de SLO:'
  },
  TITLES: {
    LIST_TITLE: 'Listar Exceções de SLO',
    CREATE_TITLE: 'Criar Exceção de SLO',
    ORIGIN: 'Origem',
    DESTINATION: 'Destino',
    SLO: 'SLO (dias)',
    SLO_OFFSET: 'Offset (dias)',
    SLO_TYPE: 'Tipo da Exceção de SLO',
    SLO_FIELD: 'Valor do SLO ou Offset'
  },
  ERRORS: {
    SLO_OFFSET_IS_ZERO: 'O valor do Offset tem que ser diferente de 0',
    SLO_IS_NEGATIVE: 'O valor do SLO não pode ser negativo'
  },
  FORM_DATA: {
    ORIGIN_SERVICE_REGION_ID_PATH: 'sloMapping.originServiceRegionGroupId',
    ORIGIN_SERVICE_REGION_NAME_PATH:
      'originServiceRegionGroup.serviceRegionInfo.name',
    DESTINATION_SERVICE_TYPE_ID_PATH:
      'sloMapping.destinationServiceTypeGroupId',
    DESTINATION_SERVICE_TYPE_NAME_PATH:
      'destinationServiceTypeGroup.serviceTypeInfo.name',
    SLO_PATH: 'sloMapping.slo',
    SLO_OFFSET_PATH: 'sloMapping.sloOffset'
  }
};

export default LABELS;
