const cutoffHourFromOffset = offset => {
  if (!offset) {
    return '';
  }

  const time = (24 + offset) % 24;
  const hour = Math.floor(time);
  const minutes = Math.round((time - hour) * 60);

  function twoDigits(number) {
    return number.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false
    });
  }

  return `${twoDigits(hour)}:${twoDigits(minutes)} (offset: ${offset.toFixed(
    1
  )})`;
};

export default cutoffHourFromOffset;
