import LABELS from './labels';

const buildDeliveryData = coverageMapping => {
  return {
    [LABELS.TITLES.ORIGIN]: coverageMapping.originServiceRegionName,
    [LABELS.TITLES.CITY]: coverageMapping.destinationCity,
    [LABELS.TITLES.STATE]: coverageMapping.destinationState,
    [LABELS.TITLES.START]: coverageMapping.postalCodeRange.start.code,
    [LABELS.TITLES.END]: coverageMapping.postalCodeRange.end.code,
    [LABELS.TITLES.PRODUCT]: coverageMapping.serviceType,
    [LABELS.TITLES.SLO]: coverageMapping.slo
  };
};

export default {
  buildDeliveryData
};
