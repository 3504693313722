import React from 'react';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';

const CustomDivider = props => {
  const { xs, mt, mb, ml, mr } = props;

  return (
    <Grid container>
      <Grid item xs={xs}>
        <Box mt={mt} mb={mb} ml={ml} mr={mr}>
          <Divider light data-testid="custom-divider" />
        </Box>
      </Grid>
    </Grid>
  );
};

CustomDivider.propTypes = {
  xs: PropTypes.number,
  mt: PropTypes.number,
  mb: PropTypes.number,
  ml: PropTypes.number,
  mr: PropTypes.number
};

CustomDivider.defaultProps = {
  xs: 12,
  mt: 1,
  mb: 3,
  ml: 0,
  mr: 0
};

export default CustomDivider;
