const LABELS = {
  TITLES: {
    NAME: 'Nome do Ponto',
    CITY: 'Cidade',
    STATE: 'UF',
    ADDRESS: 'Endereço',
    NUMBER: 'Número',
    DISTRICT: 'Bairro',
    ADDRESS_COMPLEMENT: 'Complemento',
    ZIP_CODE: 'CEP',
    FULL_ADDRESS: 'Endereço Completo',
    LATITUDE: 'Latitude',
    LONGITUDE: 'Longitude',
    WEEK_OPENED_TIME: 'Abertura(dias de semana)',
    WEEK_CLOSED_TIME: 'Fechamento(dias de semana)',
    WEEKEND_OPENED_TIME: 'Abertura(final de semana)',
    WEEKEND_CLOSED_TIME: 'Fechamento(final de semana)'
  }
};

export default LABELS;
