import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import moment from 'moment';
import { useStyles } from './styles';
import LABELS from './labels';
import DialogScheduleChanges from '../dialog-schedule-changes/dialog-schedule-changes';

const SaveGroupButton = props => {
  const {
    onSubmit,
    scheduledDate,
    changeScheduledDate,
    disabled,
    onScheduled
  } = props;
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const classes = useStyles();
  const [openScheduleDialog, setOpenScheduleDialog] = useState(false);

  const toggleScheduleDialog = () => {
    setOpenScheduleDialog(!openScheduleDialog);
  };

  const handleMenuItemClick = () => {
    setOpen(false);
    toggleScheduleDialog();
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <ButtonGroup variant="contained" ref={anchorRef}>
        <Button color="primary" onClick={onSubmit} disabled={disabled}>
          {LABELS.BUTTON_TITLE}
        </Button>
        <Button
          size="small"
          className={classes.buttonMenu}
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup
          onClick={handleToggle}
          data-testid="expand-menu-button"
          disabled={disabled}
        >
          <KeyboardArrowDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        placement="top"
        disablePortal
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'center bottom'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  <MenuItem
                    key={LABELS.SCHEDULE_BUTTON_TITLE}
                    onClick={handleMenuItemClick}
                  >
                    <ListItemIcon>
                      <EventAvailableIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography>{LABELS.SCHEDULE_BUTTON_TITLE}</Typography>
                    </ListItemText>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <DialogScheduleChanges
        open={openScheduleDialog}
        toggleScheduleDialog={toggleScheduleDialog}
        onSubmit={onScheduled}
        date={scheduledDate}
        changeDate={changeScheduledDate}
      />
    </>
  );
};

SaveGroupButton.defaultProps = {
  disabled: false
};

SaveGroupButton.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onScheduled: PropTypes.func.isRequired,
  scheduledDate: PropTypes.instanceOf(moment).isRequired,
  changeScheduledDate: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

export default SaveGroupButton;
