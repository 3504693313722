import LABELS from './labels';

const formatFullAddress = serviceLocationAddress => {
  return `${serviceLocationAddress.correiosAddress.logradouro} 
    ${serviceLocationAddress.correiosAddress.numero}, 
    ${serviceLocationAddress.correiosAddress.bairro}, 
    ${serviceLocationAddress.correiosAddress.cidade}- 
    ${serviceLocationAddress.correiosAddress.uf}, 
    ${serviceLocationAddress.correiosAddress.cep}`;
};

const buildDeliveryData = serviceLocation => {
  return {
    [LABELS.TITLES.NAME]: serviceLocation.name,
    [LABELS.TITLES.CITY]:
      serviceLocation.serviceLocationAddress.correiosAddress.cidade,
    [LABELS.TITLES.STATE]:
      serviceLocation.serviceLocationAddress.correiosAddress.uf,
    [LABELS.TITLES.ADDRESS]:
      serviceLocation.serviceLocationAddress.correiosAddress.logradouro,
    [LABELS.TITLES.NUMBER]:
      serviceLocation.serviceLocationAddress.correiosAddress.numero,
    [LABELS.TITLES.DISTRICT]:
      serviceLocation.serviceLocationAddress.correiosAddress.bairro || '',
    [LABELS.TITLES.ADDRESS_COMPLEMENT]:
      serviceLocation.serviceLocationAddress.correiosAddress.complemento || '',
    [LABELS.TITLES.ZIP_CODE]:
      serviceLocation.serviceLocationAddress.correiosAddress.cep,
    [LABELS.TITLES.FULL_ADDRESS]: formatFullAddress(
      serviceLocation.serviceLocationAddress
    ),
    [LABELS.TITLES.LATITUDE]:
      serviceLocation.serviceLocationAddress.coordinates.latitude,
    [LABELS.TITLES.LONGITUDE]:
      serviceLocation.serviceLocationAddress.coordinates.longitude,
    [LABELS.TITLES.WEEK_OPENED_TIME]: serviceLocation.workingTime[0].openAt,
    [LABELS.TITLES.WEEK_CLOSED_TIME]: serviceLocation.workingTime[0].closeAt,
    [LABELS.TITLES.WEEKEND_OPENED_TIME]: serviceLocation.workingTime[0].openAt,
    [LABELS.TITLES.WEEKEND_CLOSED_TIME]: serviceLocation.workingTime[0].closeAt
  };
};

export default {
  buildDeliveryData
};
