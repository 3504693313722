import React, { useEffect, useState } from 'react';
import { AutocompleteInput, useNotify } from 'react-admin';
import PropTypes from 'prop-types';
import Api from '../../../api/postal-code-api';
import { ERRORS } from '../../molecules/postal-code-group-autocomplete/constants';
import { isServiceRegionRequest } from '../../../api/api-utils';
import { filterServiceRegionXD, isInvalidEventType, transform } from './utils';

export default function FilterAutocompleteArrayInput(props) {
  const { resourceName, source, label, alwaysOn, dataTestId } = props;

  const notify = useNotify();
  const [options, setOptions] = useState([]);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    const getAllpostalCodeGroups = async () => {
      const args = {
        filter: { name: searchText },
        pagination: { page: 1, perPage: 10 }
      };

      if (isServiceRegionRequest(resourceName)) {
        args.sort = {
          field: 'serviceRegionInfo.serviceRegionType',
          order: 'ASC'
        };
        args.pagination.perPage = 30;
      }

      try {
        const response = await Api.getList(resourceName, args);
        setOptions(
          transform(
            isServiceRegionRequest(resourceName)
              ? filterServiceRegionXD(response.data)
              : response.data
          )
        );
      } catch {
        notify(ERRORS[resourceName], 'warning');
      }
    };
    getAllpostalCodeGroups();
  }, [notify, resourceName, searchText]);

  const handleInputChange = (newInputValue, event) => {
    if (isInvalidEventType(event)) return;
    setSearchText(newInputValue);
  };

  return (
    <AutocompleteInput
      source={source}
      alwaysOn={alwaysOn}
      label={label}
      resettable
      helperText=""
      data-testid={dataTestId}
      variant="outlined"
      choices={options}
      onInputValueChange={handleInputChange}
      inputValue={searchText}
    />
  );
}

FilterAutocompleteArrayInput.defaultProps = {
  label: '',
  alwaysOn: true,
  dataTestId: 'filter-autocomplete-array-input'
};

FilterAutocompleteArrayInput.propTypes = {
  resourceName: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  label: PropTypes.string,
  alwaysOn: PropTypes.bool,
  dataTestId: PropTypes.string
};
