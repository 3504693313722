import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import enums from '../../../enums';
import { buildRegionRangeName } from '../../../api/region-utils';
import {
  buildRange,
  isPostalCodeGroupLastMileCompany
} from '../postal-codes-table/utils';
import { validateRange } from '../postal-codes-table/validations';
import RegionsAutocomplete from '../regions-autocomplete';
import { AUTOCOMPLETE_ACTIONS } from '../regions-autocomplete/constants';
import { LABELS } from './constants';
import { useStyles } from './styles';
import ZipCodeField from './zip-code-field';

const AddPostalCodeRange = props => {
  const {
    onAdd,
    addChange,
    ranges,
    hasWritePermissions,
    postalCodeGroupInfo,
    typeOfRanges,
    showName
  } = props;

  const classes = useStyles();
  const [name, setName] = useState('');
  const [start, setStart] = useState({ code: '' });
  const [end, setEnd] = useState({ code: '' });
  const [slo, setSlo] = useState('');
  const [rangesToAdd, setRangesToAdd] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  const isFilledByRegion = rangesToAdd.length > 0;

  const isLastMileCompany = isPostalCodeGroupLastMileCompany(
    postalCodeGroupInfo
  );

  const clearInputs = () => {
    setErrorMessage('');
    setName('');
    setSlo('');
    setStart({ code: '' });
    setEnd({ code: '' });
    setRangesToAdd([]);
  };

  const handleAddPostalCodeRange = () => {
    let newRanges;

    if (isFilledByRegion) {
      newRanges = rangesToAdd.map(range =>
        buildRange({
          name,
          start: { code: range.start },
          end: { code: range.end },
          slo,
          postalCodeGroupInfo,
          rangeType: typeOfRanges
        })
      );
    } else {
      newRanges = [
        buildRange({
          name: showName
            ? name
            : buildRegionRangeName({ start: start.code, end: end.code }),
          start,
          end,
          slo,
          postalCodeGroupInfo,
          rangeType: typeOfRanges
        })
      ];
    }

    try {
      const options = { postalCodeGroupInfo };
      newRanges.forEach(newRange => {
        validateRange(newRange, ranges, options);
      });
    } catch (error) {
      setErrorMessage(error.message);
      return;
    }

    const newChanges = newRanges.map(newRange => ({
      ...newRange,
      typeChange: LABELS.TEXTS.TYPE_CHANGE
    }));

    onAdd(newRanges);
    addChange(newChanges);

    clearInputs();
  };

  const handleSelectedRegion = (selectedRegion, reason) => {
    setName(selectedRegion?.name);

    if (
      reason === AUTOCOMPLETE_ACTIONS.SELECT_OPTION &&
      selectedRegion?.ranges
    ) {
      setRangesToAdd(selectedRegion.ranges);
    }

    if (reason === AUTOCOMPLETE_ACTIONS.CLEAR) {
      setStart({ code: '' });
      setEnd({ code: '' });
      setRangesToAdd([]);
    }
  };

  const gridSizeOfSubmit = isLastMileCompany ? 2 : 3;

  return (
    <Paper elevation={3}>
      <Grid container fullWidth spacing={2} className={classes.grid}>
        {showName && (
          <Grid item xs>
            <FormControl fullWidth margin="normal">
              <RegionsAutocomplete
                handleSelectedRegion={handleSelectedRegion}
                regionNameToDisplay={name}
              />
            </FormControl>
          </Grid>
        )}
        <Grid item xs>
          <FormControl fullWidth margin="normal">
            <ZipCodeField
              dataTestId="postal-code-start-input"
              name={LABELS.INPUTS.START}
              value={start}
              setType={setStart}
              disabled={isFilledByRegion}
            />
          </FormControl>
        </Grid>
        <Grid item xs>
          <FormControl fullWidth margin="normal">
            <ZipCodeField
              dataTestId="postal-code-end-input"
              name={LABELS.INPUTS.END}
              value={end}
              setType={setEnd}
              disabled={isFilledByRegion}
            />
          </FormControl>
        </Grid>
        {isLastMileCompany && (
          <Grid item xs={1}>
            <FormControl fullWidth margin="normal">
              <TextField
                data-testid="postal-code-slo-input"
                type="number"
                placeholder={LABELS.INPUTS.SLO}
                onChange={event => setSlo(event.target.value)}
                value={slo}
              />
            </FormControl>
          </Grid>
        )}
        <Grid item xs={gridSizeOfSubmit}>
          <FormControl fullWidth margin="normal">
            <Button
              data-testid="postal-code-button-submit"
              color="primary"
              variant="outlined"
              disabled={!hasWritePermissions}
              onClick={handleAddPostalCodeRange}
            >
              {LABELS.BUTTONS.ADD}
            </Button>
            {!!errorMessage && (
              <FormHelperText className={classes.formHelperText} error>
                {errorMessage}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
      </Grid>
    </Paper>
  );
};

AddPostalCodeRange.defaultProps = {
  typeOfRanges: enums.rangeType.RANGE_TYPE_DEFAULT,
  showName: true
};

AddPostalCodeRange.propTypes = {
  onAdd: PropTypes.func.isRequired,
  addChange: PropTypes.func.isRequired,
  ranges: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      start: PropTypes.shape({
        code: PropTypes.string
      }),
      end: PropTypes.shape({
        code: PropTypes.string
      }),
      lastMileCompanyInfo: PropTypes.shape({
        slo: PropTypes.number
      }),
      rangeType: PropTypes.string
    })
  ).isRequired,
  hasWritePermissions: PropTypes.bool.isRequired,
  postalCodeGroupInfo: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.number,
    resourceName: PropTypes.string,
    groupType: PropTypes.string
  }).isRequired,
  typeOfRanges: PropTypes.string,
  showName: PropTypes.bool
};

export default AddPostalCodeRange;
