import React from 'react';
import { useNotify } from 'react-admin';
import { useForm } from 'react-final-form';
import debounce from 'lodash/debounce';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import Api from '../../../api/postal-code-api';
import { SERVICE_REGION } from '../../pages/_react-admin/service-type/constants';
import { ERRORS, LABELS } from './constants';

const MultipleServiceRegionGroupAutocomplete = props => {
  const { outputIdsField, required, label, dataTestId } = props;

  const styles = {
    autoCompleteContainer: {
      marginTop: '8px',
      marginBottom: '26px'
    }
  };

  const form = useForm();
  const notify = useNotify();
  const [
    serviceRegionGroupOptions,
    setServiceRegionGroupOptions
  ] = React.useState([]);
  const [
    selectedServiceRegionGroups,
    setSelectedServiceRegionGroups
  ] = React.useState([]);

  const getAllServiceRegionGroups = async searchText => {
    try {
      const response = await Api.getList(SERVICE_REGION.resourceName, {
        filter: { name: searchText },
        pagination: { page: 1, perPage: 30 }
      });

      return response.data || [];
    } catch {
      notify(ERRORS.FAILED_TO_FETCH, 'warning');
    }

    return [];
  };

  const updateOptionsDebounced = debounce(async inputValue => {
    const serviceRegionGroups = await getAllServiceRegionGroups(inputValue);
    setServiceRegionGroupOptions(serviceRegionGroups);
  }, 500);

  const handleOnChange = (event, selectedValues) => {
    const uniqueSelectedValues = Array.from(
      new Map(selectedValues.map(value => [value.id, value])).values()
    );

    const serviceRegionGroupIds = uniqueSelectedValues.map(value => value.id);

    form.change(outputIdsField, serviceRegionGroupIds);
    setSelectedServiceRegionGroups(uniqueSelectedValues);
  };

  React.useEffect(() => {
    const unsubscribe = form.subscribe(
      ({ values }) => {
        const fieldValue = values[outputIdsField];
        const options = values.serviceRegionGroupOptions || [];
        if (fieldValue && Array.isArray(fieldValue)) {
          const selectedGroups = options.filter(option =>
            fieldValue.includes(option.id)
          );
          setSelectedServiceRegionGroups(selectedGroups);
        }
        setServiceRegionGroupOptions(options);
      },
      { values: true }
    );
    return unsubscribe;
  }, [form, outputIdsField]);

  return (
    <div style={styles.autoCompleteContainer}>
      <Autocomplete
        multiple
        freeSolo
        clearOnBlur
        value={selectedServiceRegionGroups}
        getOptionLabel={option => option.name}
        options={serviceRegionGroupOptions}
        onInputChange={(_, newInputValue) =>
          updateOptionsDebounced(newInputValue)
        }
        onChange={handleOnChange}
        renderInput={params => (
          <TextField
            {...params}
            data-testid={dataTestId}
            label={label}
            required={required}
            helperText={LABELS.required}
            variant="outlined"
            size="small"
          />
        )}
      />
    </div>
  );
};

MultipleServiceRegionGroupAutocomplete.defaultProps = {
  required: false,
  dataTestId: 'service-region-group-autocomplete',
  outputIdsField: LABELS.defaultOutputIdsField
};

MultipleServiceRegionGroupAutocomplete.propTypes = {
  outputIdsField: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string.isRequired,
  dataTestId: PropTypes.string
};

export default MultipleServiceRegionGroupAutocomplete;
