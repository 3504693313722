import React from 'react';
import ReactDOM from 'react-dom';
import Amplify from 'aws-amplify';
import Home from './view';
import { firebaseInit } from './firebase';
import awsConfig from './config/aws-exports';
import './config/tag-manager-init';

Amplify.configure(awsConfig);

const App = () => {
  return <Home />;
};

firebaseInit();

ReactDOM.render(<App />, document.getElementById('root'));
