import React from 'react';
import {
  Edit,
  SimpleForm,
  NumberInput,
  TextInput,
  required,
  minValue
} from 'react-admin';
import PropTypes from 'prop-types';
import LABELS from './labels';
import enums from '../../../../enums';
import SimpleToolbar from '../../../atoms/simple-toolbar';
import permissionsFor from '../../../../access/access';

const MakeTitle = props => {
  return `${LABELS.PAGE_TITLE.TITLE} ${props?.record?.originServiceRegionGroup
    ?.serviceRegionInfo?.name || ''} -> ${props?.record
    ?.destinationServiceRegionGroup?.serviceRegionInfo?.name || ''}`;
};

export default function TransitTimeEdit(props) {
  const { permissions } = props;
  const canUpdate = permissionsFor(enums.resources.TRANSIT_TIME)
    .hasRole(permissions)
    .forAction(enums.actions.UPDATE);

  return (
    <Edit {...props} undoable={false} title={<MakeTitle />}>
      <SimpleForm
        warnWhenUnsavedChanges
        variant="outlined"
        toolbar={<SimpleToolbar disabled={!canUpdate} />}
      >
        <TextInput
          label={LABELS.TITLES.ORIGIN}
          source={LABELS.FORM_DATA.ORIGIN_SERVICE_REGION_NAME_PATH}
          disabled
          fullWidth
        />
        <TextInput
          label={LABELS.TITLES.DESTINATION}
          source={LABELS.FORM_DATA.DESTINATION_SERVICE_REGION_NAME_PATH}
          disabled
          fullWidth
        />
        <NumberInput
          label={LABELS.TITLES.TT}
          source="sloMapping.slo"
          data-testid="slo-mapping-slo"
          disabled={!canUpdate}
          validate={[required(), minValue(0)]}
        />
      </SimpleForm>
    </Edit>
  );
}

TransitTimeEdit.defaultProps = {
  permissions: []
};

TransitTimeEdit.propTypes = {
  id: PropTypes.string.isRequired,
  permissions: PropTypes.arrayOf(PropTypes.string)
};
