import React, { useState } from 'react';
import {
  Create,
  FileField,
  FileInput,
  SimpleForm,
  TextInput,
  useRefresh,
  usePermissions,
  required
} from 'react-admin';
import { useHistory } from 'react-router-dom';
import FormControl from '@material-ui/core/FormControl';
import Alert from '@material-ui/lab/Alert';

import enums from '../../../../enums';
import LABELS from './labels';
import PageTitle from '../../../molecules/page-title';
import SimpleToolbar from '../../../atoms/simple-toolbar';
import { isAuthorizedWithPermission } from '../../../../auth-provider';

export default function ReAnnotationPromisedDate(props) {
  const history = useHistory();
  const refresh = useRefresh();
  const [requested, setRequested] = useState(false);

  // Check for user's permission
  const { loaded, permissions } = usePermissions();
  const authorized = isAuthorizedWithPermission(
    permissions,
    enums.customActions.RE_ANNOTATION_WRITE
  );
  if (loaded && !authorized) {
    history.push(`/${enums.resources.LAST_MILE_COMPANY}`);
    return <></>;
  }

  const properties = {
    basePath: `/${enums.resources.RE_ANNOTATION_PROMISED_DATE}`,
    resource: enums.resources.RE_ANNOTATION_PROMISED_DATE,
    title: LABELS.HEADINGS.TITLE
  };

  const labelStyle = {
    color: '#757D8C',
    padding: '0',
    'font-size': '0.9rem',
    'font-family': 'Open Sans',
    'font-weight': '400',
    'line-height': '1'
  };

  const onSuccess = () => {
    refresh();
    setRequested(true);
  };

  const onSubmit = () => {
    refresh();
    setRequested(false);
  };

  const customProps = { ...props, ...properties };
  return (
    <Create {...customProps} undoable={false} onSuccess={onSuccess}>
      <SimpleForm
        variant="outlined"
        redirect="/re-annotation-promised-date"
        toolbar={<SimpleToolbar handleSubmit={onSubmit} />}
      >
        <PageTitle title={LABELS.HEADINGS.TITLE} />

        <span style={labelStyle}>{LABELS.ELEMENTS.ALERT}</span>
        <FileInput
          source="action.attachment"
          label={LABELS.HEADINGS.SUB}
          validate={required()}
          accept=".csv"
        >
          <FileField source="action.src" title="title" />
        </FileInput>

        <FormControl fullWidth>
          <TextInput
            label={LABELS.ELEMENTS.INPUT_REASON}
            source="reAnnotation.annotationReason"
            data-testid="re-annotation-reason"
            variant="outlined"
            validate={required()}
            multiline
          />
        </FormControl>
        {requested && (
          <Alert data-testid="alert-success" severity="success" fullWidth>
            {LABELS.ALERTS.SUCCESS}
          </Alert>
        )}
      </SimpleForm>
    </Create>
  );
}
