import enums from '../../enums';

const LABELS = {
  TITLES: {
    CSV_NAME: 'planilha-de-abrangência'
  },
  RANGE_TYPE: {
    [enums.rangeType.RANGE_TYPE_PICKUP]: 'coleta',
    [enums.rangeType.RANGE_TYPE_DELIVERY]: 'entrega',
    [enums.firstMileServiceType.FIRST_MILE_SERVICE_TYPE_DROPOFF]: 'dropoff'
  }
};

export default LABELS;
