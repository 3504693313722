import moment from '../../../utils/moment';
import { LABELS } from './constants';

export const validateDate = fieldValue => {
  return fieldValue !== null &&
    fieldValue !== undefined &&
    !moment.unix(fieldValue).isValid()
    ? LABELS.INVALID_DATE_MESSAGE
    : '';
};

export default { validateDate };
