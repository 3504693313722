import React from 'react';
import PropTypes from 'prop-types';
import { TextInput, required, minLength, useNotify } from 'react-admin';
import NumberFormat from 'react-number-format';
import { useForm } from 'react-final-form';
import Api from '../../../api/postal-code-api';
import { memoize } from '../../../utils/utils';
import LABELS from './labels';

const getPostalCodeInfo = memoize(async field => {
  const endpoint = `${
    process.env.REACT_APP_ANNOTATOR_URL
  }/api/v1/postalcode/info/${field}`;

  try {
    return await Api.fetchOrThrow(endpoint);
  } catch (e) {
    return null;
  }
});

function NumberFormatCustom(props) {
  const { onChange, inputRef, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value
          }
        });
      }}
      format="#####-###"
    />
  );
}

const validatePostalCode = [required(), minLength(8)];

const PostalCodeInput = props => {
  const { label, source, dataTestId, variant } = props;
  const form = useForm();
  const notify = useNotify();

  const handleChange = async event => {
    const postalCode = event.target.value;
    if (postalCode && postalCode.length === 8) {
      const postalCodeInfo = await getPostalCodeInfo(postalCode);

      if (!postalCodeInfo?.info) {
        notify(LABELS.VALIDATION_MESSAGES.FETCH_ERROR, 'warning');
        return;
      }

      form.change(
        'address.address_components.street',
        postalCodeInfo.info.name
      );
      form.change(
        'address.address_components.neighborhood',
        postalCodeInfo.info.neighborhood
      );
      form.change('address.address_components.city', postalCodeInfo.info.city);
      form.change(
        'address.address_components.state',
        postalCodeInfo.info.state
      );
    }
  };

  return (
    <TextInput
      label={label}
      source={source}
      data-testid={dataTestId}
      validate={validatePostalCode}
      onChange={handleChange}
      InputProps={{
        inputComponent: NumberFormatCustom
      }}
      variant={variant}
    />
  );
};

NumberFormatCustom.propTypes = {
  onChange: PropTypes.func.isRequired,
  inputRef: PropTypes.func.isRequired
};

PostalCodeInput.defaultProps = {
  variant: 'outlined'
};

PostalCodeInput.propTypes = {
  label: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  dataTestId: PropTypes.string.isRequired,
  variant: PropTypes.string
};

export default PostalCodeInput;
