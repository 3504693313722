const LABELS = {
  PAGE_TITLE: {
    TITLE: 'Tempo de Trânsito:'
  },
  TITLES: {
    LIST_TITLE: 'Listar Tempos de Trânsito',
    CREATE_TITLE: 'Criar Tempo de Trânsito',
    ORIGIN: 'Origem',
    DESTINATION: 'Destino',
    TT: 'Tempo de Trânsito (Dias)'
  },
  FORM_DATA: {
    ORIGIN_SERVICE_REGION_ID_PATH: 'sloMapping.originServiceRegionGroupId',
    ORIGIN_SERVICE_REGION_NAME_PATH:
      'originServiceRegionGroup.serviceRegionInfo.name',
    DESTINATION_SERVICE_REGION_ID_PATH:
      'sloMapping.destinationServiceRegionGroupId',
    DESTINATION_SERVICE_REGION_NAME_PATH:
      'destinationServiceRegionGroup.serviceRegionInfo.name'
  }
};

export default LABELS;
