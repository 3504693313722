import React from 'react';
import { TextInput, Filter } from 'react-admin';
import LABELS from './labels';

const DistributionCenterFilter = props => {
  return (
    <Filter {...props} variant="outlined">
      <TextInput
        source="name"
        alwaysOn
        label={LABELS.FILTER_BASE}
        resettable
        data-testid="name-base-filter"
      />
    </Filter>
  );
};

export default DistributionCenterFilter;
