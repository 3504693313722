import { required } from 'react-admin';
import LABELS from './labels';

const isSloType = type => type === LABELS.FORM_DATA.SLO_PATH;

const sloValueValidation = (value, allValues) => {
  const isSlo = isSloType(allValues.sloType);

  if (isSlo && value < 0) return LABELS.ERRORS.SLO_IS_NEGATIVE;
  if (!isSlo && value === 0) return LABELS.ERRORS.SLO_OFFSET_IS_ZERO;

  return undefined;
};

const sloValueValidator = [required(), sloValueValidation];

const requiredFieldValidator = [required()];

const validateSloExceptionCreate = sloMapping => {
  const errors = {};

  if (!sloMapping.sloMapping?.originServiceRegionGroupId) {
    errors.sloMapping = {
      originServiceRegionGroupId: 'ra.validation.required'
    };
  }

  if (!sloMapping.sloMapping?.destinationServiceTypeGroupId) {
    errors.sloMapping = {
      destinationServiceTypeGroupId: 'ra.validation.required'
    };
  }

  return errors;
};

export {
  isSloType,
  sloValueValidator,
  requiredFieldValidator,
  validateSloExceptionCreate
};
