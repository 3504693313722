import camelcaseKeys from 'camelcase-keys';
import queryString from 'query-string';
import { fetchOrThrow } from './api';
import { DEFAULT_INITIAL_PAGE, DEFAULT_DOCUMENTS_PER_PAGE } from './constants';

const ENDPOINT_URL = `${
  process.env.REACT_APP_ANNOTATOR_URL
}/api/v1/distribution-center`;

const getList = async args => {
  const {
    pagination = {
      page: DEFAULT_INITIAL_PAGE,
      perPage: DEFAULT_DOCUMENTS_PER_PAGE
    },
    filter = {}
  } = args;

  const params = {
    page: pagination.page,
    'results-per-page': pagination.perPage
  };

  if (filter.name) {
    params.search = filter.name;
  }

  const filters = queryString.stringify(params, { sort: false });
  const response = await fetchOrThrow(`${ENDPOINT_URL}?${filters}`);
  const {
    distributionCenters = [],
    total = distributionCenters.length
  } = response;

  return {
    data: distributionCenters,
    total
  };
};

const getOne = async id => {
  const endpoint = `${ENDPOINT_URL}/${id}`;
  const response = await fetchOrThrow(endpoint);
  const camelCaseResponse = camelcaseKeys(response, { deep: true });

  return {
    data: { id, ...camelCaseResponse }
  };
};

const updateDeadlineOffset = async args => {
  const { id, data } = args;

  const endpoint = `${ENDPOINT_URL}/${id}`;

  const response = await fetchOrThrow(endpoint, {
    method: 'PATCH',
    body: JSON.stringify({
      confirmation_deadline_offset: data.confirmationDeadlineOffset
    })
  });

  return { data: { id, ...response } };
};

export default {
  getList,
  getOne,
  updateDeadlineOffset
};
