import React from 'react';
import { usePermissions } from 'react-admin';
import { useHistory } from 'react-router-dom';
import SignIn from './signin';
import enums from '../../../enums';

const Login = () => {
  const { loaded, permissions } = usePermissions();
  const history = useHistory();

  if (loaded && permissions) {
    history.push(`/${enums.resources.LAST_MILE_COMPANY}`);
  }

  return <SignIn />;
};

export default Login;
