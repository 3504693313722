import { required, minLength } from 'react-admin';

const validateServiceTypeInputs = {
  name: [required()],
  region: [required()],
  type: [required()],
  cityType: [required()],
  slo: [required(), minLength(0)]
};

export default validateServiceTypeInputs;
