import React from 'react';
import PropTypes from 'prop-types';

import { downloadCSV } from 'react-admin';

import jsonExport from 'jsonexport/dist';

import Button from '@material-ui/core/Button';
import SaveAltIcon from '@material-ui/icons/SaveAlt';

import Api from '../../../api/postal-code-api';

import LABELS from './labels';

const ExportButton = props => {
  const handleClick = async () => {
    const {
      resource,
      record: { name, id }
    } = props;

    const response = await Api.getOne(resource, { id });

    const { ranges } = response.data.postalCodes;
    const rangesForExport = ranges.map(range => {
      return {
        [LABELS.TITLES.NAME]: range.name,
        [LABELS.TITLES.START]: range.start.code,
        [LABELS.TITLES.END]: range.end.code
      };
    });

    jsonExport(
      rangesForExport,
      {
        headers: [LABELS.TITLES.NAME, LABELS.TITLES.START, LABELS.TITLES.END]
      },
      (_, csv) => {
        downloadCSV(csv, name);
      }
    );
  };

  return (
    <Button
      size="small"
      color="primary"
      startIcon={<SaveAltIcon />}
      onClick={handleClick}
    >
      {LABELS.TITLES.DOWNLOAD}
    </Button>
  );
};

ExportButton.propTypes = {
  resource: PropTypes.string.isRequired,
  record: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string
  }).isRequired
};

export default ExportButton;
