import { LABELS } from './constants';
import {
  getRangeKey,
  isPostalCodeGroupLastMileCompany,
  isLastMileCompanyLeve
} from './utils';

export const hasOverlap = (newRange, ranges, editingRangeKey) => {
  return ranges.some(range => {
    if (
      editingRangeKey === getRangeKey(range) ||
      newRange.rangeType !== range.rangeType
    ) {
      return false;
    }

    return (
      (newRange.start.code >= range.start.code &&
        newRange.start.code <= range.end.code) ||
      (newRange.end.code >= range.start.code &&
        newRange.end.code <= range.end.code) ||
      (range.start.code >= newRange.start.code &&
        range.start.code <= newRange.end.code) ||
      (range.end.code >= newRange.start.code &&
        range.end.code <= newRange.end.code)
    );
  });
};

const postalCodeGroupShouldHaveEarningsLabel = (
  editingRangeKey,
  postalCodeGroupInfo
) =>
  editingRangeKey &&
  isPostalCodeGroupLastMileCompany(postalCodeGroupInfo) &&
  isLastMileCompanyLeve(postalCodeGroupInfo) &&
  postalCodeGroupInfo.active;

export const validateRange = (newRange, ranges, options) => {
  const { postalCodeGroupInfo, editingRangeKey } = options;

  if (!newRange.name.length) {
    throw Error(LABELS.ERRORS.NAME_IS_EMPTY);
  }

  if (newRange.start.code.length !== 8 || newRange.end.code.length !== 8) {
    throw Error(LABELS.ERRORS.CEP_LENGTH);
  }

  if (newRange.start.code > newRange.end.code) {
    throw Error(LABELS.ERRORS.START_GT_END);
  }

  const earningsLabelInputValue = newRange?.lastMileCompanyInfo?.earningsLabel;
  if (
    postalCodeGroupShouldHaveEarningsLabel(
      editingRangeKey,
      postalCodeGroupInfo
    ) &&
    !earningsLabelInputValue
  ) {
    throw Error(LABELS.ERRORS.EARNING_LABEL_NOT_FOUND);
  }

  if (hasOverlap(newRange, ranges, editingRangeKey)) {
    throw Error(LABELS.ERRORS.RANGE_INTERSECTION);
  }
};
