import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Edit,
  SimpleForm,
  useQuery,
  FormDataConsumer,
  useNotify,
  useShowController
} from 'react-admin';
import LABELS from './labels';
import enums from '../../../../enums';
import { getPostalCodeGroupInfo } from '../../../molecules/postal-codes-table/utils';
import SaveWithConfirmToolbar from '../../../molecules/save-with-confirm-toolbar';
import permissionsFor from '../../../../access/access';
import { handleFailure, transform } from './utils';
import LastMileCompanyForm from './last-mile-company-form';
import {
  isVersioned,
  shouldShowDeleteButton
} from '../../../../utils/versioning';
import TopBar from '../../../organisms/top-bar/top-bar';
import PageTitle from '../../../molecules/page-title';

export default function LastMileCompanyEdit(props) {
  const { id, permissions } = props;
  const { record } = useShowController(props);
  const canUpdate = permissionsFor(enums.resources.LAST_MILE_COMPANY)
    .hasRole(permissions)
    .forAction(enums.actions.UPDATE);

  const canUpdateLocalSlo = permissionsFor(enums.resources.LAST_MILE_COMPANY)
    .hasRole(permissions)
    .forAction(enums.customActions.UPDATE_LOCAL_SLO);

  const { loading, data: { lastMileCompanyInfo } = {} } = useQuery({
    type: 'getOne',
    resource: 'last-mile-company',
    payload: { id }
  });

  const notify = useNotify();

  const [errorRanges, setErrorRanges] = useState([]);
  const [conflictMessage, setConflictMessage] = useState();

  const pageTitle = `${LABELS.PAGE_TITLE.TITLE} ${lastMileCompanyInfo?.name ||
    ''}`;

  const onFailure = err =>
    handleFailure({ err, setErrorRanges, setConflictMessage, notify });

  const handleCloseDialog = () => {
    setErrorRanges([]);
  };

  return (
    <Edit
      undoable={false}
      {...props}
      title={pageTitle}
      onFailure={onFailure}
      transform={transform}
    >
      <SimpleForm
        warnWhenUnsavedChanges
        variant="outlined"
        toolbar={
          <FormDataConsumer>
            {({ handleSubmit, formData, ...rest }) => {
              const postalCodeGroupInfo = getPostalCodeGroupInfo(formData);
              return (
                <SaveWithConfirmToolbar
                  {...rest}
                  handleSubmit={handleSubmit}
                  hasWritePermissions={canUpdate}
                  postalCodeGroupInfo={postalCodeGroupInfo}
                  changes={formData.changes}
                  loading={loading}
                  postalCodes={formData.postalCodes}
                  deliveryEarningsTable={
                    formData?.lastMileCompanyInfo?.deliveryEarningsTable
                  }
                  isVersioned={isVersioned(formData?.versioningStatus)}
                  showDeleteButton={shouldShowDeleteButton(
                    record?.versioningStatus
                  )}
                />
              );
            }}
          </FormDataConsumer>
        }
      >
        {record && (
          <>
            <TopBar id={record.id} referenceId={record.referenceId} />
          </>
        )}

        <PageTitle
          title={LABELS.PAGE_TITLE.TITLE}
          updatedAtTimeAsInt={record?.updatedAtTimeAsInt}
          versioningStatusType={record?.versioningStatus}
          effectiveOnTimestamp={record?.effectiveOnTimeAsInt}
          expiredAtTimestamp={record?.expiredAtTimeAsInt}
          data-testid="last-mile-company-page-title"
        />
        <FormDataConsumer>
          {({ formData }) => {
            return (
              <LastMileCompanyForm
                hasPermissions={canUpdate}
                formData={formData}
                errorRanges={errorRanges}
                handleCloseDialog={handleCloseDialog}
                conflictMessage={conflictMessage}
                pageType={enums.pageType.EDIT}
                canUpdateLocalSlo={canUpdateLocalSlo}
              />
            );
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
}

LastMileCompanyEdit.defaultProps = {
  permissions: []
};

LastMileCompanyEdit.propTypes = {
  id: PropTypes.string.isRequired,
  permissions: PropTypes.arrayOf(PropTypes.string)
};
