import React from 'react';
import { useLogin } from 'react-admin';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { ReactComponent as GoogleIcon } from '../../../../assets/icons/ic-google.svg';
import { useStyles } from './styles';
import LABELS from '../labels';

const SignInForm = () => {
  const login = useLogin();
  const classes = useStyles();

  return (
    <Grid className={classes.box}>
      <Box marginBottom={3.5}>
        <Typography variant="h4" component="h1" data-testid="google-title-form">
          {LABELS.TITLE_FORM}
        </Typography>
      </Box>
      <Box>
        <form>
          <Box mt={3.5} textAlign="center">
            <Grid item xs={12}>
              <Box boxShadow={15} borderRadius={23} mt={3.5}>
                <Button
                  variant="text"
                  color="default"
                  fullWidth
                  onClick={login}
                >
                  <GoogleIcon /> {LABELS.GOOGLE}
                </Button>
              </Box>
            </Grid>
          </Box>
        </form>
      </Box>
    </Grid>
  );
};

export default SignInForm;
